import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import MyLabel from "../../myLabel";
import SocialIcon from '../socialIcons'
import MyButton from '../../myButton';


export const style = {
    card:{
        height: '250px',
        display:'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '160px',
        padding: 0,
        margin: 0,

        boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
        border:'1px solid rgba(0, 0, 0, 0.36)',
        '@media (max-width: 600px)': {
            maxWidth: '100%',
            maxHeight: '100%',
            
        }
    },
    titleArea: {
        padding: 0,
        margin: 0,
    
    },
    title: {
        padding: 0,
        margin: 0,
    },
    linked:{
        backgroundColor: 'rgb(45, 206, 137)',
        color: '#fff',
        padding: 0,
        margin: 0,
        paddingRight: '.6em',
        paddingLeft: '.6em',
        borderRadius: '10rem',
        fontWeight: 500,
    },
    unlink: {
        color:'#FFF',
        backgroundColor: '#e63306',
        ':hover': {
            backgroundColor: '#fa0000',
        }
    },
    notLinked:{
        color: '#adb5bd',
        border: '1px solid #adb5bd',
        padding: 0,
        margin: 0,
        paddingRight: '.6em',
        paddingLeft: '.6em',
        borderRadius: '10rem',
        cursor: 'pointer',
        fontWeight: 500,

    },
    iconArea:{
        display:'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '140px',
    },
     icon:{
        width: '70px',
        height: '70px',
    },
    topArea: {
        marginTop: '50px',
        display:'flex',
        flexDirection:'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: '160px',
        borderBottom: '1px solid #adb5bd',
        width: '100%',
        height: '90px',
    }
}


const ChannelPanel = (props) => {

    let title = props.channel.title;

    if (title === 'facebook'){
        title = 'facebook pages'
    } else if (title === 'fbg'){
        title = 'facebook groups'
    }

    let linked = props.socials && props.socials?.includes(props.channel.title);
    return (
        
    <Card sx={style.card}>
        <CardContent sx={{padding:0, margin: 0, display: 'flex', flexDirection:'column', justifyContent:'flex-start'}}>
            <Box sx={style.topArea}>
                <Box sx={style.titleArea}>
                    {
                        linked ?
                        <Box>
                            <MyLabel component='p' text='linked' sx={style.linked}  />
                            {
                                linked ?
                                <Box sx={{display:'flex', justifyContent:'center', marginTop:'5px'}}>
                                    <MyButton size='small' component='p' text='unlink' sx={style.unlink}  />
                                </Box>
                                : null
                            }
                        </Box>
                        : 
                        <Box>
                            <MyLabel component='p' text='click to link' sx={style.notLinked}  />
                        </Box>
                            
                    }
                </Box>
                <Box sx={{...style.titleArea, marginTop:'5px'}}>
                    <MyLabel component='p' text={title} sx={style.title}  />
                </Box>
            </Box>
            <Box sx={style.iconArea}>
                <SocialIcon sx={style.icon} icon={props.channel.icon} linked={linked} />
            </Box>
            <Box sx={{height:'20px', display:'flex', justifyContent: 'center', alignItems: 'center',}}>
                
            </Box>

        </CardContent>
    </Card>

      );


};

export default ChannelPanel;
