import React, { useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { AuthContext } from "../../contexts/auth";
import MyLabel from "../../components/myLabel";
import MyImage from "../../components/myImage";
import iconMaior from "../../assets/imgs/maior.svg";

import usePanelInformationState from "../../hooks/usePanelInformationState";
import useUserDetailState from "./../../hooks/useUserDetailState";
import toast from "react-hot-toast";

import { style } from "./styles";
import { Collapse } from "@mui/material";
import ErrorMessage from "../errorMessage";

const PanelInformation = ({ popup }) => {
  const { updatePanel } = useContext(AuthContext);

  const { user, updateUserDetail } = usePanelInformationState(popup);
  const userState = useUserDetailState();
  
  const [openHist, setOpenHist] = useState(true);

  const update = () => {
    updateUserDetail.set();
    if (!userState.get.value) {
      userState.get.set();
    }
  };

  useEffect(() => {
    update();
  }, []);

  useEffect(() => {
    if (updatePanel.value) {
      update();
      updatePanel.set(false);
    }
  }, [updatePanel.value]);

  return (
    <>
      <ErrorMessage />
      <Paper elevation={1} sx={style.paperHome}>
        <Collapse orientation="vertical" in={openHist} style={{width:'100%'}} >
          <Box sx={style.area1}>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Box sx={style.titleArea}>
                <MyLabel component="h2" text="Welcome" sx={style.title} />
                <h2
                  style={{
                    padding: "0px",
                    fontSize: "12pt",
                    fontWeight: "700",
                    fontFamily: "Proxima ,Montserrat bold, sans-serif",
                  }}
                >
                  {`${user.short_name},`}
                </h2>
              </Box>
              {
                user.in_update ? 
                  <Box 
                    sx={{
                      padding: '5px',
                      color: '#856404',
                      fontSize: '13pt',
                      boxShadow: 'rgba(0, 0, 0, 0.36) 10px 7px 10px 0px',
                      background: '#fff3cd',
                      border: '1px solid #f5dfc6',
                      textAlign: 'center',
                    }}
                    >
                    <MyLabel component="h3" text="Your user is being updated." />
                    <MyLabel component="h3" text="Wait a few minutes." />
                  </Box> : null
              }
            </Box>
            <Box>
              <MyLabel component="p" text="Month Usage" sx={style.usage} />
            </Box>
            <Box>
              <Box sx={style.informationArea}>
                <MyLabel
                  component="p"
                  text="Characters generated"
                  sx={style.information}
                />
                <p style={style.information}>{user.tokens_used} / <strong>{user.tokens}</strong></p>
              </Box>
              <Box sx={style.informationArea}>
                <MyLabel
                  component="p"
                  text="Images Generated"
                  sx={style.information}
                />
                <p style={style.information}>{user.images_used} / <strong>{user.images}</strong></p>
              </Box>
            </Box>
          </Box>
        </Collapse>
        <Box sx={style.area2}>
          <MyImage onClick={() => setOpenHist(!openHist)} src={iconMaior} alt={'icon'} sx={{height:'18px', transform: openHist ? 'rotate(-90deg)' : 'rotate(90deg)', cursor:'pointer'}} />
         
        </Box>
      </Paper>
    </>
  );
};
export default PanelInformation;