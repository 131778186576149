import { createTheme } from '@mui/material/styles';

export const themeOptions = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#721384',
      light: '#de4394',
      dark: '#9061ef',
    },
    secondary: {
      main: '#c7d7e2',
      light: '#bec6d1',
      dark: '#718d98',
    },
    background: {
      default: '#f2f2f2',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  },
});

