import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import Box from '@mui/material/Box';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MyLabel from "../../myLabel";
import MyButton from "../../myButton";
import MyInput from '../../myInput';
import CustonSelect from './custonSelect'
import ChannelPanel from './channelPanel'
import { AuthContext } from "../../../contexts/auth";
import Loading from "../../loading";
import useProfileState from "../../../hooks/ayrshare/useProfileState"
import MyPanelHelp from "../../myPanelHelp";


import CHANNELS from "../channels"

import MessageDialogEdit from "./messageDialogEdit";
import MessageDialog from "./messageDialog";
import { style } from "./styles";

const Profile = () => {

    let intl = useIntl();
    const { user } = useContext(AuthContext);
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogEditOpen, setDialogEditOpen] = useState(false)
    

    const { name, profiles, profileSelected, update, connect, submit, url, socials, put } = useProfileState();
    
    const [help, setHelp] = useState({
        title: 'Step One: Create your first account group',
        type: 1,
        open: profiles.value.length >= 1,
        top: 200,
        left: 500
    });

    let navigate = useNavigate();
    useEffect(() => {
        update.set()
    }, []);


    useEffect(() => {
        if (profileSelected.value && profileSelected.value > 0){
            connect.set()
        }
    }, [profileSelected.value]);

    const defText1 = () =>{
        let text = "With your plan you can't integrate Digital Channels"
        if (user.subscription_name === 'PRO Light'){
            text = "With your plan you can't integrate Digital Channels"
        }
        else if (user.subscription_name === 'PRO Max'){
            text = "With your plan, you can integrate up to three accounts"
        }
        else if (user.subscription_name === 'PRO Plus'){
            text = "With your plan, you can integrate up to one account"
        }

        return text
    }

    const defText2 = () =>{
        let text = "Upgrade Your Plan to Include Digital Channels"
        if (user.subscription_name === 'PRO Light'){
            text = "Upgrade Your Plan to Include Digital Channels"
        }
        else if (user.subscription_name === 'PRO Max'){
            text = " "
        }
        else if (user.subscription_name === 'PRO Plus'){
            text = "Upgrade your plan to PRO MAX to include more accounts"
        }
        return text
    }

    const handlerPost = () =>{
        navigate('/posts');
    }

    const handlerClickConnect = () => {

        if (profiles.value && profiles.value !== ''){
            window.open(url.value, '_blank');
        }
        else {
            
        }
    }

    const renderHelp = () => {

        if (help.type === 1){
            return (
                <Box>
                    <MyLabel component='p' text="This will serve as an internal label to help you organize your integrated social media channels." />
                    <br />
                    <MyLabel component='p' text="Don’t worry, the name of this group won’t be visible externally. You’re free to name it whatever helps you best manage your social platforms! Once you’ve created your account group, it’s time to connect your social media." />
                </Box>
            )
        }
        else if (help.type === 2){
            return (
                <Box>
                    <Box>
                    <p>
                        {intl.formatMessage({ id: "Click"})} {' '}
                        <span style={{fontWeight:'bold'}}>"{intl.formatMessage({ id: "Connect your Channels" })}"</span> 
                        {' '}
                        {intl.formatMessage({ id: "and follow the prompts."})}
                    </p>
                    <MyLabel component='p' text="Link all or specific platforms as per your needs." />
                    <br />
                    <MyLabel component='p' text="Remember, you can always edit these settings later." />
                    <br />
                    <MyLabel component='p' text="Ready to streamline your social media strategy? Let's go!" />
                </Box>
                </Box>
            )
        }
        return <></>

      }
    
      const handlerHelp = (type) => {
        let h = {...help}
        if (type === 1){
            h.title = 'Step One: Create your first account group'
            h.open = true
            h.top = 200
            h.left = 500
        }
        else if (type === 2){
            h.title = 'Step Two: Connect Your Social Media'
            h.open = true
            h.top = 200
            h.left = 720
        }
        else if (type === 3){
            h.title = 'Preserve your progress with "Save Chat"'
            h.open = true
            h.top = 200
            h.left = 860
        }

        h.type = type
        h.open = true
        setHelp(h)
      }

    return (
        <Box sx={style.stack} >
            <Loading render={submit.value } />
            <Box sx={style.titleArea} >
                <MyLabel component='h1' text='Integrate Your Digital Channels' sx={style.title}  />
            </Box>
            <Box sx={style.panel} >
                <Box sx={style.row}>
                    <Box>
                        <Box sx={{display: 'flex'}}>
                            <MyLabel component='p' text='Choose a name for the account' sx={style.description}  />
                            <HelpOutlineIcon onClick={()=>handlerHelp(1)} sx={{marginBottom: '5px', height:'20px', cursor: 'pointer', }}/>
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'row',}}>
                            <MyInput 
                                type='text'
                                id='name'
                                label='Brand name'
                                value={name.value}
                                onChange={(e) => name.set(e.target.value)}
                                size="small"
                                sx={style.input}
                            />
                            <MyButton 
                                disabled={ profiles.value.length >= user.integrations}
                                onClick={()=>submit.set()}
                                text='Create Account'
                                variant="contained" 
                                color="success"
                                sx={style.saveButton}
                            />
                        </Box>
                    </Box>
                    <Box sx={{...style.row, flexDirection:'column'}}>
                        <MyLabel component='p' text={defText1()} sx={style.planDescription2}  />
                        {
                            user.subscription_name !== 'PRO Max' ?
                            <MyButton onClick={() =>navigate("/pricing")} text={defText2()} variant="text" sx={style.planButton} /> :
                            null
                        }
                        
                    </Box>
                </Box>
            </Box>
            {
                profiles.value.length >= 1 ?
                <Box sx={style.panel}>
                    <Box sx={{...style.row, marginBottom:'10px', justifyContent:'space-between'}}>
                        <Box sx={{...style.row, flexDirection:'column'}}>
                            <Box sx={{display:'flex'}}>
                                <MyLabel component='p' text='Select channels to link' sx={{...style.title, marginRight:'10px'}}  />
                                <HelpOutlineIcon onClick={()=>handlerHelp(2)} sx={{marginBottom: '5px', height:'20px', cursor: 'pointer', }}/>
                            </Box>
                            <MyButton disabled={profileSelected.value === 0} variant="contained" onClick={handlerClickConnect} text="Connect your channels" sx={style.blueButton} />
                        </Box>
                        <Box sx={{display:'flex'}}>

                            <Box sx={style.selectArea}>
                                <CustonSelect 
                                    sx={style.select}
                                    id='style'
                                    label="Select a brand"
                                    options={profiles.value}
                                    value={profileSelected.value}
                                    onChange={(e)=>profileSelected.set(e.target.value)}
                                    noTranslate={true} 
                                />
                            </Box>
                            <EditOutlinedIcon onClick={()=>setDialogEditOpen(true)} sx={{cursor: 'pointer' }} />
                        </Box>
                    </Box>
                    <Box sx={{...style.row, justifyContent:'center', marginTop: '40px'}}>
                        <Box sx={style.channelsArea}>
                            {
                                CHANNELS.filter(chanel => (chanel.active)).map((channel, key)=> <Box key={key} onClick={()=>{window.open(url.value, '_blank');}}><ChannelPanel channel={channel}  socials={socials.value}/></Box>)
                            }
                        </Box>
                    </Box>
                    <Box sx={{...style.row, justifyContent:'center', marginTop:'40px'}}>
                        <MyButton 
                            sx={style.blueButton} 
                            onClick={handlerPost}
                            text='Create a post'
                            variant="contained" 
                            size="large"
                        />
                    </Box>
                </Box>
                :
                <></>
            }
            <MyPanelHelp 
                title = {help.title}
                open = {help.open}
                onClose = {()=>{
                    let h = {...help}
                    h.open = false
                    setHelp(h)
                }}
                top = {help.top}
                left = {help.left} >
                {renderHelp()}
            </MyPanelHelp>
            <MessageDialog open={dialogOpen} handleClose={()=>setDialogOpen(false)} />
            <MessageDialogEdit name={name} open={dialogEditOpen} handleClose={()=>setDialogEditOpen(false)} handleSuccess={()=>put.set()} />
        </Box>
    )
}

export default Profile;
