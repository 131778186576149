
import React from "react";
import { Box } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MyButton from "../../myButton";
import MyLabel from "../../myLabel";
import MyInput from "../../myInput";

import { style } from "./styles";


const MessageDialogEdit = ({open, name, handleClose, handleSuccess}) => {
  
    return (
        <Dialog  open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={" "}  />
        </DialogTitle>
        <DialogContent>
          <Box sx={{margin:'30px'}}>
            <Box sx={{display: 'flex', flexDirection:'column',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                <Box sx={{marginBottom:'10px'}}>

                    <MyLabel component='p' text={"Enter a new name for the account."}  />
                </Box>
                <MyInput
                    type='text'
                    size='small'
                    id='name'
                    label='Name'
                    required={true}
                    value={name.value}
                    onChange={(e)=>name.set(e.target.value)}
                />
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                    <MyButton 
                        sx={style.cancelButton}
                        onClick={handleClose}
                        text='return'
                        variant="contained" 
                        size="small"
                    />
                    <MyButton 
                        sx={style.saveButton}
                        onClick={()=> {handleSuccess(); handleClose()}}
                        text='Save'
                        variant="contained" 
                        size="small"
                    />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
}

export default MessageDialogEdit;