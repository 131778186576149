import Box from '@mui/material/Box';

const TutorialModal = ({ title, closeModal, plan_id, paypalCancel }) => {

    const language = window.navigator.language;
    let url = 'https://www.admind.ai/en/imagegeneratorstyleguide/';

    if (language === "sp") {
        url = 'https://www.admind.ai/es/imagegeneratorstyleguide/';
    } else if (language === "pt-BR") {
        url = 'https://www.admind.ai/pt/guia-de-estilo-do-gerador-de-imagens/';
    } else if (language === "it") {
        url = 'https://www.admind.ai/it/guida-allo-stile-del-generatore-di-immagini/';
    }

    return (
        <Box sx={{width: '100%', display:'flex', justifyContent:'center'}}>
            <iframe title="Tutorial" src={url} width={window.innerWidth*0.96} height={window.innerHeight*0.75} sandbox='allow-scripts allow-modal' loading='eager'></iframe>
        </Box>


    );
};

export default TutorialModal;