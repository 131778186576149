import backEnd from "../constants/backEnd";
import endpoints from "../constants/endpoints";

import formatEmail from "../utils/formatEmail";
import { getCSRF } from "./getCSRF";

export async function changePassword(email) {
    let csrf = await getCSRF();
    return fetch(`${backEnd.url}${endpoints.access.askchangepassword}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrf?.csrfToken,
        },
        body: JSON.stringify({
            email: formatEmail(email),
        }),
    }).then(async (retorno) => {
        let dados = await retorno.json();
        if (retorno.status !== 200) {
            throw new Error(dados.detail);
        }
        return dados;
    });
}
