import backEnd from "../constants/backEnd";
import endpoints from "../constants/endpoints";

export async function getCountryes() {

    return fetch(`${backEnd.url}${endpoints.countryes}`, {
        method: "GET",
    }).then(async (retorno) => {
        let dados = await retorno.json();
        if (retorno.status !== 200) {
            throw new Error(dados.detail);
        }
        return dados;
    });
}
