import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../contexts/auth";
import { postProfile } from "../../services/ayrshare/postProfile";
import { getProfile } from "../../services/ayrshare/getProfile";
import { getProfileKey } from "../../services/ayrshare/getProfileKey";
import { postProfilePost } from "../../services/ayrshare/postProfilePost";
import { deletePostAPI } from "../../services/ayrshare/deletePostAPI";
import { getProfilePost } from "../../services/ayrshare/getProfilePost";
import formatDate from "../../utils/formatDate";
import dayjs from 'dayjs';
import { getConfigOpenAI } from "../../services/openAI/getConfigOpenAI";
import { getOpenAI } from "../../services/openAI/getOpenAi";
import { postHistoric } from "../../services/postHistoric";
import { postDalle } from "../../services/openAI/dalle";
//const SocialPost = require("social-post-api");

const createMessage = (text, returnText, talk, setTalk, system) => {
    let lines = text;

    let talks = [...talk]
    if (returnText !== ''){ 
        talks.push({'role':'assistant', "content": returnText})
        
    }
    if (lines !== ''){
        talks.push({'role':'user', "content": lines})
        setTalk(talks)
    }

    let message = [{'role': 'system', "content": system}, ...talks]
    return message;
}
const usePostsState = () => {
    const { signOut } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [imageGenerate, setImageGenerate] = useState(false);
    const [openImage, setOpenImage] = useState(false);
    const [file, setFile] = useState(null)
    const [image, setImage] = useState('')
    const [dateTime, setDateTime] = useState(dayjs(Date.now()));
    const [message, setMessage] = useState('')



    const handlerImageAutoGenerate = async (imageDescription) => {

        if (imageGenerate) { return }

        let isSendTextValid = false;
        let api = 7

        if (imageDescription !== "") { isSendTextValid = true }
        else { toast.error(<FormattedMessage id="Provide a valid text" />) }

        if (isSendTextValid) {
            setImageGenerate(true);
            setMessage('thinking about the image from your publication...')
            let config = await getConfigOpenAI(imageDescription, api, setImageGenerate, signOut)
            let messages = [
                {'role': 'system', "content": "You are a helpful and creative assistant."},
                {'role':'user', "content": imageDescription.trim()}
            ]
            let send = JSON.stringify({text: imageDescription.trim()});
            let chat = await getOpenAI(messages, config)
            await postHistoric({
                send: send,
                text: JSON.stringify(messages),
                result: chat,
                date: formatDate(new Date()),
                action: api
            })
            let json = {
                text: chat.trim(),
                size: 512,
                style: 'standard',
                date: formatDate(new Date()),
            };
            setMessage('generating the image...')
            let dalle = await postDalle(json)
            if (dalle?.type === "error") {
                if (dalle.data.code === "token_not_valid") {
                    signOut();
                }
                else {
                    toast.error(<FormattedMessage id={dalle.data.detail} />);
                }
            } else {
                let imgs = JSON.parse(dalle.data);
                setImage(imgs.urls[0]);
            }

            setImageGenerate(false);
            setMessage('')
        }
    }


    return {
        dateTime: {value: dateTime, set: setDateTime},
        file: {value: file, set: setFile},
        image: {value: image, set: setImage},
        imageAutoGenerate: {value: imageGenerate, set: handlerImageAutoGenerate},
        open: {value: open, set: setOpen},
        openImage: {value: openImage, set: setOpenImage},
        message: {value: message, set: setMessage},
    }

};

export default usePostsState;