import React, { useEffect, useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import MyLabel from "../../myLabel";
import MyButton from "../../myButton";
import CustonSelect from './custonSelect'
import PostPanel from './postPanel'
import MyModal from "../../myModal";
import SocialIcon from '../socialIcons'
import CHANNELS from "../channels"
import Loading from "../../loading";

import backEnd from "../../../constants/backEnd";
import endpoints from "../../../constants/endpoints";
import { AuthContext } from "../../../contexts/auth";
import useProfileState from "../../../hooks/ayrshare/useProfileState"
import PublishPostModal from "./publishPostModal";
import AdPostModal from "./adPostModal";
import ImagePostModal from "./imagePostModal";



import { style } from "./styles";

const BodyModal = ({ modal, setModal, profileSelected, socials, profiles, publish, submit, setModalOpen }) => {
    if (modal === 1){
        return (
            <AdPostModal setModal={setModal} profileSelected={profileSelected} socials={socials} profiles={profiles} publish={publish} submit={submit} setModalOpen={setModalOpen} />
        );
    }
    else if (modal === 2){
        return (
            <ImagePostModal setModal={setModal} profileSelected={profileSelected} socials={socials} profiles={profiles} publish={publish} submit={submit} setModalOpen={setModalOpen} />
        );
    }
    else {
        return (
            <PublishPostModal setModal={setModal}  profileSelected={profileSelected} socials={socials} profiles={profiles} publish={publish} submit={submit} setModalOpen={setModalOpen} />
        );

    }
};

function MessageDialog({open, handleClose, handlerSuccess}) {
  
    return (
        <Dialog  open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={" "}  />
        </DialogTitle>
        <DialogContent>
          <Box sx={{margin:'30px'}}>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                <MyLabel component='p' text={"Are you sure you want to abandon the creation of this post? All information will be lost."}  />
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                    <MyButton 
                        sx={style.cancelButton}
                        onClick={handleClose}
                        text='no'
                        variant="contained" 
                        size="small"
                    />
                    <MyButton 
                        sx={{...style.exitButton, marginBottom:0}}
                        onClick={handlerSuccess}
                        text='yes'
                        variant="contained" 
                        size="small"
                        color='warning'
                    />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
}

const Posts = () => {

    const { integration } = useContext(AuthContext);

    const [modalOpen, setModalOpen] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [modal, setModal] = useState(0)
    const [tooltip, setTooltip] = useState(false)

    const {
        status,
        profiles, 
        profileSelected, 
        update, 
        connect, 
        submit, 
        socials, 
        publish,
        deletePost,
        listPost,
        next, 
        previous,
        getListPost } = useProfileState();


    const handlerGetListPost = (url) => getListPost.set(url);

    useEffect(() => {
        update.set();

        if (integration.value.open ) {
            setModalOpen(true)
        }
    }, []);

    useEffect(() => {
        if (profileSelected.value && profileSelected.value > 0){
            handlerGetListPost(`${backEnd.url}${endpoints.ayrshare.profilepost}${profileSelected.value}`)
            connect.set();
        }
        
    }, [profileSelected.value, modalOpen]);
    
    
    useEffect(() => {
        if (profileSelected.value && profileSelected.value > 0){
            handlerGetListPost(`${backEnd.url}${endpoints.ayrshare.profilepost}${profileSelected.value}`)
            connect.set()
        }
    }, [status.value]);

    const handlerAI = () => {
        const inte = {...integration.value}
        inte.profile = profileSelected.value;
        inte.open = false;
        inte.text = '';
        inte.image = '';
        integration.set(inte);
        setModal(1)
        setModalOpen(true)
    }

    const handlerModal = () => {
        const inte = {...integration.value}
        inte.profile = null;
        inte.open = false;
        inte.publications = [];
        inte.image = '';

        integration.set(inte);
        setModal(0)
        setModalOpen(true)
    }


    const handleScheduled = () =>{
        status.set('scheduled')
    }

    const handlePublished = () =>{
        status.set('published')
    }

    const closeModal = ()=>{
        setDialogOpen(true)
    }

    return (
        <Box sx={style.stack} >
            <Box sx={style.titleArea} >
                <MyLabel component='h1' text='Create, edit, schedule and publish your Posts' sx={style.title}  />
            </Box>
            <Box sx={style.panel} >
                <Box>
                    <MyLabel component='p' text='Create a new Post' sx={style.title}  />
                </Box>
                <Box sx={{...style.row, justifyContent:'center'}}>
                    <MyButton 
                        sx={style.blueButton}
                        onClick={handlerAI}
                        text='Generate with AI'
                        variant="contained" 
                        size="large"
                    />
                    <MyButton 
                        sx={style.blackButton}
                        onClick={handlerModal}
                        text='Create your own'
                        variant="contained" 
                        size="large"
                    />
                </Box>
            </Box>
            <Box sx={style.panel}>
                <Box sx={{...style.row, justifyContent: 'center'}}>
                    <MyLabel component='p' text='Your posts' sx={style.title}  />
                </Box>
                <Box sx={{...style.row, justifyContent: 'space-between', marginBottom: '40px'}}>
                    <Box>
                        <MyButton variant={"contained"} onClick={handleScheduled} text='Scheduled' sx={{backgroundColor:status.value !== 'scheduled' ? 'rgb(231, 231, 231)': '#721384', color:status.value !== 'scheduled' ? '#000': '#FFF', width:'120px' }}  />
                        <MyButton variant={"contained"} onClick={handlePublished} text='Published' sx={{backgroundColor:status.value === 'scheduled' ? 'rgb(231, 231, 231)': '#721384', color:status.value === 'scheduled' ? '#000': '#FFF', width:'120px' }}  />
                    </Box>
                    <Box sx={{...style.channel}}>
                        {
                            CHANNELS.map((channel, key) => 
                            {
                                let sx = {height:'40px', width: '40px'};

                                if (!channel.active){
                                    sx = {...sx, opacity: '0.3'};
                                }

                                return (
                                <Box sx={{margin:'5px', position:'relative'}} key={key}>
                                    <SocialIcon  icon={channel.icon} linked={socials.value?.includes(channel.title)} sx={sx} />
                                    <Box sx={{...style.panel, position:'absolute', padding:'5px', width:'100px', display: tooltip ? 'block': 'none'}}><MyLabel component='p' text='update soon' sx={{fontWeight:500, fontStyle:'italic'}}  /></Box>
                                </Box>
                            )})
                        }
                    </Box>
                    <Box sx={style.selectArea}>
                        <CustonSelect 
                            sx={style.select}
                            id='style'
                            label="Select a brand"
                            options={profiles.value}
                            value={profileSelected.value}
                            onChange={(e)=>profileSelected.set(e.target.value)}
                            noTranslate={true} 
                        />
                    </Box>
                </Box>
                <Box sx={style.row}>

                </Box>
                <Box >
                    <Loading render={update.value || getListPost.value || deletePost.value} />
                    <Box sx={style.postArea}>
                        {
                            status.value === 'scheduled' ?
                                <Box onClick={handlerAI} sx={{cursor:'pointer'}}>
                                    <PostPanel onClick={handlerAI} post={{post:''}}/>
                                </Box>
                            : null
                        }
                        {
                            listPost.value?.length <=0 ?
                               null
                            : 
                              listPost.value.map((post, key)=> <PostPanel deletePost={deletePost.set} key={key} post={post}/>)
                        }

                    </Box>
                    <Box sx={{...style.row, position: 'relative', marginTop:'20px', height:'25px'}}>
                        {
                            previous.value && previous.value !== '' ?
                                <MyButton 
                                    sx={{position: 'absolute', left:'5px'}}
                                    onClick={()=> handlerGetListPost(previous.value)}
                                    text='Previous'
                                    variant="contained" 
                                    size="small"
                                />: null
                        }
                        {
                            next.value && next.value !== '' ?
                            <MyButton 
                                sx={{position: 'absolute', right:'5px'}}
                                onClick={()=> handlerGetListPost(next.value)}
                                text='Next'
                                variant="contained" 
                                size="small"
                            />: null

                        }
                    </Box>
                </Box>
            </Box>
            <MyModal notClose={true} sx={{display: 'flex'}} title="Post" isOpen={modalOpen} setIsOpen={closeModal}>
                <BodyModal modal={modal} setModal={setModal} setModalOpen={setModalOpen} submit={submit} profileSelected={profileSelected} socials={socials} profiles={profiles} publish={publish}/>
            </MyModal>
            <MessageDialog open={dialogOpen} handleClose={()=>setDialogOpen(false)} handlerSuccess={()=>{setModalOpen(!modalOpen);setDialogOpen(false)}} />
        </Box>
    )
}

export default Posts;
