import styled from "styled-components";
import img from "../../assets/imgs/index/03.png";
import img2 from "../../assets/imgs/index/02.png";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px;
    z-index: 999999;
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;
    }
`;

export const Left = styled.div`
    width: 100%;
    height: 90vh;
    margin: 0;
    padding: 0;
    background-image: url(${(props) => props.credit ? img2 : img});
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width: 600px) {
        background-image: none;
        width: 0;
        height: auto;
    }
`;

export const Area = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    @media only screen and (max-width: 600px) {
        background-image: url(${(props) => props.credit ? img2 : img});
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10px;
    }
`;

export const Line = styled.div`
    box-sizing: border-box;
    display: flex;
    border: 0px solid black;
    border-bottom-width: 1px;
    border-bottom-color: rgb(242, 242, 242);
`;

export const PanelPaypal = styled.div`
    border: 0px solid black;
    box-sizing: border-box;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    height: 160px
`;

export const InLine = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
`;

export const LogoArea = styled.div`
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
        position: absolute;
        top: 0;
        height: 120px;
    }
`;

export const Logo = styled.img`
    width: 350px;
`;


export const InformationArea = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    @media only screen and (max-width: 600px) {
        color: #FFF;
    }
`;

export const Icon = styled.img`
    margin-right: 5px;
    width: 20px;

`

export const InformationValue = styled.p`
    font-weight: bold;
    font-size: 14px;
    color: #000;
    font-family: "Proxima", sans-serif;
    padding: 0px;
    margin: 0px;
    @media only screen and (max-width: 600px) {
        color: #FFF;
    }
`;

export const InformationDescription = styled.p`
    width: 219px;
    color: rgb(128, 128, 128);
    font-size: 11px;
    font-family: "Proxima", sans-serif;
    margin: 5px;

    @media only screen and (max-width: 600px) {
        color: #FFF;
        font-size: 13px;
    }
`;

export const ValueArea = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const Value = styled.p`
    font-weight: bold;
    font-size: 24px;
    font-family: Proxima, sans-serif;
    padding: 0px;
    margin: 0px;

    @media only screen and (max-width: 600px) {
        color: #FFF;
    }
`;

export const Month = styled.span`
    font-size: 15px;
    color: rgb(128, 128, 128);
`;

export const Row = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AreaCap = styled.div`

    @media only screen and (max-width: 600px) {
        background-color: rgb(0,0,0,0.5);
        border: 0px solid black;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 10px;
    }
`;

export const Title = styled.h1`
    margin: 0;
    padding: 0;
    font-size: 20pt;
    font-family: "Lato", sans-serif;
    font-weight: 800;
    margin-bottom: 10px;
    margin-left: 8px;

    @media only screen and (max-width: 600px) {
        color: #FFF;
    }
`;

