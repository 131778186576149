import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import Box from '@mui/material/Box';

import { AuthContext } from "../../../contexts/auth";

import Loading from "../../loading";
import MyLabel from "../../myLabel";
import MyInputText from "../../myInputText";
import MyButton from "../../myButton";

import RenderOptions from "./renderOptions";
import RenderCreateOptions from './renderCreateOptions';

import { style } from "./styles";

function FunctionOpenAi({
    returnText,
    describes,
    selects,
    inputs,
    createOptions,
    createAction,
    title,
    updateHistoric,
    action,
    textButton,
    textCounter
}) {
    const { integration } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    const copyToClipboard = () => {
        copy(returnText.action.value);
        toast.success(<FormattedMessage id="Copied text" />);
    };

    const handlePost = () => {
        const updatedIntegration = { ...integration.value, text: returnText.action.value };
        integration.set(updatedIntegration);
        navigate('/posts');
    }

    textButton = textButton || "Generate It";
    const screenWidth = window.innerWidth;

    return (
        <Box sx={style.stack}>
            <Loading render={action.submit.value} />
            {title && (
                <Box sx={style.titleArea}>
                    <MyLabel component='h1' text={location.pathname === `/chat`? 'ChatGPT 4':'What do you want to do?'} sx={style.title} />
                </Box>
            )}

            {createOptions && (
                <Box>
                    <RenderCreateOptions
                        options={createOptions}
                        selected={createAction}
                    />
                </Box>
            )}

            {selects || inputs ? (
                <Box>
                    <RenderOptions selects={selects} inputs={inputs} />
                </Box>
            ):null}

            {describes?.map((describe, key) => (
                <Box key={key} sx={screenWidth >= 600 ? style.describeArea : { ...style.describeArea, width: screenWidth - 20 }}>
                    <MyInputText
                        sx={style.textInput}
                        type='text'
                        id={describe.placeholder}
                        label={describe.title}
                        placeholder={describe.placeholder}
                        required={true}
                        value={describe.action.value}
                        onChange={(e) =>
                            describe.action.set(e.target.value)
                        }
                    />
                    <Box>
                        {textCounter && (
                            <Box sx={style.counterArea}>
                                <p style={style.counter}>{describe.action.value.length}{" "}{" / 4000"}</p>
                            </Box>
                        )}
                    </Box>
                </Box>
            ))}
            <Box sx={style.buttonArea}>
                <MyButton
                    disabled={action.submit.value}
                    onClick={async () => {
                        action.submit.set(updateHistoric);
                    }}
                    text={textButton}
                    variant="contained"
                    size="large"
                />
            </Box>

            {returnText && (
                <Box sx={screenWidth >= 600 ? style.describeArea : { ...style.describeArea, width: screenWidth - 20 }}>
                    <MyInputText
                        sx={style.textInput}
                        type='text'
                        id="return"
                        label=""
                        placeholder=""
                        required={false}
                        value={returnText.action.value}
                        onChange={(e) => returnText.action.set(e.target.value)}
                    />
                    {returnText.action.value !== "" && (
                        <Box sx={style.buttonArea}>
                            <MyButton
                                onClick={copyToClipboard}
                                text={'Copy'}
                                variant="text"
                                size="small"
                            />
                            {integration.value.profile &&
                                <MyButton
                                    sx={{ backgroundColor: '#376db4', ':hover': { backgroundColor: '#598FD6' } }}
                                    onClick={handlePost}
                                    text={'Post'}
                                    variant="contained"
                                    size="small"
                                />
                            }
                        </Box>
                    )}
                </Box>
            )}

        </Box>
    );
}

export default FunctionOpenAi;