export const style = {
    card:{
        minHeight: '700px',
        display:'flex',
        flexDirection:'column',
        justifyContent: 'space-between',
        position: 'relative'

    },
    titleArea: {
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
    },
    title:{
        margin: '0px',
        marginLeft: '5px',
        fontWeight: 'bold',
        fontSize: '12pt',
        padding: '0px',
        
    },
    value:{
        fontWeight: 'bold',
        fontSize: '30pt',
        margin: 0,
    },
    valueMonth:{
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'rgb(128, 128, 128)',
    },
    selectArea:{
        marginTop: '20px',
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
    },
    selectLabel:{
        marginLeft: '20px',
        color: 'rgb(128, 128, 128)',
        fontSize: '16px',
    },
    lineArea: {
        marginTop: '20px',
        width: '100%',
    },
    selectDiv: {
        border: '1px none',
        height: '22px',
        width: '45px',
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        position: 'relative',
    },
    selectDivFull: {
        backgroundColor: 'rgb(34, 125, 250)',
        borderRadius: '50px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'end',
    },
    selectDivCicle: {
        backgroundColor: 'rgb(255, 255, 255);',
        borderRadius: '100%',
        width: '15px',
        height: '15px',
        boxShadow: 'rgba(0, 0, 0, 0.6) 0px 2px 4px',
        position: 'absolute',
        right: '5px',
        top: '3px',
    },
    select: {
        height: '100%',
        width: '100%',
        top: '0px',
        display: 'none',
        position: 'absolute',
        left: '0px',
        bottom: '0px',
        padding: '0px',
        margin: '0px',
        color: 'rgb(255, 255, 255)',
        listStyle: 'none',
        appearance: 'none',
    },
    inLine:{
        display: 'inline-flex',
        //alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
        margin: '0px',
    },
    informationDescription:{
        width: '220px',
        color: '#000',
        fontSize: '12pt',
        margin: '5px',
        marginLeft: '25px',
    },
    informationArea: {
        paddingTop: '10px',
        paddingBottom: '20px',
    },
    informationArea2: {
        paddingTop: '0px',
        marginLeft:'25px'
    },
    
    informationValue:{
        fontWeight: 'bold',
        fontSize: '12pt',
        color: '#000',
        padding: '0px',
        margin: '0px',
    },
    informationValue2:{
        fontSize: '11pt',
        color: '#737373',
        padding: '0px',
        margin: '0px',
    },
    buttonArea:{
        width: '100%',
    },
    buttonYourPlan:{
        width: '100%',
    },
    buttonEscolher:{
        width: '100%',
        backgroundColor: '#227DFA',
    },
    icon:{
        marginRight: '5px',
        marginTop: '2px',
        width: '20px',
        height: '20px',

        
    },
}
