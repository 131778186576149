import React from "react";
import LinearProgress from '@mui/material/LinearProgress';

const MyProgressBar = (props) => {
    return (
        <LinearProgress {...props} variant="determinate" />
    );
};

export default MyProgressBar;
