const defLanguage = languageId => {
    switch (languageId){
        case 1:
            return "spanish"
        case 2:
            return "portuguese"
        case 3:
            return "italian"
        default:
            return "english"
    }
  };
  
  export default defLanguage