import { scrollbarTheme } from '../../../assets/customThemes/scrollbarTheme';

export const style = {
    container:{
        width:'73vw',
        display:'flex', 
        flexDirection:'row', 
        marginTop:'20px',
        '@media (max-width: 600px)': {
            flexDirection:'column', 
            width: '90vw',
        }
    },
    messageArea: {
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: '#FFF',
        borderRadius:'5px',
        width: '100% !important',
        

        '@media (max-width: 600px)': {
            width: '100%'
        }
    },

   sendArea: {
        marginTop: '10px',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: '#FFF',
        borderRadius:'5px',
        width: '100% !important',
        border:'2px solid #00a271',
        '@media (max-width: 600px)': {
            width: '100%'
        }
    },
    messageTitleArea:{
        display: 'flex', 
        width:'100%', 
        justifyContent: 'end', 
        marginRight:'10px'
    },
    title:{
        color:'#666670',
        fontSize: '12pt', 
        fontWeight: '700',
    },

    messageContainer:{
         display:'flex', 
         flexDirection:'column', 
         maxHeight:'55vh',
         width: '100% !important',
         overflowY: "auto",
         '@media (max-width: 600px)': {
            width: '100%',
            maxHeight:'55vh',
        },
         ...scrollbarTheme

        },
    cancelButton:{
        width: '180px',
        color:'black',
        backgroundColor: '#cfcfcf',
        ':hover': {
            backgroundColor: '#ebebeb',
        }
    },
    saveButton:{
        width: '180px',
        color:'black',
        backgroundColor: '#00a271',
        ':hover':{
            backgroundColor: '#11B382',
        },
    },
    submitButton:{
        backgroundColor: '#00a271',
        ':hover':{
            backgroundColor: '#11B382',
        },
    },
    submitCancelButton:{
        backgroundColor: '#CC3300',
        ':hover':{
            backgroundColor: '#DD4411',
        },
    },
    modelButton: {
        display:'flex', 
        borderRadius:'10px',
        flexDirection:'row', 
        justifyContent:'space-between', 
        width:'200px',
        backgroundColor: '#e4e4e4',
        border: 'none',
        padding: '5px',
        cursor: 'pointer'},
    modelButtonSelected:{
        borderRadius:'10px',
        backgroundColor: '#fdfdfd',
        padding: '5px 10px',
    },
    modelButtonNotSelected:{
        padding: '5px 10px',
    }
}
