import React, { useEffect } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import useRegisterPaypalSubscription from "../../hooks/useRegisterPaypalSubscription";

import toast from "react-hot-toast";
import { postSubscription } from "../../services/paypal/postSubscription";

const SubscriptionButton = ({plan_id, closeModal }) => {

    const { post } = useRegisterPaypalSubscription();
    const [{ options }, dispatch] = usePayPalScriptReducer();

    

    useEffect(() => {
            dispatch({
                type: "resetOptions",
                value: {
                    ...options,
                    intent: "subscription",
                },
            });
    }, []);

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [plan_id]);

    const createSubscription = (data, actions) => {
        return actions.subscription
            .create({
                plan_id: plan_id,
            })
            .then((orderId) => {
                return orderId;
            });
    };

    const onClick = async (data, actions) => {
        let result = await postSubscription({'plan_id': plan_id})
        if (result.type === 'success'){
            return;
        }
        else {
            toast.error(result.data.detail)
            return false;
        }
        
    };

    return (
        <PayPalButtons
                style={{
                    shape: "pill",
                    color: "gold",
                    layout: "vertical",
                    label: "paypal",
                }}
                onClick={onClick}
                createSubscription={createSubscription}
                onApprove={(data, actions) => {
                    post.set(data.subscriptionID);
                    closeModal();
                    toast.success(`Payment completed. Thank you`);
                }}
            />
    )
}

export default SubscriptionButton