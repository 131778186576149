import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { getPurchasedTokens } from "../getPurchasedTokens";
import { postModeration } from "./moderation";


export async function openAILocal(sendText, api, setSubmit, signOut, updateHistoric, action, submit=true, chat=false) {
    return await getPurchasedTokens('tokens', chat)
    .then((json) => {
        if (json?.type === "error") {
            if (json.data.code === "token_not_valid") {
                signOut();
            } else if (
                json.data.code === "violation of guidelines"
            ) {
                toast.error(
                    <FormattedMessage id={json.data.detail} />
                );
            } else if (
                json.data.detail === "Not found."
            ) {
                if (chat){
                    toast.error(
                        <FormattedMessage id="Your current session has reached a temporary limit. Please try again later." />
                    );
                }
                else {

                    toast.error(
                        <FormattedMessage id="your user has no usage tokens" />
                    );
                }
                setSubmit(false);
            }
            else {
                toast.error(<FormattedMessage id="unknown error" />);
            }
        } else {
            if (
                json.data.tokens_number <= 0
            ) {
                if (chat){
                    toast.error(
                        <FormattedMessage id="You’ve reached the temporary usage limit for Chat GPT-4o Mini on your current plan, which includes 10,000 characters every 2 hours. Want unlimited chatting? Upgrade to the PRO PLUS plan and enjoy unlimited access!" />
                    );
                }
                else {
                    toast.error(
                        <FormattedMessage id="your user has no usage tokens" />
                    );
                }
                setSubmit(false);
            }
            else {
                if (!submit){
                    return null;
                }
                postModeration({text: sendText.trim(), api: api})
                .then(async (json) => {
                    if (json?.type === "error") {
                        if (json.data.code === "token_not_valid") {
                            signOut();
                        } else if (
                            json.data.code === "violation of guidelines"
                        ) {
                            toast.error(
                                <FormattedMessage id={json.data.detail} />
                            );
                        } else {
                            toast.error(<FormattedMessage id="unknown error" />);
                        }
                        setSubmit(false);
                    } else {
                        if (
                            json.data.tokens_number <= 0 ||  json.data.tokens_number * 4 < sendText.trim().length
                        ) {
                            toast.error(
                                <FormattedMessage id="your user has no usage tokens" />
                            );
                            setSubmit(false);
                        }
                        else {
                            if (!submit){
                                return null;
                            }
                            if (!submit){
                                return null;
                            }
                            action(json.data.config, updateHistoric, submit)
                        }
                    }
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(
                            <FormattedMessage id="Connection failed to server" />
                        );
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }
                    setSubmit(false);
                });
            }
        }
    })
    .catch((error) => {
        if (error.message === "NetWork request failed") {
            toast.error(
                <FormattedMessage id="Connection failed to server" />
            );
        } else {
            toast.error(<FormattedMessage id={error.message} />);
        }
        setSubmit(false);
    });
}
