import moment from 'moment'
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/it';


export default function formatLocaleDate(date) {
    if (date){
        return moment(date).local().format('DD/MM/YYYY HH:mm:ss');
    }
}