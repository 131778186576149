import { scrollbarTheme } from '../../assets/customThemes/scrollbarTheme';

export const style = {

    card:{
        zIndex: 11,
        position:'absolute',
        width:  '450px',
        padding: '5px',
        paddingTop: '15px',
        display:'flex',
        backgroundColor: '#cce5ff',
        boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
        border:'1px solid #b8daff',
    },

    content:{
        display:'flex',
        margin: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },

    titleArea: {
        height: '30px',
        width: '100%',
        display:'flex',
        padding: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    title: {
        fontSize: '13pt',
        fontWeight: 600,
    },

    descriptionArea: {
        width: '100%',
        height: '100%',
        display:'flex',
        padding: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            overflowY: 'auto',
            ...scrollbarTheme
        }
    },
}