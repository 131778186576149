export const style = {
    stack: {
        '@media (max-width: 600px)': {
            width:'100%',
        }
    },
    titleArea:{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'row',
        margin: '14px',
    },
    title:{
        margin: '0px',
        padding: '0px',
        fontSize: '14pt',
        fontWeight: 600,
        marginBottom: '10px'
    },
    description:{
        margin: '0px',
        padding: '0px',
        fontSize: '13pt',
        fontWeight: 600,
        marginBottom: '10px'
    },
    description2:{
        margin: '0px',
        padding: '0px',
        fontSize: '11pt',
        fontWeight: 600,
        marginBottom: '10px'
    },
    panel: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        backgroundColor: '#FFF',
        padding: '40px',
        marginBottom: '20px',
         '@media (max-width: 600px)': {
            minWidth: '100%',
        }
    },
    row: {
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
        justifyContent:'space-between',
        '@media (max-width: 600px)': {
            flexDirection:'column',
        }
    },
    planDescription:{
        margin: '0px',
        padding: '0px',
        fontSize: '11pt',
        fontWeight: 600,
    },
    planButton:{
        fontSize: '11pt',
        fontWeight: 500,
        textDecoration: 'underline',
    },
    blueButton: {
        backgroundColor: '#376db4',
        ':hover': {
            backgroundColor: '#598FD6',
        }

    },
    saveButton:{
        backgroundColor: '#217efd',
        ':hover': {
            backgroundColor: '#439FfF',
        }
        
    },
    input:{
        marginRight: '5px'
    },
    selectArea: {
        width: '200px',
        '@media (max-width: 600px)': {
            width: '100%',
            marginTop: '20px',
        }
    },
    channelsArea: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gap: '40px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr 1fr',
        }
    },

}