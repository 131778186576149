export const style = {
    stack: {
        height: '100vh',
        display:'flex',
        justifyContent: 'space-between',
        alignItem: 'center',
        flexDirection: 'row',
        marginTop: '120px',
        '@media (min-width: 600px)': {
            marginTop: '65px',
          },
          
    },
    leftArea:{
        display: 'flex',
        top: '0',
        height: '100%',
        margin: '0',
        padding: '0',
        backgroundColor:'red',
        '@media (min-width: 600px)': {
            position: 'fixed',
            top: '55px',
            width: '100%',
            height: '55px',
            zIndex: '2',
          }

    },
    centerArea:{
        display: 'flex',
        height: '100%',
        marginLeft: '10px',
        marginRight: '10px',
        justifyContent: 'center',
        width: '100%',
    },
    historic: {
        display: 'flex',
        height: '100%',
        padding: '0px',
        width: 'auto',
        justifyContent: 'end',
        zIndex: 3,
        '@media (max-width: 600px)': {
            position: 'fixed',
            right: 0,
          }
    },
    
}