import React, {useContext} from "react";

import { useEffect, useState } from 'react';

import english from "../../assets/imgs/english.svg";
import spanish from "../../assets/imgs/spanish.svg";
import portuguese from "../../assets/imgs/portuguese.svg";
import italian from "../../assets/imgs/italian.svg";

import { AuthContext } from "../../contexts/auth";
import useUserDetailState from "./../../hooks/useUserDetailState";


import MyLabel from "../../components/myLabel";
import MyImage from "../../components/myImage";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';

import { style } from "./styles";


const SelectLanguage = () => {

  const [updating, setUpdating] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { language } = useContext(AuthContext);

    const options= [
      "english",
      "spanish",
      "portuguese",
      "italian",
    ]

    var languageDict = {
        "english": english,
        "spanish": spanish,
        "portuguese": portuguese,
        "italian": italian
    };

    var languageAbr = {
        "english": 'EN',
        "spanish": 'ES',
        "portuguese": 'PT',
        "italian": 'IT'
    };

    const userState = useUserDetailState();

    useEffect(() => {
      if (userState.language.value === '') {
        userState.get.set();
      }
  }, []);

    useEffect(() => {
      if (userState.language.value !== '' && updating) {
        userState.update.set();
      }
  }, [userState.language.value]);

    const renderOptions = (option, key) => {

      const handlerClick = async (languageId) =>{
          userState.language.set(options[languageId])
          language.set(languageId)
          setShowDropdown(false);
          setUpdating(true);
      }

      const DropdownButton = ({children, onClick, languageId}) =>{
        if (options[languageId] === userState.language.value){
          return (
            <Box onClick={onClick} sx={{...style.dropdownButtom, borderBottom: '1px solid black'}}>
              {children}
            </Box>
          );
        }
        else {
          return (
            <Box onClick={onClick} sx={style.dropdownButtom}>
              {children}
            </Box>
          );
        }
      }

      return (
          <DropdownButton key={key} onClick={() => handlerClick(key)}  languageId={key}>
            <MyImage src={languageDict[option]}  alt={'flag'} sx={style.flag} />
            <MyLabel component='p' text={languageAbr[option]} sx={{...style.description, marginTop:0}}  noTranslate={true}/>
          </DropdownButton>
      );
    }

    const toggleDropdown = () => {
      setShowDropdown(!showDropdown);
    }
  
    const Dropdown = () =>{
      return (
        <>
          {showDropdown ?
            <>
              <Collapse in={showDropdown} sx={style.collapse}>
              {options.map((option,key) => (
                  renderOptions(option, key)
              ))}
              </Collapse>
              <Box sx={style.bottonArea}>

              </Box>
            </>
            :
            <Box sx={style.bottonArea}>
              <Box onClick={toggleDropdown} sx={style.botton}>
                <MyImage src={languageDict[options[language.value]]} alt={'flag'} sx={style.flag} />
                <MyLabel component='p' text={languageAbr[options[language.value]] || 'Select'} sx={style.description}  noTranslate={true} />
              </Box>
            </Box>
          }
        </>
      )
    }


    return (
      <Box>
        {userState.language.value !== '' ? <Dropdown />: <></>}
      </Box>
    );
};

export default SelectLanguage;
