import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";


import Box from '@mui/material/Box';

import { AuthContext } from "../../../contexts/auth";
import MyLabel from "../../myLabel";
import MyButton from "../../myButton";
import MyInputText from "../../myInputText";
import ImageOutlined from '@mui/icons-material/ImageOutlined';

import daleImage from "../../../assets/imgs/dale.png";
import MyImage from "../../myImage";
import CircularProgress from '@mui/material/CircularProgress';
import MySelect from "../../mySelect";
import useDalleState from "../../../hooks/openAI/useDalleState";
import { STYLESIAGES } from "../../openAI/dalle/stylesImages";

const style ={
    container:{
        minWidth: '700px',
        
        '@media (max-width: 600px)': {
            minWidth: '100%',
        }
    },
    title:{
        fontSize: '12pt',
        fontWeight: '900'
    },
    descriptions:{
        fontSize: '12pt',
    },
    selectArea: {
        minWidth: '180px',
        '@media (max-width: 600px)': {
            width: 'auto',
            minWidth: 'auto',
        }
    },

    counterArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        margin: 0,
        padding: 0,

    },
    counter: {
        margin: 0,
        marginRight: '10px',
        padding: 0,
        fontSize: '11pt'
    },
    cardAreaImage:{
        width: '180px',
        height:'180px',
        display:'flex',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
         },
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 600px)': {
            width: '180px',
            height: '180px',
          }
    },
    cardAreaContent:{
        display:'flex',
        width: '100%',
        height:'100%',
        margin: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    cardTitleArea: {
        width: '100%',
        display:'flex',
        backgroundColor: '#FFF',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    returnButton:{
        width: '120px',
        color:'black',
        backgroundColor: '#cfcfcf',
        ':hover': {
            backgroundColor: '#ebebeb',
        }
    },
    blueButton: {
        width: '120px',
        backgroundColor: '#376db4',
        ':hover': {
            backgroundColor: '#598FD6',
        }
    },
    helpArea:{
        display: 'flex', 
        flexDirection:'row',
        justifyContent:'start', 
        alignItems: 'center', 
        width: '100%', 
        margin:'15px 0',
        '@media (max-width: 600px)': {
            flexDirection:'column',
            justifyContent:'center', 
          }
    },
}

const ImagePostModal = ({setModal, profileSelected, socials, profiles, publish, submit, setModalOpen}) =>{
    const { integration } = useContext(AuthContext);
    const [img, setImg] = useState(null);
    const action = useDalleState();
    let navigate = useNavigate();

    const language = window.navigator.language;
    let url = 'https://www.admind.ai/en/imagegeneratorstyleguide/';


    let urlBase = process.env.REACT_APP_DEBUG === 1 ? 'http://192.168.1.114:8000' : ''

    if (language === "sp") {
        url = 'https://www.admind.ai/es/imagegeneratorstyleguide/';
    } else if (language === "pt-BR") {
        url = 'https://www.admind.ai/pt/guia-de-estilo-do-gerador-de-imagens/';
    } else if (language === "it") {
        url = 'https://www.admind.ai/it/guida-allo-stile-del-generatore-di-immagini/';
    };


    const handlerGenerate = async () => {
        action.submit.set({set:()=>null});
    }

    const handlerPublish = () => {
        const inte = {...integration.value}
        inte.image = img;
        integration.set(inte);
        setModal(0)
    }

    const handlerReturn = () => {
        const inte = {...integration.value}
        integration.set(inte);
        setModal(0)
    }

    const selectImage = (image) => {
        setImg(image)
    }


    return (
        <Box sx={style.container}>
            <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', margin:0, marginBottom:'20px'}}  >
                <MyLabel component='h3' text='Image Generator' sx={style.title}  />
            </Box>
            <Box sx={{borderBottom: '1px solid #adb5bd', width:'100%',marginBottom:'20px'}}/>
            <Box sx={{padding: '0px 40px'}}>
                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'15px 0'}}>
                    <MySelect 
                        sx={style.select}
                        id='style'
                        label="Select a style for yout image - Highly Recommended"
                        options={STYLESIAGES}
                        value={action.style.value}
                        onChange={(e)=>action.style.set(e.target.value)}
                        noTranslate={true} 
                    />
                </Box>
                <Box sx={style.helpArea}>
                    <MyLabel component='h1' text={'Need help with the image styles?'} sx={style.helpText}  />
                    <MyButton text='Check out our Style Guide' variant="text" target="_blank" href={url} size="small" />
                </Box>
                <Box>
                    <MyInputText
                        minRows={5}
                        type='text'
                        id="An ice-cream store that sells ice-creams and serve food."
                        label="Describe the image you want to create:"
                        placeholder=""
                        required={true}
                        value={action.sendText.value}
                        onChange={(e) =>
                            action.sendText.set(e.target.value)
                        }
                    />
                </Box>
                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%',margin:'10px'}}>
                {
                    action.submit.value ? <CircularProgress color="primary" /> : 
                    <MyButton 
                        disabled={action.submit.value}
                        onClick={handlerGenerate}
                        text={action.returnImages.value.length > 0 ? 'Retry' : 'Generate It'}
                        variant="contained" 
                        size="small"
                    />
                }
                </Box>
                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%',margin:'10px'}}>
                    {
                        action.returnImages.value.length > 0 ? 
                        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <MyLabel component='p' text={'click on the image to select'} sx={style.helpText}  />
                            <Box sx={{display:'flex', flexDirection:'row' }}>
                                {
                                    action.returnImages.value.map((image, key)=>{

                                        return (
                                            <Box onClick={()=>selectImage(image)} key={key} sx={{margin:'5px', border: img===image ? '1px solid #721384': 'none'}}>
                                                <MyImage src={`${urlBase}${image}`} alt={`Image ${key}`}  sx={style.cardAreaImage} /> 
                                            </Box>
                                        )
                                    })
                                }
                                
                            </Box> 
                        </Box>
                        :
                        <Box sx={{height:'200px', width: '200px', backgroundColor:'#d6d6d6', borderRadius:'5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                            <ImageOutlined sx={{height:'100px', width: '100px', color:'#a0a0a0'}} />
                        </Box>
                    }


                </Box>
                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%',margin:'10px'}}>
                <MyButton 
                        sx={style.returnButton}
                        onClick={handlerReturn}
                        text='Back to Post'
                        variant="contained" 
                        size="small"
                    />
                    <MyButton 
                        disabled={!(img && img !== null)}
                        sx={style.blueButton}
                        onClick={handlerPublish}
                        text='Add to post'
                        variant="contained" 
                        size="small"
                    />
                </Box>
            </Box>
        </Box>

    )
}

export default ImagePostModal;