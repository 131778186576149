export async function getAyrsharePost(profile, id, platforms, API_KEY) {
    return fetch("https://app.ayrshare.com/api/analytics/post", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${API_KEY}`
        },
        body: JSON.stringify({
          profileKey: profile,
          id: id,
          platforms: platforms, // optional
        }),
      })
        .then((res) => res.json())
        .catch(console.error)
}


;