import React, {useContext, useEffect} from "react";
import Box from '@mui/material/Box';
import MyButton from "../../myButton";
import useArticlePostsGeneratorLocalState from "../../../hooks/openAI/useArticlePostsGeneratorLocalState";
import { AuthContext } from "../../../contexts/auth";
import imgArticle from "../../../assets/imgs/panels/article.svg";
import imgBlog from "../../../assets/imgs/panels/blog.svg";
import imgRewrite from "../../../assets/imgs/panels/rewrite.png";
import MyInputText from "../../myInputText";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MyImage from "../../myImage";
import downloadImage from "../../../utils/downloadImage";

import { style } from "./styles";

import FunctionOpenAi from "../functionOpenAI";

function ArticlePostsGenerator({ updateHistoric }) {

    const { historic } = useContext(AuthContext);
    const action = useArticlePostsGeneratorLocalState();
    const screenWidth = window.innerWidth;

    const saveImage = (src) => { downloadImage(src) };

    useEffect(() => {
        if (historic.value){
            action.returnText.set("");
            action.sendText.set(historic.value.text);
            action.theAmount.set(historic.value.the_amount);
            action.target.set(historic.value.target);
            action.language.set(historic.value.language);
            action.tone.set(historic.value.tone);
            historic.set(null)
        }
        
    }, [historic.value]);

    let title = "What do you want to create?";
    let createAction = action.object;
    let createOptions = [
        { name: "Write an Article", icon: imgArticle, action: "article" },
        { name: "Write a Blog Post", icon: imgBlog, action: "blog post" },
        { name: "Rewrite an Article", icon: imgRewrite, action: "rewrite article" },
    ];

    let selects = [
        {
            name: "tone",
            title: "Choose the Tone:",
            options: [
                "AI's Choice",
                "creative",
                "funny",
                "modern",
                "formal",
                "informal",
                "classic",
                "original",
                "catchy",
            ],
            action: action.tone,
        },
        {
            name: "theAmount",
            title: "How long it should be?",
            options: ["AI's Choice", "one", "two", "three", "four", "five"],
            action: action.theAmount,
        },
        {
            name: "target",
            title: "The main target?",
            options: [
                "all",
                "students",
                "professionals",
                "parents",
                "mass market",
            ],
            action: action.target,
        },
        {
            name: "goal",
            title: "What's the goal?",
            options: [
                "no specific goal",
                "enhance brand awareness",
                "generate traffic e engagement",
                "boost sales & conversions",
                "build & retain community",
                "support advocacy & promote events",
                "improve SEO & ad revenue",

            ],
            action: action.goal,
        },
        {
            name: "language",
            title: "Which language do you want it?",
            options: ["english", "spanish", "portuguese", "italian", "deutsch", 'french'],
            action: action.language,
        },
        {
            name: "emot",
            title: "Use Emoticons?",
            options: [
                "AI's Choice",
                "yes",
                "no"
            ],
            action: action.emot,
        },
    ];

    let inputs = [
        {
            name: "keywords",
            title: "Do you want AI to use specific keywords?",
            placeholder: 'separate keyword with comma',
            action: action.keywords,
        },
    ]

    let describes = [
        {
            title: action.object.value === 'rewrite article'? "Paste the article you would like to rewrite": `Describe the subject of your ${action.object.value}`,
            placeholder:
            action.object.value === 'rewrite article'? "Paste the article you would like to rewrite": "The climate changing and how we can save the future of humanity",
            action: action.sendText,
        },
    ];

    let returnText = {
        action: action.returnText,
    };

    return (
        <>
            <FunctionOpenAi
                returnText={returnText}
                describes={describes}
                selects={selects}
                createOptions={createOptions}
                createAction={createAction}
                inputs={inputs}
                title={title}
                updateHistoric={updateHistoric}
                action={action}
            />
            
                {returnText.action.value !== "" && (
                    <Box>
                        <Box sx={{display:'flex', justifyContent:'space-around'}}>
                            <MyButton
                                text='generate an image'
                                variant="contained"
                                size="small"
                                disabled={action.submit.value}
                                onClick={async () => {
                                    action.imgs.set();
                                }}
                            />
                            <MyButton
                                text='suggest an image description'
                                variant="contained"
                                size="small"
                                sx={style.button1}
                                disabled={action.getImageDescription.value}
                                onClick={async () => {
                                    action.getImageDescription.set();
                                }}
                            />
                        </Box>
                        {
                            action.imageDescription.value && action.imageDescription.value !== '' ?
                                <Box sx={screenWidth >= 600 ? style.describeArea : { ...style.describeArea, width: screenWidth - 20 }}>
                                    <MyInputText
                                        sx={style.textInput}
                                        type='text'
                                        id="imageDescription"
                                        label="image description"
                                        placeholder=""
                                        required={false}
                                        value={action.imageDescription.value}
                                        onChange={(e) => action.imageDescription.set(e.target.value)}
                                    />
                                </Box> : null

                        }
                        {
                            action.imgs.value.length > 0 ?

                            <Box sx={style.center} >
                                <Box sx={style.imageArea} >
                                    {action.imgs.value.map((img, key)=>{
                                        return (
                                            <Card key={key} >
                                                <CardContent sx={style.cardAreaContent}>
                                                    <Box>
                                                        <MyImage src={`${img}`} alt={`Image ${key}`} sx={style.imageReturn} />
                                                    </Box>
                                                    <Box sx={{display:'flex', flexDirection: 'row'}}>
                                                        <MyButton 
                                                            onClick={() => saveImage(img)}
                                                            text="Download"
                                                            variant="contained" 
                                                            size="small"
                                                            
                                                        />
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        )
                                    })}
                                </Box>
                            </Box>
                                 : null
                        }
                    </Box>
                )}
            
            <br />
            <br />
            <br />
            <br />
        </>
    );
}

export default ArticlePostsGenerator;
