import api from "../../services/api";

import { getCSRF } from "../getCSRF";

export async function getProfilePost(url, status, month, year) {
    let csrf = await getCSRF();
    return api
        .get(url, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf?.csrfToken,
            },
            params: { status:status, month: month, year: year }
        })
        .then((response) => {
            return { type: "success", data: response.data };
        })
        .catch((error) => {
            return {
                type: "error",
                data: {
                    code: error.response.data.code,
                    detail: error.response.data.detail,
                },
            };
        });
}
