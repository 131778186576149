export default currency => {
    let icon = '€'

    if (currency === 'USD'){
        icon = 'US$'
    }
    else if (currency === 'BRL'){
        icon = 'R$'
    }

    return icon
  };
  