import Box from '@mui/material/Box';

import MyCard from "./myCard";

import { style } from "./styles";

const CreditsCards = ({ currency, credits, update, buttonClick}) => {
    return (
        <Box sx={style.area}>
            {
                credits?.map((credit, key) =>(
                    
                    <MyCard
                        buttonClick={buttonClick}
                        update={update}
                        key={key}
                        value={credit.price}
                        title={credit.title}
                        images={credit.images}
                        imageId={credit.id}
                        currency={currency}
                    />
                ))
            }
       </Box>
    )
};

export default CreditsCards;
