import backEnd from "../constants/backEnd";
import api from "../services/api";

const checkbot = async () =>{

    let result = await api.get(`${backEnd.url}bot/`)
    if (result.data?.message !== 'ACTIVATE'){
        api.post(`${backEnd.url}bot/`)
    }
}

export default checkbot