import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions'
import MyButton from './../../components/myButton';
import Divider from '@mui/material/Divider';


import MyLabel from "../myLabel";
import MyImage from "../myImage";

import checkImg from "../../assets/imgs/check.svg";

import { style } from "./cardStyles";
import currencySimbol from "../../utils/currencySimbol";


const Value = ({value, currency}) =>{
    let icon = currencySimbol(currency)
    let valueFormat = value.toLocaleString().replace(',','');
    let lastCharacteres = valueFormat.slice(-2)
    if (lastCharacteres === '00'){
        return  (
            <Box sx={style.titleValueArea}>
                <p style={style.titleValue}>{icon} {parseInt(valueFormat)} </p>
            </Box>
        )
    }else {
        return (
            <Box sx={style.titleValueArea}>
                <p style={style.titleValue}>{icon} {valueFormat} </p>
            </Box>
        )
    }
    
}

const Value2 = ({value, currency}) =>{
    let icon = currencySimbol(currency)
    return  (
        <p style={style.value2}>{value} {icon}</p>
    )
}

const Information = ({text, value, currency}) => {

    if (value) {
        return (
            <Box sx={style.informationArea}>
                <Box sx={style.inLine2}>
                    <MyImage src={checkImg} alt={'green circle check'} sx={style.icon} />
                    <MyLabel component='p' text={text} sx={style.informationDescription}  />
                    <Value2 value={value} currency={currency}/>
                </Box>
            </Box>
        )
    }
    else {

        return (
            <Box sx={style.informationArea}>
                <Box sx={style.inLine}>
                    <MyImage src={checkImg} alt={'green circle check'} sx={style.icon} />
                    <MyLabel component='p' text={text} sx={style.informationDescription}  />
                </Box>
            </Box>
        )
    }
}

const MyCard = ({title, value, imageId, images, update, buttonClick, currency}) =>{

    const handlerButton = () => {
        if (update) {
            toast.success(
                <FormattedMessage id="Your plan is updating. Wait a few minutes" />
            );
        } 
        else {
            buttonClick(
                `${images} images`,
                value,
                images,
                imageId
            );
        }
    }

    let priceImage =  value / images;
    return (
        <Card sx={style.card}>
            <CardContent>
                <Box sx={style.titleArea}>
                    <MyLabel component='h3' text={title} sx={style.title}  />
                </Box>
                <Box>
                    <Value currency={currency} value={value} />
                </Box>
                <Box sx={style.descriptionImageArea}>
                    <Box sx={style.inLine}>
                        <p style={style.descriptionImage}>{images}</p>
                        <MyLabel component='p' text='images' sx={style.descriptionImage}  />
                    </Box>
                </Box>
                <Box sx={style.lineArea} >
                    <Divider variant="middle" sx={style.line} />
                    <Information  text = "No Expiration" />
                    <Information  text = "Immediately Available" />
                    <Information  text = "Cost Per Image" value={priceImage.toFixed(2)} currency={currency} />
                </Box>
            </CardContent>
            <CardActions>
                <Box sx={style.buttonArea}>
                    <MyButton onClick={handlerButton} text="Choose package" variant="contained" sx={style.buttonEscolher} />
                </Box>
            </CardActions>
        </Card>
    )
}

export default MyCard