import { useEffect, useState } from "react"
import moment from 'moment'
import { Box, Dialog, DialogContent, DialogTitle, Divider } from "@mui/material"

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import classNames from "classnames";
import PropTypes from "prop-types";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SocialIcon from '../../components/ayrshare/socialIcons';
import Loading from "../../components/loading"
import usePlannerState from "../../hooks/ayrshare/planner/usePlannerState"
import "./styles.css";
import impoSvg from '../../assets/imgs/impo.svg'
import iconMaior2 from "../../assets/imgs/maior.svg";
import Collapse from '@mui/material/Collapse';

import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import plannerBackground from "../../assets/imgs/planner-background.png";

import {
    daysOfWeek,
    daysOfWeekMobile,
    createDaysForCurrentMonth,
    createDaysForNextMonth,
    createDaysForPreviousMonth,
    isWeekendDay,
    getMonthDropdownOptions,
    getYearDropdownOptions
  } from "./helpers";


import Generating from "./generating"
import MyDialog from "../../components/myDialog"
import AdPostModal from "./adPostModal"
import MyModal from "../../components/myModal"
import MyLabel from "../../components/myLabel"
import MyButton from "../../components/myButton"
import CustonSelect from "../../components/ayrshare/posts/custonSelect";
import MyImage from "../../components/myImage";
import { useNavigate } from "react-router-dom";
import ViewTemplate from "../../components/ayrshare/viewTemplate";
import PostPanel from "../../components/ayrshare/posts/postPanel";

const mapWidthMonths = {
    1: '5.7rem',
    2: '6.2rem',
    3: '5.2rem',
    4: '4rem',
    5: '3.7rem',
    6: '4.2rem',
    7: '3.7rem',
    8: '5.5rem',
    9: '7.7rem',
    10: '5.7rem',
    11: '7.2rem',
    12: '7.2rem'
  }

const imagesMapped = {
twitter: {
    text: 'X',
    backgroundColor: '#F0F0F0',
    color: 'black'
},
twitterActive: {
    text: 'X',
    backgroundColor: '#14171A',
    color: 'white'
},
linkedin: {
    text: 'LI',
    backgroundColor: '#F0F0F0',
    color: 'black',
},
linkedinActive: {
    text: 'LI',
    backgroundColor: '#0e76a8',
    color: 'white'
},
facebook: {
    text: 'FB',
    backgroundColor: '#F0F0F0',
    color: 'black'
},
facebookActive: {
    text: 'FB',
    backgroundColor: '#4267B2',
    color: 'white'
},

instagram: {
    text: 'IG',
    backgroundColor: '#F0F0F0',
    color: 'black'
},
instagramActive: {
    text: 'IG',
    backgroundColor: '#E1306C',
    color: 'white'
},

fbg: {
    text: 'FBG',
    backgroundColor: '#F0F0F0',
    color: 'black'
},
fbgActive: {
    text: 'FBG',
    backgroundColor: '#ff9d2e',
    color: 'white'
},

}

CalendarDayHeader.propTypes = {
    calendarDayObject: PropTypes.object.isRequired
  };
  
export function CalendarDayHeader({ dayClickedNow, calendarDayObject, isToday }) {
return dayClickedNow && window.innerWidth < 600 ? 
    (
        <div className={"day-grid-item-header-today"} style={{ color: 'white', backgroundColor: '#721384' }}>
            {calendarDayObject.dayOfMonth}
        </div>
    ) 
: isToday ? 
    (
        <div className="day-grid-item-header-today" style={{ backgroundColor: window.innerWidth > 600 && '#721384', color: window.innerWidth > 600 && 'white' }}>
            {calendarDayObject.dayOfMonth}
        </div>
    ) 
: 
    (
        <div className="day-grid-item-header">{calendarDayObject.dayOfMonth}</div>
    );
}

const MessageDialog = ({ open, handleClose, handlerSuccess }) => {

return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">
        <MyLabel component='p' text={" "} />
    </DialogTitle>
    <DialogContent>
        <Box sx={{ margin: '30px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', margin: '10px' }}>
            <MyLabel component='p' text={"Are you sure you want to abandon the creation of this post? All information will be lost."} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', margin: '10px' }}>
            <MyButton
            sx={style.cancelButton}
            onClick={handleClose}
            text='no'
            variant="contained"
            size="small"
            />
            <MyButton
            sx={{ ...style.exitButton, marginBottom: 0 }}
            onClick={handlerSuccess}
            text='yes'
            variant="contained"
            size="small"
            color='warning'
            />
        </Box>
        </Box>
    </DialogContent>
    </Dialog>
)
}

const RenderDay = ({ states, isMobile, profileSelected, calendarDayObject, postsInDay, setModalOpen }) => {
    //dayClickedNow = states.dayClickedNow.value
    //setDaysSelected = states.daysSelected.set
    //setModalOpen = states.modalOpen.set
    //updateListPost = states.posts.set
    const dayClickedNowProcessed = states.dayClickedNow.value === calendarDayObject.dateString
    const isToday = moment().format('YYYY-MM-DD') === moment(calendarDayObject.dateString).format('YYYY-MM-DD')
    //const isAfter = moment(calendarDayObject.dateString).isAfter(moment())
    
    const [postExpanded, setPostExpanded] = useState(null)
  
  
    const handlerAddPost = () => {
      states.daysSelected.set(calendarDayObject.dateString)
      setModalOpen(true)
    }

    const [dialogViewTemplate, setDialogViewTemplate] = useState(false);
  
  
    return (
      <div>
        {
            dialogViewTemplate ?
                <ViewTemplate profileSelected={profileSelected} post={dialogViewTemplate} open={dialogViewTemplate} handleClose={() => setDialogViewTemplate(false)} updateListPost={states.posts.set} />
            :
                <></>
        }
        {
            postExpanded && !isMobile ?
                <MyDialog isOpen={postExpanded} setIsOpen={() => setPostExpanded(null)}>
                    <div style={{ color: 'grey', fontWeight: 'bold', textAlign: 'left', position: 'relative', bottom: '1.5rem', width: '50%' }}>{moment(calendarDayObject.dateString).format('MMMM Do YYYY')}</div>
                    <Box sx={{ padding: '0.5rem 1rem', height: '30vh', paddingRight: '2rem', overflow: 'scroll' }}>
                    {postsInDay.map((post, key) => {
        
        
                        return <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        
                            <SocialIcon sx={{
        
                            width: '40px',
                            position: 'relative',
        
        
                            }} icon={post.platform} linked={true} />
        
                            <Box style={{ listStyleType: 'none', fontSize: '9pt', marginTop: '0.5rem', marginLeft: '1rem', fontWeight: 'bold' }} key={key}>
                            <Box sx={{ float: 'left' }}>{post.post.text.length > 50 ? `${post.post.text.slice(0, 50)}...` : post.post.text}</Box>
                            {post.post.image && <MyImage sx={{ float: 'right', height: '45px', borderRadius: '5px', width: '45px', objectFit: 'cover', position: 'relative', left: '1rem', bottom: '0.5rem' }} src={post.post.image} />}
                            <Box style={{ listStyleType: 'none', fontSize: '9pt', color: 'lightgrey' }} key={key}>
                                Status: {post.post.status} at {moment(post.post.send_date).format('hh:mm')}
                                <span style={{ marginLeft: '2rem', color: '#e2945c', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                setDialogViewTemplate(post)
                                setPostExpanded(null)
                                }}>
                                View post
                                </span>
                            </Box>
                            </Box>
        
                        </Box>
        
                        </Box>
        
                    }
        
                    )}
                    </Box>
                </MyDialog>
            :
                <></>
        }
        {
            postsInDay.length > 3 && !isMobile ? 
                <Box
                    width={'40%'} textAlign={'center'} fontSize={'0.6rem'} fontWeight={'bold'} style={{ cursor: 'pointer', float: 'right', marginTop: '0.4rem' }} 
                    onClick={() => {
                        setPostExpanded(calendarDayObject.dateString)
                    }} 
                >
                    {postsInDay.length - 3} more...
                </Box>
            :
                <></>
        }
        <CalendarDayHeader dayClickedNow={dayClickedNowProcessed} calendarDayObject={calendarDayObject} isToday={isToday} />
  
        {
            !isMobile ?
                <Box data-for={calendarDayObject.dateString} data-tip>
                    <Box sx={{
                            display: 'flex',
                            flexDirection: 'row', marginTop: '0.3rem', flexWrap: 'wrap', justifyContent: 'center',
                            height: '45px',
                            overflow: 'scroll'}}
                    >
                        {
                        !isMobile ? 
                            postsInDay.slice(0, 3).map((post, key) => (<PostPanel key={key} profileSelected={profileSelected} post={post} isCalendar updateListPost={states.posts.set} />)) 
                        : postsInDay?.length > 0 ? 
                            <FiberManualRecordIcon style={{ width: '120px', color: '#721384', position: 'relative', zIndex: 1, left: '18px'}} />
                        : <></>
                        }
                    </Box>
                </Box> 
            : 
                <Box data-for={calendarDayObject.dateString} data-tip>
                    <ul style={{
                    display: 'flex',
                    columnCount: 2,
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    padding: '15px 0',
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
        
                    }}>
                    {
                        !isMobile ? 
                            postsInDay.slice(0, 3).map((post, key) => (
                                <li style={{ listStyleType: 'none' }} key={key}>
                                    <PostPanel profileSelected={profileSelected} post={post} isCalendar updateListPost={states.posts.set} />
                                </li>)
                            ) 
                        : postsInDay?.length > 0 ? 
                            <FiberManualRecordIcon style={{ width: '8px', color: '#721384', zIndex: 1, bottom: '20px', left: '18px', position: 'relative' }} />
                        : <></>
                    }
                    </ul>
                </Box>
        }
      </div>
    )
  }

const RenderPage = ({children, page}) =>{
    
    if (page.value ==='generating'){
        return <Generating page={page} origin='calendar' />
    }
    else{
        return <>{children}</>
    }
}

const Planner = () =>{

    let states = usePlannerState()
    const navigate = useNavigate();

    const [isTodayClicked, setIsTodayClicked] = useState(false)
    const [openAddMenu, setOpenAddMenu] = useState(false)
    const [isAfterClicked, setIsAfterClicked] = useState(false)
    
    const isMobile = window.innerWidth < 600;

    const daysOfWeekFormatted = isMobile ? daysOfWeekMobile : daysOfWeek

    let currentMonthDays = createDaysForCurrentMonth(states.yearAndMonth.value[0], states.yearAndMonth.value[1]);
    let previousMonthDays = createDaysForPreviousMonth(states.yearAndMonth.value[0],states.yearAndMonth.value[1],currentMonthDays);
    let nextMonthDays = createDaysForNextMonth(states.yearAndMonth.value[0], states.yearAndMonth.value[1], currentMonthDays);
    let calendarGridDayObjects = [
        ...previousMonthDays,
        ...currentMonthDays,
        ...nextMonthDays
    ];

    useEffect(()=>{
        if (states.profileSelected.value){
            states.socials.set()
        }
    },[states.profileSelected.value])

    useEffect(()=>{
        states.submit.set()
    },[])

    useEffect(() => {
        if (!states.modalOpen.value && !states.addModalOpen.value && states.profileSelected.value && states.profileSelected.value > 0) {
            states.posts.set()
        }
    
      }, [states.modalOpen.value, states.addModalOpen.value, states.profileSelected.value]);

    const handleMonthSelect = (evt) => {
        let nextYear = states.yearAndMonth.value[0];
        let nextMonth = parseInt(evt.target.value, 10);
        states.yearAndMonth.set([nextYear, nextMonth]);
      };
    
      const handleYearSelect = (evt) => {
        let nextMonth = states.yearAndMonth.value[1];
        let nextYear = parseInt(evt.target.value, 10);
        states.yearAndMonth.set([nextYear, nextMonth]);
      };

    return(
        <Box  sx={states.page.value === 'generating' ? {
            backgroundImage:`url(${plannerBackground})`, 
            backgroundSize: 'cover',
            width:'100%', 
            display:'flex', 
            justifyContent:'center', 
            alignItems:'center',
            '@media (max-width: 600px)': {
                alignItems:'normal',

            }
        }:null} width={'100%'} display={'flex'} flexDirection={'column'}>
            <Loading render={states.submit.value} />
            {
                <RenderPage page={states.page}>
                    <div className="calendar-root" >
                        {
                            isMobile ? 
                                <MyDialog fullScreen={isMobile} notClose={true} sx={{ top: '5%', overflowY: 'auto' }} title="Post" isOpen={states.addModalOpen.value} setIsOpen={() => states.addModalOpen.set(false)}>
                                    <AdPostModal setClose={() => states.addModalOpen.set(false)} profile={states.profiles.value.length > 0 ? states.profiles.value.filter(item=> item.id === states.profileSelected.value)[0] : null} profileSelected={states.profileSelected.value} socials={states.socials} daysSelected={states.daysSelected.value} />
                                </MyDialog>
                            : 
                                <MyModal notClose={true} sx={{ display: 'flex', top: '5%', overflowY: 'auto' }} title="Post" isOpen={states.addModalOpen.value} setIsOpen={() => states.addModalOpen.set(false)}>
                                    <AdPostModal setClose={() => states.addModalOpen.set(false)} profile={states.profiles.value.length > 0 ? states.profiles.value.filter(item=> item.id === states.profileSelected.value)[0] : null} profileSelected={states.profileSelected.value} socials={states.socials} daysSelected={states.daysSelected.value} />
                                </MyModal>
                        }
                        <MessageDialog open={states.dialogOpen.value} handleClose={() => states.dialogOpen.set(false)} handlerSuccess={() => { states.modalOpen.set(!states.modalOpen.value); states.dialogOpen.set(false) }} />
                        <Box sx={{

                            display: 'flex',
                            justifyContent:'space-between',
                            width: '100%',
                            marginBottom:'10px',
                            '@media (max-width: 600px)': {
                            // paddingBottom: '30px'
                            }
                        }}>
                            <Box sx={{display:'flex', flexDirection:'row'}}>
                                <ArrowBackIcon onClick={()=>navigate('/planner-welcome')} fontSize={'large'} sx={{ cursor:'pointer', marginRight:'20px'}} />
                                <Box sx={style.selectArea} >
                                    <CustonSelect
                                    id='style'
                                    label="Select a brand"
                                    size={'small'}
                                    options={states.profiles.value}
                                    value={states.profileSelected.value}
                                    onChange={(e) => states.profileSelected.set(e.target.value)}
                                    noTranslate={true}
                                    />
                                </Box>
                            </Box>
                            {
                                !isMobile ? 
                                <Box>
                                    <ArrowBackIosIcon 
                                        sx={{ position: 'relative', cursor: 'pointer', fontSize: '1rem', top: '0.6rem', right: '0.6rem' }}
                                        onClick={() => {
                                            const yearAndMonthNew = states.yearAndMonth.value[1] === 1 ? [states.yearAndMonth.value[0] - 1, 12] : [states.yearAndMonth.value[0], states.yearAndMonth.value[1] - 1]
                                            states.yearAndMonth.set(yearAndMonthNew);
                                        }} 
                                    />
                                    <select
                                        style={{ position: 'relative', top: '0.5rem', width: mapWidthMonths[states.yearAndMonth.value[1]], marginRight: '1.5rem' }}
                                        className="month-select"
                                        value={states.yearAndMonth.value[1]}
                                        onChange={handleMonthSelect}
                                    >
                                        {
                                            getMonthDropdownOptions(null, false, window.innerWidth < 600).map(({ label, value }) => (
                                                <option value={value} key={value}>
                                                    {label}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <select
                                        style={{ position: 'relative', top: '0.5rem' }}
                                        className="year-select"
                                        value={states.yearAndMonth.value[0]}
                                        onChange={handleYearSelect}
                                    >
                                        {
                                            getYearDropdownOptions(states.yearAndMonth.value[0]).map(({ label, value }) => (
                                            <option value={value} key={value}>
                                                {label}
                                            </option>
                                            ))
                                        }
                                    </select>
                                    <ArrowForwardIosIcon 
                                        sx={{ position: 'relative', cursor: 'pointer', fontSize: '1rem', top: '0.6rem', left: '0.6rem' }}
                                        onClick={() => {
                                            const yearAndMonthNew =  states.yearAndMonth.value[1] === 12 ? [ states.yearAndMonth.value[0] + 1, 1] : [ states.yearAndMonth.value[0],  states.yearAndMonth.value[1] + 1]
                                            states.yearAndMonth.set(yearAndMonthNew);
                                        }}  
                                    />
                                </Box> : <></>
                            }

                            <Box sx={{
                            '@media (max-width: 600px)': {
                                width: '10%',
                            }
                            }}>
                                <MyImage onClick={() => navigate('/planner-settings')}  src={impoSvg} sx={{ float:'right', cursor:'pointer'}} />
                                {
                                    !isMobile ? 
                                        <Box sx={{position:'relative',float: 'right',marginRight:'1rem'}} >
                                            <Box 
                                                onClick={() => setOpenAddMenu(!openAddMenu)} 
                                                sx={{
                                                        cursor:'pointer',
                                                        display:'flex',
                                                        justifyContent:'space-between',
                                                        flexDirection:'row',
                                                        padding: '6px 16px',
                                                        borderRadius: '4px',
                                                        backgroundColor: '#721384', 
                                                        fontSize: '0.8rem', 
                                                        color: 'white',
                                                        float: 'right',
                                                        width:'130px',                                                        
                                                        marginRight:'1rem',
                                                        '@media (max-width: 600px)': {
                                                            fontSize: '0.6rem',
                                                        }
                                                    }}>
                                                <MyLabel sx={{fontWeight:600}} component={'p'} text='+ ADD' />
                                                <MyImage 
                                                    src={iconMaior2} 
                                                    alt={`icon Hamburger`} 
                                                    sx={{
                                                        color: '#FFF',
                                                        height:'18px', 
                                                        marginLeft: '0.5rem', 
                                                        marginTop:'0.1rem', 
                                                        transform: openAddMenu ? 'rotate(-90deg)' : 'rotate(90deg)', 
                                                        }} />
                                            </Box>
                                            <Box sx={{position:'absolute', top:'40px', zIndex:9999, left:'0px'}}>
                                            <Collapse  orientation="vertical" in={openAddMenu} collapsedSize={0} >
                                                <Box sx={{
                                                    backgroundColor:'#FFF', 
                                                    width:'130px',
                                                    boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
                                                    padding:'5px',
                                                    borderWidth:'1px',
                                                    borderColor:'rgb(242, 242, 242)'
                                                    }}>
                                                    <Box
                                                        onClick={() => {
                                                            setOpenAddMenu(false);
                                                            states.addModalOpen.set(true);
                                                        }}
                                                        sx={{
                                                            cursor:'pointer',
                                                            padding: '5px',
                                                            borderRadius:'4px',
                                                            
                                                            ':hover': {
                                                                backgroundColor: '#acacac',
                                                            }
                                                        }}
                                                        >
                                                        <MyLabel sx={{fontWeight:600, fontSize:'12pt'}} component={'p'} text='Single Post' />
                                                    </Box>
                                                    
                                                    <Divider sx={{marginTop:'5px', width:'100%'}} />
                                                    
                                                    <Box
                                                        onClick={()=>{
                                                            setOpenAddMenu(false);
                                                            navigate('/planner-add-editorial');
                                                        }}
                                                        sx={{
                                                            cursor:'pointer',
                                                            padding: '5px',
                                                            borderRadius:'4px',
                                                            
                                                            ':hover': {
                                                                backgroundColor: '#acacac',
                                                            }
                                                        }}
                                                    >
                                                        <MyLabel sx={{fontWeight:600, fontSize:'12pt'}} component={'p'} text='Entire month' />
                                                        <MyLabel sx={{fontWeight:600, fontSize:'10pt'}} component={'p'} text='(editorial plan)' />
                                                    </Box>
                                                </Box>
                                            </Collapse>

                                            </Box>
                                        </Box>
                                    :
                                    <></>
                                }
                            </Box>
                        </Box>
                        
                        {
                                isMobile ? 
                                <Box sx={{

                                    display: 'flex',
                                    justifyContent:'center',
                                    width: '100%',
                                    marginBottom:'10px',
                                    '@media (max-width: 600px)': {
                                    // paddingBottom: '30px'
                                    }
                                    }}>
                                    <ArrowBackIosIcon 
                                        sx={{ position: 'relative', cursor: 'pointer', fontSize: '1rem', top: '0.6rem', right: '0.6rem' }}
                                        onClick={() => {
                                            const yearAndMonthNew = states.yearAndMonth.value[1] === 1 ? [states.yearAndMonth.value[0] - 1, 12] : [states.yearAndMonth.value[0], states.yearAndMonth.value[1] - 1]
                                            states.yearAndMonth.set(yearAndMonthNew);
                                        }} 
                                    />
                                    <select
                                        style={{ position: 'relative', top: '0.5rem', width: mapWidthMonths[states.yearAndMonth.value[1]], marginRight: '1.5rem' }}
                                        className="month-select"
                                        value={states.yearAndMonth.value[1]}
                                        onChange={handleMonthSelect}
                                    >
                                        {
                                            getMonthDropdownOptions(null, false, window.innerWidth < 600).map(({ label, value }) => (
                                                <option value={value} key={value}>
                                                    {label}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <select
                                        style={{ position: 'relative', top: '0.5rem' }}
                                        className="year-select"
                                        value={states.yearAndMonth.value[0]}
                                        onChange={handleYearSelect}
                                    >
                                        {
                                            getYearDropdownOptions(states.yearAndMonth.value[0]).map(({ label, value }) => (
                                            <option value={value} key={value}>
                                                {label}
                                            </option>
                                            ))
                                        }
                                    </select>
                                    <ArrowForwardIosIcon 
                                        sx={{ position: 'relative', cursor: 'pointer', fontSize: '1rem', top: '0.6rem', left: '0.6rem' }}
                                        onClick={() => {
                                            const yearAndMonthNew =  states.yearAndMonth.value[1] === 12 ? [ states.yearAndMonth.value[0] + 1, 1] : [ states.yearAndMonth.value[0],  states.yearAndMonth.value[1] + 1]
                                            states.yearAndMonth.set(yearAndMonthNew);
                                        }}  
                                    />
                                </Box> : <></>
                            }

                        <div onClick={()=>setOpenAddMenu(false)} className="days-of-week">
                            {daysOfWeekFormatted.map((day, index) => (
                            <div
                                key={`${day}-${index}`}
                                className={classNames("day-of-week-header-cell", {
                                "weekend-day": [6, 0].includes(index)
                                })}
                            >
                                {day.toUpperCase()}
                            </div>
                            ))}
                        </div>
                        <div onClick={()=>setOpenAddMenu(false)} className={isMobile ? "days-grid-mobile" : "days-grid"}>
                            {
                                calendarGridDayObjects.map((day, key) => {
                                    
                                    const isToday = moment().format('YYYY-MM-DD') === moment(day.dateString).format('YYYY-MM-DD')
                                    const isAfter = moment(day.dateString).isAfter(moment())

                                    const listPostsValueProcesed = states.posts?.value ? states.posts?.value : []


                                    const postsInDay = listPostsValueProcesed.filter(({ post }) => moment(post.post_date).format('YYYY-MM-DD') === moment(day.dateString).format('YYYY-MM-DD')).map((post) => {
                                        return (isAfter || isToday) ? {
                                            image: imagesMapped[`${post.platform}Active`], ...post
                                        } : {
                                            image: imagesMapped[post.platform], ...post
                                        }
                                    })
                                    return (
                                        <div
                                            key={day.dateString}
                                            onClick={() => {
                                                states.mobilePosts.set(postsInDay)
                                                states.dayClickedNow.set(day.dateString)
                                                setIsTodayClicked(moment().format('YYYY-MM-DD') === moment(day.dateString).format('YYYY-MM-DD'))
                                                setIsAfterClicked(moment(day.dateString).isAfter(moment()))
                                            }}
                                            className={
                                                classNames("day-grid-item-container", {
                                                    "weekend-day": isWeekendDay(day.dateString),
                                                    "current-month": day.isCurrentMonth
                                                })
                                            }
                                        >
                                            <div key={day} className="day-content-wrapper">
                                                {
                                                    <RenderDay 
                                                        isMobile={isMobile}
                                                        states={states}
                                                        profileSelected={states.profileSelected.value} 
                                                        dayClickedNow={states.dayClickedNow.value} 
                                                        isGenerateModal={true} 
                                                        postsInDay={postsInDay} 
                                                        //setIsAfterClicked={setIsAfterClicked} 
                                                        //setIsTodayClicked={setIsTodayClicked} 
                                                        setMobilePosts={states.mobilePosts.set} 
                                                        calendarDayObject={day} 
                                                        setDaysSelected={states.daysSelected.set} 
                                                    />
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div style={{position:'absolute', bottom: 10, left:0, width:'100%', zIndex:88}}>
                        {isMobile &&
                        <div>
                            {states.mobilePosts.value.map((post, key) => {
                            return <div style={{ backgroundColor: post.status === 'error' ? '#f7cac6' : post.status === 'success' ? '#daece4' : '#f8f8f8', paddingTop: '10px', paddingBottom: '5px', marginBottom: '0.4rem' }} key={key}>
                                <PostPanel profileSelected={states.profileSelected.value} mobile post={post} isCalendar updateListPost={states.posts.set} />
                            </div>
                            })}
                        </div>
                        }

                        {(isAfterClicked || isTodayClicked) && window.innerWidth < 600 &&
                        <Box onClick={() => states.addModalOpen.set(true)} sx={{ backgroundColor: '#f8f8f8', padding: '10px', display: 'flex', justifyContent: 'center', cursor: 'pointer', fontSize: '1rem', marginRight: '0.2rem', color: '#721384', marginBottom: '1rem' }}>
                            <AddIcon sx={{ fontSize: '1rem', position: 'relative', top: '0.3rem', }} /> <MyLabel text={"add post"} />
                        </Box>

                        }
                    </div>
                </RenderPage>
            }
        </Box>
    )
}

const style = {
    cancelButton: {
        width: '180px',
        color: 'black',
        backgroundColor: '#cfcfcf',
        ':hover': {
          backgroundColor: '#ebebeb',
        }
      },
      exitButton: {
        width: '180px',
      },
      selectArea: {
        width: '200px',
    },
}

export default Planner