import { useState, useContext } from "react";
import { AuthContext } from "../contexts/auth";

import { FormattedMessage } from "react-intl";
import { getUserDetail } from "../services/getUserDetail";
import { putUserDetail } from "../services/putUserDetail";

import toast from "react-hot-toast";

const useUserDetailState = () => {
    const { signOut, setUser } = useContext(AuthContext);

    const [countrys, setCountrys] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);

    const [fistName, setFistName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [using, setUsing] = useState("");

    const [country, setCountry] = useState("Italy");
    const [profession, setProfession] = useState("");
    const [company, setCompany] = useState("");
    const [subscription, setSubscription] = useState("");
    const [inUpdate, setInUpdate] = useState(false);
    const [language, setLanguage] = useState("");
    const [freeImage, setFreeImage] = useState(0);
    const [paymentType, setPaymentType] = useState("undefined");

    const [update, setUpdate] = useState(false);
    const [get, setGet] = useState(false);

    const handleGet = () => {
        if (update || get) {
            return;
        }
        setGet(true);
        return getUserDetail()
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    } else if (json.data.code === "violation of guidelines") {
                        toast(
                            <FormattedMessage key={4} id={json.data.detail} />
                        );
                    } else if (json.data.code === "no tokens") {
                        toast.error(<FormattedMessage id={json.data.detail} />);
                    } else {
                        <FormattedMessage id={"unknown error"} />;
                    }
                } else {
                    let user = json.data.user;
                    let count = [];

                    json.data.countries.forEach((item) => {
                        if (user.country !== "" && item[0] === user.country) {
                            setCountry(item[1]);
                        }
                        count.push(item[1]);
                    });
                    setCountrys(count);

                    let subsc = [];

                    json.data.subscription.forEach((item) => {
                        subsc.push(item.name);
                    });

                    setFreeImage(user.images - user.images_used)
                    setSubscriptions(subsc);
                    setFistName(user.first_name);
                    setLastName(user.last_name);
                    setEmail(user.email);
                    setPaymentType(user.type_of_payment);
                    setUsing(user.using);
                    setInUpdate(user.in_update);
                    setProfession(user.profession);
                    setCompany(user.company);
                    setSubscription(user.subscription);
                    if (user.language !== "") {
                        setLanguage(user.language);
                    } else {
                        //const language = window.navigator.language;
                        //console.log(language);
                    }
                }
                setGet(false);
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast("Connection failed to server");
                } else {
                    toast(<FormattedMessage key={4} id={error.message} />);
                }
                setGet(false);
            });
    };

    
    const handleUpdate = () => {
        if (update || get) {
            return;
        }
        setUpdate(true);
        let json = {
            first_name: fistName,
            last_name: lastName,
            email: email,
            country: country,
            profession: profession,
            company: company,
            subscription: subscription,
            language: language,
            using: using,
        };

        return putUserDetail(json)
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    } else if (json.data.code === "violation of guidelines") {
                        toast(
                            <FormattedMessage key={4} id={json.data.detail} />
                        );
                    } else if (json.data.code === "no tokens") {
                        toast.error(<FormattedMessage id={json.data.detail} />);
                    } else {
                        <FormattedMessage id={"unknown error"} />;
                    }
                } else {
                    if (json?.type === "success") {
                        toast.success(
                            <FormattedMessage id={"Udated  profile."} />
                        );
                    }
                }
                setUpdate(false);
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast("Connection failed to server");
                } else {
                    toast(<FormattedMessage key={4} id={error.message} />);
                }
                setUpdate(false);
            });

    };
    return {
        fistName: {
            value: fistName,
            set: setFistName,
        },
        lastName: {
            value: lastName,
            set: setLastName,
        },
        email: {
            value: email,
            set: setEmail,
        },
        country: {
            value: country,
            set: setCountry,
        },
        profession: {
            value: profession,
            set: setProfession,
        },
        company: {
            value: company,
            set: setCompany,
        },
        subscription: {
            value: subscription,
            set: setSubscription,
        },
        language: {
            value: language,
            set: setLanguage,
        },
        countrys: {
            value: countrys,
            set: setCountrys,
        },
        subscriptions: {
            value: subscriptions,
            set: setSubscriptions,
        },

        paymentType: {
            value: paymentType,
            set: setPaymentType,
        },
        using: {
            value: using,
            set: setUsing,
        },
        inUpdate: {
            value: inUpdate,
            set: setInUpdate,
        },
        freeImage: {
            value: freeImage,
            set: setFreeImage,
        },
        update: {
            value: update,
            set: handleUpdate,
        },
        get: {
            value: get,
            set: handleGet,
        },
    };
};

export default useUserDetailState;
