const CHANNELS = [
    {value:0, title:'facebook', icon: 'facebook', active: true},
    {value:1, title:'fbg', icon: 'fbg', active: true},
    {value:2, title:'instagram', icon: 'instagram', active: true},
    {value:3, title:'linkedin', icon: 'linkedin', active: true},
    {value:4, title:'twitter', icon: 'twitter', active: true},
    {value:5, title:'google', icon: 'google', active: false},
    {value:6, title:'pinterest', icon: 'pinterest', active: false},
    {value:7, title:'reddit', icon: 'reddit', active: false},
    {value:8, title:'telegram', icon: 'telegram', active: false},
    {value:9, title:'tiktok', icon: 'tiktok', active: false},
    {value:10, title:'youtube', icon: 'youtube', active: false},
]

export default CHANNELS;