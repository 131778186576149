import React from "react";
import { FormattedMessage } from "react-intl";


import checkImg from "../../assets/imgs/Raggruppa_159.svg";
import SubscriptionPanel from "./subscriptionPanel";
import CreditPanel from "./creditPanel";

import * as C from "./styles.js";
import currencySimbol from "../../utils/currencySimbol";


const Information = ({number, text, integration}) => {

    if (number <= 0) {
        return (
            <C.InformationArea>
                <C.InLine>
                   {" "}
                </C.InLine>
                <C.InformationDescription>{" "}</C.InformationDescription>
        </C.InformationArea>
        )
    }
    else if (integration) {
        return (
            <C.InformationArea>
                <C.InLine>
                    <C.Icon alt="checkImg" src={checkImg} ></C.Icon>

                    <C.InformationValue><FormattedMessage id="pricing_p_8" /></C.InformationValue>
                </C.InLine>
                <C.InformationDescription>{number} <FormattedMessage id={text} /></C.InformationDescription>
        </C.InformationArea>
        )
    }
    else {

        return (
            <C.InformationArea>
            <C.InLine>
                <C.Icon alt="checkImg" src={checkImg} ></C.Icon> 
                <C.InformationValue>{number.toLocaleString()}</C.InformationValue>
            </C.InLine>
            <C.InformationDescription><FormattedMessage id={text} /></C.InformationDescription>
            </C.InformationArea>
            
        )
    }
}

const Value = ({currency, value, credit}) =>{
    let icon = currencySimbol(currency)

    return (
        <C.Value>
            {icon} {value} 
            {credit?<></>:<C.Month> / <FormattedMessage id="pricing_p_9"></FormattedMessage></C.Month>}
        </C.Value>)
}


const PaymentPanel = ({
    title,
    closeModal,
    plan_id,
    paypalCancel,
    information,
    value,
    currency,


    subscription,
    plan,
    credit,
    images,
    imageId,
    userId
}) => {
    return (
        <C.Container>
            <C.Left credit={credit}>
            </C.Left>
            <C.Area credit={credit}>
                <C.AreaCap>
                    <C.Row>
                        <C.Title>
                            <FormattedMessage id={title} />
                        </C.Title>
                    </C.Row>
                    <C.Row>
                    <C.Line />
                        <C.ValueArea>
                            <Value currency={currency} value={value} credit={credit}/>
                        </C.ValueArea>
                    </C.Row>
                    {
                        credit ?
                            <CreditPanel title={title} images={images} value={value} 
                                closeModal={closeModal} imageId={imageId} userId={userId}
                                currency={currency}
                             />
                        : 
                            <SubscriptionPanel title={title} subscription={subscription} 
                                plan={plan} closeModal={closeModal} plan_id={plan_id} 
                                paypalCancel={paypalCancel} information={information} value={value}
                                currency={currency}
                            />
                    }
                </C.AreaCap>
            </C.Area>
        </C.Container>
    );
};
export default PaymentPanel;
