const endpoints = {
    access: {
        register: "access/register/",
        login: "access/login/",
        csrf: "access/csrf/",
        subscriptionType: "access/subscriptiontype/",
        picturePack: "access/picturepack/",
        finishedTutorial: "access/finishedtutorial/",
        loginFacebook: "access/loginfacebook/",
        user: "access/user/",
        term_pdf: "access/term_pdf/",
        askchangepassword: "access/askchangepassword/",
    },
    historic: "historic/",
    panelInformation: "panel/",
    billing: "billing/",
    countryes: 'countries/',
    purchasedTokens: 'tokens/',

    paypal: {
        subscription: "register_paypal/",
        createSubscription: "subscription_paypal/",
        oneShoot: "one_shoot_paypal/",
        cancel: "paypal_cancel/",
        upgrade: "paypal_upgrade/"
    },
    monsterai:{
        imagesSDXL: "monsterai/imagessdxl/",
    },
    openai: {
        adFromProductDescription: "openai/adfromproductdescription/",
        articlepostsgenerator: "openai/articlepostsgenerator/",
        chat: "openai/chat/",
        dalle: "openai/dalle/",
        englishgrammarcheck: "openai/englishgrammarcheck/",
        essayOutline: "openai/essayoutline/",
        keyword: "openai/keyword/",
        productNameGenerator: "openai/productnamegenerator/",
        moderation: "openai/moderation/",
        saveChat: "openai/savechat/",
    },
    ayrshare: {
        profile: "ayrshare/profile/",
        profileKey: "ayrshare/profilekey/",
        profilepost: "ayrshare/profilepost/",
        profileTemplate: "ayrshare/profile/template/",
        postLog: "ayrshare/profile/log/",
    },
};

export default endpoints;
