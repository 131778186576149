import { createContext, useState } from "react";
import api from "../services/api";
import Cookies from 'js-cookie'
import { getPanelDetail } from '../services/getPanelDetail'

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [historic, setHistoric] = useState(null);
    
    const [upgradePanel, setUpgradePanel] = useState({
        title: 'This is a PRO feature.',
        text: 'Integrate your Digital Channels and unlock the full potential of our platform by upgrading to the PRO plan and enjoy this exclusive feature!',
        textButton: 'Upgrade your plan',
        show: false,
        data: null,
        view: false,
        navegate:'/pricing'
    });

    const [updatePanel, setUpdatePanel] = useState(false);
    const [language, setLanguage] = useState();
    const [integration, setIntegration] = useState({open:false, profile:null, publications:[], image:''});
    const [signed, setSigned] = useState(false);

    const signIn = (register, remember) => {
        let token = register.token;
        api.defaults.headers.common.Authorization = `Bearer ${register.token}`;

        let user = register.user;

        let data = {
            user: user,
            token: token,
            remember: remember,
        };

        Cookies.set("airton@data", JSON.stringify(data), { expires: 90 });
        setUser(user);
        setLanguage(user.language);
        setSigned(true);
        return "SUCCESS";
    };

    const signOut = () => {
        api.defaults.headers.common.Authorization = `Bearer `;

        Cookies.remove("airton@data");
        setUser(null);
        setSigned(false);
        return "SUCCESS";
    };

    const _setUser = () => {
        getPanelDetail()
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    }
                }
                else {
                    const session = JSON.parse(Cookies.get('airton@data'))
                    let data = {
                        ...session,
                        user: json.data
                    };
                    Cookies.set("airton@data", JSON.stringify(data), { expires: 90 });
                    setUser(json.data);
                    setLanguage(json.data.language);
                }
                
            })
            .catch((error) => {
                return "ERROR";
            });
        return "SUCCESS";
    };

    
    return (
        <AuthContext.Provider
            value={
                { 
                    user, 
                    signed: signed, 
                    signIn, 
                    signOut, 
                    setUser:_setUser, 
                    language:{ set: setLanguage, value: language}, 
                    historic:{ set: setHistoric, value: historic},
                    updatePanel:{ set: setUpdatePanel, value: updatePanel},
                    integration:{ set: setIntegration, value: integration},
                    upgradePanel:{ set: setUpgradePanel, value: upgradePanel},
                }}
        >
            {children}
        </AuthContext.Provider>
    );
};
