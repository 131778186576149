import OpenAI from "openai";
import { postHistoric } from "../postHistoric";
import formatDate from "../../utils/formatDate";

const openAIAssistant = async (api, message, idAsssistent, setReturnText, send, updateHistoric, model='') => {

    const apiKey = process.env.REACT_APP_OPENAI_KEY;
    const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true});

    if (model===''){
        model = 'gpt-4o-2024-08-06';
    }

    const stream = await openai.beta.threads.createAndRun({
        assistant_id: idAsssistent,
        model: model,
        thread: {
          messages: [
            { role: "user", content: message },
          ],
        },
        stream: true
    });
    
    let finalText = ''

    for await (const event of stream) {
        if (event?.data?.delta && event?.data?.delta.content){
            finalText += event?.data?.delta.content[0].text.value
            setReturnText(finalText)

        }
        else if (event?.data?.content?.length > 0){
            finalText = event?.data?.content[0].text.value
            setReturnText(event?.data?.content[0].text.value)
        }
    }

    let historic = {
        send: send,
        text: JSON.stringify([
            { role: "user", content: message },
          ]),
        result: finalText,
        date: formatDate(new Date()),
        action: api,
    }

    await postHistoric(historic)
    updateHistoric.set(finalText);
}

export default openAIAssistant