import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { AuthContext } from "../contexts/auth";
import { getHistoric } from "../services/getHistoric";

const useHistoricState = () => {
    const { signOut, updatePanel } = useContext(AuthContext);
    

    const [historics, setHistorics] = useState([]);
    const [submit, setSubmit] = useState(false);

    const handleAPI = async () => {
        if (submit) {
            return;
        }
        setSubmit(true);
        getHistoric()
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    }
                }
                setSubmit(false);
                setHistorics(json.data.results);
                updatePanel.set(true)
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast(
                        <FormattedMessage id="Connection failed to server" />
                    );
                } else {
                    toast(<FormattedMessage id={error.message} />);
                }
                setSubmit(false);
            });
    };
    return {
        historics: historics,
        updateHistoric: {
            value: submit,
            set: handleAPI,
        },
    };
};

export default useHistoricState;
