import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';

import { AuthContext } from "../../contexts/auth";

import imgArtigle from "../../assets/imgs/panels/article.svg";
import imgPhotos from "../../assets/imgs/panels/photos.svg";
import imgHat from "../../assets/imgs/panels/hat.svg";
import imgArrowDown from "../../assets/imgs/arrowDown.svg";

import useUserDetailState from "./../../hooks/useUserDetailState";
import useBillingDetailState from "./../../hooks/useBillingDetailState";
import usePanelInformationState from "../../hooks/usePanelInformationState";
import useCancelPaypalSubscription from "../../hooks/useCancelPaypalSubscription";

import MyLabel from "../../components/myLabel";
import MyButton from './../../components/myButton';
import MyImage from "../../components/myImage";
import MyProgressBar from "../../components/myProgressBar";
import MyInput from './../../components/myInput';
import MySelect from './../../components/mySelect';

import { style } from "./styles";

const Values = ({ value, valueMax }) => {
    return (
        <p style={style.monthUsageValue}>{value} / <strong>{valueMax}</strong></p>
    );
};

const MonthUsage = ({ title, src, description, value, valueMax }) => {
    let porcent = (value * 100) / valueMax;
    return (
        <Box sx={style.monthArea}>
            <Box>
                <MyImage src={src} alt={`icon ${title}`} sx={style.icon} />
            </Box>
            <Box sx={{width:'100%'}}>

                <Box>
                    {title !== "" ? <MyLabel component='h3' text={title} sx={style.monthUsageTitle}  /> : <></>}
                </Box>
                <Box>
                    <Box sx={style.monthDescriptionArea}>
                        {description !== "" ? (
                            <MyLabel component='p' text={description} sx={style.monthUsageDescription}  />
                        ) : (
                            <></>
                        )}
                    </Box>
                    {title === "English Grammar Check" ? (
                        <MyLabel component='p' text="Unlimited"sx={style.monthUsageDescription}  />
                    ) : (
                        <Values value={value} valueMax={valueMax} />
                    )}
                </Box>
                <Box sx={{width:'100%'}}>
                    <MyProgressBar value={porcent} />
                </Box>
            </Box>
        </Box>
    );
};

const CollapsePanel = ({title, children, show, set, onClick}) =>{

    let transform = `scaleY(1)`;
    if (show){
        transform = `scaleY(-1)`
    }

    return (
            <Collapse in={show} collapsedSize={60}>
                <Card sx={style.panel}>
                    <CardContent>
                        <Box onClick={() =>set(!show)} sx={style.panelTitleArea}>
                            <MyLabel component='h2' text={title} sx={style.title}  />
                            <MyImage src={imgArrowDown} alt={`icon open`} sx={{...style.expansiveIcon, transform:transform}} />
                        </Box>
                        <Box>
                            <Box component="form" sx={style.form}>
                                <Box sx={style.formRow}>
                                    {children}
                                </Box>
                                <Box sx={style.formRowButton}>
                                    <MyButton size="small" onClick={onClick} text="Update Settings" variant="contained" />
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Collapse>
    )
}

function User({popup}) {
    const { language } = useContext(AuthContext);
    const navigate = useNavigate();
    const [openProfile, setOpenProfile] = useState(false);
    const [openBilling, setOpenBilling] = useState(false);

    const userState = useUserDetailState();
    const billingState = useBillingDetailState();
    const { user, updateUserDetail } = usePanelInformationState(popup);
    const { put } = useCancelPaypalSubscription();

    useEffect(() => {
        if (!userState.get.value) {
            updateUserDetail.set();
            userState.get.set();
            billingState.get.set();
        }
    }, []);

    const cancelHandler = () => {
        put.set();
    };

    const pricingHandler = () => {
        navigate("/pricing");
    };

    return (
        <Fade in={true} timeout={500} >
            <Stack spacing={2} sx={style.stack}>
                <Box >
                    <Box sx={style.titleArea}>
                        <Box>
                            <MyLabel component='h2' text='My Account' sx={style.title}  />
                        </Box>
                        <Box >
                            <Box sx={style.titleAreaDescription}>
                                <MyLabel component='p' text='Your Plan' sx={style.titleDescription}  />
                                <p style={style.titleDescription}>-</p>
                                {userState.subscription.value !== "" ? (
                                    <MyLabel component='p' text={userState.subscription.value} sx={{...style.titleDescription, fontWeight:'600'}}  />
                                ) : (
                                    <></>
                                )}
                            </Box>
                            <MyButton size="small" onClick={pricingHandler} text="Upgrade" variant="contained" sx={style.upgradeButton} />
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Card sx={style.panel}>
                        <CardContent>
                            <Box>
                                <MyLabel component='h2' text='Month Usage' sx={style.title}  />
                                {
                                    user.is_pro ?
                                        <MyButton size="small" onClick={cancelHandler} text="cancel plan" variant="contained" sx={style.cancelButton} />
                                        : null
                                }
                            </Box>
                            <MonthUsage
                                src={imgArtigle}
                                title="Characters Generate"
                                description="Text Generator, Articles/Posts Generator, Produc Name Generator, Keywords Generator, Topic Generator"
                                value={user.tokens_used}
                                valueMax={user.tokens}
                            />
                            <MonthUsage
                                src={imgPhotos}
                                title="Images Generated"
                                description=""
                                value={user.images_used}
                                valueMax={user.images}
                            />
                            <MonthUsage
                                src={imgHat}
                                title="Translation and English Grammar Check"
                                description=""
                                value={user.translations_used}
                                valueMax={user.translations}
                            />
                        </CardContent>
                    </Card>
                </Box>
                <Box>
                    <CollapsePanel 
                        title="My Profile" 
                        show={openProfile} 
                        set={setOpenProfile} 
                        onClick={(e) => {
                            e.preventDefault();
                            userState.update.set();
                            switch (userState.language.value){
                                case 'spanish':
                                    language.set(1)
                                    break
                                case 'portuguese':
                                    language.set(2)
                                    break
                                case 'italian':
                                    language.set(3)
                                    break
                                default:
                                    language.set(0)
                        }}}>
                        <Box sx={style.formColumn}>
                            <MyInput 
                                size="small"
                                type='text'
                                id='name'
                                label='First Name'
                                required={false}
                                value={userState.fistName.value}
                                onChange={(e) => userState.fistName.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='text'
                                id='name2'
                                label='Last Name'
                                required={false}
                                value={userState.lastName.value}
                                onChange={(e) => userState.lastName.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='email'
                                id='email'
                                label='E-mail'
                                required={false}
                                value={userState.email.value}
                                onChange={(e) => userState.email.set(e.target.value)}
                            />
                            <MySelect 
                                size="small"
                                id="country"
                                label="Country"
                                options={userState.countrys.value}
                                value={userState.country.value}
                                onChange={(e)=>userState.country.set(e.target.value)}
                            />

                        </Box>
                        <Box sx={style.formColumn}>
                            <MyInput 
                                size="small"
                                type='text'
                                id='profession'
                                label='Profession'
                                required={false}
                                value={userState.profession.value}
                                onChange={(e) => userState.profession.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='text'
                                id='company'
                                label='Company name'
                                required={false}
                                value={userState.company.value}
                                onChange={(e) => userState.company.set(e.target.value)}
                            />
                            <MySelect 
                                size="small"
                                id="country"
                                label="What will you be using AdMind For"
                                options={[
                                    "",
                                    "For a small business",
                                    "For a business",
                                    "For the company I work for",
                                    "For myself",
                                    "For my clients",
                                    "I’m a Student",
                                    "I’m a Teacher",
                                ]}
                                value={userState.using.value}
                                onChange={(e)=>userState.using.set(e.target.value)}
                            />
                            <MySelect 
                                size="small"
                                id="language"
                                label="Platform language"
                                options={[
                                    "english",
                                    "spanish",
                                    "portuguese",
                                    "italian",
                                ]}
                                value={userState.language.value}
                                onChange={(e)=>userState.language.set(e.target.value)}
                            />
                        </Box>
                    </CollapsePanel>
                </Box>
                <Box>
                    <CollapsePanel 
                        title="Billing Settings" 
                        show={openBilling} 
                        set={setOpenBilling} 
                        onClick={(e) => {
                            e.preventDefault();
                            billingState.update.set();
                        }}>
                        <Box sx={style.formColumn}>
                            <MyInput 
                                size="small"
                                type='text'
                                id='billingName'
                                label='Name or Company Name'
                                required={false}
                                value={billingState.name.value}
                                onChange={(e) => billingState.name.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='text'
                                id='billingCode'
                                label='Fiscal Code / VAT Number'
                                required={false}
                                value={billingState.fiscalCode.value}
                                onChange={(e) => billingState.fiscalCode.set(e.target.value)}
                            />
                            <MySelect 
                                size="small"
                                id="billingCountry"
                                label="Country"
                                options={billingState.countrys.value}
                                value={billingState.country.value}
                                onChange={(e)=>billingState.country.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='text'
                                id='billingRegion'
                                label='Region'
                                required={false}
                                value={billingState.region.value}
                                onChange={(e) => billingState.region.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='text'
                                id='billingProvincia'
                                label='Provincia'
                                required={false}
                                value={billingState.province.value}
                                onChange={(e) => billingState.province.set(e.target.value)}
                            />
                        </Box>
                        <Box sx={style.formColumn}>
                            <MyInput 
                                size="small"
                                type='text'
                                id='billingCity'
                                label='City'
                                required={false}
                                value={billingState.city.value}
                                onChange={(e) => billingState.city.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='text'
                                id='billingAdress'
                                label='Address'
                                required={false}
                                value={billingState.adress.value}
                                onChange={(e) => billingState.adress.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='text'
                                id='billingCAP'
                                label='CAP'
                                required={false}
                                value={billingState.cap.value}
                                onChange={(e) => billingState.cap.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='text'
                                id='billingUniqueCode'
                                label='Unique Code'
                                required={false}
                                value={billingState.uniqueCode.value}
                                onChange={(e) => billingState.uniqueCode.set(e.target.value)}
                            />
                            <MyInput 
                                size="small"
                                type='text'
                                id='billingEmail'
                                label='Email PEC'
                                required={false}
                                value={billingState.emailPec.value}
                                onChange={(e) => billingState.emailPec.set(e.target.value)}
                            />
                        </Box>
                    </CollapsePanel>
                </Box>
            </Stack>
        </Fade>
    );
}

export default User;
