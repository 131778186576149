import React from "react";
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import useChangePasswordState from "../../hooks/useChangePasswordState";

import Loading from "../../components/loading";
import MyImage from "../../components/myImage";
import MyLabel from "../../components/myLabel";
import MyInput from './../../components/myInput';
import MyButton from './../../components/myButton';

import logo from "../../assets/imgs/logo03.png";
import logo1 from "../../assets/imgs/logo01.png";

import { style } from "./styles";


function ChangePassword() {
    const { email, submit, errors } = useChangePasswordState();

    let navigate = useNavigate();

    const loginClick = () => {
        navigate("/login");
    };

    return (
        <Fade in={true} timeout={500}>
            <Stack direction="row" sx={style.stack}>
                <Loading render={submit.value} />
                <Container sx={style.leftArea}>
                    <Box sx={style.logo1}>
                        <Link href="https://admind.ai/" underline="none">
                            <MyImage src={logo} alt={'Admind logo'} sx={style.logo} />
                        </Link>
                    </Box>
                </Container>
                <Container sx={style.rightArea}>
                    <Box sx={style.logo2}>
                        <Link href="https://admind.ai/" underline="none" >
                            <MyImage src={logo1} alt={'Admind logo'} sx={style.logo} />
                        </Link>
                    </Box>
                    <Box sx={style.titleArea}>
                        <MyLabel component='h1' text='Change Password' sx={style.title}  />
                    </Box>
                    <Box sx={style.inputArea}>
                        <MyInput 
                            icon="user"
                            error={errors.value.email}
                            type='email'
                            id='email'
                            label='E-mail'
                            required={true}
                            value={email.value}
                            onChange={(e) => email.set(e.target.value)}
                        />
                    </Box>
                    <Box sx={style.loginArea}>
                        <MyButton disabled={submit.value} onClick={submit.set} text="Send" variant="contained" size="large" sx={style.loginButton} />
                    </Box>
                    <Box sx={style.lineArea}>
                        <Divider variant="middle" sx={style.line} />
                    </Box>
                    <Box sx={style.newUserArea}>
                        <MyLabel component='p' text='Already changed your password'  />
                        {"?"}
                        <MyButton onClick={loginClick} text='Sign in here' variant="text" />
                    </Box>
                    
                </Container>
            </Stack>
        </Fade>
   )
}
export default ChangePassword;
