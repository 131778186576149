import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import { AuthContext } from "../../contexts/auth";

import PaymentPanel from "../../components/paymentPanel";
import MyModal from "../../components/myModal";
import useUserDetailState from "./../../hooks/useUserDetailState";
import useCreditState from "./../../hooks/useCreditState";
import CreditsCards from "../../components/creditsCards";
import Loading from "../../components/loading";
import MyLabel from "../../components/myLabel";
import MySelect from '../../components/mySelect';

import { style } from "./styles";


const Credits = () => {
    const state = useUserDetailState();
    const creditState = useCreditState()
    const { user } = useContext(AuthContext);

    const [stateModal, setStateModal] = useState({
        title:'',
        open:false,
        imageId: 0,
        value: 0,
        images: 0,
        currency: 'euro',
    });

    const BodyModal = ({ title, value, images, imageId, userId, currency }) => {
        return (
            <PaymentPanel
                title={title}
                closeModal={()=>setStateModal({
                    title:'',
                    open:false,
                    imageId: 0,
                    value: 0,
                    images: 0,
                    currency: 'euro',
                })}
                paypalCancel={()=>console.log('Ainda não implementado')}
                credit={true}
                value={value}
                images={images}
                imageId={imageId}
                userId={userId}
                currency={currency}
            />
        );
    };


    useEffect(() => {
        if (!creditState.getPrincings.value) {
            creditState.getPrincings.set();
        }
    }, []);

    useEffect(() => {
        creditState.getPrincings.set();
    }, [creditState.currency.value]);

    const buttonClick = async (title, value, images, imageId) => {
        if (state.subscription.value === title) {
            toast.success(<FormattedMessage id="Your Plan" />);
        } else if (state.inUpdate.value) {
            toast.success(
                <FormattedMessage id="Your plan is updating. Wait a few minutes" />
            );
        } else {
            setStateModal({
                title: title,
                open:true, 
                imageId: imageId, 
                value: value,
                images: images,
                currency: creditState.currency.value
            })
        }
    };

    
    return (
        <Fade in={true} timeout={500}>
        <Stack sx={style.stack}>
            <Box sx={style.counter}>
                <Loading render={creditState.getPrincings.value} />
                <Box sx={style.header}>
                    <MyLabel component='h1' text='PricingMessage' sx={style.title}  />
                </Box>
                <Box sx={style.selectArea}>
                        <Box>
                            <MySelect 
                                sx={style.select}
                                size="small"
                                id='currency'
                                label='currency'
                                options={['EUR', 'USD','BRL']}
                                value={creditState.currency.value}
                                onChange={(e)=>creditState.currency.set(e.target.value)}
                            />
                        </Box>
                    </Box>
                <Box>
                    <CreditsCards 
                        credits={creditState.credits.value} 
                        update={state.inUpdate.value}
                        state={state} buttonClick={buttonClick}
                        currency={creditState.currency.value}
                    />
                </Box>
            </Box>
            <MyModal title="Credits" isOpen={stateModal.open} setIsOpen={()=>setStateModal({...stateModal, open:!stateModal.open})}>
                <BodyModal
                    title={stateModal.title}
                    value={stateModal.value}
                    images={stateModal.images}
                    imageId={stateModal.imageId}
                    userId={user.id}
                    currency={stateModal.currency}
                />
            </MyModal>
        </Stack>
    </Fade>
    );
};
export default Credits;
