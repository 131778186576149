import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import { postProfile } from "../../../services/ayrshare/postProfile";
import { getTemplatePost } from "../../../services/ayrshare/getTemplatePost";
import { getProfileKey } from "../../../services/ayrshare/getProfileKey";
import { postTemplatePost } from "../../../services/ayrshare/postTemplatePost";
import { useNavigate } from "react-router-dom";

const useEditorialState = () => {

    let navigate = useNavigate();
    const { signOut, user } = useContext(AuthContext);

    let lang = "english";

    if (user.language === 1) {
        lang = "spanish";
    } else if (user.language === 2) {
        lang = "portuguese";
    } else if (user.language === 3) {
        lang = "italian";
    }

    let [submit, setSubmit] = useState(false);
    let [profilePost, setProfilePost] = useState(false);
    let [profile, setProfile] = useState(null);
    let [profileUrl, setProfileUrl] = useState('');
    let [socials, setSocials] = useState([]);
    let [name, setName] = useState('');
    let [site, setSite] = useState('');
    let [step, setStep] = useState(0);
    let [description, setDescription] = useState('');
    let [noHasSite, setNoHasSite] = useState(false);
    let [generateImage, setGenerateImage] = useState(true);
    let [language, setLanguage] = useState(lang)

    const handlerPostProfile = async () =>{
        
        if (profilePost){
            return
        }
        setProfilePost(true)
        

        let nameValid = false
        let siteValid = false
        let descriptionValid = false
        
        if (!name || name === ''){
            toast.error(<FormattedMessage id="Provide a valid name" />);
            setStep(0)
        }
        else if ( !noHasSite && (!site || site === '')){
            toast.error(<FormattedMessage id="Provide a valid site" />);
            setStep(0)
        }
        else if (!description || description === ''){
            toast.error(<FormattedMessage id="Provide a valid description" />);
            setStep(2)
        }
        else{
            siteValid = true
            descriptionValid = true
            nameValid = true
        }
        if (nameValid && siteValid && descriptionValid){
            if (!profile || !profile?.id === undefined){
                let json = {
                    name: name.trim(),
                    description: name.trim(),
                };
    
                let result = await postProfile(json)

                if (result?.type === "error") {
                    if (result.data.code === "token_not_valid") {
                        signOut();
                    } else if (
                        result.data.code === "violation of guidelines"
                    ) {
                        toast.error(
                            <FormattedMessage id={result.data.detail} />
                        );
                    }else if (result.data.detail === "You have a profile with this name") {
                        toast.error(<FormattedMessage id="You have a profile with this name" />);
                    }else if (result.data.detail === "You have no more possible integrations") {
                        toast.error(<FormattedMessage id="You have no more possible integrations" />);
                    }else if (result.data.detail === "Invalid name") {
                        toast.error(<FormattedMessage id="Invalid name" />);
                    }  else {
                        toast.error(<FormattedMessage id="unknown error" />);
                    }
                } else {
                    setProfile(result.data)
                    setStep(3)
                    setProfilePost(false);
                }
            }
            else{
                setStep(3)
            }
        }
        setProfilePost(false)

    }

    const getProfile = async () =>{
        if (submit){
            return
        }

        setSubmit(true)
        let result = await getTemplatePost();
        if (result.type === 'success'){
            if (result.data?.posts_template.length > 0){
                navigate('/planner')
            }
            setProfile(result.data)
        }

        setSubmit(false)
    }

    const getProfileUrl = async () =>{

        if (profile?.id && profile.id > 0){
            let result = await getProfileKey(profile.id)
            if (result?.type === "error") {
                if (result.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    result.data.code === "violation of guidelines"
                ) {
                    toast.error(
                        <FormattedMessage id={result.data.detail} />
                    );
                } else {
                    toast.error(<FormattedMessage id="unknown error" />);
                }
            } else {
                setProfileUrl(result.data.url)
                if (result.data.social){
                    setSocials(result.data.social)
                }
            }

        }

    }

    const handlerSubmit = async () => {
        if (profilePost){
            return
        }
        setProfilePost(true)        

        let nameValid = false
        let siteValid = false
        let descriptionValid = false
        
        if (!name || name === ''){
            toast.error(<FormattedMessage id="Provide a valid name" />);
            setStep(0)
        }
        else if ( !noHasSite && (!site || site === '')){
            toast.error(<FormattedMessage id="Provide a valid site" />);
            setStep(0)
        }
        else if (!description || description === ''){
            toast.error(<FormattedMessage id="Provide a valid description" />);
            setStep(2)
        }
        else{
            siteValid = true
            descriptionValid = true
            nameValid = true
        }

        if (siteValid && descriptionValid && nameValid){
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let json = {
                description: description,
                language: language,
                name: name,
                profile: profile.id,
                generate_image: generateImage,
                timezone: timezone
            }
    
            if (!noHasSite){
                json = {
                    ...json,
                    site: site
                }
            }
            let result = await postTemplatePost(json)
            if (result?.type === "error") {
                if (result.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    result.data.code === "violation of guidelines"
                ) {
                    toast.error(
                        <FormattedMessage id={result.data.detail} />
                    );
                } else {
                    toast.error(<FormattedMessage id="unknown error" />);
                }
            } else {
                navigate('/planner')
            }

        }
        setProfilePost(false)   
        
    }

    return {
        step: {set:setStep, value: step},
        profile: {set:getProfile, value: profile},
        profilePost:{set:handlerPostProfile, value: profilePost},
        name: {set:setName, value: name},
        site: {set:setSite, value: site},
        socials: {set:setSocials, value: socials},
        profileUrl: {set:getProfileUrl, value: profileUrl},
        noHasSite: {set:setNoHasSite, value: noHasSite},
        language: {set:setLanguage, value: language},
        description: {set:setDescription, value: description},
        generateImage: {set:setGenerateImage, value: generateImage},
        submit: {set:handlerSubmit, value: submit}
    }
}

export default useEditorialState