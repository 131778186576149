import { useLocation } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import PanelInformation from "../../../components/panelInformation";
import AdFromProductDescription from "../../../components/openAI/adFromProductDescription";
import Keyword from "../../../components/openAI/keyword";
import EssayOutline from "../../../components/openAI/essayOutline";
import ProductNameGenerator from "../../../components/openAI/productNameGenerator";
import Dalle from "../../../components/openAI/dalle";
import EnglishGrammarCheck from "../../../components/openAI/englishGrammarCheck";
import ArticlePostsGenerator from "../../../components/openAI/articlePostsGenerator";
import Chat from "../../../components/openAI/chat";
import Profile from "../../../components/ayrshare/profile";
import Posts from "../../../components/ayrshare/posts";


function Generates({
    updateHistoric,
    setOpenHist,
    popup,
    showPopup
}) {


    const location = useLocation();

    const actions = (updateHistoric) => {
        if (location.pathname === `/image`) {
            return <Dalle updateHistoric={updateHistoric} />;
        } else if (location.pathname === `/ad`) {
            return (
                <AdFromProductDescription updateHistoric={updateHistoric} />
            );
        } else if (location.pathname === `/articles`) {
            return (
                <ArticlePostsGenerator updateHistoric={updateHistoric} />
            );
        } else if (location.pathname === `/products`)  {
            return (
                <ProductNameGenerator updateHistoric={updateHistoric} />
            );
        } else if (location.pathname === `/keywords`)  {
            return <Keyword updateHistoric={updateHistoric} />;
        } else if (location.pathname === `/topics`) {
            return <EssayOutline updateHistoric={updateHistoric} />
        } else if (location.pathname === `/translator`) {
            return <EnglishGrammarCheck updateHistoric={updateHistoric} />
        }
        else if (location.pathname === `/chat`) {
            return <Chat updateHistoric={updateHistoric} />
        }
        else if (location.pathname === `/integrate`) {
            return <Profile updateHistoric={updateHistoric} />
        }
        else if (location.pathname === `/posts`) {
            return <Posts updateHistoric={updateHistoric} />
        }
    };
    return (
        <Stack sx={{width:'100%', '@media (max-width: 600px)': {marginLeft:'0px', marginTop:'20px'}}}>
            {
                location.pathname !== `/integrate` && location.pathname !== `/posts` ?
                <PanelInformation popup={popup} />
                :<></>
            }
            
            <Box onClick={() => showPopup.value ? popup():null} >
                <Box 
                    onClick={() => {setOpenHist(false)}} 
                    sx={{
                        pointerEvents: showPopup.value  ? 'none' : 'initial'
                    }}>
                    {actions(updateHistoric)}
                </Box>
            </Box>
        </Stack>
    );
}
export default Generates;
