import { useState } from "react";
import { useLocation } from "react-router-dom";


const useUpgradePopUp = () => {

    
    const location = useLocation();

    const [title, setTitle] = useState('This is a PRO feature.');
    const [text, setText] = useState('Integrate your Digital Channels and unlock the full potential of our platform by upgrading to the PRO plan and enjoy this exclusive feature!');
    const [textButton, setTextButton] = useState('Upgrade your plan');

    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [view, setView] = useState(false);
    const [navegate, setNavegate] = useState('/pricing');


    const getPopup = async (json) =>{
        
        let user = data
        if (json)  {
            user = json
            await setData(json)
        }
        if (user){
            setView(false);
            if (location.pathname === `/image`){
                if (user.images_used >= user.images){
                    setTitle('Hey there!');
                    setText("Looks like you've used up your free image credits for this month. But don't let that stop your creativity! You can either upgrade to a PRO plan or purchase an image pack starting from just €3,50 (no subscription required). Keep on creating amazing art with us!");
                    setTextButton('Upgrade your Plan or Buy image credits now!');
                    setNavegate('/credits')
                    setShow(true);
                    setView(true);
                }
            }
            else if (user.pro){
                if (user.tokens_used >= user.tokens){
                    setTitle("Hey there!");
                    setText("It looks like you've used up all the characters included in your current plan. To continue enjoying our services without limits, consider upgrading to a superior plan! Keep on creating incredible content with even more benefits.");
                    setTextButton('Upgrade to a higher plan now!');
                    setNavegate('/pricing')
                    setShow(true);
                    setView(true);
                }
            }
            else {
                if (user.tokens_used >= user.tokens){
                    setTitle("Oops! You've used up all your free characters for this month.");
                    setText("Don't worry though, you can upgrade to a PRO plan and continue creating amazing content! Plus, if you decide to wait, you'll get a fresh set of free characters next month. The choice is yours, but we'd love to have you on board with our PRO plan!");
                    setTextButton('Upgrade your plan');
                    setNavegate('/pricing')
                    setShow(true);
                    setView(true);
                }
            }
        }
    }
    return {
        title:      { value: title,      set: setTitle },
        text:       { value: text,       set: setText  },
        textButton: { value: textButton, set: setTextButton },
        show:       { value: show,       set: setShow },
        view:       { value: view,       set: setView },
        popup:      { value: null,       set: getPopup },
        navegate:   { value: navegate,   set: setNavegate },
    };
};

export default useUpgradePopUp;
