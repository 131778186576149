import api from "../../services/api";
import backEnd from "../../constants/backEnd";
import endpoints from "../../constants/endpoints";
import { getCSRF } from "../getCSRF";

export async function putTemplatePost(json) {
    let csrf = await getCSRF()
    return api
        .put(`${backEnd.url}${endpoints.ayrshare.profileTemplate}`, json, {
            headers: {
                "Content-Type": "multipart/form-data",
                "X-CSRFToken": csrf?.csrfToken,
            },
        })
        .then((response) => {
            return { type: "success", data: response.data };
        })
        .catch((error) => {
            
            if (error.response.status === 404){
                return {
                    type: "error",
                    data: {
                        code: error.response.status,
                        detail: error.response.statusText,
                    },    
                }
            }
            return {
                type: "error",
                data: {
                    code: error.response.data.code,
                    detail: error.response.data.detail,
                },
            };
        });
}
