const CHANNELS_NEW = [
    {id: 0, name: 'facebook', title:'facebook', icon: 'facebook', active: true},
    //{id: 1, name: 'fbg', title:'fbg', icon: 'fbg', active: true},
    {id: 2, name: 'instagram', title:'instagram', icon: 'instagram', active: true},
    {id: 3, name: 'linkedin', title:'linkedin', icon: 'linkedin', active: true},
    {id: 4, name: 'twitter', title:'twitter', icon: 'twitter', active: true},
    // {id: 5, name: 'google', title:'google', icon: 'google', active: false},
    // {id: 6, name: 'pinterest', title:'pinterest', icon: 'pinterest', active: false},
    // {id: 7, name: 'reddit', title:'reddit', icon: 'reddit', active: false},
    // {id: 8, name: 'tiktok', title:'tiktok', icon: 'tiktok', active: false},
    // {id: 9, name: 'youtube', title:'youtube', icon: 'youtube', active: false},
]

export default CHANNELS_NEW;