import styled from "styled-components";

export const Content = styled.div`
    position:relative;
    margin: ${({ margin }) => margin? `${margin}px` : '10px'}; 
`;

export const Body = styled.div`
    border: 0px solid black;
    box-sizing: border-box;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;

`;

export const ModalClose = styled.img`
    height: 15px;
    margin-right: 5px;
    cursor: pointer;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-bottom: 10px;
`;
