import React, { useRef } from "react";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import close from "../../assets/imgs/close.svg";

import * as C from "./styles";

const MyDialog = ({ sx, isOpen, setIsOpen, children, margin, notClose, fullScreen }) => {
    function closeModal() {
        setIsOpen();
    }

    const rootRef = useRef(null);

    const style = {
        
        justifyContent: 'center',
        padding:0,
        //display: 'flex',
        //flexDirection: 'row',
      };
      

    return (
        <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={!notClose ? ()=>closeModal() : null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{...style, ...sx}}
        
      >
            <C.Content margin={margin} >
                <C.Body>
                    <C.Header>
                        <C.ModalClose src={close} onClick={closeModal} />
                    </C.Header>
                        {children}
                     </C.Body>
            </C.Content>
        </Dialog>

    );
};
export default MyDialog;

/*


*/