import { scrollbarTheme } from '../../../assets/customThemes/scrollbarTheme';

export const style = {
    list:{
        backgroundColor: '#FFF',
        paddingBottom: '20px',
        overflowY: 'auto',
        
        ...scrollbarTheme
    },
    icon: {
        height: '20px',
        width: '20px',
        background: 'none',
        margin: 0,
        
    },
    iconOff: {
        height: '20px',
        width: '20px',
        background: 'none',
        margin: 0,
        filter:'grayscale(1)',
        
    },
    listItemText: {
        fontSize: '10pt',
    },
    listItemButton: {
        padding: '0 8px',
        justifyContent: 'space-between',
        display:'flex',
        
    },
    listItemRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'start',
        padding:'15px',

    },
    mobileArea: {
        display: 'none',
        '@media (max-width: 600px)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center',
            width: '100wv',
          }
    },
    mobileTitle: {
        margin: 0,
        padding: 0,
    },
    iconHamburguer:{
        height: '20px',
        marginLeft:'14px'
    },
    iconHistoric:{
        height: '30px',
        marginRight:'14px'
    },
    upgradeButton:{
        height:'25pt',
        marginRight: '10px',
        backgroundColor: '#6366F1',
        textTransform: 'capitalize !important',
        ':hover':{
            backgroundColor: '#9193F5',
        },
        
    },
    imageButton:{
        height:'25pt',
        marginRight: '10px',
        backgroundColor: '#EE9051',
        textTransform: 'capitalize !important',
        ':hover':{
            backgroundColor: '#F5AD7C',
        }   
    },
    iconCrown: {
        height: '10px',
        width: '10px',
        position: 'absolute',
        top: '-5px',
        right: '-5px'
    },
}
