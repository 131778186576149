import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import MuiDrawer from '@mui/material/Drawer';
import toast from "react-hot-toast";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { AuthContext } from "../../../contexts/auth";
import svgCrown from "../../../assets/imgs/crown.svg";
import iconMaior from "../../../assets/imgs/maior-navbar.svg";
import iconMaior2 from "../../../assets/imgs/maior.svg";
import svgExit from "../../../assets/imgs/exit.svg";
import svgHamburger from "../../../assets/imgs/hamburger2.svg";
import svgHistoric from "../../../assets/imgs/historic.svg";
import svgImgenerator from "../../../assets/imgs/imgenerator.svg";
import svgHome from "../../../assets/imgs/home.svg";
import daleRettSvg from "../../../assets/imgs/DALLE3.svg";
import tagPng from '../../../assets/imgs/dalle/tag.png'
import MyImage from "../../../components/myImage";
import MyLabel from "../../../components/myLabel";
import MyButton from '../../../components/myButton'
import logoAutopost from "../../../assets/imgs/dashboard/logo-autopost.svg";

import { style } from "./styles";
import svgAd from "../../../assets/imgs/dashboard/ad.svg";
import svgArticle from "../../../assets/imgs/dashboard/article.svg";
import svgProducts from "../../../assets/imgs/dashboard/products.svg";
import svgKeywords from "../../../assets/imgs/dashboard/keywords.svg";
import svgTopics from "../../../assets/imgs/dashboard/topics.svg";
import svgTranslator from "../../../assets/imgs/dashboard/translator.svg";
import svgSocialLink from "../../../assets/imgs/dashboard/social-link.svg";
import svgChat from "../../../assets/imgs/dashboard/chat.svg";
import admindIcon from "../../../assets/imgs/logo04.png"
import { Typography } from "@mui/material";
import isTestUser from "../../../utils/isTestUser";

const drawerWidthMin = 70

function Function({ menu, onClick, active, drawerWidth }) {
    let styleArea = style.listItemRow
    let styleIcon = style.icon

    if (active){
        styleArea = {...styleArea, 
            backgroundColor: '#721384',
            color: '#FFF',
            borderRadius: '10px',
            
        }

        styleIcon = {...styleIcon,
            filter: 'invert(100%) sepia(0%) saturate(100%) brightness(1000%) contrast(1000%)' 

        }
    }
    if(menu.isOff){
        styleIcon = {...styleIcon,
            filter: 'grayscale(1)'

        }
    } 

    
    return (
        <ListItem disablePadding onClick={onClick}>
            <ListItemButton sx={style.listItemButton} >
                <Box sx={{...styleArea}}>
                    <Box sx={{marginRight:'15px', position: 'relative', display: 'flex', alignContent: 'center',alignItems: 'center', width:'20px'}}>
                    {menu?.isPro && <MyImage src={svgCrown} sx={style.iconCrown} alt={`icon crown`}/>  }
                        <MyImage src={menu.icon} alt={`icon ${menu.name}`} sx={styleIcon} />
                    </Box>
                    

                    {menu.url === 'planner' ? <Box sx={{display:'flex', flexDirection:'column'}}>
                        <MyLabel component='p' text={menu.name} sx={{...style.listItemText,color: !active && '#721384',fontWeight:'bold', fontSize:'1rem', display: drawerWidth === drawerWidthMin ?'none':'inline'}}  />
                       </Box>
                    : <MyLabel component='p' text={menu.name} sx={{...style.listItemText, display: drawerWidth === drawerWidthMin ?'none':'inline'}}  />    
                }
                    {
                        (menu.url === 'image' && drawerWidth !== drawerWidthMin) ?
                        <Box sx={{backgroundColor:'#FFF',right:0, position: 'relative', border: '1px solid #721384', borderRadius:'10px', padding:'2px', width:'71px', marginLeft:'15px', display: 'flex', alignContent: 'center',alignItems: 'center',}}>
                            <MyImage src={daleRettSvg} alt={`icon ${menu.name}`} sx={{width:'60px'}} />
                        </Box> : null
                    }
                    {
                        (menu.url === 'chat' || menu.url === 'planner') && (drawerWidth !== drawerWidthMin) ?
                        <Box sx={{position:"relative", top:'0.3rem', left:'0.3rem'}}>
                            <MyImage src={tagPng} alt={`icon tag new`} sx={{width:'35px'}} />
                        </Box> : null
                    }
                </Box>
            </ListItemButton>
        </ListItem>
    );
}

function Function2({ menu, onClick, active }) {

    let estilo = style.listItemButton
    let styleArea = style.listItemRow

    if (active){
        styleArea = {...styleArea, 
            backgroundColor: '#721384',
            color: '#FFF',
            borderRadius: '10px',
        }
    }

   
    else if (menu.name ==='Analytics'){
        estilo ={...estilo, color: '#B9B9B9;'}
    }
    else if (menu.name ==='Planner'){
        estilo ={...estilo, color: '#B9B9B9;'}
    }

    if(menu.isOff){
        styleArea = {...styleArea,
            color: '#B9B9B9;'

        }
    } 

    return (
        <ListItem disablePadding onClick={onClick} >
            <ListItemButton selected={active} sx={estilo} >
                <Box sx={styleArea} style={{padding:'8px'}}>
                    <MyLabel component='p' text={menu.name} sx={style.listItemText}  />
                </Box>
            </ListItemButton>
        </ListItem>
    );
}

function Functions({
    open,
    setOpen,
    openHist,
    setOpenHist,
    userState,
    setDrawerWidth,
    drawerWidth
}) {
    let navigate = useNavigate();
    const location = useLocation();
    

    const { signOut, user } = useContext(AuthContext);
    const creditsHandler = () => navigate("/credits");
    const pricingHandler = () => navigate("/pricing");
    const [openHistBottom, setOpenHistBottom] = useState(false);
    
    
    

    useEffect(() => {
        if(['/planner', '/planner-welcome', '/planner-editorial'].includes(window.location.pathname)){
            setDrawerWidth(drawerWidthMin)
        }
    }, [window.location])

    const changeDrawerWidth = () =>{
        if (drawerWidth === drawerWidthMin){
            setDrawerWidth(300)
        } else {
            setDrawerWidth(drawerWidthMin)
        }
    }


    const handlerNavigate = (url) =>{
        navigate(`/${url}`)
    }

    let functions = [
        { name: "Home", icon: svgHome, url:'dashboard' },
        { isOff: !user.is_pro, isPro:true, name: "AUTOPOST", icon: logoAutopost, url:'planner-welcome' },
        { name: "Articles /Posts Generator", icon: svgArticle, url:'articles' },
        { name: "ADs Generator", icon: svgAd, url:'ad' },
        { name: "Topics Generator", icon: svgTopics, url:'topics' },
        { isOff: !user.is_pro, isPro:true, name: "AI Chat - GPT-4o", icon: svgChat, url:'chat' },
        { name: "Image Generator",isPro:true, icon: svgImgenerator, url:'image' },
        { isOff: !user.is_pro, isPro:true, name: "Magic Keywords", icon: svgKeywords, url:'keywords' },
        { isOff: !user.is_pro, isPro:true, name: "Product Name Generator", icon: svgProducts, url:'products' },
        { isOff: !user.is_pro, isPro:true, name: "English Grammar Check & Translation", icon: svgTranslator, url:'translator' },
    ]

    
    if (user.email === 'lario.developer@gmail.com' || user.email === 'ma@admind.ai' || user.email === 'ga@admind.ai'){
        functions.push(
            { isOff: false, name: "Vision", icon: admindIcon, url:'vision' },
        )
    }
    
    let integrationFunctions = [
        { isOff: user.subscription_name === 'PRO Light' ? true : !user.is_pro, name: "Integrate Channel",  icon: null, url:'integrate' },
        { isOff: user.subscription_name === 'PRO Light' ? true : !user.is_pro, name: "Posts",  icon: null, url:'posts' },
        { isOff: user.subscription_name === 'PRO Light' ? true : !user.is_pro, name: "Planner",  icon: null, url:'planner' },
        { isOff: user.subscription_name === 'PRO Light' ? true : !user.is_pro, name: "Analytics",  icon: null, url:'analitic' },
    ];
    
    let collapseStyle = style.list

    if (open || window.innerWidth < 600){
        collapseStyle = {...collapseStyle, height:'80vh'}
    }


    let menuStyle = {
        position:'relative',
        transition: 'width 0.5s',
        width: drawerWidth,
        display:'flex',
        flexDirection: 'column',
        marginTop:'70px',
        
        
    }
   
    const handlerFunction = (menu) => {
        if(['/planner-editorial','/planner-welcome', '/planner'].includes(`/${menu.url}`)){
            setDrawerWidth(drawerWidthMin)
        }
        if(!menu.isOff){
            handlerNavigate(menu.url)
            setOpen(false);
        }else if ( !user.is_pro){
            if (user.has_tested){
                if (`/${menu.url}` === '/image'){
                    navigate("/credits");
                    
                }
                else {
                    navigate("/pricing");
                }
            }
            else{
                navigate(window.location.pathname + '?try-pro=true')
            }
        }
        if (window.innerWidth <600){
         setDrawerWidth(drawerWidthMin);
        }
        
    }

    const handlerFunction2 = (menu) => {
       if (menu.url === 'analitic' ){
            toast("This tool will be available in a few weeks");
        }
        else if ( menu.url === 'planner' ){
            toast("This tool will be available in a few weeks");
        }
        else if(!menu.isOff){
            handlerNavigate(menu.url)
            setOpen(false);
            
            
        }else if ( !user.is_pro){
            if (user.has_tested){
                if (`/${menu.url}` === '/image'){
                    navigate("/credits");
                    
                }
                else {
                    navigate("/pricing");
                }
            }
            else{
                navigate(window.location.pathname + '?try-pro=true')
            }
        }

        if (window.innerWidth <600){
         setDrawerWidth(drawerWidthMin);
        }
        
    }
    
    if (window.innerWidth <600){
        return (
            <Box sx={{
                    position: "absolute",
                    width:'100%',
                    top:'60px',
                    zIndex:10
                
            }}>
                <Box sx={{...style.mobileArea, backgroundColor: 'white', padding: '20px 0'}}>
                    <MyImage onClick={() => setOpen(!open)} src={svgHamburger} alt={`icon Hamburger`} sx={style.iconHamburguer} />
                    <Box sx={style.areaButtons}>
                        <>
                            <MyButton  onClick={pricingHandler} text={userState?.subscription?.value?.includes('PRO') ? `Upgrade` : "Try AdMind PRO"} variant="contained" md={style.upgradeButton} sx={style.upgradeButton} />
                            <MyButton  onClick={creditsHandler} text="Get More Images" variant="contained" md={style.imageButton} sx={style.imageButton} />
                        </>
                    </Box>
                    <MyImage onClick={() => setOpenHist(!openHist)} src={svgHistoric} alt={`icon Historic`} sx={style.iconHistoric} />
                </Box>
                <Collapse in={open || window.innerWidth >=600} >
                    <Box sx={collapseStyle}>
                        <List >
                            {functions.map((menu, key) => (
                                <Function
                                    menu={menu}
                                    onClick={() => handlerFunction(menu)}
                                    key={key}
                                    active={
                                        location.pathname === `/${menu.url}`
                                    }
                                />
                            ))}
                           <ListItem disablePadding>
                                <Box onClick={() => setOpenHistBottom(!openHistBottom)} sx={{...style.listItemButton, color:"#721384", marginLeft:'14px', marginTop:'10px', marginBottom:'10px'}} >
                                    <Box sx={{marginRight:'15px', position: 'relative', display: 'flex', alignContent: 'center',alignItems: 'center', width:'20px'}}>
                                    <MyImage src={svgCrown} sx={style.iconCrown} alt={`icon crown`}/>  
                                    <MyImage src={svgSocialLink} alt={`icon social media`} sx={userState.subscription.value === 'PRO Light' ? style.iconOff : userState.subscription.value.includes('PRO') ? style.icon : style.iconOff} />
                                    </Box>
                                    <Typography component='p'  sx={{...style.listItemText, fontWeight:700}}> SOCIAL MEDIA INTEGRATION</Typography>
                                    <MyImage src={iconMaior} alt={'icon'} sx={{height:'15px', marginLeft: '0.5rem', marginTop:'0.1rem', transform: openHistBottom ? 'rotate(-90deg)' : 'rotate(90deg)', cursor:'pointer'}} />
                                </Box>
                            </ListItem>
                            <Collapse orientation="vertical" in={openHistBottom} style={{width:'100%', paddingLeft:'3rem'}} >
                                         
                            <Box >
                                {
                                    integrationFunctions.map((menu, key) => (
                                        <Function2
                                            menu={menu}
                                            onClick={() => handlerFunction2(menu)}
                                            key={key}
                                            active={
                                                location.pathname === `/${menu.url}`
                                            }
                                        />
                                    ))
                                }
                            </Box>
                            </Collapse>
                            
                        </List>
                        <Box sx={{width:'100%'}}>
                            <Button sx={{marginLeft:'20px', marginTop:'40px', color:'#000'}} onClick={() => signOut()} >
                                <MyImage src={svgExit} alt={`icon exit`} sx={{...style.icon, marginRight:'10px'}} />
                                <FormattedMessage id="Log Out" />
                            </Button>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        )
    }
    else {
        return (
        <MuiDrawer variant="permanent" sx={menuStyle}>
            <Box sx={{
                ...menuStyle,
            }}>
                <Box onClick={changeDrawerWidth}  sx={{padding:'0 10px',backgroundColor:'#FFF',borderRadius:'5px',display:'flex', justifyContent:drawerWidth !== drawerWidthMin ? 'right': 'center', alignItems:'center'}}>
                    <MyImage  src={iconMaior2} alt={'icon'} sx={{height:'20px', marginLeft: '0.5rem', marginTop:'0.1rem', transform: drawerWidth !== drawerWidthMin ? 'rotate(-180deg)' : 'rotate(0deg)', cursor:'pointer'}} />
                </Box>
                <Box>
                    <List sx={style.list} >
                        {functions.map((menu, key) => (
                            <Function
                                menu={menu}
                                onClick={()=>handlerFunction(menu)}
                                key={key}
                                active={
                                    location.pathname === `/${menu.url}`
                                }
                                drawerWidth={drawerWidth}
                            />
                        ))}   
                    </List>
                    <Box sx={{width:'100%'}}>
                        <Button sx={{marginLeft:'8px', marginTop:'40px', color:'#000'}} onClick={() => signOut()} >
                            <MyImage src={svgExit} alt={`icon exit`} sx={{...style.icon, marginRight:'10px'}} />
                            <Box sx={{display: drawerWidth === drawerWidthMin ?'none':'inline'}}>
                                <FormattedMessage id="Log Out" />
                            </Box>
                        </Button>
                    </Box>
                </Box>

            </Box>
        </MuiDrawer>)
    }

}

export default Functions;
