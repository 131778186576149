import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Fade from '@mui/material/Fade';
import Container from '@mui/material/Container';
import Loading from "../../components/loading";
import logo from "../../assets/imgs/logo03.png";
import logo1 from "../../assets/imgs/logo01.png";
import MyImage from "../../components/myImage";
import MyLabel from "../../components/myLabel";
import MyInput from "../../components/myInput";
import MySelect from "../../components/mySelect";
import MyCheckbox from "../../components/myCheckbox";
import backEnd from "../../constants/backEnd";
import endpoints from "../../constants/endpoints";
import MyButton from "../../components/myButton";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import imgLeft from "../../assets/imgs/index/01.png";
import validEmail from "../../utils/validEmail";
import validPassWord from "../../utils/validPassWord";
import formatEmail from "../../utils/formatEmail";
import { login, register } from "../../services/auth";
import svgGift from "../../assets/imgs/gift.svg";
import formatTryProDate from "../../utils/formatTryProDate";
import moment from 'moment';
import SubscriptionButton from "../../components/try-pro-modal/subscriptionButton";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { AuthContext } from "../../contexts/auth";
import { postRegisterPaypalSubscription } from "../../services/postRegisterPaypalSubscription";

const CreateUser = ({signIn}) => {

    let languageNavigator = window.navigator.language;
    

    if (languageNavigator === "sp") {
        languageNavigator = 'spanish';
    } else if (languageNavigator === "pt-BR") {
        languageNavigator = 'portuguese';
    } else if (languageNavigator === "it") {
        languageNavigator = 'italian';
    } else {
        languageNavigator = 'english';
    }

    const pdfHandler = () => {
        fetch(`${backEnd.url}${endpoints.access.term_pdf}`).then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "Terms and Conditions AdMind SRL.pdf";
                alink.click();
            });
        });
    };

    let [submit, setSubmit] = useState(false)
    let [email, setEmail] = useState('')
    let [password, setPassword] = useState('')
    let [passwordConf, setPasswordConf] = useState('')
    let [language, setLanguage] = useState(languageNavigator)
    let [privacy, setPrivacy] = useState(false)
    let [terms, setTerms] = useState(false)
    let [offers, setOffers] = useState(false)


    const handlerSubmit = () => {
        if (submit) {
            return;
        }

        let isEmailValid = false;
        let isPasswordValid = false;
        let isBirthday = true;
        let isTherms = false;
        let isPrivacy = false;

        if (!terms) {
            toast.error(
                <FormattedMessage id="read and accept the Terms & Conditions" />
            );
        }
        else {
            isTherms = true;
        }

        if (!privacy) {
            toast.error(
                <FormattedMessage id="read and accept the Privacy policy" />
            );
        }
        else {
            isPrivacy = true;
        }
        if (email === "" || !validEmail(email)) {
            toast.error(<FormattedMessage id="Provide a valid email" />);
        }
        else {
            isEmailValid = true;
        }
    
        if (password === "" || !validPassWord(password)) {
            toast.error(
                <FormattedMessage id="Provide a valid password. It must have at least 8 characters, letters and numbers." />
            );
        }
        else if (passwordConf === "" && !validPassWord(passwordConf)) {
            toast.error(
                <FormattedMessage id="Provide a valid password. It must have at least 8 characters, letters and numbers." />
            );
        } else if (passwordConf !== password) {
            toast.error(<FormattedMessage id="Password are different" />);
        } else {
            isPasswordValid = true;
        }

        let lang = 0;

        if (language === "spanish") {
            lang = 1;
        } else if (language === "portuguese") {
            lang = 2;
        } else if (language === "italian") {
            lang = 3;
        }
    
        if (isTherms && isPrivacy && isEmailValid && isPasswordValid && isBirthday) {
            let json = {
                email: formatEmail(email.replace(" ", "")),
                password: password,
                profession: '',
                company: '',
                country: 'Italy',
                marketing_professional: 0,
                privacy: privacy,
                terms: terms,
                offers: offers,
                partner: '',
                language: lang,
                //birthday: `${birthdaySplit[0]}-${birthdaySplit[1]}-${birthdaySplit[2]} 00:00:00`,
            };
            setSubmit(true);
            register(json)
                .then(async () => {
                    toast.success("User registered successfully");
                    await login(email.replace(" ", ""), password)
                        .then(async (user) => {
                            let state = await signIn(user, false);
                            if (state === "SUCCESS") {
                                setSubmit(false);
                            }
                        })
                        .catch((error) => {
                            if (error.message === "NetWork request failed") {
                                toast.error(<FormattedMessage id="Connection failed to server" />);
                            } else {
                                toast.error(<FormattedMessage id={error.message} />);
                            }
                            setSubmit(false);
                        });
                    
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(
                            <FormattedMessage id="Connection failed to server" />
                        );
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }
                    setSubmit(false);
                });
        }
            
        
    }
    
    

    return (
        <Fade in={true} timeout={500}>
            <Stack direction="row" sx={{
                width: 'auto',
                height: '100vh',
                '@media (max-width: 600px)': {
                    marginTop: '10px',
                    height: 'auto',
                }
            }}>
                <Loading render={submit} />
                <Container sx={{
                        backgroundImage:`url(${imgLeft})`,
                        height: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        display:{ xs: 'none', sm: 'block'}
                    }}>
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        display:'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Link href="https://admind.ai/" underline="none">
                            <MyImage src={logo} alt={'Admind logo'} sx={{
                                width: '350px',
                            }} />
                        </Link>
                    </Box>
                </Container>
                <Container sx={{
                    width: '100%',
                    height: '100%',
                    display:'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection:'column'
                    
                }} >
                    <Box sx={{
                        display:{ xs: 'block', sm: 'none'}
                    }}>
                        <Link href="https://admind.ai/" underline="none" >
                            <MyImage src={logo1} alt={'Admind logo 1'} sx={{
                                width: '350px',
                            }}/>
                        </Link>
                    </Box>
                    <Box sx={{
                        display:'flex',
                        width: '70%',
                    }}>
                        <MyLabel component='h1' text='Sign Up for FREE' sx={{
                            fontSize: '20pt',
                            color: "#5f615f",
                            fontWeight: '700',
                            marginBottom: '20px',
                            marginTop: '20px',
                            
                        }}  />
                    </Box>
                    
                    <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            gap: '25px',
                            width: '70%',
                        }}>
                        <MyInput 
                            icon="user"
                            //error={errors.value.email}
                            type='email'
                            id='email'
                            label='E-mail'
                            required={true}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <MyInput 
                            icon="password"
                            //error={errors.value.password}
                            type='password'
                            id='password'
                            label='Password'
                            required={true}
                            value={password}
                            onChange={(e) =>setPassword(e.target.value)}
                        />
                        <MyInput 
                            icon="password"
                            //error={errors.value.passwordConf}
                            type='password'
                            id='password2'
                            label='Repeat Password'
                            required={true}
                            value={passwordConf}
                            onChange={(e) => setPasswordConf(e.target.value)}
                        />
                        <MySelect 
                            id="language"
                            label="Platform language"
                            options={[
                                "english",
                                "spanish",
                                "portuguese",
                                "italian",
                            ]}
                            value={language}
                            onChange={(e)=>setLanguage(e.target.value)}
                        />
                    
                    </Box>
                    <Box sx={{
                        width: '70%',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}>
                        <Box sx={{
                            display:'flex',
                            width: '100%',
                            alignItems: 'center',
                            margin: 0,
                            padding: 0,
                        }}>
                            <MyCheckbox 
                                size='small'
                                checked={privacy}
                                label="I read and accept the" 
                                value={privacy}
                                onChange={(e) => setPrivacy(!privacy)}
                            />
                            <Link  
                                href="https://admind.ai/en/privacy-policy/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <MyLabel sx={{ fontSize: '0.8rem' }} component='p' text='Privacy policy' />
                            </Link>
                        </Box>
                        <Box sx={{
                            display:'flex',
                            width: '100%',
                            alignItems: 'center',
                            margin: 0,
                            padding: 0,
                        }}>
                            <MyCheckbox 
                                size='small'
                                checked={terms}
                                label="I read and accept the" 
                                value={terms}
                                onChange={(e) => setTerms(!terms)}
                            />
                            <Link  
                                onClick={pdfHandler}    
                            >
                                <MyLabel sx={{ fontSize: '0.8rem' }} component='p' text='Terms & Conditions' />
                            </Link>
                        </Box>
                        <Box sx={{
                            display:'flex',
                            width: '100%',
                            alignItems: 'center',
                            margin: 0,
                            padding: 0,
                        }}>
                            <MyCheckbox 
                                size='small'
                                checked={offers}
                                label="I want to receive offers, news and free credits" 
                                value={offers}
                                onChange={(e) => setOffers(!offers)}
                            />
                        </Box>
                    </Box>
                    <Box sx={{
                        width: '70%',
                    }}>
                        <MyButton 
                            disabled={submit} 
                            onClick={handlerSubmit} 
                            text="Sign Up" 
                            variant="contained" 
                            size="large" 
                            sx={{width: '100%'}} 
                        />
                    </Box>
           
                    
                </Container>
            </Stack>
        </Fade>
    )

}

const PayPalView = ({code, navigate}) => {

    let languageNavigator = window.navigator.language;
    let lan = 'en'

    if (languageNavigator === "sp") {
        lan = 'es';
    } else if (languageNavigator === "pt-BR") {
        lan = 'pt';
    } else if (languageNavigator === "it") {
        lan = 'it';
    }

    let [submit, setSubmit] = useState(false)
    let days = code ==='M3M4AFQ' ? 30 : 60
    let planIdTrial = code ==='M3M4AFQ' ? 'P-29S92406TL026953YM3M4AFQ' : 'P-8XB72303A7217100TM3M4AYA'

    const handlePost = async (paymentID) => {
        if (submit) {
            return;
        }

        setSubmit(true);
        

        let json = {
            paypal_actionn_id: paymentID,
        };

        postRegisterPaypalSubscription(json)
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "violation of guidelines") {
                        toast(
                            <FormattedMessage key={4} id={json.data.detail} />
                        );
                    } else if (json.data.code === "no tokens") {
                        toast.error(<FormattedMessage id={json.data.detail} />);
                    } else {
                        <FormattedMessage id={"unknown error"} />;
                    }
                } else {
                    if (json?.type === "success") {
                        toast.success(
                            <FormattedMessage id={"Registered signature."} />
                        );
                        navigate("/dashboard");
                    }
                }
                setSubmit(false);
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast("Connection failed to server");
                } else {
                    toast(<FormattedMessage key={4} id={error.message} />);
                }
                setSubmit(false);
            });
            setSubmit(false);
    };

    return (
<Fade in={true} timeout={500}>
            <Stack direction="row" sx={{
                width: 'auto',
                height: '100vh',
                '@media (max-width: 600px)': {
                    marginTop: '10px',
                    height: 'auto',
                }
            }}>
                <Loading render={submit} />
                <Container sx={{
                        backgroundImage:`url(${imgLeft})`,
                        height: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        display:{ xs: 'none', sm: 'block'}
                    }}>
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        display:'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        
                    }}>
                        <Link href="https://admind.ai/" underline="none">
                            <MyImage src={logo} alt={'Admind logo'} sx={{
                                width: '350px',
                            }} />
                        </Link>
                    </Box>
                </Container>
                <Container sx={{
                    width: '100%',
                    height: '100%',
                    display:'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection:'column',
                    
                  
                }} >
                    <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column', alignContent:'center', alignItems:'center', width:'100%'}}>
                        <MyImage src={svgGift} sx={{ height: '80px', width: '80px', background: 'none', }} alt={`gift`} />
                        <MyLabel sx={{ fontSize: '1.8rem', fontWeight: '600' }} component='p' text={`Receive your ${days}-day free gift`} />
                        <MyLabel sx={{ fontWeight: '600' }} component='p' text={"You can cancel at any time!"} />
                        <MyLabel sx={{ fontWeight: '600' }} component='p' text={"Admind PRO Max"} />
                    </Box>
                    <Box>
                    <Box sx={{display:'flex', justifyContent:'space-between', width:'450px', marginTop:'50px',
                    '@media (max-width: 600px)': {
                        width: '90vw',
                        fontSize:"11pt"
                        }

                    }}>
                            <Box >
                                <FormattedMessage id='Pay on'/>{' '}{formatTryProDate(moment().add(days, 'day'), lan)}
                            </Box>
                            <Box >
                                PRO Max: 39,00 €
                            </Box>
                    </Box>
                    <Box display={'flex'} marginTop={'0.5rem'} sx={{display:'flex', justifyContent:'space-between', width:'450px',
                    '@media (max-width: 600px)': {
                        width: '90vw',
                        fontSize:"11pt"
                        }

                    }}>
                        <Box >
                            <FormattedMessage id='Pay today'/>{' '}  
                                <Box component='span' style={{ color: "green", marginLeft: '0.5rem', fontWeight: '800' }}>
                                    <FormattedMessage id={`(${days} days FREE)`}/>{' '}
                                </Box>
                        </Box>
                        <Box >
                            0 €
                        </Box>
                    </Box>
                </Box>
                <Box display={'flex'} marginTop={'50px'} marginBottom={'50px'}>
                    <PayPalButtons
                        style={{
                            shape: "pill",
                            color: "gold",
                            layout: "vertical",
                            label: "paypal",
                        }}
                        createSubscription={(data, actions)=>{
                            return actions.subscription
                            .create({
                                plan_id: planIdTrial,
                            })
                            .then((orderId) => {
                                return orderId;
                            });
                        }}
                        onApprove={(data, actions) => {
                            handlePost(data.subscriptionID)
                            toast.success(`Payment completed. Thank you`);
                        }}
                    />
                </Box>
                <Box >
                    <Typography sx={{color:'gray'}}>
                        <FormattedMessage id={'Cancel at any time. Your first payment will be due on'} />
                        {' '}
                        {formatTryProDate(moment().add(days, 'day'), lan)},
                        {' '}
                        <FormattedMessage id={'but you can cancel at any point before this date with no penalty.'} />
                    </Typography>
                </Box>
                    
           
                    
                </Container>
            </Stack>
        </Fade>
    )

}

const Gifts = ({}) =>{

    let { code } = useParams();
    let navigate = useNavigate();
    const { signIn, user } = useContext(AuthContext);

    useEffect(
        ()=>{
            if (code !== 'M3M4AFQ' && code !== 'M3M4AYA'){
                navigate('/giftnotexists');
            }
        },[code]
    )

    
    if (user === undefined){
        return <CreateUser signIn={signIn}/>
    } else {
        return (
            <PayPalView code={code} navigate={navigate} />
        )
    }

}

export default Gifts