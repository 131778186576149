import daleImage from "../../../assets/imgs/dale.png";
import tutorialUpCenter from '../../../assets/imgs/tutorial-up-center.svg' 
import tutorialLeftUp from '../../../assets/imgs/tutorial-left-up.svg'
import tutorialUpLeftIcon from '../../../assets/imgs/tutorial-up-left.svg'

export const style = {
    stack: {
        width: '100%',
    },
    counter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    titleArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        margin: '14px',
        width: '100%',
        '@media (max-width: 600px)': {
            width: 'auto',
        }
    },
    title: {
        margin: '0px',
        padding: '0px',
        fontSize: '14pt',
        fontWeight: 600,
        marginBottom: '10px'
    },
    optionRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 0,
        padding: 0,

        '@media (max-width: 600px)': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            justifyContent: 'center',
        }
    },
    selectArea: {
        marginTop: '30px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '10px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr 1fr',
        }
    },
    select: {
        backgroundColor: '#FFF',
    },
    textInput: {
        width: '100%',
        backgroundColor: '#FFF',
    },
    describeArea: {
        marginTop: '20px',
        width: '100%',
    },
    counterArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0,

    },
    counter: {
        margin: 0,
        marginRight: '10px',
        padding: 0,
        fontSize: '11pt'
    },
    buttonArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '10px',
        padding: 0,

    },
    cardAreaImage: {
        width: '220px',
        height: '220px',
        display: 'flex',
        backgroundImage: `url(${daleImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
        },
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 600px)': {
            width: '180px',
            height: '180px',
        }
    },
    cardAreaContent: {
        display: 'flex',
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    center: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectArea2: {
        marginTop: '20px',
        width: '460px',
        '@media (max-width: 600px)': {
            width: '100%',
        }

    },
    cardTitleArea: {
        width: '100%',
        display: 'flex',
        backgroundColor: '#FFF',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    cardArea: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '20px',
    },
    cardTitle: {
        fontSize: '11pt',
        fontWeight: 600,
    },
    cardDescription: {
        fontSize: '11pt',
    },
    imageReturn: {
        width: '100%',
        margin: 0,
        padding: 0,
        maxWidth: '400px',
    },
    imageArea: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',
        }
    },
    tutorialBox: {
        backgroundImage: `url(${tutorialUpCenter})`,
        backgroundSize:'380px',
        width:'380px',
        height:'220px',
        backgroundRepeat:'repeat-y',
        zIndex:2,
        position:'absolute',
        left:'670px',
        top: '630px',
        '@media (max-width: 600px)': {
            left:0,
            top: '700px',

        },
    },

    tutorial2Box: {
        backgroundImage: `url(${tutorialLeftUp})`,
        backgroundSize:'380px',
        width:'380px',
        height:'220px',
        backgroundRepeat:'repeat-y',
        zIndex:2,
        position:'absolute',
        left:'500px',
        top: '635px',
        '@media (max-width: 600px)': {
            left:'30px',
            top: '770px',
            backgroundImage: `url(${tutorialUpLeftIcon})`,

        },
    },

    tutorial3and4Box: {
        backgroundImage: `url(${tutorialLeftUp})`,
        backgroundSize:'380px',
        width:'380px',
        height:'220px',
        backgroundRepeat:'repeat-y',
        zIndex:2,
        position:'absolute',
        left:'840px',
        top: '740px',
        '@media (max-width: 600px)': {
            left:0,
            top: '900px',
            backgroundImage: `url(${tutorialUpCenter})`,

        },
    },

    tutorial1CloseImage:{
        cursor: 'pointer', 
        position:'relative',
        top: '20px', 
        color: 'white', 
        float:'right',
        right:'25px',
    },
    tutorial1Title:{
        fontWeight: 600, 
        padding:'25px 100px 10px 25px',
        color: 'white',
    },
    tutorial1Content:{
        fontSize: '0.8rem', 
        color: 'white',
        padding:'0 30px 0 25px',
    },
    tutorial1Step:{
        position: 'relative', 
        top: '30px', 
        left: '30px', 
        fontSize: '0.8rem', 
        color: 'white',
    },
    tutorial1NextButton:{
        position: 'relative', 
        textTransform: 'capitalize', 
        width: 0, 
        float:'right',
        backgroundColor: '#EE9051', 
        right: '25px', 
        top:'5px',
        cursor: 'pointer', 
        fontSize: '0.8rem', 
        color: 'white',
       
    },
    tutorial1Previous:{
        cursor: 'default', 
        float:'right',
        position: 'relative', 
        top:'10px',
        right: '20px', 
        fontSize: '0.8rem', 
        paddingRight:'20px',
        color: 'white',
    },
}