export const style = {
    paperHome: {
        display:{ xs: 'flex', sm: 'flex'},
        flexDirection:'column',
        padding: '20px 10px 0 10px',
        justifyContent: 'space-between',
        width:'100%',
    },
    paper: {
        display:{ xs: 'none', sm: 'flex'},
        flexDirection:'row',
        padding: '10px',
        justifyContent: 'space-between',
        minWidth:'700px',
    },
    area1: {
        padding: '0 0 10px 20px',
        margin: '0px',
    },
    area2: {
        width:'100%',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center'
    },
    areaButtons: {
        display:{ xs: 'flex', md: 'none'},
        display:'flex',
        flexDirection:'column',
        justifyContent: 'space-between',
    },
    titleArea: {
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
        padding: '0px',
        margin: '0px'
    },
    informationArea: {
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
        padding: '0px',
        margin: '0px'
    },
    information: {
        padding: '0px',
        fontSize: '11pt',
        margin: '0px',
        marginRight: '5px',
    },
    title: {
        padding: '0px',
        fontSize: '12pt',
        fontWeight: '700',
        margin: '0px',
        marginRight: '5px',
    },

    usage:{
        color: 'rgb(240, 115, 122)',
        fontSize: '11pt',
    },
    upgradeButton:{
        height:'25pt',
        marginRight: '10px',
        backgroundColor: '#6366F1',
        textTransform: 'capitalize !important',
        ':hover':{
            backgroundColor: '#9193F5',
        },
        
    },
    imageButton:{
        height:'25pt',
        marginRight: '10px',
        backgroundColor: '#EE9051',
        textTransform: 'capitalize !important',
        ':hover':{
            backgroundColor: '#F5AD7C',
        },
        
    },
}