import api from "../api";
import backEnd from "../../constants/backEnd";
import endpoints from "../../constants/endpoints";
import { getCSRF } from "../getCSRF";

export async function getSaveChat() {
    let csrf = await getCSRF();
    return api
        .get(`${backEnd.url}${endpoints.openai.saveChat}`, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf?.csrfToken,
            },
        })
        .then((response) => {
            return { type: "success", data: response.data };
        })
        .catch((error) => {
            return {
                type: "error",
                data: {
                    code: error.response.data.code,
                    detail: error.response.data.detail,
                },
            };
        });
}
