const createADMessage = (platform, ad, type, product, tone, target, sector, sendText, language) =>{

    const defAd =  () =>{

        if (ad === "A Post"){
            return `${platform} post`
        }
        else if (ad === "An AD"){
            return `${platform} AD`
        }
        else if (ad === "A comment"){
            return `${platform} comment`
        }
        
    }

    const defType =  () => type === "----" ? '' : ` Type of product: ${type};`

    const defType2 = (c) => type === "----" ? '' : ` of the ${type}${c}`

    const defProduct=  () => product === "" ? '' : ` Name${defType2(';')}: ${product};`

    let text = `Type of content: ${defAd()}; Tone of Voice: ${tone};${defType()} Target: ${target}; Target sector: ${sector};${defProduct()} Description${defType2('')}: ${sendText}; languade of the answer: ${language}`

    let message = []
    message.push({'role':'user', "content": text})
    return message;
    
}

export default createADMessage