export const style = {
    stack: {
        width: '100%',
        marginLeft:'65px', 
        height: '100vh',
        display:'flex',
        justifyContent: 'center',
        alignItem: 'center',
        flexDirection: 'row',
        marginTop: '70px',
        '@media (max-width: 600px)': {
            marginLeft:'0px', marginTop:'20px'
          }

    },
    header: {
        marginTop: '10px',
    },
    title:{
        fontSize: '25px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 0,
        margin: 0,
    },
    centerArea:{
        height: '100%',
        marginLeft: '10px',
        marginRight: '10px',
    },
    leftAreaSpace:{
        width: '300px',
        margin: '0',
        padding: '0',
        '@media (max-width: 600px)': {
            display: 'none',
          }

    },
    selectArea:{
        display: 'flex',
        justifyContent: 'end',
        alignItem: 'center',
        marginTop: '20px',
        
        width: '100%',

    },
    select: {
        width: '100px',
        marginRight: '20px',
        backgroundColor: '#FFF',
    },
}