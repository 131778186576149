import React from "react";

import Box from '@mui/material/Box';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LoopIcon from '@mui/icons-material/Loop';

import MyInputText from "../../myInputText";

import SocialIcon from '../socialIcons'
import { CircularProgress } from "@mui/material";

const style ={
    container:{
        display: 'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        marginBottom:'10px',
        paddingRight:'10px',
    },
    iconArea:{
        display: 'flex',
        flexDirection:'column',
        justifyContent:'start',
        alignItems: 'center',
        margin:'10px',
        marginTop:'0',
        marginLeft:'0',
        
        

    },
    icon:{
        height:'30px',
        width:'30px',
        marginBottom:' 5px'
    },
    centerArea:{
        display: 'flex', 
        flexDirection:'column', 
        width:'100%'
    },
    buttonArea:{
        display: 'flex', 
        flexDirection:'column', 
        justifyContent:'space-between', 
        marginBottom:'10px', 
        marginTop:'10px'
    },
    counterArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        margin: 0,
        padding: 0,

    },
    counter: {
        margin: 0,
        marginRight: '10px',
        padding: 0,
        fontSize: '11pt'
    },
}


const PublicationsPanel = ({publication, publications, setPublications, update}) => {

    const handlerDelete = (social) => {
        let publis = publications.map((publi)=> {
            if (publi.social === social){
                publi.active = false;
            }
            return publi;
        })

        setPublications(publis)
        publication.channel.set(false)
    }

    const onChange = (e, social) => {
        let publis = publications.map((publi)=> {
            if (publi.social === social){
                publi.text = e
            }
            return publi;
        })

        setPublications(publis)
    }


    return (
        <Box sx={style.container} >
            <Box sx={style.iconArea} >
                <SocialIcon sx={style.icon} icon={publication.social} linked={true}  />
                {
                    publication.generating ? <CircularProgress size={25} color="primary"/> : null
                }
            </Box>
            <Box sx={style.centerArea}>
                <Box>
                    <MyInputText
                        rows={4}
                        type='text'
                        id={`publication_${publication.social}`}
                        label=""
                        placeholder=""
                        required={false}
                        value={publication.text}
                        onChange={(e) =>{
                                onChange(e.target.value, publication.social)
                            }
                        }
                    />
                </Box>
                <Box>
                    {publication.social === 'twitter' ? (
                        <Box sx={style.counterArea}>
                            <p style={style.counter}>{publication.text.length}{" "}{" / 280"}</p>
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
            <Box sx={style.buttonArea}>
                <DeleteOutlinedIcon onClick={()=> handlerDelete(publication.social)} sx={{ color: 'red', cursor: 'pointer' }} />
                {
                    update ?
                    <LoopIcon onClick={()=> update()} color='primary' /> : null
                }
                
            </Box>
        </Box>
    )
}

export default PublicationsPanel;