import { useEffect, useState } from "react";
import { getPostLog } from "../../../services/ayrshare/getPostLog";
import { getTemplatePost } from "../../../services/ayrshare/getTemplatePost";
import { putPostLog } from "../../../services/ayrshare/putPostLog";
import { useNavigate } from "react-router-dom";

const useGeneratingState = (page, origin) =>{

    let navigate = useNavigate();
    let [submit, setSubmit] = useState(false);
    let [logId, setLogId] = useState(null);
    let [attempt, setAttempt] = useState(0);

    useEffect(()=>{
        handlerSubmit()
    },[attempt])
    
    const handlerSubmit = async ()=> {
        if (logId){
            setSubmit(true)
            let result = await getPostLog(logId)
            if (result.type === 'success'){
                if (result.data.stage==='CANCELLED' || result.data.stage==='SUCCESS' || result.data.stage === 'NOT TOKEN'){
                    page.set(origin)
                    navigate('/planner')
                }
                else{
                    setTimeout(()=>{
                        setAttempt(attempt+1)
                    }, 5000)
                }
            }
            else {
                setTimeout(()=>{setAttempt(attempt+1)}, 5000)
            }
        }
        
        setSubmit(false)
    }

    const handlerGetLogId = async () =>{
        if (submit){
            return 
        }
        setSubmit(true)
        let result = await getTemplatePost();
        if (result.data.code === 404){
            navigate('/planner-welcome')
        }
        else {
            if (result.data.post_logs?.filter(item=>item.stage === null || item.stage === 'PROGRESS').length > 0)
            {
                let logs = result.data.post_logs.filter(item=>item.stage === null || item.stage === 'PROGRESS')
                if (logs[0]?.id){
                    setLogId(result.data.post_logs[0]?.id)
                }
            }
            else {
                navigate('/planner')
            }
        }
        setSubmit(false)
    }
    
    const handlerCancelGeneration = async () => {
        if (logId){
            let json={
                action:'CANCELLED'
            }
            let result = await putPostLog(logId, json)
            if (result.type === 'success'){
                navigate('/planner')
            }
        }
    }

    return {
        logId: {set:handlerGetLogId, value: logId},
        submit: {set:handlerSubmit, value: submit},
        cancel: {set:handlerCancelGeneration, value: null},
        attempt: {set:()=>console.log('not implemented'), value: attempt},
    }

}


export default useGeneratingState