import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { style } from "./styles";

const MyDataTimeInput = ({value, onChange, size}) => {

  let slotProps = {
    layout: {
      sx: {
        [`.Mui-selected`]: {
          color: '#721384',
          fontWeight:500,
          backgroundColor: '#FFF',

        },
      },
    },
    
  }

  if (size === 'small'){
    slotProps = {
      ...slotProps, textField: { size: 'small' }
    }
  }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker 
            slotProps={slotProps}
            value={value} 
            onChange={onChange} 
            format="DD/MMM/YYYY HH:mm"
            />
        </LocalizationProvider>
    );
};

export default MyDataTimeInput;
