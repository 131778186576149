import { useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../contexts/auth";
import { postProfile } from "../../services/ayrshare/postProfile";
import { putProfile } from "../../services/ayrshare/putProfile";
import { getProfile } from "../../services/ayrshare/getProfile";
import { getProfileKey } from "../../services/ayrshare/getProfileKey";
import { postProfilePost } from "../../services/ayrshare/postProfilePost";
import { deletePostAPI } from "../../services/ayrshare/deletePostAPI";
import { getProfilePost } from "../../services/ayrshare/getProfilePost";
import formatDate from "../../utils/formatDate";
import generatesPublicationObject from './utils/generatesPublicationObject'
import { getTemplatePost } from "../../services/ayrshare/getTemplatePost";
import { postTemplatePost } from "../../services/ayrshare/postTemplatePost";
import { getPostLog } from "../../services/ayrshare/getPostLog";
import { putPostLog } from "../../services/ayrshare/putPostLog";

//const SocialPost = require("social-post-api");

const useProfileState = () => {
    const { signOut, user } = useContext(AuthContext);

    const [name, setName] = useState("");
    
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [status, setStatus] = useState("scheduled");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");
    const [profiles, setProfiles] = useState([]);
    const [socials, setSocials] = useState([]);
    const [profileSelected, setProfileSelected,] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [connect, setConnect] = useState(false);
    const [update, setUpdate] = useState(false);
    const [list, setList] = useState(false);
    const [listPost, setListPost] = useState([]);
    const [getListPost, setGetListPost] = useState(false);
    const [publish, setPublish] = useState(false);
    const [isIntroOpen, setIntroOpen] = useState(false)
    const [steps, setSteps] = useState(4)
    const [deletePost, setDeletePost] = useState(false);


    let lang = "english";

    if (user.language === 1) {
        lang = "spanish";
    } else if (user.language === 2) {
        lang = "portuguese";
    } else if (user.language === 3) {
        lang = "italian";
    }

    let [nameTemplate, setNameTemplate] = useState('')
    let [generationID, setGenerationID] = useState(null)
    let [siteTemplate, setSiteTemplate] = useState('')
    let [noHasSiteTemplate, setNoHasSiteTemplate] = useState(false)
    let [generateImageTemplate, setGenerateImageTemplate] = useState(true)
    let [languageTemplate, setLanguadeTemplate] = useState(lang)
    let [descriptionTemplate, setDescriptionTemplate] = useState('')
   
    useEffect(()=>{
        if (generationID && generationID > 0){
            setTimeout(()=>{
                handlerCheckGeneration()
            }, 5000)
        }
    },[generationID])

    const handlePut = async (updateHistoric) => {
        if (submit) {
            return;
        }

        let isNameValid = false;

        if (name !== "") {
            isNameValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid name" />);
        }

        if (isNameValid) {
            setSubmit(true);
            let json = {
                id: profileSelected,
                name: name.trim(),
                description: description.trim(),
            };
            return await putProfile(json)
                .then((json) => {
                    if (json?.type === "error") {
                        if (json.data.code === "token_not_valid") {
                            signOut();
                        } else if (
                            json.data.code === "violation of guidelines"
                        ) {

                            toast.error(
                                <FormattedMessage id={json.data.detail} />
                            );
                        } else if (json.data.code === "no tokens") {
                            toast.error('Error: Something went wrong', {
                                message:<>
                                                <FormattedMessage id="withoutTokens"></FormattedMessage>
                                                <FormattedMessage id="upgrade_to_use"></FormattedMessage>

                                        </>,
                            });
                        } else if (json.data.detail === "You have a profile with this name") {
                            toast.error(<FormattedMessage id="You have a profile with this name" />);
                        }else if (json.data.detail === "You have no more possible integrations") {
                            toast.error(<FormattedMessage id="You have no more possible integrations" />);
                        } else {
                            toast.error(<FormattedMessage id="unknown error" />);
                        }
                    } else {
                        toast.success(<FormattedMessage id="Account successfully changed!" />);
                        setSubmit(false);
                        return handleGet();
                    }
                    setSubmit(false);
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(
                            <FormattedMessage id="Connection failed to server" />
                        );
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }
                    setSubmit(false);
                });
        }
    };

    
    const handleAPI = async (updateHistoric) => {
        if (steps === 3) {
            return;
        }

        let isNameValid = false;
        if (!nameTemplate || nameTemplate === ''){
            toast.error(<FormattedMessage id="Provide a valid name" />);
            setSteps(1)
        }
        else if (!descriptionTemplate || descriptionTemplate === ''){
            toast.error(<FormattedMessage id="Provide a valid description" />);
            setSteps(1)
        }
        else if ( !noHasSiteTemplate && (!siteTemplate || siteTemplate === '')){
            toast.error(<FormattedMessage id="Provide a valid site" />);
            setSteps(1)
        }
        else {
            isNameValid = true;
        }


        if (isNameValid) {
            
            setSteps(3)
            let json = {
                name: nameTemplate.trim(),
                description: descriptionTemplate.trim(),
            };
            return await postProfile(json)
                .then((json) => {
                    
                    if (json?.type === "error") {
                        if (json.data.code === "token_not_valid") {
                            signOut();
                        } else if (
                            json.data.code === "violation of guidelines"
                        ) {

                            toast.error(
                                <FormattedMessage id={json.data.detail} />
                            );
                        } else if (json.data.code === "no tokens") {
                            toast.error('Error: Something went wrong', {
                                message:<>
                                                <FormattedMessage id="withoutTokens"></FormattedMessage>
                                                <FormattedMessage id="upgrade_to_use"></FormattedMessage>

                                        </>,
                            });
                        } else if (json.data.detail === "You have a profile with this name") {
                            toast.error(<FormattedMessage id="You have a profile with this name" />);
                        }else if (json.data.detail === "You have no more possible integrations") {
                            toast.error(<FormattedMessage id="You have no more possible integrations" />);
                        }else if (json.data.detail === "Invalid name") {
                            toast.error(<FormattedMessage id="Invalid name" />);
                        }  else {
                            toast.error(<FormattedMessage id="unknown error" />);
                        }
                    } else {
                        toast.success(<FormattedMessage id="Account successfully created!" />);
                        setSteps(4)
                        setSubmit(false);
                        return handleGet();
                    }
                    setSteps(1)
                    setSubmit(false);
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(
                            <FormattedMessage id="Connection failed to server" />
                        );
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }
                    setSteps(1)
                    setSubmit(false);
                });
        }
    };

    const handleGetListPost = async (url, type=null, month, year) => {
        if (getListPost) {
            return;
        } 
        const statusType  = type ?? status
        setGetListPost(true);
        getProfilePost(url, statusType, month, year).then(async (json) => {
            if (json?.type === "error") {
                if (json.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    json.data.code === "violation of guidelines"
                ) {

                    toast.error(
                        <FormattedMessage id={json.data.detail} />
                    );
                } else if (json.data.code === "no tokens") {
                    toast.error('Error: Something went wrong', {
                        message:<>
                                    <FormattedMessage id="withoutTokens"></FormattedMessage>
                                    <FormattedMessage id="upgrade_to_use"></FormattedMessage>
                                </>,
                    });
                } else {
                    toast.error(<FormattedMessage id={json.data.detail} />);
                }
            } else {

                if (statusType === 'all'){
                    setListPost(json.data)    
                }
                else {
                    setListPost(json.data.results)
                }
                setNext(json.data.next)
                setPrevious(json.data.previous)
            }
            setGetListPost(false);
        })
        .catch((error) => {
            if (error.message === "NetWork request failed") {
                toast.error(
                    <FormattedMessage id="Connection failed to server" />
                );
            } else {
                toast.error(<FormattedMessage id={error.message} />);
            }
        });
    }

    const handleGet = async () => {
        if (update) {
            return;
        }

        setUpdate(true);

        return await getProfile()
        .then((json) => {
            if (json?.type === "error") {
                if (json.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    json.data.code === "violation of guidelines"
                ) {

                    toast.error(
                        <FormattedMessage id={json.data.detail} />
                    );
                } else if (json.data.code === "no tokens") {
                    toast.error('Error: Something went wrong', {
                        message:<>
                                    <FormattedMessage id="withoutTokens"></FormattedMessage>
                                    <FormattedMessage id="upgrade_to_use"></FormattedMessage>
                                </>,
                    });
                } else {
                    toast.error(<FormattedMessage id="unknown error" />);
                }
            } else {
                setProfiles(json.data)
                
                if (json.data.length > 0){
                    setProfileSelected(json.data[0].id)
                }
            }
            setUpdate(false);
        })
        .catch((error) => {
            if (error.message === "NetWork request failed") {
                toast.error(
                    <FormattedMessage id="Connection failed to server" />
                );
            } else {
                toast.error(<FormattedMessage id={error.message} />);
            }
            setUpdate(false);
        });
        
    };

    const handleDeletePost = async (id) => {
        if (deletePost) {
            return;
        }
        setDeletePost(true);

        return await deletePostAPI(id)
        .then(async (json) => {
            if (json?.type === "error") {
                if (json.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    json.data.code === "violation of guidelines"
                ) {

                    toast.error(
                        <FormattedMessage id={json.data.detail} />
                    );
                } else if (json.data.code === "no tokens") {
                    toast.error('Error: Something went wrong', {
                        message:<>
                                    <FormattedMessage id="withoutTokens"></FormattedMessage>
                                    <FormattedMessage id="upgrade_to_use"></FormattedMessage>
                                </>,
                    });
                }
                else {
                    toast.error(<FormattedMessage id="unknown error" />);
                }
            } else {

                toast.success(<FormattedMessage id="deleted post" />);
                let list = listPost.filter(p => p.post.id !== id)
                setListPost(list)

            }
            setDeletePost(false);
        })
        .catch((error) => {
            if (error.message === "NetWork request failed") {
                toast.error(
                    <FormattedMessage id="Connection failed to server" />
                );
            } else {
                toast.error(<FormattedMessage id={error.message} />);
            }
            setDeletePost(false);
        });
    }

    const handleConnect = async () => {
        if (connect) {
            return;
        }

        setConnect(true);

        return await getProfileKey(profileSelected)
        .then(async (json) => {
            if (json?.type === "error") {
                if (json.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    json.data.code === "violation of guidelines"
                ) {

                    toast.error(
                        <FormattedMessage id={json.data.detail} />
                    );
                } else if (json.data.code === "no tokens") {
                    toast.error('Error: Something went wrong', {
                        message:<>
                                    <FormattedMessage id="withoutTokens"></FormattedMessage>
                                    <FormattedMessage id="upgrade_to_use"></FormattedMessage>
                                </>,
                    });
                }
                else {
                    toast.error(<FormattedMessage id="unknown error" />);
                }
            } else {
                setUrl(json.data.url)
                setSocials(json.data.social)
            }
            setConnect(false);
        })
        .catch((error) => {
            if (error.message === "NetWork request failed") {
                toast.error(
                    <FormattedMessage id="Connection failed to server" />
                );
            } else {
                toast.error(<FormattedMessage id={error.message} />);
            }
            setConnect(false);
        });
        
    };


    const handlePost = async (publications, image, date, file) =>{

        setSubmit(true)

        let publis = publications.map(publi=>{
            return generatesPublicationObject(publi.text, 
                [image], 
                [publi.social], 
                formatDate(new Date()),
                date ? formatDate(new Date(date)) : formatDate(new Date()), 
                file)
        })
        let r = ''

        
        
        for (var index = 0; index < publis.length; index++) {
            let result = await postProfilePost(profileSelected, publis[index]).then(async (json) => {
                if (json?.type === "error") {
                    return json.data.code
                } else {
                    return ''
                }
            })

            if (result ==="token_not_valid"){
                setSubmit(false)
                signOut();
                break
            }
            else if(result ==="violation of guidelines"){
                toast.error(
                    <FormattedMessage id="violation of guidelines" />
                );
                setSubmit(false)
                break
            }

            if (index === publis.length -1 && result ===''){
                toast.success(<FormattedMessage id='successful publication' />);
                setSubmit(false)
                r = 'success'
            } else if (index === publis.length -1 && result !==''){
                /*toast.error(
                    <FormattedMessage id={result} />
                );*/
                setSubmit(false)
            }
            
        }
        
        setSubmit(false)
        return r
    }

    const checkConfig = async ()=>{
        setGenerationID(null)
        let result = await getTemplatePost();

        if (result.data.code === 404){
            setIntroOpen(true)
        }
        else {
            setNameTemplate(result.data.name)
            handleGet()
            if (result.data.post_logs?.filter(item=>item.stage === null || item.stage === 'PROGRESS').length > 0)
            {
                result.data.post_logs?.forEach(item=>{
                    if (item.stage === null || item.stage === 'PROGRESS'){
                        setGenerationID(item.id)

                    }
                })
                setSteps(6)
                
            }
            else if (result.data?.posts_template?.length <= 0){
                setSteps(0)
            }
            else {
                setSteps(0)
            }
        }

    }

    
    const postTemmplate = async () => {
        if (steps === 6){
            return;
        }

        setSteps(6)

        if (!nameTemplate || nameTemplate === ''){
            toast.error(<FormattedMessage id="Provide a valid name" />);
            setSteps(1)
            return
        }

        if (!descriptionTemplate || descriptionTemplate === ''){
            toast.error(<FormattedMessage id="Provide a valid description" />);
            setSteps(1)
            return
        }
        
        if ( !noHasSiteTemplate && (!siteTemplate || siteTemplate === '')){
            toast.error(<FormattedMessage id="Provide a valid site" />);
            setSteps(1)
            return
        }
        let json = {
            description: descriptionTemplate,
            language: languageTemplate,
            name: nameTemplate,
            profile: profiles[0].id,
            generate_image: generateImageTemplate
        }

        if (!noHasSiteTemplate){
            json = {
                ...json,
                site: siteTemplate
            }
        }
       
        let result = await postTemplatePost(json)
        if (result.type === 'success'){
            setSteps(6)
        }

    }

    const handlerCheckGeneration = async () => {
        if (generationID && generationID > 0){
            let result = await getPostLog(generationID)
            if (result.type === 'success'){
                if (result.data.stage==='CANCELLED' || result.data.stage==='SUCCESS'){
                    checkConfig()
                }
                else{
                    setTimeout(()=>{
                        handlerCheckGeneration()
                    }, 5000)
                }
            }
            else{
                setTimeout(()=>{
                    handlerCheckGeneration()
                }, 5000)
            }
        }

    }

    const handlerCancelGeneration = async () => {
        if (generationID && generationID > 0){
            let json={
                action:'CANCELLED'
            }
            let result = await putPostLog(generationID, json)
            
            checkConfig()
        }
        

    }

    return {
        name: {
            value: name,
            set: setName,
        },        
        description: {
            value: description,
            set: setDescription,
        },        
        profiles: {
            value: profiles,
            set: setProfiles,
        },  
        profileSelected: {
            value: profileSelected,
            set: setProfileSelected,
        },  
        url: {
            value: url,
            set: setUrl,
        },  
        socials: {
            value: socials,
            set: setSocials,
        },  
        update: {
            value: update,
            set: handleGet,
        },  
        connect: {
            value: connect,
            set: handleConnect,
        },
        put: {
            value: submit,
            set: handlePut,
        },
        submit: {
            value: submit,
            set: handleAPI,
        },
        publish:{
            value:  publish,
            set: handlePost
        },
        listPost:{
            value:  listPost,
            set: setListPost
        },
        next:{
            value:  next,
            set: setNext
        },
        previous:{
            value:  previous,
            set: setPrevious
        },
        getListPost:{
            value:  getListPost,
            set: handleGetListPost
        },
        deletePost:{
            value:  deletePost,
            set: handleDeletePost
        },
        checkConfig: {
            value:  submit,
            set: checkConfig
        },
        status:{
            value:  status,
            set: setStatus
        },
        isIntroOpen:{
            value:  isIntroOpen,
            set: setIntroOpen
        },
        nameTemplate:{
            value:  nameTemplate,
            set: setNameTemplate
        },
        siteTemplate:{
            value:  siteTemplate,
            set: setSiteTemplate
        },
        noHasSiteTemplate:{
            value:  noHasSiteTemplate,
            set: setNoHasSiteTemplate
        },
        generateImageTemplate:{
            value:  generateImageTemplate,
            set: setGenerateImageTemplate
        },
        languageTemplate:{
            value:  languageTemplate,
            set: setLanguadeTemplate
        },
        descriptionTemplate:{
            value:  descriptionTemplate,
            set: setDescriptionTemplate
        },
        steps:{
            value:  steps,
            set: setSteps
        },
        postTemmplate:{
            value:  null,
            set: postTemmplate
        },
        generation: {
            value: generationID,
            set: handlerCheckGeneration
        },
        cancelGeneration:{
            value:null,
            set: handlerCancelGeneration
        }
    };
};

export default useProfileState;
