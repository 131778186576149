export const style = {
    stack: {
        width: '100%',
        '@media (max-width: 600px)': {
            width: '100%',
        }
    },
    titleArea:{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'row',
        margin: '14px',
        width:'100%',
    },
    title:{
        margin: '0px',
        padding: '0px',
        fontSize: '14pt',
        fontWeight: 600,
        marginBottom: '10px'
    },
    optionRow:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 0,
        padding: 0,

        '@media (max-width: 600px)': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            justifyContent: 'center',
          }
    },
    selectArea:{
        marginTop: '20px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '10px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr 1fr',
          }
    },
    select: {
        backgroundColor: '#FFF',
    },
    textInput: {
        width: '100%',
        backgroundColor: '#FFF',
    },
    describeArea: {
        marginTop: '20px',
        width: '100%',
    },
    counterArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        margin: 0,
        padding: 0,

    },
    counter: {
        margin: 0,
        marginRight: '10px',
        padding: 0,
        fontSize: '11pt'
    },
    buttonArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '10px',
        padding: 0,

    },
    center:{
        marginTop: '20px',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}