import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MyLabel from "../../components/myLabel"
import CHANNELS from "../../components/ayrshare/channelsNew";
import useEditorialAddState from "../../hooks/ayrshare/planner/useEditorialAddState";
import SocialIcon from "../../components/ayrshare/socialIcons";
import MyButton from "../../components/myButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MyInputText from "../../components/myInputText";
import Loading from "../../components/loading";


const SettingsDialog = ({ open, handleClose, handlerSuccess, postTemplate }) => {

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={" "} />
        </DialogTitle>
        <DialogContent>
            <Box sx={{ margin: '30px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%'}}>
                <Box  sx={{ width: '100%' }}>
                    <MyLabel sx={{ fontSize: '14pt' }} text={'Enter the name of your brand or business'} />
                    <MyInputText
                        sx={{ backgroundColor: 'white', width: '100%' }}
                        minRows={1}
                        type='text'

                        id="Enter the name of your brand or business"

                        required={true}
                        value={postTemplate.value?.name}
                        onChange={(e) =>{
                                let _postTemplate = {...postTemplate.value}
                                _postTemplate.name= e.target.value
                                postTemplate.set(_postTemplate)
                            }
                        }
                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '2rem' }}>
                <Box width={'50%'} marginRight={4}>
                    <MyLabel sx={{ fontSize: '14pt' }} text={'Do you have a website? Enter it here'} />
                    <MyInputText
                        sx={{ backgroundColor: 'white', width: '100%' }}
                        minRows={1}
                        type='text'

                        id='Do you have a website? Enter it here'

                        required={true}
                        value={postTemplate.value?.site}
                        onChange={(e) =>{
                            let _postTemplate = {...postTemplate.value}
                            _postTemplate.site= e.target.value
                            postTemplate.set(_postTemplate)
                            }
                        }

                    />
                </Box>
                <Box width={'50%'}>
                    <MyLabel sx={{ fontSize: '14pt' }} text={'Which language do you want the content in?'} />
                    <MyInputText
                        sx={{ backgroundColor: 'white', width: '100%' }}
                        minRows={1}
                        type='text'

                        id='Which language do you want the content in?'

                        required={true}
                        value={postTemplate.value?.language}
                        onChange={(e) =>{
                            let _postTemplate = {...postTemplate.value}
                            _postTemplate.language= e.target.value
                            postTemplate.set(_postTemplate)
                            }
                        }

                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '2rem' }}>
                <Box width={'100%'} marginRight={4}>
                    <MyLabel sx={{ fontSize: '14pt' }} text={'Describe your business in more detail; the more information you provide, the better the AI will be at creating customized content for you!'} />
                    <MyInputText
                        sx={{ backgroundColor: 'white', width: '100%' }}
                        minRows={12}
                        type='text'

                        id="Enter the name of your brand or business"

                        required={true}
                        value={postTemplate.value?.description}
                        onChange={(e) =>{
                            let _postTemplate = {...postTemplate.value}
                            _postTemplate.description= e.target.value
                            postTemplate.set(_postTemplate)
                          }
                        }

                    />
                </Box>


            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', margin: '10px' }}>
                <MyButton
                sx={styles.cancelButton}
                onClick={handleClose}
                text='Return'
                variant="contained"
                size="small"
                />
                <MyButton
                sx={{ ...styles.exitButton, marginBottom: 0 }}
                onClick={handlerSuccess}
                text='Save'
                variant="contained"
                size="small"
                color='warning'
                />
            </Box>
            </Box>
        </DialogContent>
        </Dialog>
    )
    }

const PlannerAddEditorial = () => {

    let states = useEditorialAddState()
    const navigate = useNavigate();
    let [attempt, setAttempt] = useState(0);

    const checkSocials = () => {
        if (!states.submit.value && states.profile.value && states.profile.value?.id > 0 
            && states.profileUrl.value && states.profileUrl.value !== '') {
            states.profileUrl.set()
            setTimeout(()=>{setAttempt(attempt+1)}, 2500)
        }
        else {
            setAttempt(0)
        }
      }

    useEffect(
        ()=>{
            states.profile.set()

        },[])

    useEffect(
        ()=>{
            if (states.profile.value){
                states.profileUrl.set()
                setTimeout(()=>{setAttempt(attempt+1)}, 5000)
            }

        },[states.profile.value])
        
        useEffect(()=>{
            if (attempt > 0){
                checkSocials()
            }
        },[attempt])

    return (
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
            <Loading render={states.submit.value} />
            <Box sx={styles.content}>
                <Box sx={{ position:'relative', textAlign: 'center', width: '100%'}}>
                    <ArrowBackIcon onClick={()=>navigate(-1)} fontSize={'large'} sx={{ position:'absolute', left: '10px', cursor:'pointer'}} />
                    <MyLabel component={'h1'} sx={styles.title} text={`Connect your social media`} />
                </Box>
                <Box sx={styles.container}>
                    <MyLabel component={'p'} sx={styles.title2} text={`Click on the icons of the social networks where you wish to post and connect your accounts:`} />
                    <Box sx={{
                    marginTop: '3rem',
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '30px', justifyContent: 'center',
                    '@media (max-width: 600px)': {
                        gap: '20px',
                    }
                }}>
                    {
                    CHANNELS.map((channel, key) => {
                        let sx = { height: '60px', width: '60px', };

                        if (!channel.active) {
                        sx = { ...sx, opacity: '0.3' };
                        }
                        return (
                        <Box sx={{ margin: 0, position: 'relative' }} key={key} onClick={() => { 
                                states.socials.value?.filter(item => item === channel.icon).length === 0 && window.open(states.profileUrl.value, '_blank'); 
                            
                            }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                            <SocialIcon sx={{
                                ...sx,
                                width: '120px',
                                marginTop: '3em',
                                '@media (max-width: 600px)': {
                                width: '60px',
                                }
                            }} icon={channel.icon} linked={states.socials.value?.filter(item => item === channel.icon).length > 0} />
                            <Box>
                                <MyButton
                                sx={{
                                    backgroundColor: states.socials.value?.filter(item => item === channel.icon).length > 0 ? 'green' : 'grey',
                                    marginTop: '1rem',
                                    fontSize: '0.8rem',
                                    ':disabled': {
                                    backgroundColor: "green",
                                    color: 'white'
                                    }

                                }}
                                disabled={states.socials.value?.filter(item => item === channel.icon).length > 0}
                                text={states.socials.value?.filter(item => item === channel.icon).length > 0 ? 'Connect' : 'Connect'}
                                variant="contained"
                                size="small"
                                />
                            </Box>
                            </Box>
                            {states.socials.value?.filter(item => item === channel.icon).length > 0 && <Box sx={{ position: 'relative', left: '0.7rem', top: '0.2rem', width: '100px', }}>
                                <MyLabel onClick={() => {states.socials.value?.filter(item => item === channel.icon).length === 0 && window.open(states.profileUrl.value, '_blank');}}component='p' text='Disconnect' sx={{ cursor: 'pointer', fontWeight: 500, textDecoration: 'underline', fontSize: '0.8rem', }} />
                            </Box>
                            }
                            <Box sx={{ position: 'absolute', padding: '5px', width: '100px', display: 'none' }}>
                            <MyLabel component='p' text='update soon' sx={{ fontWeight: 500, fontStyle: 'italic' }} />
                            </Box>
                        </Box>
                        )
                    })
                    }
                    </Box>
                    <Box sx={{marginTop:'20px'}}>
                        <MyLabel onClick={()=>states.dialogOpen.set(true)} component='a' text='Review your account settings *' sx={{ cursor:'pointer', color:'#2681fd', fontWeight: 500, fontSize:'15pt', textDecoration:'underline' }} />
                        <MyLabel component='p' text='* by modifying the settings, all your future automated posts will adhere to the latest changes.' sx={{ fontWeight: 500, fontStyle: 'italic' }} />
                    </Box>
                    <Box sx={{marginTop:'20px', display:'flex', justifyContent:'end'}}>
                        <MyButton
                            sx={styles.button}
                            disabled={false}
                            onClick={()=>{states.submit.set()}}
                            text='Save and proceed'
                            variant="contained"
                            size="medium"
                        />
                    </Box>
                </Box>
                
            </Box>
            <SettingsDialog open={states.dialogOpen.value} handleClose={() => states.dialogOpen.set(false)} handlerSuccess={() => { states.updatePostTemplate.set();states.dialogOpen.set(false) }} postTemplate={states.postTemplate} />
        </Box>
    )

}

const containerBase = {
    boxShadow: '0 0 3px 1px #BCBCBC',
    marginTop:'2rem',
    borderRadius: '12px', 
    border: '1px solid lightgrey', 
    padding: '20px'
}

const contentBase = {
    backgroundColor: 'white',
    height:'90%',
    textAlign: 'center',
    margin: '0.5rem 0 0 0',
    padding: '4rem 3rem', 
}
const textBase = {
    margin: '0px',
    padding: '0px',
    marginBottom: '10px'
}


const styles = {
    content:{
        ...contentBase,
    },
    container:{
        ...containerBase
    },
    title:{
        ...textBase,
        fontSize: '16pt',
        fontWeight: 600,
      },
    title2:{
    ...textBase,
    fontSize: '16pt',
    marginBottom: '1rem',
    '@media (max-width: 600px)': {
        marginBottom: 0,
        fontSize: '12pt',
    }},
}

export default PlannerAddEditorial