import { useContext, useState } from "react"
import { deletePostAPI } from "../../services/ayrshare/deletePostAPI"
import { AuthContext } from "../../contexts/auth";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import formatDate from "../../utils/formatDate";
import generatesPublicationObject from "./utils/generatesPublicationObject";
import { putProfilePost } from "../../services/ayrshare/putProfilePost";
import { getConfigOpenAI } from "../../services/openAI/getConfigOpenAI";
import { getOpenAI } from "../../services/openAI/getOpenAi";
import { postHistoric } from "../../services/postHistoric";
import { postDalle } from "../../services/openAI/dalle";

const useViewTemplateState =  (profileSelected, post, handleClose, updateListPost) => {

    const { signOut } = useContext(AuthContext);

    const [postDate , setPostDate] = useState(null)
    const [text , setText] = useState(null)
    const [image , setImage] = useState(null)
    const [file , setFile] = useState(null)
    
    const [submit , setSubmit] = useState(null)

    const putPost = async () => {
        let editDate = postDate !== null
        let editText = text !== null
        let editImage = (image !== null) || (file !== null)

        if (editDate && new Date(postDate) < new Date()){
            toast.error(<FormattedMessage id='select a future date.' />);
            return
        }
        let mediaUrl = ''

        if (editImage && image && file !== null){
            if (image !== '-'){
                mediaUrl = image
            }
        }
        else if (post.post.image){
            mediaUrl = post.post.image
        }
        else if (post.post.image){
            mediaUrl = post.post.image
        }

        if (editDate || editText || editImage){
            setSubmit(true)
            
            let publis = generatesPublicationObject(editText ? text : post.post.text, 
                mediaUrl, 
                [post.platform], 
                formatDate(new Date()), 
                editDate ? new Date(postDate).toISOString() : new Date().toISOString(),
                file,
                post.post.id
            )
            
//2/08/2024 07:00
            let result = await putProfilePost(profileSelected, publis)
            
            if (result.type === 'success'){
                if (editDate){
                    setPostDate(null)
                }
                if (editText){
                    setText(null)
                }
                if (editImage){
                    setImage(null)
                }
                if (updateListPost){
                    updateListPost()
                }
                toast.success(<FormattedMessage id="update post" />);
                handleClose()
            }
            else {
                toast.error(<FormattedMessage id={result.data.detail} />);
            }

            setSubmit(false)
        }

    }

    const deletePost = async (id) => {
        if (submit) {
            return;
        }
        setSubmit(true);

        return await deletePostAPI(id)
        .then(async (json) => {
            if (json?.type === "error") {
                if (json.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    json.data.code === "violation of guidelines"
                ) {

                    toast.error(
                        <FormattedMessage id={json.data.detail} />
                    );
                } else if (json.data.code === "no tokens") {
                    toast.error('Error: Something went wrong', {
                        message:<>
                                    <FormattedMessage id="withoutTokens"></FormattedMessage>
                                    <FormattedMessage id="upgrade_to_use"></FormattedMessage>
                                </>,
                    });
                }
                else {
                    toast.error(<FormattedMessage id="unknown error" />);
                }
            } else {
                toast.success(<FormattedMessage id="deleted post" />);
                if (updateListPost){
                    updateListPost()
                }
                handleClose()
            }
            setSubmit(false);
        })
        .catch((error) => {
            if (error.message === "NetWork request failed") {
                toast.error(
                    <FormattedMessage id="Connection failed to server" />
                );
            } else {
                toast.error(<FormattedMessage id={error.message} />);
            }
            setSubmit(false);
        });
    }

    const autoGeneraImage = async () =>{
        if (submit)
        {
            return;
        }
        setSubmit(true);
        let imageDescription = `Can you suggest a simple frase for generating an image using AI (DALLE) for this post? It should be something simple and objective, so the AI will easily generate something appropriate. Remember to don't create a description that makes the AI generate faces or words, as it won't work well. Also don't use any names as AI won't generate the image well. It can be either an objective or an abstract image, depending on the text: ${text ? text : post.post.text}`
        let config = await getConfigOpenAI(imageDescription, 7, ()=>{}, signOut)
        let messages = [
            {'role': 'system', "content": "You are a helpful and creative assistant."},
            {'role':'user', "content": imageDescription.trim()}
        ]
        let send = JSON.stringify({text: imageDescription.trim()});
        let chat = await getOpenAI(messages, config)
                    await postHistoric({
                        send: send,
                        text: JSON.stringify(messages),
                        result: chat,
                        date: formatDate(new Date()),
                        action: 5
                    })
        let json = {
            text: chat.trim(),
            size: 512,
            style: 'standard',
            date: formatDate(new Date()),
        };
        let dalle = await postDalle(json)
        if (dalle?.type === "error") {
            if (dalle.data.code === "token_not_valid") {
                setSubmit(false);
                signOut();
            }
            else {
                toast.error(<FormattedMessage id={dalle.data.detail} />);
            }
        } else {
            let imgs = JSON.parse(dalle.data);
            setImage(imgs.urls[0])
        }
        setSubmit(false);
    }
    

    return {
        postDate: { set: setPostDate,  value: postDate  },
        text: { set: setText,  value: text  },
        image: { set: setImage,  value: image  },
        delete: { set: deletePost,  value: submit  },
        file: { set:setFile ,  value: file  },
        imageAuto: { set: autoGeneraImage,  value: image  },
        update: { set: putPost,  value: submit  },
        submit: { set: setSubmit,  value: submit  }

    }
}

export default useViewTemplateState