import backEnd from "../constants/backEnd";
import endpoints from "../constants/endpoints";

import formatEmail from "../utils/formatEmail";
import { getCSRF } from "./getCSRF";

export async function login(email, password) {
    let csrf = await getCSRF();

    return fetch(`${backEnd.url}${endpoints.access.login}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrf?.csrfToken,
        },
        body: JSON.stringify({
            email: formatEmail(email),
            password: password,
        }),
    }).then(async (retorno) => {
        let dados = await retorno.json();
        if (retorno.status !== 200) {
            throw new Error(dados.detail);
        }
        return dados;
    });
}

export async function register(json) {
    let csrf = await getCSRF();
    return fetch(`${backEnd.url}${endpoints.access.register}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrf?.csrfToken,
        },
        body: JSON.stringify(json),
    }).then(async (retorno) => {
        let dados = await retorno.json();
        if (retorno.status === 400) {
            if (dados.email) {
                throw new Error(dados.email);
            } else if (dados.password) {
                throw new Error(dados.password);
            } else {
                throw new Error(dados);
            }
        } else if (retorno.status !== 201) {
            throw new Error(dados.detail);
        }
        return dados;
    });
}
