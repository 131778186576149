import api from '../services/api';
import backEnd from '../constants/backEnd';
import endpoints from '../constants/endpoints';

export async function getPurchasedTokens(token_type, mini) {
  return api
    .get(`${backEnd.url}${endpoints.purchasedTokens}${token_type}?mini=${mini}`)
    .then(response => {
      return {type: 'success', data: response.data};
    })
    .catch(error => {
      return {
        type: 'error',
        data: {
          code: error.response.data.code,
          detail: error.response.data.detail,
        },
      };
    });
}
