import React from "react";
import { FormattedMessage } from "react-intl";
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';


const MyButton = (props) => {
    
    return <Button {...props} ><FormattedMessage id={props.text} /></Button>
};


export default MyButton;
