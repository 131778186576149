import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MyImage from "../../myImage";
import MyLabel from "../../myLabel";


export const style = {

    card:{
        position:'relative',
        padding: '5px',
        display:'flex',
        cursor: 'pointer',
        '&:hover': {
            
            boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
         },
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            width: '180px',
            height: '180px',
          }
    },

    content:{
        display: 'flex',
        margin: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        alignContent: 'center'
    },

    titleArea: {
        width: '100%',
        display:'flex',
        backgroundColor: '#FFF',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center'
    },

    title: {
        fontSize: '11pt',
    },

    icon: {
        height: '40px',
        width: '40px',
        background: 'none',
    },
    new:{
        top: '1px',
        left: '5px',
        position:'absolute',
        textDecoration: 'underline',
        padding: '5px',
        borderRadius: '40px',
        color: '#de4394',
        fontWeight:700,
        fontSize: '12pt',
    }
}

const MyPanel = ({ onClick, icon, name, width, height, selected, isNew, content}) => {
    let cardStyle = {...style.card, width: `${width}px`, height:`${height}px`,};

    if (selected ){
        cardStyle = {...cardStyle, boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)', border: '1px solid #721384',}
    }
    return (
        <Card onClick={onClick} sx={cardStyle} >
            {
                isNew ? <MyLabel component='p' text={'new'} sx={style.new}  /> 
                :
                null
            }
            <CardContent sx={style.content}>
                <MyImage src={icon} alt={`icon ${name}`} sx={style.icon} />
                <Box sx={style.titleArea} >
                    <MyLabel component='p' text={name} sx={style.title}  />
                </Box>
            </CardContent>
        </Card>
    )
};

export default MyPanel;
