import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import { postProfile } from "../../../services/ayrshare/postProfile";
import { getTemplatePost } from "../../../services/ayrshare/getTemplatePost";
import { getProfileKey } from "../../../services/ayrshare/getProfileKey";
import { postTemplatePost } from "../../../services/ayrshare/postTemplatePost";
import { useNavigate, useSubmit } from "react-router-dom";
import { putTemplatePost } from "../../../services/ayrshare/putTemplatePost";
import { postPostLog } from "../../../services/ayrshare/postPostLog";

const useEditorialAddState = () => {

    let navigate = useNavigate();
    const { signOut, user } = useContext(AuthContext);

    let [submit, setSubmit] = useState(false);
    let [dialogOpen, setDialogOpen] = useState(false);
    let [profilePost, setProfilePost] = useState(false);
    let [profile, setProfile] = useState(null);
    let [postTemplate, setPostTemplate] = useState(null);
    let [profileUrl, setProfileUrl] = useState('');
    let [socials, setSocials] = useState([]);
    let [socialsBase, setSocialsBase] = useState([]);

    const getProfile = async () =>{
        if (submit){
            return
        }

        setSubmit(true)
        let result = await getTemplatePost();
        if (result.type === 'success'){
            setProfile(result.data)
            if (result.data.posts_template?.length > 0){
                setPostTemplate(result.data.posts_template[0])
            }
        }

        setSubmit(false)
    }

    const getProfileUrl = async () =>{
        if (profile?.id && profile.id > 0){
            let result = await getProfileKey(profile.id)
            if (result?.type === "error") {
                if (result.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    result.data.code === "violation of guidelines"
                ) {
                    toast.error(
                        <FormattedMessage id={result.data.detail} />
                    );
                } else {
                    toast.error(<FormattedMessage id="unknown error" />);
                }
            } else {
                setProfileUrl(result.data.url)
                if (result.data.social){
                    setSocials(result.data.social)
                    if (socialsBase.length <= 0){
                        setSocialsBase(result.data.social)
                    }
                }
            }

        }
    }

    const handlerSubmit = async () => {
        if (submit){
            return
        }
        let isOtherSocial = false
        socials.forEach(social=>{
            if (!isOtherSocial){
                isOtherSocial = socialsBase.filter(socialBase => socialBase === social).length <= 0
            }
        })

        /*if (!isOtherSocial){
            toast.error(<FormattedMessage id="connect a new social network" />);
            return
        }*/
        
        setSubmit(true)  

        if ((profile.post_logs.length > 0) /*&& isOtherSocial*/){

            let post_log = profile.post_logs[0]
            let json = {
                profile: post_log.profile,
                post_template: post_log.post_template,
                description: post_log.description,
                period_start: post_log.period_start,
                period_end: post_log.period_end,
                generate_image: post_log.generate_image,
                extra_posts: post_log.extra_posts,
            }
            let result = await postPostLog(json)
            if (result?.type === "error") {
                if (result.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    result.data.code === "violation of guidelines"
                ) {
                    toast.error(
                        <FormattedMessage id={result.data.detail} />
                    );
                } else {
                    toast.error(<FormattedMessage id="unknown error" />);
                }
            } else {
                navigate('/planner')
            }
        }
        setSubmit(false)   
        
    }

    const handlerUpdate = async () =>{
        if (postTemplate){
            setSubmit(true)
            let result = await putTemplatePost(postTemplate)
            if (result.type === 'success'){
                toast.success(
                    <FormattedMessage id="brand updated successfully" />
                );
            }
            else{
                toast.error(
                    <FormattedMessage id={"unknown error"} />
                );
            }
            setSubmit(false)
        }
    }
    
    return {
        updatePostTemplate:{set:handlerUpdate, value: postTemplate},
        postTemplate: {set:setPostTemplate, value: postTemplate},
        dialogOpen: {set:setDialogOpen, value: dialogOpen},
        profile: {set:getProfile, value: profile},
        socials: {set:setSocials, value: socials},
        socialsBase: {set:setSocialsBase, value: socialsBase},
        profileUrl: {set:getProfileUrl, value: profileUrl},
        submit: {set:handlerSubmit, value: submit}
    }
}

export default useEditorialAddState