const generatesPublicationObject = (post, mediaUrls, platforms, date,schedule, file, id) => {
    const formData = new FormData();
    if (file){
        formData.append('file', file);
    }
    if (id){
        formData.append('id', id);
    }

    formData.append('post', post);
    formData.append('mediaUrls', mediaUrls);
    formData.append('platforms', platforms);
    formData.append('date', date);
    formData.append('schedule', schedule);
    return formData
}

export default generatesPublicationObject