import React, { useContext, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import svgCrown from "../../assets/imgs/crown.svg";
import svgCheckGreen from "../../assets/imgs/check-green.svg";
import svgDotUpgrade from "../../assets/imgs/dot-upgrade.svg";
import svgRettangleModalPro from "../../assets/imgs/rettangle-modal-pro.svg";
import fireSvg from "../../assets/imgs/FIRE.svg";
import circelRedSelected from "../../assets/imgs/circle-red-selected.svg";
import svgDotUpgradeEmpty from "../../assets/imgs/dot-upgrade-empty.svg";
import blackFridayPng from "../../assets/imgs/black_friday.png";
import blackFridayMobilePng from "../../assets/imgs/black_friday_mobile.png";
import svgGift from "../../assets/imgs/gift.svg";
import { AuthContext } from "../../contexts/auth";
import MyLabel from '../myLabel';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import MyImage from '../myImage';
import moment from 'moment';
import formatTryProDate from '../../utils/formatTryProDate';
import MyButton from '../myButton';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import SubscriptionButton from './subscriptionButton';

const mappingNewPrices = {
    "PRO Light": '2,99 €',
    "PRO Plus": '5,49 €',
    "PRO Max": '12,99 €'
}

const mappingPrices = {
    "PRO Light": '9,00 €',
    "PRO Plus": '19,00 €',
    "PRO Max": '49,00 €'
}

const style = {
    container:{
        display: 'flex', 
        flexDirection: 'row', 
        width: '100%',
        '@media (max-width: 600px)': {
            flexDirection: 'column', 
            width: '100vw',
        },
    },
    firstX:{
        position: 'absolute',
        right: 10,
        top: 8,
        color:'white',
    },
    secondtX:{
            position: 'absolute',
            right: 10,
            top: 8,
            color:'white',
            '@media (max-width: 600px)': {
                zIndex: 99999
            },
    },
    container1:{
        cursor:'pointer',
        width: '55em',
        height:'30em',
        backgroundSize:'cover',
        backgroundPosition: 'center center',
        resize: 'both',
        backgroundImage:`url(${blackFridayPng})`,
        backgroundRepeat:'no-repeat',
        '@media (max-width: 600px)': {
            backgroundSize:'cover',
            height:'92%',
            width: '92%',
            backgroundImage:`url(${blackFridayMobilePng})`,
        },
    },

    countdownBox:{
            fontSize:'3rem', 
            // position:'relative', 
            marginTop:'110px', 
            marginLeft:'40px', 
            color:'white', 
            color:'red',
            '@media (max-width: 600px)': {
                marginTop:'130px', 

            }
    },
    firstBox:{
        backgroundColor: '#f2f2f2', 
        width: '50%', 
        padding: '18px', 
        textAlign: 'center',
        '@media (max-width: 600px)': {
            width: '100%', 
        },
    },
    firstBox2:{
        position: 'relative', 
        left: '5%', 
        top: '15%', 
        padding: '0 40px',
        '@media (max-width: 600px)': {
            top: '0', 
        },
    },
    secondBox:{
        backgroundColor:'black',
        width: '50%', 
        width: '50%', 
        padding: '18px',
        '@media (max-width: 600px)': {
            width: '100%', 
        },
    }
}

const TryProBlackFridayDialog = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const navigate = useNavigate();
    const modalTryPro = urlParams.get('try-pro')
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [planSelected, setPlanSelected] = useState('PRO Plus');
    const [modalView, setModalView] = useState(window.innerWidth < 600? 1 : 0 )
    const { language, user, setUser } = useContext(AuthContext);
    const [countDown, setCountDown] = useState('')
    const handleClose = () => {
        setModalView(window.innerWidth < 600? 1 : 0)
        setPlanSelected('PRO Plus')
        navigate(window.location.pathname)
        setUser()
    }

    const languageMapped = {
        0:'en', 
        1: 'es',
        2: 'pt',
        3: 'it'
    }

    const planIdTrial = () =>{

        if (planSelected === 'PRO Light'){
            return process.env.REACT_APP_SUBSCRIPTION_PLAN_BLACK_FRIDAY_LIGHT_ID
        }
        else if (planSelected === 'PRO Max'){
            return process.env.REACT_APP_SUBSCRIPTION_PLAN_BLACK_FRIDAY_MAX_ID
        }
        else {
            return process.env.REACT_APP_SUBSCRIPTION_PLAN_BLACK_FRIDAY_PLUS_ID
        }
    }

    
    const countDownDate = new Date(new Date("Nov 25, 2023 08:00:00").toLocaleString("en-US", {timeZone: "Europe/Rome"}))

    // Update the count down every 1 second
    const x = setInterval(function() {

    // Get today's date and time
    const now = new Date().getTime();
        
    // Find the distance between now and the count down date
    const distance = countDownDate - now;
        
    // Time calculations for days, hours, minutes and seconds
    const hours = Math.floor((distance / (1000 * 60 * 60)));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
    // Output the result in an element with id="demo"
    setCountDown(hours + "h "
    + minutes + "m " + seconds + "s ")
        
    // If the count down is over, write some text 
    if (distance < 0) {
        clearInterval(x);
        setCountDown("EXPIRED");
    }
    }, 1000);

    return (
        // open={!!modalTryPro}
        <Dialog fullScreen={fullScreen} open={!!modalTryPro} maxWidth="lg"
            onClose={() => handleClose()}
        >
            {modalView === 1 ? (
            <IconButton
                aria-label="close"
                onClick={() => handleClose()}
                sx={style.firstX}
            >
                <CloseIcon />
            </IconButton>
            ) : (
                <IconButton
                aria-label="close"
                onClick={() => handleClose()}
                sx={style.secondtX}
                
            >
                <CloseIcon />
            </IconButton>
            )}
            {modalView === 1 ? (
            <Box sx={style.container} >
                <Box sx={style.secondBox}>
                <>
                        
                        <DialogTitle id="alert-dialog-title">
                            <Box style={{display:'flex', flexDirection:'row'}}>
                            <MyLabel sx={{ fontSize: '1.6rem', fontWeight: '600', color:'white' }} component='p' text={"LIMITED OFFER - BLACK FRIDAY"} />
                            <MyImage src={svgCrown} sx={{ height: '35px', width: '35px', marginLeft:'1rem' }} alt={`crown`} />
                            </Box>
                            
                            <MyLabel sx={{ fontWeight: '600', color: '#ffc439', }} component='p' text={"GPT 4.0 TURBO E DALLE 3 INCLUDED IN ALL PLANS"} />
                            <MyLabel sx={{ fontWeight: '600', color: 'white', }} component='p' text={"AdMind PRO starting from only €2,99 for the first 3 months"} />
                        </DialogTitle>
                        </>
                    {modalView === 1 && (
                       <>
                       <DialogContent>
                           <Box display={'flex'} flexDirection={'row'}>
                               <MyImage onClick={() => setPlanSelected('PRO Light')} src={planSelected === 'PRO Light' ? circelRedSelected : svgDotUpgradeEmpty} sx={{ cursor: 'pointer', height: '15px', width: '15px', background: 'none', marginTop: '0.4rem', marginRight: '0.6rem' }} alt={`check`} />
                               <Typography sx={{ fontWeight: '600', color:'white' }} component='p' >PRO LIGHT</Typography>

                           </Box>
                           <Box style={{display:'flex'}}>
                           <Typography sx={{ marginLeft: '1.6rem', color: 'white', textDecoration:'line-through', textDecorationColor:'red' }} component='p' >9,00 €</Typography>
                           <Typography sx={{ marginLeft: '1.6rem', color: '#ffc439', fontWeight:'600',fontSize:'1.1rem' }} component='p' >2,99 €</Typography>
                           </Box>
                           
                           <MyLabel sx={{ marginLeft: '1.6rem', color:'white' }} component='p' text={"- 500,000 characters for all tools (including Article Generator, AD Generator, Keyword Wizard, Product name Generator, Topic Ideas Generator)"} />
                           <MyLabel sx={{ marginLeft: '1.6rem', color:'white' }} component='p' text={"- Unlimited use of the Grammar Check & Translator Tool"} />
                           <MyLabel sx={{ marginLeft: '1.6rem', color:'white' }} component='p' text={"- 35 AI-generated images"} />

                       </DialogContent>

                       <DialogContent>
                           <Box display={'flex'} flexDirection={'row'}>
                               <MyImage onClick={() => setPlanSelected('PRO Plus')} src={planSelected === 'PRO Plus' ? circelRedSelected : svgDotUpgradeEmpty} sx={{ cursor: 'pointer', height: '15px', width: '15px', background: 'none', marginTop: '0.4rem', marginRight: '0.6rem' }} alt={`check`} />
                               <Typography sx={{ fontWeight: '600', color:'white' }} component='p'  >PRO PLUS</Typography>
                               <MyImage src={fireSvg} sx={{ height: '30px', width: '30px', position:'relative', bottom:'0.3rem'}} alt={`fire`} />
                               <Box style={{ marginLeft:'0.8rem', padding:'7px 16px 2px 16px', borderRadius:'18px', backgroundImage: `url(${svgRettangleModalPro})`}}>
                                   <MyLabel sx={{fontSize:'0.7rem', color:'white'}} text={'BEST SELLER'} />
                               </Box>
                              

                           </Box>
                           <Box style={{display:'flex'}}>
                           <Typography sx={{ marginLeft: '1.6rem', color: 'white', textDecoration:'line-through', textDecorationColor:'red'  }} component='p'>19,00 €</Typography>
                           <Typography sx={{ marginLeft: '1.6rem', color: '#ffc439', fontWeight:'600',fontSize:'1.1rem' }} component='p' >5,49 €</Typography>
                           </Box>
                           
                           <MyLabel sx={{ marginLeft: '1.6rem', color:'white' }} component='p' text={"- Enjoy everything included in PRO Light"} />
                           <MyLabel sx={{ marginLeft: '1.6rem', color:'white' }} component='p' text={"- Link one social media account (which can include 1 profile each of Facebook, Facebook Group, Twitter/X, LinkedIn, Instagram)"} />


                       </DialogContent>

                       <DialogContent>
                           <Box display={'flex'} flexDirection={'row'}>
                               <MyImage onClick={() => setPlanSelected('PRO Max')} src={planSelected === 'PRO Max' ? circelRedSelected : svgDotUpgradeEmpty} sx={{ cursor: 'pointer', height: '15px', width: '15px', background: 'none', marginTop: '0.4rem', marginRight: '0.6rem' }} alt={`check`} />
                               <Typography sx={{ fontWeight: '600', color:'white' }} component='p' >PRO MAX</Typography>

                           </Box>
                          
                           <Box style={{display:'flex'}}>
                           <Typography sx={{ marginLeft: '1.6rem', color: 'white', textDecoration:'line-through', textDecorationColor:'red'  }} component='p' >49,00 €</Typography>
                           <Typography sx={{ marginLeft: '1.6rem', color: '#ffc439', fontWeight:'600',fontSize:'1.1rem' }} component='p' >12,99 €</Typography>
                           </Box>
                           
                           <MyLabel sx={{ marginLeft: '1.6rem', color:'white' }} component='p' text={"- 1,500,000 characters for all tools (Article Generator, AD Generator, Keyword Wizard, Product Name Generator, Topic Ideas Generator, Grammar Check & Translator)"} />
                           {/* <MyLabel sx={{ marginLeft: '1.6rem', color:'white' }} component='p' text={"- Unlimited use of the Grammar Check & Translator Tool"} /> */}
                           <MyLabel sx={{ marginLeft: '1.6rem', color:'white' }} component='p' text={"- Generate up to 180 AI-designed images"} />
                           <MyLabel sx={{ marginLeft: '1.6rem', color:'white' }} component='p' text={"- Link up to three social media accounts (each account can include 1 profile of Facebook, Facebook Group, Twitter/X, LinkedIn, Instagram)"} />
                       </DialogContent>


                   </>
                    )}


                </Box>

                <Box sx={style.firstBox}>
                    <Box sx={style.firstBox2}>
                    {modalView === 1 && (
                        <>
                        <MyImage src={svgCrown} sx={{ height: '80px', width: '80px', background: 'none', }} alt={`gift`} />
                        <DialogTitle id="alert-dialog-title">
                            <MyLabel sx={{ fontSize: '1.6rem', fontWeight: '600' }} component='p' text={"AdMind PRO - BLACK FRIDAY"} />
                            <MyLabel sx={{ fontWeight: '600' }} component='p' text={"You can cancel at any time!"} />
                        </DialogTitle>
                        </>
                    )}
                        {modalView === 1 && (
                            <>
                            <DialogContent sx={{ marginTop: '1.4rem', textAlign: 'left' }}>
                                <Typography sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                                    {planSelected}
                                </Typography>

                                <Box display={'flex'} marginTop={'0.5rem'}>
                                    <Box display={'flex'} justifyContent={'flex-start'} width={'60%'}>
                                        <FormattedMessage id='Pay for the first 3 months'/>{' '}
                                        {/* {formatTryProDate(moment().add('1', 'month'), languageMapped[language?.value])} */}
                                    </Box>
                                    <Box display={'flex'} justifyContent={'flex-end'} width={'40%'}>
                                        {mappingNewPrices[planSelected]}
                                    </Box>
                                </Box>
                                <Box display={'flex'} marginTop={'0.5rem'}>
                                <Box display={'flex'} justifyContent={'flex-start'} width={'60%'}>
                                    <FormattedMessage id='After, you will pay'/>{' '}  <Box style={{ color: "green", marginLeft: '0.5rem', fontWeight: '800' }}>
                                        {/* <FormattedMessage id='(7 days FREE)'/>{' '} */}
                                    </Box>
                                    </Box>
                                   
                                    <Box display={'flex'} justifyContent={'flex-end'} width={'40%'}>
                                    {mappingPrices[planSelected]}
                                    </Box>
                                </Box>
                            </DialogContent>
                        </>
                        )}
                        {modalView === 1 && (<SubscriptionButton plan_id={planIdTrial()} closeModal={()=>handleClose()} />)}
                                    {/* <DialogContent >
                                        <Typography>
                                            <FormattedMessage id={'Cancel at any time. Your first payment will be due on'} />
                                            {' '}
                                            {formatTryProDate(moment().add(1, 'month'), languageMapped[language?.value])},
                                            {' '}
                                            <FormattedMessage id={'but you can cancel at any point before this date with no penalty.'} />
                                        </Typography>
                                    </DialogContent> */}
                    </Box>
                </Box>
            </Box>
            ) : 
                        <Box onClick={modalView === 0 ? () => setModalView(user.in_update ? 0 : 1) : () => console.log('submitting...')} sx={style.container1}>
                            <Box sx={style.countdownBox}>
                                {countDown}

                            </Box>
                        </Box>
                    
            }
        </Dialog>

    )
}


export default TryProBlackFridayDialog