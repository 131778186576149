let styles = {
    stack: {
        width: '100%',
        padding:'20px',
        '@media (max-width: 600px)': {
            width: '100%',
        }
    },
    card:{
        padding: '10px',
        boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
        border: '1px solid rgba(0, 0, 0, 0.15)',
    },
    selectArea:{
        marginTop: '20px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '10px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr 1fr',
          }
    },
    select: {
        backgroundColor: '#FFF',
    },
    describeArea: {
        marginTop: '20px',
        width: '100%',
    },
    textInput: {
        width: '100%',
        backgroundColor: '#FFF',
    },
    titleArea:{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'row',
        margin: '14px',
        width:'100%',
    },
    title:{
        margin: '0px',
        padding: '0px',
        fontSize: '14pt',
        fontWeight: 600,
        marginBottom: '10px'
    },
    buttonArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '10px',
        padding: 0,

    },
    describeArea: {
        marginTop: '20px',
        width: '100%',
    },

}

export default styles