import React from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';

import pngLogo02 from "../../assets/imgs/logo02.png";
import imgUser from "../../assets/imgs/user.svg";

import MyImage from "../myImage";
import MyButton from "../myButton";
import SelectLanguage from '../selectLanguage';

import { style } from "./styles";

const MyHeader = ({ userState }) => {
    const navigate = useNavigate();

    const userHandler = () => {
        navigate("/user");
    };
    const pricingHandler = () => {
        navigate("/pricing");
    };
    const creditsHandler = () => {
        navigate("/credits");
    };
    return (
        <AppBar sx={style.stack}>
            <Box sx={style.content}>
                <Box sx={style.logoArea}>
                    <Link onClick={() => navigate("/dashboard")} underline="none" sx={style.logoArea}>
                        <MyImage src={pngLogo02} alt={'Admind logo'} sx={style.logo} />
                    </Link>
                </Box>

                <Box sx={style.areaLeft}>
                    <Box sx={style.areaButtons}>
                        <>
                            <MyButton  onClick={pricingHandler} text={userState?.subscription?.value?.includes('PRO') ? `Upgrade` : "Try AdMind PRO"} variant="contained" md={style.upgradeButton} sx={style.upgradeButton} />
                            <MyButton  onClick={creditsHandler} text="Get More Images" variant="contained" md={style.imageButton} sx={style.imageButton} />
                        </>
                    </Box>
                    <Box>
                        <SelectLanguage />
                    </Box>
                    <Link onClick={userHandler} underline="none" sx={style.iconArea}>
                        <MyImage src={imgUser} alt={'user icon'} sx={style.icon} />
                    </Link>
                </Box>
            </Box>
        </AppBar>
    );
};

export default MyHeader;
