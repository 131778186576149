import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { AuthContext } from "../../contexts/auth";
import defLanguage from "../../utils/defLanguage";
import { openAILocal } from "../../services/openAI/openAILocal";
import { completions } from "../../services/openAI/completions";

//gerador de Tópicos
const useEssayOutlineLocalState = () => {
    const api = 2;
    const { signOut, language } = useContext(AuthContext);

    let lang = defLanguage(language.value);

    const [sendText, setSendText] = useState("");
    const [type, setType] = useState("list of topics");
    const [target, setTarget] = useState("a serie of posts");
    const [_language, setLanguage] = useState(lang);
    const [returnText, setReturnText] = useState("");
    const [submit, setSubmit] = useState(false);

    const createMessage = () =>{

        let text = `Create a ${type} for about ${target.trim()} ${sendText.trim()}.

The answer in language ${_language}.
`

        let message = []
        message.push({'role':'system', "content": "you are a marketing and SEO expert and always create amazing content."})
        message.push({'role':'user', "content": text})
        return message;
        
    }

    const EssayOutline = async (config, updateHistoric) => {

        let messages = createMessage();

        let send = JSON.stringify({
            type: type, 
            target: target,
            text: sendText, 
            language: _language});

        completions(api, messages, config, send, updateHistoric, setReturnText, setSubmit)
    }

    const handleAPI = async (updateHistoric) => {
        if (submit) {
            return;
        }

        let isSendTextValid = false;

        if (sendText !== "") {
            isSendTextValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }

        if (isSendTextValid) {
            setSubmit(true);
            openAILocal(sendText, api, setSubmit, signOut, updateHistoric, EssayOutline)
        }
    };

    return {
        sendText: {
            value: sendText,
            set: setSendText,
        },
        type: {
            value: type,
            set: setType,
        },
        language: {
            value: _language,
            set: setLanguage,
        },
        target: {
            value: target,
            set: setTarget,
        },
        returnText: {
            value: returnText,
            set: setReturnText,
        },
        submit: {
            value: submit,
            set: handleAPI,
        },
    };
};

export default useEssayOutlineLocalState;
