import { Box, Fade } from "@mui/material"
import styles from "./styles"
import Loading from "../../../components/loading"
import { useEffect, useState } from "react";
import MyLabel from "../../../components/myLabel";
import MyInputText from "../../../components/myInputText";
import MyButton from "../../../components/myButton";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import OpenAI from "openai";
import MySelect from "../../../components/mySelect";

const AisolutionsBstranslator = () => {
    const [sendText, setSendText] = useState(``);
    const [returnText, setReturnText] = useState("");
    const [languages, setLanguages] = useState("Italian");
    const [submit, setSubmit] = useState(false);
    const screenWidth = window.innerWidth;
    
    const apiKey = process.env.REACT_APP_OPENAI_KEY;
    const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true});
    

    const copyToClipboard = () => {
        copy(returnText);
        toast.success("Copied text" );
    };

    const openAIService = async () => {
        let assistant_id = 'asst_RdLYjPqVc4KocmDBfNugpZQj'
        if (languages === 'French') {
            assistant_id = 'asst_8ItiQVdllTLtD07emF5bhPJe'
        }
        else if (languages === 'Spanish') {
            assistant_id = 'asst_x01Z8sUpBe44MD8viC1Tg4FM'
        }
        else if (languages === 'Deutsch') {
            assistant_id = 'asst_CfpRpfUkdb0Un6TifF7NPSNS'
        }
        else if (languages === 'English') {
            assistant_id = 'asst_IdJIaF0gORU0NwF1rKrDYu5n'
        }

        
        const stream = await openai.beta.threads.createAndRun({
            assistant_id: assistant_id,
            model:'gpt-4o-2024-08-06',
            thread: {
              messages: [
                { role: "user", content: sendText },
              ],
            },
            stream: true
        });
        
        let text = ''

        for await (const event of stream) {
            if (event?.data?.delta && event?.data?.delta.content){
                text += event?.data?.delta.content[0].text.value
                setReturnText(text)

            }
            else if (event?.data?.content?.length > 0){
                setReturnText(event?.data?.content[0].text.value)
            }
        }
        setSubmit(false)

    }
    
    useEffect(()=>{
        if (submit){
            openAIService()
        }

    },[submit])

    const handlerTranslate = () => {
        if (sendText){
            setReturnText('')
            setSubmit(true)
        }
        else {
            toast.error("Provide a valid text");
        }
    }

    return (
        <Fade in={true} timeout={500}>

            <Box sx={styles.stack}>
                <Loading render={submit} />
                <Box sx={styles.card}>
                    <Box sx={styles.titleArea}>
                        <MyLabel component='h1' text='Bstranslator' sx={styles.title} noTranslate={true}/>
                    </Box>
                    <Box sx={styles.selectArea}>
                        <MySelect
                            sx={styles.select}
                            id='languages'
                            label='languages'
                            options={[
                                'Italian',
                                'French',
                                'Spanish',
                                'Deutsch',
                                'English'
                            ]}
                            value={languages}
                            onChange={(e)=>setLanguages(e.target.value)}
                        />
                </Box>
                    <Box sx={styles.describeArea}>
                        <MyInputText
                            sx={styles.textInput}
                            type='text'
                            id={'translate'}
                            label={"Write the text you would like to translate to English or verify the English grammar"}
                            placeholder='white your text'
                            required={true}
                            value={sendText}
                            onChange={(e) =>
                                setSendText(e.target.value)
                            }
                        />
                    </Box>
                    <Box sx={styles.buttonArea}>
                        <MyButton
                            disabled={submit}
                            onClick={handlerTranslate}
                            text={'Translate'}
                            variant="contained"
                            size="large"
                        />
                    </Box>
                    {
                        returnText && returnText !== '' ?
                            <Box sx={styles.describeArea}>
                                <MyInputText
                                    sx={styles.textInput}
                                    type='text'
                                    id="return"
                                    label=""
                                    placeholder=""
                                    required={false}
                                    value={returnText}
                                    onChange={(e) => setReturnText(e.target.value)}
                                />
                                {returnText !== "" && (
                                    <Box sx={styles.buttonArea}>
                                        <MyButton
                                            onClick={copyToClipboard}
                                            text={'Copy'}
                                            variant="text"
                                            size="small"
                                        />
                                    </Box>
                                )}
                            </Box>
                        :
                            <></>
                    }
                </Box>
            </Box>
        </Fade>

    )

}

export default AisolutionsBstranslator