import React, { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import useDalleState from "../../../hooks/openAI/useDalleState";
import useChatLocalState from "../../../hooks/openAI/useChatLocalState";
import { AuthContext } from "../../../contexts/auth";
import MyLabel from "../../myLabel";
import MyButton from "../../myButton";
import MyDataTimeInput from '../../myDataTimeInput';

import CustonSelect from './custonSelect'
import SocialIcon from '../socialIcons'
import CHANNELS from "../channels"
import MyCheckbox from '../../myCheckbox';
import daleImage from "../../../assets/imgs/dale.png";
import MyImage from "../../myImage";
import CircularProgress from '@mui/material/CircularProgress';
import PublicationsPanel from './publicationsPanel'
import DragDropImage from './dragDropImage'
import ButtonDropImage from './buttonDropImage'
import image01 from "../../../assets/imgs/image01.png";
import { scrollbarTheme } from '../../../assets/customThemes/scrollbarTheme';
import usePostsState from "../../../hooks/ayrshare/usePostsState";

const style ={
    container:{
        minWidth: '700px',
        maxHeight:'85vh',
        overflowY: "auto",
        '@media (max-width: 600px)': {
            width: 'auto',
            minWidth: 'auto',
        },
        ...scrollbarTheme
    },
    title:{
        fontSize: '12pt',
        fontWeight: '900'
    },
    descriptions:{
        fontSize: '12pt',
    },
    message: {
        fontSize: '12pt',
        color:'#85048a',
    },
    selectArea: {
        minWidth: '150px',
        '@media (max-width: 600px)': {
            width: 'auto',
        }
    },
    blueButton: {
        width: '180px',
        backgroundColor: '#376db4',
        ':hover': {
            backgroundColor: '#598FD6',
        },
        marginBottom:'5px',
    },
    centerArea:{
        padding: '0px 40px',
        '@media (max-width: 600px)': {
            padding: '0px 10px',
        }
    },
    scheduleButton:{
        width: '180px',
        color:'black',
        backgroundColor: '#cfcfcf',
        ':hover': {
            backgroundColor: '#ebebeb',
        }
    },
    cardAreaImage:{
        width: '200px',
        height:'200px',
        display:'flex',
        backgroundImage: `url(${daleImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
         },
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 600px)': {
            width: '180px',
            height: '180px',
          }
    },
    cardAreaContent:{
        display:'flex',
        width: '100%',
        height:'100%',
        margin: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    cardTitleArea: {
        width: '100%',
        display:'flex',
        backgroundColor: '#85048a',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        alignCenter: 'center',
    },
    cardTitle: { 
        fontSize: '9pt',
        fontWeight: 700,
        color: '#FFF',
        textAlign: 'center',
    },
    publicationsArea: {
        maxHeight:'270px', 
        overflowY: "auto", 
        marginBottom:'10px',
        marginTop:'10px',
        ...scrollbarTheme
    },
    addImageArea:{
        padding: 0,
        margin: 0,
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '1px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',

        }
    },
}

function MessageDialogImage({open, handleClose, image}) {
    return (
        <Dialog  open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={" "}  />
        </DialogTitle>
        <DialogContent>
          <Box sx={{margin:'30px'}}>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                <MyImage src={image} alt={`Image`}  sx={{width:'500px'}} />
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                    <MyButton 
                        sx={style.scheduleButton}
                        onClick={handleClose}
                        text='return'
                        variant="contained" 
                        size="small"
                    />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )

}

function MessageDialog({open, handleClose, dateTime, setDateTime, handlerSchedulePublish}) {
  
    return (
        <Dialog  open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={"Schedule post"}  />
        </DialogTitle>
        <DialogContent>
          <Box sx={{margin:'30px'}}>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                <MyDataTimeInput value={dateTime} onChange={(newValue) => setDateTime(newValue)} />
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                    <MyButton 
                        sx={style.scheduleButton}
                        onClick={handleClose}
                        text='cancel'
                        variant="contained" 
                        size="small"
                    />
                    <MyButton 
                        sx={{...style.blueButton, marginBottom:0}}
                        onClick={handlerSchedulePublish}
                        text='Schedule'
                        variant="contained" 
                        size="small"
                    />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
}

const PublishPostModal = ({setModal, profileSelected, socials, profiles, publish, submit, setModalOpen}) =>{

    const state = usePostsState();

    const { integration } = useContext(AuthContext);

    const activeChannels = CHANNELS.filter(channel => channel.active).map(channel => channel.title)

    const [postFacebookPage, setPostFacebookPage] = useState(socials.value && socials.value?.includes('facebook') && socials.value?.includes('facebook') && activeChannels.includes('facebook'))
    const [postFacebookGroup, setPostFacebookGroup] = useState(socials.value && socials.value?.includes('fbg') && activeChannels.includes('fbg'))
    const [postInstagram, setPostInstagram] = useState(socials.value && socials.value?.includes('instagram') && activeChannels.includes('instagram'))
    const [postTwitter, setPostTwitter] = useState(socials.value && socials.value?.includes('twitter') && activeChannels.includes('twitter'))
    const [postLinkedin, setPostLinkedin] = useState(socials.value && socials.value?.includes('linkedin') && activeChannels.includes('linkedin'))
    const [postTiktok, setPostTiktok] = useState(socials.value && socials.value?.includes('tiktok') && activeChannels.includes('tiktok'))
    const [postYoutube, setPostYoutube] = useState(socials.value && socials.value?.includes('youtube') && activeChannels.includes('youtube'))


    const [publications, setPublications] = useState([
        {active: postFacebookPage, social: 'facebook', text:'',  channel:{ value: postFacebookPage, set: setPostFacebookPage}},
        {active: postFacebookGroup, social: 'fbg', text:'',  channel:{ value: postFacebookGroup, set: setPostFacebookGroup}},
        {active: postInstagram, social: 'instagram', text:'',  channel:{ value: postInstagram, set: setPostInstagram}},
        {active: postTwitter, social: 'twitter', text:'', channel:{ value: postTwitter, set: setPostTwitter}},
        {active: postLinkedin, social: 'linkedin', text:'',  channel:{ value: postLinkedin, set: setPostLinkedin}},
        {active: false, social: 'tiktok', text:'',  channel:{ value: postTiktok, set: setPostTiktok}},
        {active: false, social: 'youtube', text:'',  channel:{ value: postYoutube, set: setPostYoutube}},
    ])

    useEffect(() => {
        if (integration.value.publications && integration.value.publications.length > 0) {
            let publis = [...publications]
            integration.value.publications.forEach((inter)=>{
                publis = publis.map((p) => {
                    if (p.social === inter.social){
                        p.text = inter.text
                        p.channel.set(true)
                    }
                    return p
                })
            })
            setPublications(publis)
        }
        if (integration.value.image && integration.value.image !=='') {
            state.image.set(integration.value.image)
        }

    }, []);

    const handlerPublish = async () => {

        let platforms = []

        if (!postFacebookPage &&
            !postFacebookGroup &&
            !postInstagram &&
            !postTwitter &&
            !postLinkedin &&
            !postTiktok &&
            !postYoutube){
                toast.error(<FormattedMessage id='Select at least one publishing channel' />);
                return
        }

        if (postTwitter && publications[3].text.length > 280){
                toast.error(<FormattedMessage id='You cannot post posts to twitter longer than 280 characters.' />);
                return
        }

        let r = await publish.set(publications.filter(p=> p.active && p.text.trim() !== ''), state.image.value, null, state.file.value)
        if (r === 'success') {
            setModalOpen(false)

        }
    }


    const handlerSchedulePublish = async () => {


        if (!postFacebookPage &&
            !postFacebookGroup &&
            !postInstagram &&
            !postTwitter &&
            !postLinkedin &&
            !postTiktok &&
            !postYoutube){
                toast.error(<FormattedMessage id='Select at least one publishing channel' />);
                return
        }

        if (state.dateTime.value < new Date()){
            toast.error(<FormattedMessage id='select a future date.' />);
            return
        }
        if (postTwitter && publications[3].text.length > 280){
                toast.error(<FormattedMessage id='You cannot post posts to twitter longer than 280 characters.' />);
                return
        }

        let r = await publish.set(publications.filter(p=> p.active && p.text.trim() !== ''), [state.image.value], state.dateTime.value, state.file.value)
        if (r === 'success') {
            setModalOpen(false)
        }
    }
    

    const handlerGenerateIA = () => {

        const inte = {...integration.value}
        inte.profile = profileSelected.value;
        let publis = publications.filter(p=>p.active).map(p=>{ return {social: p.social, text: p.text}})
        inte.publications = publis;
        inte.image = '';
        integration.set(inte);
        setModal(2)
    }

    const handlerSchedule = () => {

        state.open.set(true)
    }

    const handlerDelete = (social, boolean) => {
        let publis = publications.map((publi)=> {
            if (publi.social === social){
                publi.active = boolean;
            }
            return publi;
        })

        setPublications(publis)
    }

    const handlerAutoGenerateIA = (social, boolean) => {
        let pub = publications.filter(p=> p.active && p.text.trim() !== '');
        if (pub.length > 0){
            
            state.imageAutoGenerate.set(`Can you suggest a simple frase for generating an image using AI (DALLE) for this post? It should be something simple and objective, so the AI will easily generate something appropriate. Remember to don't create a description that makes the AI generate faces or words, as it won't work well. Also don't use any names as AI won't generate the image well. It can be either an objective or an abstract image, depending on the text:
            ${pub[0].text}`)
        }
    }

    return (
        <Box sx={style.container}>
            <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', margin:0, marginBottom:'20px'}}  >
                <MyLabel component='h3' text='Review your post' sx={style.title}  />
            </Box>
            <Box sx={{borderBottom: '1px solid #adb5bd', width:'100%',marginBottom:'20px'}}/>
            <Box sx={style.centerArea}>
                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-between', alignItems: 'center'}} >
                    <Box sx={{margin: 0, display: 'flex', height:'105px'}}>
                        <Box sx={style.selectArea}>
                            <MyLabel component='p' text='Select a brand' sx={style.descriptions}  />
                            <CustonSelect 
                                id='style'
                                label=""
                                options={profiles.value}
                                value={profileSelected.value}
                                onChange={(e)=>profileSelected.set(e.target.value)}
                                noTranslate={true} 
                            />
                        </Box>
                    </Box>
                    <Box sx={{ marginLeft:'20px', minWidth:'50px', height:'110px'}}>
                        <Box>
                            <MyLabel component='p' text='Select channels' sx={style.descriptions}  />    
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'row'}}>
                            {
                                    CHANNELS.filter(chanel=>chanel.active).map((channel, key)=> {
                                        if (socials.value && socials.value?.includes(channel.title)){

                                            let checked = postFacebookPage;
                                            let onChange = setPostFacebookPage;
                                            if (channel.title ==='fbg' ){
                                                checked = postFacebookGroup;
                                                onChange = setPostFacebookGroup; 
                                            }
                                            else if (channel.title ==='instagram' ){
                                                checked = postInstagram;
                                                onChange = setPostInstagram; 
                                            }
                                            else if (channel.title ==='twitter' ){
                                                checked = postTwitter;
                                                onChange = setPostTwitter; 
                                            }
                                            else if (channel.title ==='linkedin' ){
                                                checked = postLinkedin;
                                                onChange = setPostLinkedin; 
                                            }
                                            else if (channel.title ==='tiktok' ){
                                                checked = postTiktok;
                                                onChange = setPostTiktok; 
                                            }
                                            else if (channel.title ==='youtube' ){
                                                checked = postYoutube;
                                                onChange = setPostYoutube; 
                                            }
                                        return (
                                            <Box key={key} sx={{padding:0, margin:0}}>
                                                <SocialIcon sx={{height:'32px', width:'32px'}} icon={channel.icon} linked={true}  />
                                                <MyCheckbox
                                                    sx={{margin:0}}
                                                    size='small'
                                                    checked={checked}
                                                    label=" " 
                                                    value={checked}
                                                    onChange={(e) => {onChange(!checked);handlerDelete(channel.title, !checked)}}
                                                />
                                            </Box>
                                        )
                                        }
                                        else {
                                            return <></>
                                        }
                                    })
                            }
                        </Box>
                    </Box>
                </Box>

                <Box sx={style.publicationsArea}>
                    {
                        publications.filter(publication =>publication.active).map((publication, key) => <Box key={key}><PublicationsPanel publication={publication} publications={publications} setPublications={setPublications} update={null} /></Box>)
                    }
                </Box>
                <Box>
                    <Box sx={{display: 'flex', justifyContent:'center'}}>
                        <MyLabel component='p' text='Add Image' sx={style.title}  /> 
                    </Box>
                    <Box >
                        {
                            state.image.value ? 

                            <Box sx={{display: 'flex', justifyContent:'center', width: '100%',margin:'10px'}}>
                                <Box sx={{display: 'flex', justifyContent:'center', flexDirection: 'column', alignItems:'center'}}>
                                    
                                    <MyImage onClick={()=>state.openImage.set(true)} src={state.image.value} alt={`Image`}  sx={{...style.cardAreaImage,backgroundImage:'none'}} />

                                    {
                                        state.file.value ? 
                                        <Box sx={{marginTop:'5px'}}>
                                            <ButtonDropImage image={state.image} setFile={state.file.set} />
                                        </Box>
                                        : null
                                    }

                                    <MyButton onClick={handlerGenerateIA} text={state.file.value ? "Generate with AI2":"Generate other image"} variant="text" /> 

                                </Box>
                            </Box>
                             :
                            <Box sx={style.addImageArea}>
                                <Box sx={{display:'flex', justifyContent:'center'}} >
                                    <DragDropImage image={state.image} setFile={state.file.set} />
                                </Box>
                                <Box sx={{display:'flex', justifyContent:'center'}} >

                                    <Card onClick={handlerGenerateIA} sx={style.cardAreaImage}>
                                        <CardContent sx={style.cardAreaContent}>
                                            <Box sx={style.cardTitleArea}>
                                                <MyLabel component='p' text="MANUALLY GENERATE WITH AI" sx={style.cardTitle}  />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                                <Box sx={{display:'flex', justifyContent:'center'}} >
                                    <Card onClick={handlerAutoGenerateIA} sx={{...style.cardAreaImage, backgroundImage: `url(${image01})`,}}>
                                        <CardContent sx={style.cardAreaContent}>
                                            <Box sx={{...style.cardTitleArea, backgroundColor:'#0082ff'}}>
                                                <MyLabel component='p' text="AUTO-GENERATE WITH AI" sx={style.cardTitle}  />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Box>
                        }

                    </Box>
                </Box>

                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'center'}}>
                {
                    submit.value || state.imageAutoGenerate.value ? <Box sx={{padding:'5px', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}> <CircularProgress color="primary" /><MyLabel component='p' text={state.message.value} sx={style.message} /></Box> : null
                }
                </Box>
                <Box sx={{display: 'flex', flexDirection:'column',justifyContent:'space-around', alignItems: 'center', width: '100%',margin:'10px'}}>
                    <MyButton 
                        disabled={!(publications.filter(publication => publication.active && publication.text !=='').length > 0)}
                        sx={style.blueButton}
                        onClick={handlerPublish}
                        text='Publish now'
                        variant="contained" 
                        size="small"
                    />
                    <MyButton 
                        disabled={!(publications.filter(publication => publication.active && publication.text !=='').length > 0)}
                        sx={style.scheduleButton}
                        onClick={handlerSchedule}
                        text='Schedule'
                        variant="contained" 
                        size="small"
                    />
                </Box>
            </Box>
            <MessageDialog open={state.open.value} handleClose={()=>state.open.set(false)} dateTime={state.dateTime.value} setDateTime={state.dateTime.set} handlerSchedulePublish={handlerSchedulePublish} />
            <MessageDialogImage open={state.openImage.value} handleClose={()=>state.openImage.set(false)} image={state.image.value} />
        </Box>

    )
}

export default PublishPostModal;