import React, {useContext, useEffect} from "react";
import { AuthContext } from "../../../contexts/auth";
import useProductNameGeneratorLocalState from "../../../hooks/openAI/useProductNameGeneratorLocalState";

import imgBusiness from "../../../assets/imgs/panels/business.svg";
import imgProduct from "../../../assets/imgs/panels/product.svg";

import FunctionOpenAi from "../functionOpenAI";

function ProductNameGenerator({ updateHistoric }) {

    const action = useProductNameGeneratorLocalState();
    const { historic } = useContext(AuthContext);

    useEffect(() => {
        if (historic.value){

            action.returnText.set("");
            action.sendText.set(historic.value.description);
            action.seeds.set(historic.value.seed_words);
            action.elements.set(historic.value.elements);
            action.language.set(historic.value.language);
            historic.set(null)
        }
        
    }, [historic.value]);

    let title = "What do you want to create?";
    let createAction = action.elements;
    let createOptions = [
        { name: "A name for a product", icon: imgProduct, action: "product" },
        {
            name: "A name for website or a business",
            icon: imgBusiness,
            action: "business",
        },
    ];

    let selects = [
        {
            name: "language",
            title: "In what language do you want it?",
            options: ["english", "spanish", "portuguese", "italian"],
            action: action.language,
        },
    ];

    let describes = [
        {
            title: "Describe your product or business",
            placeholder:
                "A marketing platform that uses AI for helping people to create effective content.",
            action: action.sendText,
        },
        /*{
            title: "Add some seed words",
            placeholder: "Smart, Mind, Ad, Marketing, Content",
            action: action.seeds,
        },*/
    ];

    let returnText = {
        action: action.returnText,
    };

    return (
        <>
            <FunctionOpenAi
                returnText={returnText}
                describes={describes}
                selects={selects}
                createOptions={createOptions}
                createAction={createAction}
                title={title}
                updateHistoric={updateHistoric}
                action={action}
            />
            <br />
            <br />
            <br />
            <br />
        </>
    );
}

export default ProductNameGenerator;
