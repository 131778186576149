const getIdAssistant = (platform) => {

    let idAsssistent = 'asst_3z6xwPLipD9qyTKNG8VBSpnf' //'asst_TAW85531PzftTUm6H4DT70na'
    
    if (platform ==='fbg'){
        idAsssistent = 'asst_7bAihrAWr51kkKOrSffZ7Mv5' //'asst_9QO9aBESVmlsWLJTsKGe16JS'
    }
    else if (platform ==='instagram'){
        idAsssistent = 'asst_YKmYmmuW4qsIbpVNmgrivanf' //'asst_rDQxAxOze58nAkSbIK2xXVe8'
    }
    else if (platform ==='linkedin'){
        idAsssistent = 'asst_9jtlRqYkkQW4JAF4VjRM9yWl' //'asst_qeiZRq81rnaF59mSG8fukemz'
    }
    else if (platform ==='twitter'){
        idAsssistent = 'asst_A5epH5hyov0Rohmg0wMJaLjL' //'asst_65jdSsR6t1JpJKX8WqgcWFkN'
    }
    
    return idAsssistent
}

export default getIdAssistant