import { postHistoric } from "../postHistoric";
import formatDate from "../../utils/formatDate";
import OpenAI from "openai";

export async function completions(api, messages, config, send, updateHistoric, setReturnText, setSubmit, chat=false, submit=true) {
    const apiKey = process.env.REACT_APP_OPENAI_KEY;


    const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });

    let model = chat ? 'gpt-4o-mini' : config.model

    let finalText = '';
    let initialText = '';
    const completion = await openai.chat.completions.create({
        model: model,
        messages: messages,
        temperature: config.temperature,
        max_tokens:config.max_tokens,
        top_p: config.top_p,
        frequency_penalty: config.frequency_penalty,
        presence_penalty: config.presence_penalty,
        stream: true,
        user:`user${config.user}`
    });
    
    for await (const chunk of completion) {
        if (!submit){
            break
        }
        if (chunk.choices[0].delta.content){
            if (finalText === ''){
                finalText = initialText + chunk.choices[0].delta.content;
            } else {
                finalText = finalText + chunk.choices[0].delta.content;
            }
            setReturnText(finalText);
        }
    }

    let historic = {
        send: send,
        text: JSON.stringify(messages),
        result: finalText,
        date: formatDate(new Date()),
        action: api,
    }
    if (chat){
        historic = {
            ...historic,
            model: 'gpt-4o-mini'
        }
    }
    await postHistoric(historic)
    updateHistoric.set(finalText);
    setSubmit(false);
    
}



