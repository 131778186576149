export const scrollbarTheme = {
    '&::-webkit-scrollbar': {
        width: '0.5em',
        borderRadius:'5px',
        zIndex: 5,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px #B0B0B0',
        webkitBoxShadow: 'inset 0 0 6px #B0B0B0'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#d0d0d0',
        outline: '1px solid #B0B0B0'
      }
}