import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { AuthContext } from "../../contexts/auth";
import defLanguage from "../../utils/defLanguage";
import { openAILocal } from "../../services/openAI/openAILocal";
import { completions } from "../../services/openAI/completions";


const useProductNameGeneratorLocalState = () => {
    const api = 3;
    const { signOut, language } = useContext(AuthContext);

    let lang = defLanguage(language.value);

    const [sendText, setSendText] = useState("");
    const [elements, setElements] = useState("product");
    const [seeds, setSeeds] = useState("");
    const [_language, setLanguage] = useState(lang);
    const [returnText, setReturnText] = useState("");
    const [submit, setSubmit] = useState(false);


    const createMessage = () =>{
/*
        let text = `Come up with a name for a ${elements}.

${elements} description: ${sendText.trim()}.
Seed words: ${seeds.trim()}.
The answer in language ${_language}.`
*/
    let text = `Come up with  some names for a ${elements} and a website.

${elements} description: ${sendText.trim()}.
The answer in language ${_language}.`

    if (elements==='product'){
        text = `Come up with some names for a ${elements}.

${elements} description: ${sendText.trim()}.
The answer in language ${_language}.`        
    }

        let message = []
        message.push({'role':'system', "content": "you are a marketing and SEO expert and always create amazing content."})
        message.push({'role':'user', "content": text})
        return message;
    }

    const ProductNameGenerator = async (config, updateHistoric) => {

        let messages = createMessage();

        let send = JSON.stringify({
            description: sendText, 
            seed_words: seeds, 
            elements: elements, 
            language: _language});

        completions(api, messages, config, send, updateHistoric, setReturnText, setSubmit)
        
    
    }
    

    const handleAPI = async (updateHistoric) => {
        if (submit) {
            return;
        }

        let isSendTextValid = false;

        if (sendText !== "") {
            isSendTextValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }

        if (isSendTextValid) {
            setSubmit(true);
            openAILocal(sendText, api, setSubmit, signOut, updateHistoric, ProductNameGenerator)
        }
    };

    return {
        sendText: {
            value: sendText,
            set: setSendText,
        },
        seeds: {
            value: seeds,
            set: setSeeds,
        },
        elements: {
            value: elements,
            set: setElements,
        },
        language: {
            value: _language,
            set: setLanguage,
        },
        returnText: {value: returnText,
            set: setReturnText,
        },
        submit: {
            value: submit,
            set: handleAPI,
        },
    };
};

export default useProductNameGeneratorLocalState;
