import Typography from '@mui/material/Typography';
import { FormattedMessage } from "react-intl";

const MyLabel = ({ onClick, variant, component, text, sx, noTranslate}) => {
    return (
        <Typography onClick={onClick} variant={variant} component={component} sx={sx}>
            {
                noTranslate ? text : <FormattedMessage id={text ? text:' '} />
            }
        </Typography>
    )
};

export default MyLabel;
