import { useState, useContext } from "react";
import { AuthContext } from "../contexts/auth";

import { FormattedMessage } from "react-intl";
import { postRegisterPaypalSubscription } from "../services/postRegisterPaypalSubscription";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useRegisterPaypalSubscription = () => {
    const { signOut } = useContext(AuthContext);
    const navigate = useNavigate();

    const [post, setPost] = useState(false);

    const handlePost = async (paymentID) => {
        if (post) {
            return;
        }

        setPost(true);
        

        let json = {
            paypal_actionn_id: paymentID,
        };

        postRegisterPaypalSubscription(json)
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    } else if (json.data.code === "violation of guidelines") {
                        toast(
                            <FormattedMessage key={4} id={json.data.detail} />
                        );
                    } else if (json.data.code === "no tokens") {
                        toast.error(<FormattedMessage id={json.data.detail} />);
                    } else {
                        <FormattedMessage id={"unknown error"} />;
                    }
                } else {
                    if (json?.type === "success") {
                        toast.success(
                            <FormattedMessage id={"Registered signature."} />
                        );
                        navigate("/dashboard");
                    }
                }
                setPost(false);
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast("Connection failed to server");
                } else {
                    toast(<FormattedMessage key={4} id={error.message} />);
                }
                setPost(false);
            });
        setPost(false);
    };
    return {
        post: {
            value: post,
            set: handlePost,
        },
    };
};

export default useRegisterPaypalSubscription;
