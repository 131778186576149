import { postHistoric } from "../postHistoric";
import formatDate from "../../utils/formatDate";

export async function getOpenAI(messages, config) {
    const url = "https://api.openai.com/v1/chat/completions";
    const apiKey = process.env.REACT_APP_OPENAI_KEY;
    const access =  `Bearer ${apiKey}`


    let sendJson = JSON.stringify({
        model: config.model,
        messages: messages,
        temperature: config.temperature,
        max_tokens:config.max_tokens,
        top_p: config.top_p,
        frequency_penalty: config.frequency_penalty,
        presence_penalty: config.presence_penalty,
        user:`user${config.user}`
    })

    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": access
        },
        body: sendJson
    }).then(async (retorno) => {
        let dados = await retorno.json();
        if (retorno.status !== 200) {
            throw new Error(dados.detail);
        }
        return dados.choices[0].message.content;
    });

}



