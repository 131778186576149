import { AlertTitle, Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import { useState } from "react";
import Fade from '@mui/material/Fade';
import { FormattedMessage } from "react-intl";

const ErrorMessage = ()=> {

    const [open, setOpen] = useState(false)

    return (
        <Fade in={open} timeout={50}>
            <Box sx={{display:open?'inline-block':'none', marginBottom:'10px'}} >
                <Alert severity="error" onClose={() => setOpen(false)}>
                    <AlertTitle><strong><FormattedMessage id='Service information:'  /></strong></AlertTitle>
                    <p><FormattedMessage id='Partial and temporary interruption on AdMind for general updates on AI models.' /></p>
                    <p><FormattedMessage id='We are working to resolve the inconvenience and return to operations as soon as possible.' /></p>
                </Alert>
            </Box>
        </Fade>
    )

}

export default ErrorMessage;