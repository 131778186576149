import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import toast from "react-hot-toast";
import Cookies from 'js-cookie'
import { AuthContext } from "../../contexts/auth";
import { loginFacebook } from "../../services/authFacebook";
import useLoginState from "../../hooks/useLoginState";

import Loading from "../../components/loading";
import MyImage from "../../components/myImage";
import MyLabel from "../../components/myLabel";
import MyInput from './../../components/myInput';
import MyCheckbox from "../../components/myCheckbox";
import MyButton from './../../components/myButton';

import logo from "../../assets/imgs/logo03.png";
import logo1 from "../../assets/imgs/logo01.png";

import { style } from "./styles";

const siginFacebook = (res, signIn, navigate) => {
    if (res?.id) {
        loginFacebook(res.email, res.name, res.id)
            .then(console.log("sucesso"))
            .then(async (register) => {
                if (register.user?.verified) {
                    let state = await signIn(register);
                    if (state === "SUCCESS") {
                        navigate("/dashboard");
                    }
                } else {
                    navigate("/userNotValited");
                }
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast.error("Connection failed to server");
                } else {
                    toast.error(error.message);
                }
            });
    } else {
        toast.error("Error when logging in via facebook");
    }
};

function Login() {
    const { signIn } = useContext(AuthContext);
    const { email, password, submit, remember, errors } = useLoginState();

    let navigate = useNavigate();

    const recover = () => {
        navigate("/changePassword");
    };

    const register = () => {
        navigate("/registration");
    };

    useEffect(() => {
        const session = Cookies.get("airton@data") && JSON.parse(Cookies.get("airton@data"));
        if (session?.remember) {
            email.set(session.user.email);
            remember.set(true);
        }
    }, []);

    const keyDown = (event) => {
        if (event.key === "Enter") submit.set();
    };

    return (
        <Fade in={true} timeout={500}>
            <Stack direction="row" sx={style.stack}>
                <Loading render={submit.value} />
                <Container sx={style.leftArea}>
                    <Box sx={style.logo1}>
                        <Link href="https://admind.ai/" underline="none">
                            <MyImage src={logo} alt={'Admind logo'} sx={style.logo} />
                        </Link>
                    </Box>
                </Container>
                <Container sx={style.rightArea}>
                    <Box sx={style.logo2}>
                        <Link href="https://admind.ai/" underline="none" >
                            <MyImage src={logo1} alt={'Admind logo 1'} sx={style.logo} />
                        </Link>
                    </Box>
                    <Box sx={style.titleArea}>
                        <MyLabel component='h1' text='Sign In' sx={style.title}  />
                    </Box>
                    <Box sx={style.inputArea}>
                        <MyInput 
                            icon="user"
                            error={errors.value.email}
                            type='email'
                            id='email'
                            label='E-mail'
                            required={true}
                            value={email.value}
                            onChange={(e) => email.set(e.target.value)}
                        />
                        <MyInput 
                            icon="password"
                            error={errors.value.password}
                            type='password'
                            id='password'
                            label='Password'
                            required={true}
                            value={password.value}
                            onChange={(e) => password.set(e.target.value)}
                            onKeyPress={keyDown}
                        />
                    </Box>
                    <Box sx={style.centerArea}>
                        <MyCheckbox label="Remember me" />
                        <MyButton onClick={recover} text="Forgot Password" variant="text" />
                    </Box>
                    <Box sx={style.loginArea}>
                        <MyButton disabled={submit.value} onClick={submit.set} text="Sign in" variant="contained" size="large" sx={style.loginButton} />
                    </Box>
                    <Box sx={style.newUserArea}>
                        <MyLabel component='p' text='New here?' />
                        <MyButton onClick={register} text="Create an Account" variant="text" />
                    </Box>
                    <Box sx={style.lineArea}>
                        <Divider variant="middle" sx={style.line} />
                    </Box>
                    <Box sx={style.socialArea}>
                    {/*<FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_ID}
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={(res) => siginFacebook(res, signIn, navigate)}
                        render={(renderProps) => (
                            <MyButton activate={true} onClick={renderProps.onClick} text="Continue with Facebook" variant="contained" size="large" sx={style.facebookButton} />

                        )}
                        />*/}
                        <MyButton onClick={() => toast.error("undefined")} text="Continue with Facebook" variant="contained" size="large" sx={style.facebookButton} />
                        <MyButton onClick={() => toast.error("undefined")} text="Continue with Google" variant="contained" size="large" sx={style.googleButton} />
                    </Box>
                </Container>
            </Stack>
        </Fade>
    )
}

export default Login;
