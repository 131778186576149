import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import PanelInformation from "../../components/panelInformation";
import svgAd from "../../assets/imgs/dashboard/ad.svg";
import svgArticle from "../../assets/imgs/dashboard/article.svg";
import svgProducts from "../../assets/imgs/dashboard/products.svg";
import svgKeywords from "../../assets/imgs/dashboard/keywords.svg";
import svgTopics from "../../assets/imgs/dashboard/topics.svg";
import svgTranslator from "../../assets/imgs/dashboard/translator.svg";
import svgChat from "../../assets/imgs/dashboard/chat.svg";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MyImage from "../../components/myImage";
import MyLabel from "../../components/myLabel";
import MyPanel from "../../components/myPanel";
import svgCrown from "../../assets/imgs/crown.svg";
import { style } from "./styles";
import { AuthContext } from "../../contexts/auth";
import image1Png from "../../assets/imgs/dalle/1.png";
import image2Png from "../../assets/imgs/dalle/2.png";
import image3Png from "../../assets/imgs/dalle/3.png";
import image4Png from "../../assets/imgs/dalle/4.png";
import image5Png from "../../assets/imgs/dalle/5.png";
import image6Png from "../../assets/imgs/dalle/6.png";
import image7Png from "../../assets/imgs/dalle/7.png";
import image8Png from "../../assets/imgs/dalle/8.png";
import image9Png from "../../assets/imgs/dalle/9.png";
import image10Png from "../../assets/imgs/dalle/10.png";
import image11Png from "../../assets/imgs/dalle/11.png";
import image12Png from "../../assets/imgs/dalle/12.png";
import daleRettSvg from "../../assets/imgs/DALLE3.svg";
import logoAutopost from "../../assets/imgs/dashboard/logo-autopost.svg";


const Panel = ({ item, handleNavegate, isOff, bottomPanel }) => {
  const arrayOfImages = [
    image1Png,
    image2Png,
    image3Png,
    image4Png,
    image5Png,
    image6Png,
    image7Png,
    image8Png,
    image9Png,
    image10Png,
    image11Png,
    image12Png,
  ];
  const [image, setImage] = useState(
    arrayOfImages[Math.floor(Math.random() * arrayOfImages.length)]
  );
  const navigate = useNavigate();
  const handlerClick = () => {
    if (isOff && item.url !== "image") {
      navigate(`${window.location.pathname}?try-pro=true`);
    } else {
      handleNavegate(item.url);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setImage(arrayOfImages[Math.floor(Math.random() * arrayOfImages.length)]);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  if (item.url === "image") {
    return (
      <Card
        onClick={handlerClick}
        sx={
          isOff
            ? { ...style.cardAreaImageOff, backgroundImage: `url(${image})` }
            : { ...style.cardAreaImage, backgroundImage: `url(${image})` }
        }
      >
        <img
          style={{ display: "none" }}
          src={image1Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image2Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image3Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image4Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image5Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image6Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image7Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image8Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image9Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image10Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image11Png}
          alt={`image-display-none`}
        />
        <img
          style={{ display: "none" }}
          src={image12Png}
          alt={`image-display-none`}
        />

        {bottomPanel && (
          <MyImage src={svgCrown} sx={style.iconCrown} alt={`icon crown`} />
        )}
        <CardContent sx={style.cardAreaContent}>
          <Box sx={style.cardTitleArea}>
            <MyLabel component="p" text={item.name} sx={style.cardTitle} />
            <MyImage
              sx={{
                width: "90px",
                height: "30px",
                position: "relative",
                left: "8px",
              }}
              src={daleRettSvg}
              alt={`dalle rett`}
            />
          </Box>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <MyPanel
        bottomPanel={bottomPanel}
        isOff={isOff}
        width={320}
        height={240}
        onClick={handlerClick}
        icon={item.icon}
        name={item.name}
        url={item.url}
        content={item.content}
      />
    );
  }
};

const Dashboard = ({ setOpenHist, popup, setDrawerWidth }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const handleNavegate = (url) => {
    if (url === "dashboard") {
      toast(
        <FormattedMessage id={"This tool will be available in a few weeks"} />
      );
    }
    if(['/planner', 'planner-welcome'].includes(`/${url}`)) {
      setDrawerWidth(70)
    }
    navigate(`/${url}`);
  };

  const [items, setItems] = useState([]);
  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setItems([
        {
          name: "Write an Article or a Blog Post",
          content: "Write an Article or a Blog Post Content",
          icon: svgArticle,
          url: "articles",
          isNew: false,
        },
        {
          name: "Create an AD/Post",
          content: "Create an AD/Post Content",
          icon: svgAd,
          url: "ad",
          isNew: false,
        },
        {
          name: "Generate a list of Topics",
          content: "Generate a list of Topics Content",
          icon: svgTopics,
          url: "topics",
          isNew: false,
        },
        { name: "IMAGE GENERATOR", url: "image", isNew: false },
        {
          name: "Create your monthly editorial plan in a few clicks",
          content: "Connect your Social Media Channels Content",
          icon: logoAutopost,
          url: "planner-welcome",
          isNew: false,
        },
        {
          name: "AI Chat - GPT-4o",
          content: "AI Chat - GPT-4 Content",
          icon: svgChat,
          url: "chat",
          isNew: false,
        },
        {
          name: "Magic Keywords",
          content: "Magic Keywords Content",
          icon: svgKeywords,
          url: "keywords",
          isNew: false,
        },
        {
          name: "Generate a name for a product",
          content: "Generate a name for a product Content",
          icon: svgProducts,
          url: "products",
          isNew: false,
        },
        {
          name: "English Grammar Check & Translation",
          content: "English Grammar Check & Translation Content",
          icon: svgTranslator,
          url: "translator",
          isNew: false,
        },
      ]);
    } else {
      setItems([
        {
          name: "Write an Article or a Blog Post",
          content: "Write an Article or a Blog Post Content",
          icon: svgArticle,
          url: "articles",
          isNew: false,
        },
        {
          name: "Create an AD/Post",
          content: "Create an AD/Post Content",
          icon: svgAd,
          url: "ad",
          isNew: false,
        },
        {
          name: "Generate a list of Topics",
          content: "Generate a list of Topics Content",
          icon: svgTopics,
          url: "topics",
          isNew: false,
        },
        {
          name: "Create your monthly editorial plan in a few clicks",
          content: "Connect your Social Media Channels Content",
          icon: logoAutopost,
          url: "planner-welcome",
          isNew: false,
        },
        {
          name: "AI Chat - GPT-4o",
          content: "AI Chat - GPT-4 Content",
          icon: svgChat,
          url: "chat",
          isNew: false,
        },
        { name: "IMAGE GENERATOR", url: "image", isNew: false },
        {
          name: "Magic Keywords",
          content: "Magic Keywords Content",
          icon: svgKeywords,
          url: "keywords",
          isNew: false,
        },
        {
          name: "Generate a name for a product",
          content: "Generate a name for a product Content",
          icon: svgProducts,
          url: "products",
          isNew: false,
        },
        {
          name: "English Grammar Check & Translation",
          content: "English Grammar Check & Translation Content",
          icon: svgTranslator,
          url: "translator",
          isNew: false,
        },
      ]);
    }
  }, []);

  const handlerClick = () => {
    setOpenHist(false);
  };

  const handleSetOpen = () => setOpen(!open);

  return (
    <Fade in={true} timeout={500}>
      <Stack  sx={{width:'100%', '@media (max-width: 600px)': {marginLeft:'0px', marginTop:'20px'}}}>
        <Box sx={style.counter} onClick={handlerClick}>
          <PanelInformation popup={popup} />
          <Box sx={style.titleArea}>
            <MyLabel
              component="h1"
              text="What do you want to do?"
              sx={style.title}
            />
          </Box>
          <Box sx={style.panelArea}>
            {items.slice(0, 3).map((item, key) => (
              <Panel item={item} key={key} handleNavegate={handleNavegate} />
            ))}
          </Box>
          <Box sx={style.titleArea} style={{ marginTop: "2rem" }}>
            <MyLabel component="h1" text="PRO Features" sx={style.title} />
          </Box>
          <Box sx={style.panelArea}>
            {items.slice(3).map((item, key) => (
              <Panel
                handleSetOpen={handleSetOpen}
                bottomPanel
                isOff={
                  item.url === "integrate" &&
                  user.subscription_name === "PRO Light" &&
                  !user.has_tested
                    ? true
                    : !user.is_pro
                }
                item={item}
                key={key}
                handleNavegate={handleNavegate}
              />
            ))}
          </Box>

          <br />
          <br />
          <br />
        </Box>
      </Stack>
    </Fade>
  );
};
export default Dashboard;
