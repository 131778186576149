export const style = {
    stack: {
        zIndex: 1201,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        margin: 0,
        padding: 0,
        backgroundColor: "#FFF",
        height: '60px',
        borderBottom: '1px solid "#f2f2f2"',
    },
    content:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        justifyItems: 'center',
        alignItems: 'center',
        alignContent: 'center',
        height: '60px',
        margin: 0,
        padding: 0,
    },
    areaLeft: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: '20px'
    },
    logoArea:{
        marginLeft: '10px'
    },
    logo:{
        height: '40px',
    },
    areaButtons:{
        display:{ xs: 'none', sm: 'flex'},
    },
    upgradeButton:{
        height:'32pt',
        marginRight: '10px',
        backgroundColor: '#6366F1',
        textTransform: 'capitalize !important',
        ':hover':{
            backgroundColor: '#9193F5',
        },
        
    },
    imageButton:{
        height:'32pt',
        marginRight: '10px',
        backgroundColor: '#EE9051',
        textTransform: 'capitalize !important',
        ':hover':{
            backgroundColor: '#F5AD7C',
        },
        
    },
    iconArea:{
        marginTop: '8px',
        marginLeft: '20px',

    },
    icon:{
        height: '30px',
    }
}