import "./App.css";


import React, {useEffect } from 'react'
import RoutesApp from "./routes/index.js";
import { AuthProvider } from "./contexts/auth";
import { ThemeProvider } from '@mui/material/styles';
import { themeOptions } from "./assets/themeOptions";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import "react-activity/dist/Digital.css";
import CssBaseline from "@mui/material/CssBaseline";

function App() {

    useEffect(() => {
        console.log(`Version: ${process.env.REACT_APP_VERSION_APP}`)
    }, []);

    return (
        <PayPalScriptProvider
            options={{
                "client-id": process.env.REACT_APP_PAYPAL_ID,
                intent: "subscription",
                vault: true,
            }}
        >
            <AuthProvider>
                <ThemeProvider theme={themeOptions}>
                    <CssBaseline />
                    <RoutesApp />
                </ThemeProvider>
            </AuthProvider>
        </PayPalScriptProvider>
    );
}

export default App;
