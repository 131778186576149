
import React from "react";
import { Box } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MyButton from "../../myButton";
import MyLabel from "../../myLabel";


import { style } from "./styles";


const MessageDialog = ({open, handleClose, handlerSuccess}) => {
  
    return (
        <Dialog  open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={" "}  />
        </DialogTitle>
        <DialogContent>
          <Box sx={{margin:'30px'}}>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                <MyLabel component='p' text={"Are you sure you want to abandon the creation of this post? All information will be lost."}  />
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                    <MyButton 
                        sx={style.cancelButton}
                        onClick={handleClose}
                        text='ok'
                        variant="contained" 
                        size="small"
                    />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
}

export default MessageDialog;