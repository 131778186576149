import { Box, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react";

import MyLabel from "../../components/myLabel"
import MyButton from "../../components/myButton";
import MyImage from "../../components/myImage";
import MyInputText from "../../components/myInputText";


const openAI = (file) => {

    let url = "https://api.openai.com/v1/chat/completions";
    let apiKey = process.env.REACT_APP_OPENAI_KEY;
    let access =  `Bearer ${apiKey}`

    return {
        url: url,
        access: access
    }

}

const openAIVision = async (file, sendText) => {

    const { url, access} = openAI()
    
     let sendJson = {
         "model": "gpt-4-vision-preview",
         "messages": [
           {
             "role": "user",
             "content": [
               {
                 "type": "text",
                 "text": sendText
               },
               {
                 "type": "image_url",
                 "image_url": {
                   "url": file
                 }
               }
             ]
           }
         ],
         "max_tokens": 300
       }
 
     
 
     return fetch(url, {
         method: "POST",
         headers: {
             "Content-Type": "application/json",
             "Authorization": access
         },
         body: JSON.stringify(sendJson)
     }).then(async (retorno) => {
         let dados = await retorno.json();
         if (retorno.status !== 200) {
             throw new Error(dados.detail);
         }
         return dados.choices[0].message.content;
     });
 
    
 }

 const openAIDalle3 = async (description, text) => {
    const { access} = openAI()
    const url = 'https://api.openai.com/v1/images/generations'

    let _description = `${text}: ${description}`

    let sendJson = {
      "model": "dall-e-3",
      "prompt": _description,
      "n": 1,
      "size": "1024x1792",
      "quality": "standard"
    }

    

    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": access
        },
        body: JSON.stringify(sendJson)
    }).then(async (retorno) => {
        let dados = await retorno.json();
        if (retorno.status !== 200) {
            throw new Error(dados.detail);
        }
        return dados.data[0].url;
    });
}

 

const Vision = ( {setOpenHist, popup, userState }) => {

    const [file, setFile] = useState(null)
    const [sendVision, setSendVision] = useState("Describe this image in as much detail as possible.")
    const [sendDalle, setSendDalle] = useState("A drawing of the following description")
    const [sendDalleTotal, setSendDalleTotal] = useState("A drawing of the following description")
    const [returnVision, setReturnVision] = useState('')
    const [urlResult, setUrlResult] = useState(null)
    const [message, setMessage] = useState('')
    const [submit, setSubmit] = useState(null)

    const handleAPI = async () =>{
        if (submit){
            return
        }
        setSubmit(true)
        let reader = new FileReader();
        
        await reader.readAsDataURL(file);
        reader.onload = async () => {
            if (returnVision === ''){
                setMessage('Processing your image...')
                let result = await openAIVision(reader.result, sendVision)
                setReturnVision(result)
                setMessage('Generating a drawing...')
                let result2 = await openAIDalle3(result, sendDalle)
                setMessage('')
                setUrlResult(result2)
                setSubmit(false)
            }
            else{
                setMessage('Generating a drawing...')
                let result2 = await openAIDalle3(returnVision, sendDalle)
                setMessage('')
                setUrlResult(result2)
                setSubmit(false)
            }
          
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          setSubmit(false)
        };
        
    }
    useEffect(
        () =>{
            setSendDalleTotal(`${sendDalle}: ${returnVision}`)
        },[sendDalle, returnVision]
    )

    return (
        <Box>
            <MyLabel noTranslate={true} component='p' text={'Image generation tests with gptVision and Dalle-3'}   />

            <Box>
            <input
                type="file"
                name="myImage"
                onChange={(event) => {
                setFile(event.target.files[0]);
                }}
            />
        </Box>
            
            <Box sx={{display: 'flex', width:'700px'}}>

            {file && (
                <Box>
                <MyImage src={URL.createObjectURL(file)} alt="not found" 
                sx={{
                    height:'400px',
                    display:'flex',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    justifyContent: 'center',
                    alignItems: 'center',
            
                    '@media (max-width: 600px)': {
                        width: '180px',
                        height: '180px',
                      }
                }}
                 />
                <br />
                <Box sx={{display: 'flex', justifyContent:'space-between'}}>
                    <MyButton onClick={() => setFile(null)} text="Remove" variant="contained" size="large" color='error'/>
                    <MyButton onClick={() => handleAPI()} text="Send" variant="contained" size="large" />
                </Box>
                <br />
                </Box>
            )}
            {
            urlResult ?
                <Box sx={{display:'flex',justifyContent:'center',height:'400px', width:'400px', marginLeft:'10px'}}>
                    <MyImage src={urlResult} alt="not found" 
                    sx={{
                        height:'400px',
                        display:'flex',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        justifyContent: 'center',
                        alignItems: 'center',
                
                        '@media (max-width: 600px)': {
                            width: '180px',
                            height: '180px',
                            }
                    }}
                        />
                </Box>
                : null
            }
            </Box>
            
            <Box>

        <br />
            {
                submit ? 
                <Box sx={{display: 'flex', width:'700px'}}>
                    <CircularProgress color="primary" />
                    <MyLabel sx={{marginLeft:"10px"}} component='p' text={message}   />
                </Box>
                : null
            }
        <br />
        <Box sx={{display: 'flex', width:'700px'}}>
            <MyInputText
                noTranslate={true}
                type='text'
                id="send"
                label="Text sent to vision"
                placeholder=""
                required={false}
                value={sendVision}
                onChange={(e) => setSendVision(e.target.value)}
            />
            <MyInputText
                noTranslate={true}
                sx={{marginLeft:'10px'}}
                type='text'
                id="return"
                label="text returned from vision"
                placeholder=""
                required={false}
                value={returnVision}
                onChange={(e) => setReturnVision(e.target.value)}
            />
        </Box>
        <br />
        <br />
        <Box sx={{display: 'flex', width:'700px'}}>
                <MyInputText
                    sx={{minWidth:'400px'}}
                    noTranslate={true}
                    type='text'
                    id="send"
                    label="Text to dalle"
                    placeholder=""
                    required={false}
                    value={sendDalle}
                    onChange={(e) => setSendDalle(e.target.value)}
                />
                <MyLabel 
                    noTranslate={true} 
                    component='p' 
                    sx={{marginLeft:'10px'}}
                    text={sendDalleTotal}   />
            </Box>
            </Box>
        </Box>
    )
}

export default Vision