import { useState, useContext } from "react";
import { AuthContext } from "../contexts/auth";

import { FormattedMessage } from "react-intl";
import { getPanelDetail } from "../services/getPanelDetail";
import toast from "react-hot-toast";

const usePanelInformationState = (popup) => {
    const { user, setUser } = useContext(AuthContext);

    const [submit, setSubmit] = useState(false);

    return {
        user: user,
        updateUserDetail: {
            value: submit,
            set: setUser,
        },
    };
};

export default usePanelInformationState;
