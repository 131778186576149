import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { AuthContext } from "../../contexts/auth";
import defLanguage from "../../utils/defLanguage";
import { openAILocal } from "../../services/openAI/openAILocal";
import { completions } from "../../services/openAI/completions";
import { getConfigOpenAI } from "../../services/openAI/getConfigOpenAI";
import { getOpenAI } from "../../services/openAI/getOpenAi";
import { postHistoric } from "../../services/postHistoric";
import formatDate from "../../utils/formatDate";
import { postDalle } from "../../services/openAI/dalle";
import openAIAssistant from "../../services/openAI/openAIAssistant";

const useArticlePostsGeneratorLocalState = () => {
    const api = 6;
    const { signOut, language } = useContext(AuthContext);

    let lang = defLanguage(language.value);

    const [sendText, setSendText] = useState("");
    const [object, setObject] = useState("article");
    const [tone, setTone] = useState("AI's Choice");
    const [theAmount, setTheAmount] = useState("AI's Choice");
    const [target, setTarget] = useState("all");
    const [goal, setGoal] = useState("no specific goal");
    const [emot, setEmot] = useState("AI's Choice");
    const [keywords, setKeywords] = useState("");
    const [imageDescription, setImageDescription] =  useState("");
    const [imgs, setImgs] =  useState([]);

    const [_language, setLanguage] = useState(lang);
    const [returnText, setReturnText] = useState("");
    const [submit, setSubmit] = useState(false);



    const createMessage = () =>{ 
        let message =`Topic: ${sendText};
Language: ${_language};
${emot ==='yes' ? `Emoticons: USE;`: emot ==='no' ? `Emoticons: DO NOT USE;`:''}
${keywords.trim() !== '' ? `Keywords: ${keywords.trim()};`:''}
${goal !=='no specific goal' ? `Goal: ${goal};`:''}
${theAmount !== "AI's Choice" ? `Length: ${theAmount} ${theAmount ==='one' ? 'paragraph': 'paragraphs'};` : ''}`

        return message;
        
    }

    const ArticlePosts = async (config, updateHistoric) => {

        let message = createMessage();
        
        let idAsssistent = 'asst_DKcD0u0w3o3K0DjHAIC2Xm9C'
        if (object === 'rewrite article'){
            idAsssistent = 'asst_Nb4D0ShgEnczjN7G0BUJRU8F'
        }

        let send = JSON.stringify({
            id_asssistent:idAsssistent,
            tone: tone, 
            target_object: object,
            target: target, 
            product: sendText, 
            the_amount: theAmount, 
            language: _language});

        await openAIAssistant(api, message, idAsssistent, setReturnText, send, updateHistoric, config.model)
        setSubmit(false)

    }

    const handleAPI = async (updateHistoric) => {

        if (submit) {
            return;
        }

        let isSendTextValid = false;

        if (sendText !== "") {
            isSendTextValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }

        if (isSendTextValid) {
            setSubmit(true);
            openAILocal(sendText, api, setSubmit, signOut, updateHistoric, ArticlePosts)
        }
    };

    const handleGetImageDescription = async ()=>{
        let descriptionText = `Can you write a simple description for generating an image using DALLE 2, for composing this article? In the description you must use only image elements (no names, no texts, no faces and no logos or the model won't work well). 

Please write directly the description without explaining the concept. Put a phrase "Image description suggestion:" and under write the description.

the article is:

${returnText}
`
        if (submit) {
            return;
        }
        setSubmit(true);
        let config = await getConfigOpenAI(descriptionText, 7, setSubmit, signOut)
        let messages = [
            {'role': 'system', "content": "You are an expert in writing prompt for DALL-E"},
            {'role':'user', "content": descriptionText.trim()}
        ]
        let send = JSON.stringify({text: descriptionText.trim()});
        let chat = await getOpenAI(messages, config)
        await postHistoric({
            send: send,
            text: JSON.stringify(messages),
            result: chat,
            date: formatDate(new Date()),
            action: api
        })
        setImageDescription(chat.trim())
        setSubmit(false);

    }

    const handleGetImage = async ()=>{
        let descriptionText = `Can you write a simple description for generating an image using DALLE 2, for composing this article? In the description you must use only image elements (no names, no texts, no faces and no logos or the model won't work well). 

the article is:

${returnText}
`
        if (submit) {
            return;
        }
        setSubmit(true);
        let config = await getConfigOpenAI(descriptionText, 7, setSubmit, signOut)
        let messages = [
            {'role': 'system', "content": "You are an expert in writing prompt for DALL-E"},
            {'role':'user', "content": descriptionText.trim()}
        ]
        let send = JSON.stringify({text: descriptionText.trim()});
        let chat = await getOpenAI(messages, config)
        await postHistoric({
            send: send,
            text: JSON.stringify(messages),
            result: chat,
            date: formatDate(new Date()),
            action: api
        })
        let json = {
            text: chat.trim(),
            size: 512,
            style: 'standard',
            date: formatDate(new Date()),
        };
        let dalle = await postDalle(json)
        if (dalle?.type === "error") {
            if (dalle.data.code === "token_not_valid") {
                signOut();
            }
            else {
                toast.error(<FormattedMessage id={dalle.data.detail} />);
            }
        } else {
            let imgs = JSON.parse(dalle.data);
            setImgs(imgs.urls);
        }
        setSubmit(false);

    }

    return {
        sendText: { value: sendText, set: setSendText},
        tone: { value: tone, set: setTone},
        object: {value: object, set: setObject},
        theAmount: { value: theAmount, set: setTheAmount},
        target: { value: target, set: setTarget},
        language: { value: _language, set: setLanguage},
        returnText: { value: returnText, set: setReturnText},
        goal: { value: goal, set: setGoal},
        emot: { value: emot, set: setEmot},
        keywords: { value: keywords, set: setKeywords},
        imageDescription: { value: imageDescription, set: setImageDescription},
        getImageDescription: { value: submit, set: handleGetImageDescription},
        imgs: { value: imgs, set: handleGetImage},
        submit: { value: submit, set: handleAPI},
    };
};

export default useArticlePostsGeneratorLocalState;

