import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const fadeInAnimation = keyframes`${fadeIn}`;

export const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 9999999999;
    animation: 1s ${fadeInAnimation};
`;
