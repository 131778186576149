import React, { useContext, useEffect } from "react";

import { AuthContext } from "../../../contexts/auth";
import useAdFromProductDescriptionLocalState from "../../../hooks/openAI/useAdFromProductDescriptionLocalState";

import imgAdPost from "../../../assets/imgs/panels/adPost.svg";
import imgEmail from "../../../assets/imgs/panels/email.svg";
import imgMessage from "../../../assets/imgs/panels/message.svg";
import imgSlogan from "../../../assets/imgs/panels/slogan.svg";

import FunctionOpenAi from "../functionOpenAI";

function AdFromProductDescription({ updateHistoric }) {

    const { historic } = useContext(AuthContext);
    const action = useAdFromProductDescriptionLocalState();

    useEffect(() => {
        if (historic.value){
            action.returnText.set("");
            action.sendText.set(historic.value.product);
            action.type.set(historic.value.type);
            action.tone.set(historic.value.tone);
            action.platform.set(historic.value.platform);
            action.target.set(historic.value.target);
            action.ad.set(historic.value.ad);
            action.sector.set(historic.value.sector);
            action.theAmount.set(historic.value.the_amount);
            action.language.set(historic.value.language);
            historic.set(null)
        }
        
    }, [historic.value]);

    let title = "What do you want to create?";
    let createAction = action.ad;
    let createOptions = [
        { name: "AD / Post", icon: imgAdPost, action: "ad" },
        { name: "E-mail", icon: imgEmail, action: "e-mail" },
        { name: "Message", icon: imgMessage, action: "message" },
        { name: "Slogan / phrase", icon: imgSlogan, action: "slogan / phrase" },
    ];

    let selects = [
        {
            name: "tone",
            title: "Choose the Tone:",
            options: [
                "creative",
                "funny",
                "modern",
                "formal",
                "informal",
                "classic",
                "original",
                "catchy",
            ],
            action: action.tone,
        },
        {
            name: "platform",
            title: "Where do you want to use it?",
            options: [
                "facebook",
                "twitter",
                "google ads",
                "linkedin feed",
                "linkedin inbox",
                "instagram",
                "instagram stories",
                "newsletter",
                "e-mail",
            ],
            action: action.platform,
        },
        {
            name: "type",
            title: "The type of the subject?",
            options: [
                "product",
                "service",
                "artist",
                "company",
                "tv show",
                "event",
                "----",
            ],
            action: action.type,
        },
        {
            name: "target",
            title: "The main target?",
            options: [
                "all",
                "professionals",
                "parents",
                "mass market",
                "students",
            ],
            action: action.target,
        },
        {
            name: "sector",
            title: "The sector target?",
            options: [
                "all",
                "arts",
                "business",
                "construction",
                "engineering",
                "finance",
                "healthcare",
                "veterinary medicine",
                "medicine",
                "hospitality / travel",
                "technology",
                "law",
                "media/marketing",
                "public and charity",
                "retail",
                "fashion",
                "science",
                "education",
            ],
            action: action.sector,
        },
        {
            name: "language",
            title: "Which language do you want it?",
            options: ["english", "spanish", "portuguese", "italian"],
            action: action.language,
        },
        {
            name: "theAmount",
            title: "How many variants would you like?",
            options: ["one", "two", "three"],
            action: action.theAmount,
        },
    ];

    let inputs = [
        {
            name: "product",
            title: "Product name or website",
            action: action.product,
        },
    ]

    let describes = [
        {
            title: "Describe your Product/service:",
            placeholder:
                "An ice-cream store that sells ice-creams and serve food.",
            action: action.sendText,
        },
    ];

    let returnText = {
        action: action.returnText,
    };

    return (
        <>
            <FunctionOpenAi
                returnText={returnText}
                describes={describes}
                selects={selects}
                inputs={inputs}
                createOptions={createOptions}
                createAction={createAction}
                title={title}
                updateHistoric={updateHistoric}
                action={action}
            />
            <br />
            <br />
            <br />
            <br />
        </>
    );
}

export default AdFromProductDescription;
