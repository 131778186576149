export const style = {
    
    describeArea: {
        marginTop: '20px',
        width: '100%',
    },
    textInput: {
        width: '100%',
        backgroundColor: '#FFF',
    },
    button1:{
        backgroundColor: '#3b82f6',
        ':hover': {
            backgroundColor: '#598FD6',
        },
    },
    center:{
        marginTop: '20px',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageArea:{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',
          }
    },
    cardAreaContent:{
        display:'flex',
        width: '100%',
        height:'100%',
        margin: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    imageReturn:{
        width: '100%',
        margin: 0,
        padding: 0,
        maxWidth: '400px',
    },
}