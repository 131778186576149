import React, { useEffect, useContext } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import useRegisterPaypalSubscription from "../../hooks/useRegisterPaypalSubscription";
import useCancelPaypalSubscription from "../../hooks/useCancelPaypalSubscription";

import toast from "react-hot-toast";

import * as C from "./styles";
import { AuthContext } from "../../contexts/auth";
import MyButton from "../myButton";
import { putUpgradePaypalSubscription } from "../../services/putUpgradePaypalSubscription";
import { postSubscription } from "../../services/paypal/postSubscription";


const CreditButton = ({createOrder, closeModal }) => {

    return (
        <PayPalButtons
                style={{
                    shape: "pill",
                    color: "gold",
                    layout: "vertical",
                    label: "paypal",
                }}
                createOrder={createOrder}
                onApprove={(data, actions) => {
                    actions.order.capture().then((details) => {
                        const name = details.payer;
                        closeModal();
                        toast.success(`Your plan is updating. It might take 1 or 2 minutes.`);
                    });
                }}
                onError={(err) => {
                    toast.error("There was an error processing your payment. If this error please contact support.", { duration: 6000 });
                  }}
            />
    )
}


const SubscriptionButton = ({createSubscription, paypalCancel, put, post, closeModal, onClick }) => {

    return (
        <PayPalButtons
                style={{
                    shape: "pill",
                    color: "gold",
                    layout: "vertical",
                    label: "paypal",
                }}
                onClick={onClick}
                createSubscription={createSubscription}
                onApprove={(data, actions) => {
                    post.set(data.subscriptionID);
                    closeModal();
                    toast.success(`Payment completed. Thank you`);
                }}
            />
    )
}

const PayPal = ({ plan_id, closeModal, paypalCancel, credit, value, imageId, userId, currency }) => {
    const [{ options }, dispatch] = usePayPalScriptReducer();
    const { user } = useContext(AuthContext);

    const { post } = useRegisterPaypalSubscription();
    const { put } = useCancelPaypalSubscription();

    useEffect(() => {
        if (credit){
            dispatch({
                type: "resetOptions",
                value: {
                    currency: currency,
                    intent: "capture",
                    "client-id": process.env.REACT_APP_PAYPAL_ID,
                },
            });
        }
        else {
            dispatch({
                type: "resetOptions",
                value: {
                    ...options,
                    intent: "subscription",
                },
            });
        }
    }, []);

    const onClick = async (data, actions) => {
        return;
        //só permite o usuario registrar de 1 em 1 min.mj
        let result = await postSubscription({'plan_id': plan_id})
        if (result.type === 'success'){
            return;
        }
        else {
            toast.error(result.data.detail)
            return false;
        }
        
    };

    const createSubscription = (data, actions) => {
        if (user.is_pro){
            return actions.subscription
            .revise(user.paypal_id, {
                plan_id: plan_id,
            })
            .then((orderId) => {
                return orderId;
            });
        }
        else{
            return actions.subscription
            .create({
                plan_id: plan_id,
            })
            .then((orderId) => {
                return orderId;
            });
        }
        
        
    };


    const RenderButom = () =>{

        if (credit){
            return <CreditButton createOrder={createOrder}  closeModal={closeModal} />
        }
        /*else if (user.is_pro){
            return <MyButton sx={{width:'100%'}} onClick={handlerUpgradePlan} text='Upgrade' variant="contained"  size="small"/>
        }*/
        else {
            return <SubscriptionButton 
            createSubscription={createSubscription} 
            paypalCancel={paypalCancel} 
            put={put} 
            post={post} 
            closeModal={closeModal}
            onClick={onClick}
            />
        }


    }
    const createOrder= (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: currency,
                        value: value.toLocaleString().replace(',',''),
                    },
                    custom_id: `${userId}|${imageId}`,
                },
            ],
        });
    };

    
    return (
        <C.ButtonWith>
            <RenderButom />
        </C.ButtonWith>
    );
};
export default PayPal;
