import { Box, LinearProgress } from "@mui/material"
import MyLabel from "../../components/myLabel"
import MyButton from "../../components/myButton"
import useGeneratingState from "../../hooks/ayrshare/planner/useGeneratingState"
import { useEffect } from "react"
import { Height } from "@mui/icons-material"

const Generating = ({page, origin}) =>{

    const states = useGeneratingState(page, origin)

    useEffect(()=>{
        
       if (!states.submit.value){
        states.logId.set()
       }
    },[])

    useEffect(()=>{
        if (states.logId.value){
            states.submit.set()
        }
    },[states.logId.value])

    

    return (
        <Box sx={styles.content}>
            <Box sx={styles.box}>

                <Box sx={styles.centerArea}>
                    <MyLabel component={'h1'} sx={styles.title} text={`The AI is generating...`} />
                    <MyLabel component={'p'} sx={styles.description01} text={`...you can leave this page with no worries, the Artificial Intelligence will work for you!`} />
                    <Box sx={{ width: '100%', marginTop: '2rem' }}>
                    <LinearProgress color="success" />
                    </Box>
                    <MyLabel component={'h1'} sx={styles.description02} text={`The operation may take up to 1 hour...`} />
                    <MyButton sx={{ backgroundColor: '#FF0000', marginTop: '0.5rem' }} variant="contained" text={'cancel'} onClick={states.cancel.set} />
                </Box>
            </Box>
          </Box>
    )

}

const textBase = {
    margin: '0px',
    padding: '0px',
    marginBottom: '10px',
}

const styles = {
    content:{
        position: 'absolute',
        display:'flex',
        top:0,
        left:0,
        width: '100%',
        height: '100%',
        justifyContent:'center',
        alignItems:'center',
        
    },
    box:{
        padding:'40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px 2px #BCBCBC',
        backgroundColor:'#ffffff',
        '@media (max-width: 600px)': {
            padding:`0px`,
            marginTop:'40px'
        }
    },
    centerArea:{
        textAlign: 'center',
        
        '@media (max-width: 600px)': {
            padding: '1rem'
            }
    },
    title:{
        ...textBase,
        fontSize: '16pt',
        fontWeight: 600,
        color: '#601C7E'
    },
    description01:{
        ...textBase,
        fontSize: '14pt',
    },
    description02:{
        ...textBase,
        fontSize: '14pt',
        color: '#B1B1B1',
        marginTop: '1rem'
      }
}

export default Generating