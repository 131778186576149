export const style = {
    stack: {
        
    },
    counter: {
        width: '100%',
        display:'flex',
        flexDirection:'column',
        justifyContent: 'center',
    },
    titleArea:{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'row',
        margin: '14px',
        width:'100%',
    },
    title:{
        margin: '0px',
        padding: '0px',
        fontSize: '14pt',
        fontWeight: 600,
        marginBottom: '10px'
    },
    panelArea: {
        display: 'grid',
        gridTemplateColumns: '300px 300px 300px',
        gap: '30px',
        width:'100%',
        justifyContent:'center',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '300px',
          }
    },
    cardAreaImageOff:{
        width: '320px',
        height:'240px',
        display:'flex',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            width: '300px',
            height:'200px',
          }
    },
    
    cardAreaImage:{
        width: '320px',
        height:'240px',
        display:'flex',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
            border: '1px solid #721384',
         },
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 600px)': {
            width: '300px',
            height:'230px',
          }
    },
    cardAreaContent:{
        display:'flex',
        width: '100%',
        height:'100%',
        margin: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    cardTitleArea: {
        position:'relative',
        bottom:'3rem',
        right:'0.5rem',
        textAlign:'right',
        width: '100%',
        color: '#FFF',
        padding: '10px',
    },
    cardTitle: { 
        fontSize: '14pt',
        fontWeight: 700,
        color: '#FFF'
    },
    iconCrown: {
        height: '15px',
        width: '15px',
        background: 'none',
        float:'right',
        position: 'relative',
        bottom: '200px',
        left: '300px'
    },
    
}
