import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useIntl } from "react-intl";


const MySelect = (props) => {

    let intl = useIntl();

    let label = props.label;
    label = label && label !=='' ? intl.formatMessage({ id: label }):'';

    let textAux = [];
    props.options?.forEach((option) => textAux.push(""));

    if (props.label === "How long it should be?" && props.id === "theAmount") {
        textAux = ["", "paragraphs", "paragraphs", "paragraphs", "paragraphs", "paragraphs", "paragraphs", "paragraphs", "paragraphs"];
    }

    const renderOptions = (option, key, textAux) => {
      let opt = option;
      if (textAux !== "") {
          opt += " " + textAux;
      }

      if (props.noTranslate){
        return <MenuItem key={key} value={option}>{option}</MenuItem>  
      }

      return <MenuItem key={key} value={option}>{opt !== "" ? intl.formatMessage({ id: opt}).charAt(0).toUpperCase() + intl.formatMessage({ id: opt}).slice(1) : <></>}</MenuItem>
  };

    return (

    <FormControl fullWidth>
      <InputLabel id={`labelId${label}`}>{label}</InputLabel>
      <Select
        id={props.id}
        labelId={`labelId${label}`}
        value={props.value}
        label={label}
        onChange={props.onChange}
        size={props.size}
        sx={props.sx}
      >
        {props.options?.map((option, key) => renderOptions(option, key, textAux[key])
        )}
      </Select>
    </FormControl>

      );


};

export default MySelect;
