import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { AuthContext } from "../../contexts/auth";
import defLanguage from "../../utils/defLanguage";
import { openAILocal } from "../../services/openAI/openAILocal";
import { completions } from "../../services/openAI/completions";

const useKeywordLocalState = () => {
    const api = 1;
    const { signOut, language } = useContext(AuthContext);

    let lang = defLanguage(language.value);

    const [sendText, setSendText] = useState("");
    const [objective, setObjective] = useState("generate");
    const [returnText, setReturnText] = useState("");
    const [_language, setLanguage] = useState(lang);
    const [submit, setSubmit] = useState(false);

    const createMessage = () =>{

        let text = `Extract keywords from this text:

${sendText}.

The answer in language ${_language}.
`
        if (objective === 'generate'){
            text = `Generate keywords about the following subject:

${sendText}.`
        }

        let message = []
        message.push({'role':'system', "content": "you are a marketing and SEO expert and always create amazing content."})
        message.push({'role':'user', "content": text})
        return message;
        
    }

    const Keyword = async (config, updateHistoric) => {

        let messages = createMessage();

        let send = JSON.stringify({
            objective: objective,
            text: sendText, 
            language: _language});

        completions(api, messages, config, send, updateHistoric, setReturnText, setSubmit)
    }

    const handleAPI = async (updateHistoric) => {
        if (submit) {
            return;
        }

        let isSendTextValid = false;

        if (sendText !== "") {
            isSendTextValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }

        if (isSendTextValid) {
            setSubmit(true);
            openAILocal(sendText, api, setSubmit, signOut, updateHistoric, Keyword)
        }
    };

    return {
        sendText: {
            value: sendText,
            set: setSendText,
        },
        objective: {
            value: objective,
            set: setObjective,
        },
        language: {
            value: _language,
            set: setLanguage,
        },
        returnText: {
            value: returnText,
            set: setReturnText,
        },
        submit: {
            value: submit,
            set: handleAPI,
        },
    };
};

export default useKeywordLocalState;
