import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";

import useRegisterState from "../../hooks/useRegisterState";

import backEnd from "../../constants/backEnd";
import endpoints from "../../constants/endpoints";
import TagManager from 'react-gtm-module'


import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import Loading from "../../components/loading";
import MyImage from "../../components/myImage";
import MyLabel from "../../components/myLabel";
import MyInput from './../../components/myInput';
import MyCheckbox from "../../components/myCheckbox";
import MyButton from './../../components/myButton';
import MySelect from './../../components/mySelect';


import logo from "../../assets/imgs/logo03.png";
import logo1 from "../../assets/imgs/logo01.png";


import { style } from "./styles";


const tagManagerArgs = {
    gtmId: 'G-CVBJ86HRVP'
}

const ScreenStandart = ({
    email,
    password,
    passwordConf,
    errors,
    profession,
    company,
    submit,
    loginClick,
    professionalClick,
    privacy,
    terms,
    offers,
    professional,
    language,
    birthday,
    country,
    countrys,
}) => {
    const pdfHandler = () => {
        fetch(`${backEnd.url}${endpoints.access.term_pdf}`).then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "Terms and Conditions AdMind SRL.pdf";
                alink.click();
            });
        });
    };

    return (
        <>
        <Box sx={style.inputArea}>
            {
                professional ?
                <>
                    <MySelect 
                        id="profession"
                        label="profession"
                        options={[
                            "I'm a...",
                            "Marketing Student",
                            "Marketing Professional (Employee)",
                            "Marketing Professional (Free-lance)",
                            "Marketing Professional (Business owner)",
                        ]}
                        value={profession.value}
                        onChange={(e)=>profession.set(e.target.value)}
                    
                    />
                    <MyInput 
                        icon="school"
                        //error={errors.value.email}
                        type='text'
                        id='company'
                        label='Company or University name'
                        required={true}
                        value={company.value}
                        onChange={(e) => company.set(e.target.value)}
                    />
                    
                </>
                :
                <></>}
            <MyInput 
                icon="user"
                //error={errors.value.email}
                type='email'
                id='email'
                label='E-mail'
                required={true}
                value={email.value}
                onChange={(e) => email.set(e.target.value)}
            />
            <MyInput 
                icon="password"
                //error={errors.value.password}
                type='password'
                id='password'
                label='Password'
                required={true}
                value={password.value}
                onChange={(e) => password.set(e.target.value)}
            />
            <MyInput 
                icon="password"
                //error={errors.value.passwordConf}
                type='password'
                id='password2'
                label='Repeat Password'
                required={true}
                value={passwordConf.value}
                onChange={(e) => passwordConf.set(e.target.value)}
            />
            <MySelect 
                id="language"
                label="Platform language"
                options={[
                    "english",
                    "spanish",
                    "portuguese",
                    "italian",
                ]}
                value={language.value}
                onChange={(e)=>language.set(e.target.value)}
            />
           
        </Box>
        <Box sx={style.centerArea}>
            <Box sx={style.checkboxArea}>
                <MyCheckbox 
                    size='small'
                    checked={privacy.value}
                    label="I read and accept the" 
                    value={privacy.value}
                    onChange={(e) => privacy.set(!privacy.value)}
                />
                <Link  
                    href="https://admind.ai/en/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <MyLabel sx={{ fontSize: '0.8rem' }} component='p' text='Privacy policy' />
                </Link>
            </Box>
            <Box sx={style.checkboxArea}>
                <MyCheckbox 
                    size='small'
                    checked={terms.value}
                    label="I read and accept the" 
                    value={terms.value}
                    onChange={(e) => terms.set(!terms.value)}
                />
                <Link  
                    onClick={pdfHandler}    
                >
                    <MyLabel sx={{ fontSize: '0.8rem' }} component='p' text='Terms & Conditions' />
                </Link>
            </Box>
            <Box sx={style.checkboxArea}>
                <MyCheckbox 
                    size='small'
                    checked={offers.value}
                    label="I want to receive offers, news and free credits" 
                    value={offers.value}
                    onChange={(e) => offers.set(!offers.value)}
                />
            </Box>
        </Box>
        <Box sx={style.loginArea}>
            <MyButton disabled={submit.value} onClick={() => {
                        if (!terms.value) {
                            toast.error(
                                <FormattedMessage id="read and accept the Terms & Conditions" />
                            );
                        }
                        if (!privacy.value) {
                            toast.error(
                                <FormattedMessage id="read and accept the Privacy policy" />
                            );
                        }
                        if (privacy.value && terms.value) {
                            submit.set();
                        }
                    }} text="Sign Up" variant="contained" size="large" sx={style.loginButton} />
        </Box>
        <Box sx={style.oldUserArea}>
            <MyLabel component='p' text='Already have an account' />{"?"}
            <MyButton onClick={loginClick} text="Sign in here" variant="text" />
        </Box>
        <Box sx={style.lineArea}>
            <Divider variant="middle" sx={style.line} />
        </Box>
        <Box sx={style.lineArea}>
            {
                professional ?
                <></>
                :
                <MyLabel sx={style.text} component='p' text='If you a marketing professional or a marketing student we have a special FREE access for you!' />
            }
            <MyButton color="secondary" onClick={professionalClick} text={professional?"I'm a standart user":"I'm a professional or student"} variant="contained" sx={style.loginButton} />
        </Box>
        </>
    );
};

const Registration = () => {

    TagManager.initialize(tagManagerArgs)

    const {
        email,
        password,
        passwordConf,
        profession,
        marketingProfessional,
        company,
        submit,
        privacy,
        terms,
        offers,
        partnership,
        birthday,
        country,
        countrys,
        language
    } = useRegisterState();
    
    let navigate = useNavigate();
    let { partnerships } = useParams();

    useEffect(() => {
        countrys.set()
        if (partnerships !== undefined){
            partnership.set(partnerships)
        }
    }, []);


    const login = () => {
        navigate("/login");
    };

    return (
        <Fade in={true} timeout={500}>
            <Stack direction="row" sx={style.stack}>
                <Loading render={submit.value} />
                <Container sx={style.leftArea}>
                    <Box sx={style.logo1}>
                        <Link href="https://admind.ai/" underline="none">
                            <MyImage src={logo} alt={'Admind logo'} sx={style.logo} />
                        </Link>
                    </Box>
                </Container>
                <Container sx={style.rightArea}>
                    <Box sx={style.logo2}>
                        <Link href="https://admind.ai/" underline="none" >
                            <MyImage src={logo1} alt={'Admind logo 1'} sx={style.logo} />
                        </Link>
                    </Box>
                    <Box sx={style.titleArea}>
                        <MyLabel component='h1' text='Sign Up for FREE' sx={style.title}  />
                    </Box>
                    <ScreenStandart
                        email={email}
                        password={password}
                        passwordConf={passwordConf}
                        submit={submit}
                        privacy={privacy}
                        terms={terms}
                        offers={offers}
                        loginClick={login}
                        professionalClick={() =>
                            marketingProfessional.set(marketingProfessional.value===0 ? 1 : 0)
                        }
                        country={country}
                        countrys={countrys}
                        birthday={birthday}
                        profession={profession}
                        company={company}
                        professional={marketingProfessional.value}
                        language={language}
                    />
                </Container>
            </Stack>
        </Fade>
    );
};
export default Registration;
