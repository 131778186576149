import dayjs from "dayjs";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { getPurchasedTokens } from "../../services/getPurchasedTokens";
import { AuthContext } from "../../contexts/auth";
import { postModeration } from "../../services/openAI/moderation";
import OpenAI from "openai";
import getIdAssistant from "./utils/getIdAssistant";
import { postHistoric } from "../../services/postHistoric";
import formatDate from "../../utils/formatDate";
import { getConfigOpenAI } from "../../services/openAI/getConfigOpenAI";
import { getOpenAI } from "../../services/openAI/getOpenAi";
import { postDalle } from "../../services/openAI/dalle";
import generatesPublicationObject from "./utils/generatesPublicationObject";
import { postProfilePost } from "../../services/ayrshare/postProfilePost";

const openai = new OpenAI({apiKey: process.env.REACT_APP_OPENAI_KEY, dangerouslyAllowBrowser: true});

const useAIAdFromProductDescriptionState = (setClose, profile)=>{
    const api = 0;
    const { signOut} = useContext(AuthContext);
    

    let [profileSelected, setProfileSelected] = useState(0);
    let [sendText, setSendText] = useState("");
    let [social, setSocial] = useState("facebook");
    let [isGenerateModal, setIsGenerateModal] = useState(false)
    let [open, setOpen] = useState(false);
    let [dateTime, setDateTime] = useState(dayjs(Date.now()));
    let [language, setLanguage] = useState('Languages')
    const [imageGenerate, setImageGenerate] = useState(false);
    const [openImage, setOpenImage] = useState(false);
    const [file, setFile] = useState(null)
    const [image, setImage] = useState('')
    let [messageUser, setMessageUser] = useState("");
    let [submit, setSubmit] = useState(false);

    const handlerImageAutoGenerate = async () => {

        if (imageGenerate) { return }

        let isSendTextValid = false;
        let api2 = 7

        if (sendText !== "") { isSendTextValid = true }
        else { toast.error(<FormattedMessage id="Provide a valid text" />) }

        if (isSendTextValid) {
            setImageGenerate(true);
            let text = `Can you suggest a simple frase for generating an image using AI (DALLE) for this post? It should be something simple and objective, so the AI will easily generate something appropriate. Remember to don't create a description that makes the AI generate faces or words, as it won't work well. Also don't use any names as AI won't generate the image well. It can be either an objective or an abstract image, depending on the text: ${sendText}`
            
            let config = await getConfigOpenAI(text, api, setImageGenerate, signOut)
            let messages = [
                {'role': 'system', "content": "You are a helpful and creative assistant."},
                {'role':'user', "content": text}
            ]
            let send = JSON.stringify({text: text});
            let chat = await getOpenAI(messages, config)
            await postHistoric({
                send: send,
                text: JSON.stringify(messages),
                result: chat,
                date: formatDate(new Date()),
                action: api
            })
            let json = {
                text: chat.trim(),
                size: 512,
                style: 'standard',
                date: formatDate(new Date()),
            };

            let dalle = await postDalle(json)
            if (dalle?.type === "error") {
                if (dalle.data.code === "token_not_valid") {
                    signOut();
                }
                else {
                    toast.error(<FormattedMessage id={dalle.data.detail} />);
                }
            } else {
                let imgs = JSON.parse(dalle.data);
                setImage(imgs.urls[0]);
            }

            setImageGenerate(false);
        }
    }

    const generate = async () => {
        setIsGenerateModal(true)

        let isSendTextValid = false
        let textIA = sendText
        let posts_template = null
        if (textIA === "" && profile.posts_template.length > 0){
            posts_template = profile.posts_template[0]
            textIA = posts_template.description
        }

        if (textIA !== "") {
            isSendTextValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }
        if (isSendTextValid){
            
            let finalText = ''
            setSendText('')
            let json = await getPurchasedTokens('tokens')
            if (json?.type === "error") {
                if (json.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    json.data.code === "violation of guidelines"
                ) {
                    toast.error(
                        <FormattedMessage id={json.data.detail} />
                    );
                    setSendText(textIA)
                    setIsGenerateModal(false)
                } else if (
                    json.data.detail === "Not found."
                ) {
                    toast.error(
                        <FormattedMessage id="your user has no usage tokens" />
                    );
                    setSendText(textIA)
                    setIsGenerateModal(false)
                }
                else {
                    toast.error(<FormattedMessage id="unknown error" />);
                    setSendText(textIA)
                    setIsGenerateModal(false)
                }
            } else {
                if (
                    json.data.tokens_number <= 0
                ) {
                    toast.error(
                        <FormattedMessage id="your user has no usage tokens" />
                    );
                    setSendText(textIA)
                    setIsGenerateModal(false)
                }
                else {
                    let moderation = await postModeration({text: textIA.trim(), api: api})
        
                    if (moderation?.type === "error") {
                        if (moderation.data.code === "token_not_valid") {
                            signOut();
                        } else if (
                            moderation.data.code === "violation of guidelines"
                        ) {
                            toast.error(
                                <FormattedMessage id={moderation.data.detail} />
                            );
                            setSendText(textIA)
                            setIsGenerateModal(false)
                        } else {
                            toast.error(<FormattedMessage id="unknown error" />);
                            setSendText(textIA)
                            setIsGenerateModal(false)
                        }
                    } else {
                        if (
                            moderation.data.tokens_number <= 0 ||  moderation.data.tokens_number * 4 < textIA.trim().length
                        ) {
                            toast.error(
                                <FormattedMessage id="your user has no usage tokens" />
                            );
                            setSendText(textIA)
                            setIsGenerateModal(false)
                        }
                        else {
                            let messages = `Description: ${textIA}`
                            if (posts_template){
                                messages = `Name of service or product: ${posts_template.name};
                                ${posts_template.site ? `Website: ${posts_template.site};` : ''}
                                Description:  ${textIA};
                                Language: ${ language && language !== '' && language !== 'Languages' ? language : posts_template.language}';`
                            }
                            
                            let idAsssistent = getIdAssistant(social)
                            const thread = await openai.beta.threads.createAndRun({
                                assistant_id: idAsssistent,
                                thread: {
                                  messages: [{'role':'user', "content": messages}],
                                },
                            });
                            
                            const awaitAssistent = async () =>{
                  
                                const run = await openai.beta.threads.runs.retrieve(
                                    thread.thread_id,
                                    thread.id
                                );
                                
                                if (run.status === 'in_progress')
                                {
                                    setTimeout(awaitAssistent, 500);
                                }else if (run.status === 'completed')
                                {
                   
                                    const messagesOpenAI = await openai.beta.threads.messages.list(
                                        thread.thread_id,
                                    );
                                    
                                    finalText = messagesOpenAI.data[0]?.content[0]?.text.value;
                        
                                    let newSend = {description:textIA, assistentID:idAsssistent}

                                    await postHistoric({
                                        send: JSON.stringify(newSend),
                                        text: JSON.stringify(messages),
                                        result: finalText,
                                        date: formatDate(new Date()),
                                        action: api
                                    })
                    
                                    setSendText(finalText)
                                    setMessageUser(``)
                                    setIsGenerateModal(false)
                                }
                            }
                            
                    
                            setTimeout(awaitAssistent, 500);
                        }
                    }
                }
            }

        }

    }

    const  handleAPI = async () => {


        if (submit){
            return
        }

        let isSendTextValid = false

        if (sendText !== "") {
            isSendTextValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }
        
        if (isSendTextValid){
            
            setSubmit(true)
            let publis = generatesPublicationObject(sendText, 
                image, 
                [social], 
                new Date().toISOString(), 
                dateTime ? dateTime.toISOString() : new Date().toISOString(),
                file
            )
            await postProfilePost(profileSelected, publis).then(async (json) => {
                if (json?.type === "error") {
                    toast.error(<FormattedMessage id="unknown error" />);
                    setSubmit(false)
                    
                } else {
                    setSubmit(false)
                    setClose()
                }
            })

        }
    
    }

    return {
        profileSelected: { value: profileSelected, set: setProfileSelected},
        sendText: {value: sendText, set: setSendText},
        social: {value: social, set: setSocial},
        open: {value: open, set: setOpen},
        dateTime: {value: dateTime, set: setDateTime},
        file: {value: file, set: setFile},
        image: {value: image, set: setImage},
        imageAutoGenerate: {value: imageGenerate, set: handlerImageAutoGenerate},
        openImage: {value: openImage, set: setOpenImage},
        messageUser: {value: messageUser,set: setMessageUser},
        isGenerateModal: {value: isGenerateModal, set: generate},
        language: {value: language, set: setLanguage},
        submit: {
            value: submit,
            set: handleAPI,
        },
    }


}

export default useAIAdFromProductDescriptionState