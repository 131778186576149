import daleImage from "../../../assets/imgs/dale.png";

export const style = {
    content: {

        minWidth:'600px',
        '@media (max-width: 600px)': {
            minWidth:'100%',
            width:'85vw',
            maxHeight:'85vh',
        }
    },
    addImageArea:{
        padding: 0,
        margin: 0,
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        gap: '1px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',

        }
    },
    cardAreaImage:{
        width: '150px',
        height:'150px',
        display:'flex',
        backgroundImage: `url(${daleImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 600px)': {
            width: '180px',
            height: '180px',
          }
    },
    cardAreaContent:{
        display:'flex',
        width: '100%',
        height:'100%',
        margin: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    cardTitleArea: {
        width: '100%',
        display:'flex',
        backgroundColor: '#85048a',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        alignCenter: 'center',
    },
    cardTitle: { 
        fontSize: '9pt',
        fontWeight: 700,
        color: '#FFF',
        textAlign: 'center',
    },
    areaClick:{
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
         },
    }
}