import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import moment from 'moment'
import { getTemplatePost } from "../../../services/ayrshare/getTemplatePost";
import backEnd from "../../../constants/backEnd";
import endpoints from "../../../constants/endpoints";
import { getProfilePost } from "../../../services/ayrshare/getProfilePost";
import { AuthContext } from "../../../contexts/auth";
import { getProfile } from "../../../services/ayrshare/getProfile";
import { getProfileKey } from "../../../services/ayrshare/getProfileKey";

const usePlannerState = () =>{
    const { signOut, user } = useContext(AuthContext);

    let [submit, setSubmit] = useState(false);
    let [addModalOpen, setAddModalOpen] = useState(false);
    let [modalOpen, setModalOpen] = useState(false);
    let [dialogOpen, setDialogOpen] = useState(false);
    let [profileSelected, setProfileSelected,] = useState(0);
    let [profiles, setProfiles] = useState([]);
    let [posts, setPosts] = useState([]);
    let [mobilePosts, setMobilePosts] = useState([]);
    
    let [daysSelected, setDaysSelected] = useState(null)
    let [page, setPage] = useState('calendar');
    let [yearAndMonth, setYearAndMonth] = useState([moment().year(), moment().month() + 1]);
    let [socials, setSocials] = useState([]);

    let [dayClickedNow, setDayClickedNow] = useState(null)

    const handleGetProfiles = async () => {
        if (submit){
            return 
        }
        setSubmit(true)
        let result = await getProfile()
        if (result?.type === "error") {
            if (result.data.code === "token_not_valid") {
                signOut();
            } else if (
                result.data.code === "violation of guidelines"
            ) {

                toast.error(
                    <FormattedMessage id={result.data.detail} />
                );
            }else {
                toast.error(<FormattedMessage id="unknown error" />);
            }
        } else {
           setProfiles(result.data.map(item => { return {...item, name: item?.posts_template.length > 0 ? item?.posts_template[0]?.name : item.name}}))
        }

    }

    const handlerSubmit = async () =>{
        if (submit){
            return 
        }
        setSubmit(true)
        let result = await getTemplatePost();
        if (result.data.code === 404){
            setPage('welcome')
        }
        else {
            if (result.data.post_logs?.filter(item=>item.stage === null || item.stage === 'PROGRESS').length > 0)
            {
                setPage('generating')
            } 
            else if (result.data?.posts_template.length <= 0)
            {
                setPage('welcome')
            }
            else {
                setProfileSelected(result.data.id)
                handleGetProfiles()
                setPage('calendar')
            }
        }
        setSubmit(false)

    }

/*
    const updateListPost = () => {
        
        connect.set();
    }*/

    const getListPost = async () =>{
        if (submit) {
            return;
        }
        if (profileSelected){
            setSubmit(true)
        
           
            let type = 'all'
            let url = `${backEnd.url}${endpoints.ayrshare.profilepost}${profileSelected}`
            let month = yearAndMonth[1]
            let year =yearAndMonth[0]
    
            let result = await getProfilePost(url, type, month, year)
            if (result?.type === "error") {
                if (result.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    result.data.code === "violation of guidelines"
                ) {
    
                    toast.error(
                        <FormattedMessage id={result.data.detail} />
                    );
                }else {
                    toast.error(<FormattedMessage id={result.data.detail} />);
                }
            } else {
                setPosts(result.data)
            }

            setSubmit(false)
        }
    }

    const getSocials = async ()=>{
        if (submit) {
            return;
        }
        if (profileSelected){
            setSubmit(true)
            let result = await getProfileKey(profileSelected)
            if (result?.type === "error") {
                if (result.data.code === "token_not_valid") {
                    signOut();
                } else if (
                    result.data.code === "violation of guidelines"
                ) {

                    toast.error(
                        <FormattedMessage id={result.data.detail} />
                    );
                }else {
                    toast.error(<FormattedMessage id="unknown error" />);
                }
            } else {
                setSocials(result.data.social)
            }
            setSubmit(false)
        }
    }

    
    return {
        addModalOpen: {set:setAddModalOpen, value: addModalOpen},
        modalOpen: {set:setModalOpen, value: modalOpen},
        dialogOpen: {set:setDialogOpen, value: dialogOpen},
        page: {set:setPage, value: page},
        profiles: {set:setProfiles, value: profiles},
        profileSelected: {set:setProfileSelected, value: profileSelected},
        socials: {set:getSocials, value: socials},
        daysSelected: {set:setDaysSelected, value: daysSelected},
        yearAndMonth: {set:setYearAndMonth, value: yearAndMonth},
        posts: {set:getListPost, value: posts},
        mobilePosts: {set:setMobilePosts, value: mobilePosts},
        dayClickedNow: {set:setDayClickedNow, value: dayClickedNow},
        submit: {set:handlerSubmit, value: submit}
    }
}


export default usePlannerState