export const STYLESIAGES = [
    '---',
    '3D render', 
    'Abstract Art', 
    'Abstract Expressionism', 
    'Ancient Greek Art', 
	'Art Deco', 
	'Baroque Art', 
	'Bohemian-style image', 
	'Byzantine Art', 
	'Cartoon', 
	'Chalkboard Art', 
	'Collage art', 
	'Collagraphy image', 
	'Comic Book Style', 
	'Cubism', 
	'Cyberpunk', 
	'Digital Art', 
	'Dry Brush/Scratchboard', 
	'Dystopian Art', 
	'Expressionism', 
	'Futuristic Neon Lit', 
	'Game-style art', 
	'Gothic Art', 
	'Graffiti Art', 
	'High-Quality Photography', 
	'Hyperrealism image', 
	'Impasto Painting', 
	'Impressionism', 
	'Infographics', 
	'Kitsch', 
	'Line Art', 
	'Lowbrow', 
	'Minimalism', 
	'Mixed Media', 
	'Monochrome Art', 
	'Mural Art', 
	'Naive Art', 
	'Neoexpressionism', 
	'Oil Pastel Drawing', 
	'Ombre Painting', 
	'Outsider-style Art', 
	'Paper Crafts', 
	'Paper Cutouts art', 
	'Pen & Ink art', 
	'Pencil Sketch', 
	'Photocopy-style image', 
	'Photomontage', 
	'Photorealism', 
	'Pixel Art', 
	'Pointillism', 
	'Pop Art', 
	'Prehistoric Art', 
	'Realism', 
	'Renaissance Art', 
	'Romanesque Art', 
	'Spray Paint Art', 
	'Stencil Art', 
	'Stippling Art', 
	'Surrealism', 
	'Synthwave Art', 
	'Textured Painting', 
	'Ukiyo-e', 
	'Vaporwave Art', 
	'Vector Graphic', 
	'Vector Illustration', 
	'Visionary Art', 
	'Watercolor drawing', 
	'Zentangle Art', 
	'coverknitted art', 
	'knitted out of wool', 
	'macro 35mm photograph', 
	'one-line drawing', 
	'stained glass',
]