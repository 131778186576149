import moment from 'moment'
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/it';


export default function formatTryProDate(date, language) {

    if (!date) return null
    language && moment.locale(language);
    return moment(date, 'YYYY-MM-DD').format('DD MMMM, YYYY')
}