import React from "react";
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import MyImage from "../../components/myImage";
import MyLabel from "../../components/myLabel";
import MyButton from './../../components/myButton';

import logo from "../../assets/imgs/logo03.png";
import logo1 from "../../assets/imgs/logo01.png";

import { style } from "./styles";

function NotValidated() {
    const navigate = useNavigate();

    return (
        <Fade in={true} timeout={500}>
            <Stack direction="row" sx={style.stack}>
                <Container sx={style.leftArea}>
                    <Box sx={style.logo1}>
                        <Link href="https://admind.ai/" underline="none">
                            <MyImage src={logo} alt={'Admind logo'} sx={style.logo} />
                        </Link>
                    </Box>
                </Container>
                <Container sx={style.rightArea}>
                    <Box sx={style.logo2}>
                        <Link href="https://admind.ai/" underline="none" >
                            <MyImage src={logo1} alt={'Admind logo'} sx={style.logo} />
                        </Link>
                    </Box>
                    <Box sx={style.titleArea}>
                        <MyLabel component='h1' text='Please confirm your email address' sx={style.title}  />
                    </Box>
                    <Box sx={style.loginArea}>
                        <MyButton onClick={() => navigate("/login")} text="Ok" variant="contained" size="large" sx={style.loginButton} />
                    </Box>
                    
                </Container>
            </Stack>
        </Fade>
    );
}
export default NotValidated;
