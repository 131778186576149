import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useIntl } from "react-intl";


const MyCheckbox = (props) => {

    let intl = useIntl();

    let label = props.label;
    label = label && label !=='' ? intl.formatMessage({ id: label }):'';

    return (
        <FormGroup >
          <FormControlLabel 
            required={props.required} 
            control={<Checkbox checked={props.checked}
            onChange={props.onChange}/>} 
            label={props.size==='small'?<span style={{ fontSize: '0.8rem' }}>{label}</span>:label}/>
        </FormGroup>
      );


};

export default MyCheckbox;
