import React, { useEffect, useState } from "react";
import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Box from '@mui/material/Box';
import MyLabel from "../../myLabel";
import MyButton from "../../myButton";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import { style } from "./styles";
import { getAyrsharePost } from "../../../services/ayrshare/getAyrsharePost";
import MyInputText from "../../myInputText";
import MyImage from "../../myImage"
import SocialIcon from '../socialIcons'
import MyDataTimeInput from "../../myDataTimeInput";
import useViewTemplateState from "../../../hooks/ayrshare/useViewTemplateState";
import formatLocaleDate from "../../../utils/formatLocaleDate";
import DragDropImage from "../../dragDropImage";
import DeleteIcon from '@mui/icons-material/Delete';
import image01 from "../../../assets/imgs/image01.png";
import close from "../../../assets/imgs/close.svg";
import downloadSvg from "../../../assets/imgs/DOWNLOAD.svg";
import ButtonDropImage from "./buttonDropImage";
import downloadImage from "../../../utils/downloadImage";
import PreviewIcon from '@mui/icons-material/Preview';

const MessageDialogDelete = ({ open, handleClose, handlerSuccess }) =>{

    return (
        <Dialog open={open} onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <MyLabel component='p' text={" "} />
            </DialogTitle>
            <DialogContent>
                <Box sx={{ margin: '30px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', margin: '10px' }}>
                        <MyLabel component='p' text={"Do you really want to delete this post?"} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', margin: '10px' }}>
                        <MyButton
                            sx={style.cancelButton}
                            onClick={handleClose}
                            text='no'
                            variant="contained"
                            size="small"
                        />
                        <MyButton
                            sx={{ ...style.exitButton, marginBottom: 0 }}
                            onClick={handlerSuccess}
                            text='yes'
                            variant="contained"
                            size="small"
                            color='warning'
                        />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}


const MessageDialogImage = ({open, handleClose, image}) => {
    return (
        <Dialog  open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={" "}  />
        </DialogTitle>
        <DialogContent>
          <Box sx={{margin:0}}>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:0}}>
                <MyImage src={image} alt={`Image`}  sx={{width:'500px'}} />
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                    <MyButton 
                        sx={style.scheduleButton}
                        onClick={handleClose}
                        text='return'
                        variant="contained" 
                        size="small"
                    />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )

}

const ViewFacebook = ({ deleteShow, handlerEdit, post, information, dialogImage, setDialogImage, states, dialogDelete, setDialogDelete }) => {

    const AnalitcArea = () => {
        if (post.platform === 'facebook') {
            if (!information.facebook) {
                return <></>
            }
            return (
                <>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <MyLabel component='p' text={information.facebook?.analytics?.likeCount ?? 0} sx={{ fontSize: '11pt', marginRight: '5px' }} noTranslate={true} />

                            <MyLabel component='p' text='Likes' sx={{ fontSize: '11pt' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                        <Box sx={{ marginRight: '20px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <MyLabel component='p' text={information.facebook.analytics?.commentsCount ?? 0} sx={{ fontSize: '11pt', marginRight: '5px' }} noTranslate={true} />
                                <MyLabel component='p' text='Comments' sx={{ fontSize: '11pt' }} />
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <MyLabel component='p' text={information.facebook.analytics?.sharesCount ?? 0} sx={{ fontSize: '11pt', marginRight: '5px' }} noTranslate={true} />
                                <MyLabel component='p' text='Share' sx={{ fontSize: '11pt' }} />
                            </Box>
                        </Box>
                    </Box>
                </>

            )
        }
        else if (post.platform === 'linkedin') {
            return (
                <>

                </>

            )
        }
        else if (post.platform === 'twitter') {
            if (!information.twitter) {
                return <></>
            }
            return (
                <>
                    <Box>
                        {/* <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <MyLabel component='p' text={information?.twitter?.analytics?.publicMetrics?.likeCount} sx={{fontSize:'11pt', marginRight:'5px'}} noTranslate={true} />
                                <MyLabel component='p' text='Likes' sx={{fontSize:'11pt'}} />
                            </Box> */}
                    </Box>
                    {/* <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'start'}}>
                            <Box sx={{marginRight:'20px'}}>
                                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <MyLabel component='p' text={information?.twitter?.analytics?.publicMetrics.replyCount} sx={{fontSize:'11pt', marginRight:'5px'}} noTranslate={true} />
                                    <MyLabel component='p' text='Reply' sx={{fontSize:'11pt'}} />
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <MyLabel component='p' text={information?.twitter.analytics?.publicMetrics.retweetCount} sx={{fontSize:'11pt', marginRight:'5px'}} noTranslate={true} />
                                    <MyLabel component='p' text='Retweet' sx={{fontSize:'11pt'}} />
                                </Box>
                            </Box>
                        </Box> */}
                </>

            )
        }
        else if (post.platform === 'instagram') {
            if (!information.instagram) {
                return <></>
            }

            return (
                <>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <MyLabel component='p' text={information?.instagram?.analytics?.likeCount ?? 0} sx={{ fontSize: '11pt', marginRight: '5px' }} noTranslate={true} />
                            <MyLabel component='p' text='Likes' sx={{ fontSize: '11pt' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                        <Box sx={{ marginRight: '20px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <MyLabel component='p' text={information.instagram.analytics?.commentsCount ?? 0} sx={{ fontSize: '11pt', marginRight: '5px' }} noTranslate={true} />
                                <MyLabel component='p' text='Comments' sx={{ fontSize: '11pt' }} />
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <MyLabel component='p' text={information.instagram.analytics?.sharesCount ?? 0} sx={{ fontSize: '11pt', marginRight: '5px' }} noTranslate={true} />
                                <MyLabel component='p' text='Share' sx={{ fontSize: '11pt' }} />
                            </Box>
                        </Box>
                    </Box>
                </>

            )
        }

    }


    let name = post.platform;


    const handlerAutoGenerateIA = () => {
        states.imageAuto.set()
    }

    const ImageArea = () => {
        
        if (states.image.value) {

            return (

                <Box sx={{ position: 'relative', left: '10%', marginTop: '15px', display: 'flex', justifyContent: 'center', width: window.innerWidth > 600 ? '80%' : '100%', paddingLeft: '1rem', paddingBottom: '1rem' }}>
                     {
                            states.file.value ? 
                            <Box sx={{marginTop:'5px'}}>
                                <ButtonDropImage image={states.image} setFile={states.file.set} />
                            </Box>
                            : null
                        }
                    {
                        states.image.value !== '-' && states.image.value !== ''?
                            <Box sx={{position:'relative'}}>
                                <MyImage src={`${states.image.value}`} alt={`Image`}  sx={{...style.cardAreaImage,backgroundImage:'none'}} />
                                <PreviewIcon onClick={()=>setDialogImage(true)} sx={{backgroundColor:'black', color: 'white', cursor:'pointer', height:'30px', position:'absolute', left:'45px', bottom:'10px'}} />
                                <DeleteIcon onClick={()=>states.image.set('-')} sx={{cursor:'pointer', backgroundColor:'#000', height:'25px', width:'25px', position:'absolute', top: 2, right: 2, display:'flex', flexDirection:'row', color:'#fff'}} />
                                <MyImage onClick={()=>saveImage(post.post.image)}src={downloadSvg} alt={`download icon`} sx={{cursor:'pointer', height:'30px', position:'absolute', left:'10px', bottom:'10px'}} />
                            </Box>
                        :
                        states.image.value === '-' ?
                            <Box sx={{ ...style.addImageArea, marginTop: '1rem', marginBottom: '1rem' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', }} >
                                    <DragDropImage image={states.image} setFile={states.file.set} />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                                    <Card onClick={handlerAutoGenerateIA} sx={{ ...style.cardAreaImage, ...style.areaClick, backgroundImage: `url(${image01})`, }}>
                                        <CardContent sx={style.cardAreaContent}>
                                            <Box sx={{ ...style.cardTitleArea, backgroundColor: '#0082ff' }}>
                                                <MyLabel component='p' text="AUTO-GENERATE WITH AI" sx={style.cardTitle} />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Box>
                            : window.innerWidth > 600 ?
                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '10px' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', border: '1px solid lightgrey', borderRadius: '12px', }}>
                                            <Box sx={{ padding: '1rem' }}><DragDropImage /></Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '1.5rem', paddingRight: '1rem' }} >

                                                <Card sx={style.cardAreaImage}>
                                                    <CardContent sx={style.cardAreaContent}>
                                                        <Box sx={style.cardTitleArea}>
                                                            <MyLabel component='p' text="AUTO-GENERATE WITH AI" sx={style.cardTitle} />
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </Box>
                                        {/* <MyImage src={states.image.value} alt={`Image`}  sx={{...style.cardAreaImage,backgroundImage:'none'}} /> */}

                                        {/* {
                                        states.file.value ? 
                                        <Box sx={{marginTop:'5px'}}>
                                            <ButtonDropImage image={states.image} setFile={states.file.set} />
                                        </Box>
                                        : null
                                    }
                                    <MyButton onClick={handlerGenerateIA} text={states.file.value ? "Generate with AI2":"Generate other image"} variant="text" />  */}
                                    </Box>
                                </Box> : 
                             
                                    <Box sx={{ position:"relative", right:'10%',}}>
                                    <MyButton sx={{fontSize:'0.8rem', backgroundColor:"#f2f2f2", color:'black', width:'90%', padding:'0.8rem', marginTop:'1rem'}} text={"Genera un'altra immagine con AI"} />
                                    <MyButton sx={{fontSize:'0.8rem', backgroundColor:"#f2f2f2", color:'black', width:'90%', padding:'0.8rem', marginTop:'1rem'}} text={"Carica un altra immagine"} />
                                    <MyButton sx={{fontSize:'0.8rem', backgroundColor:"#f2f2f2", color:'black', width:'90%', padding:'0.8rem', marginTop:'1rem'}} text={"Ellimina immagine"} />
                                    </Box>
                                    
                                
                        }
                </Box>

            )
        }

        const saveImage = (src) => { downloadImage(src) };

        return (
            <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>

                {
                    post.post.image ?
                        <Box sx={{position:'relative'}}>
                            <MyImage sx={{ maxHeight: '30vh', borderRadius: '5px', width: '100%', objectFit: 'contain' }} src={`${post.post.image}`} alt={"image post"} />
                            <PreviewIcon onClick={()=>setDialogImage(true)} sx={{backgroundColor:'black', color: 'white', cursor:'pointer', height:'30px', position:'absolute', left:'45px', bottom:'10px'}} />
                            <MyImage onClick={()=>saveImage(post.post.image)} src={downloadSvg} alt={`download icon`} sx={{cursor:'pointer', height:'30px', position:'absolute', left:'10px', bottom:'10px'}} />
                        </Box>
                        :
                        <Box sx={{ display: 'flex', justifyContent: 'center', position:'relative' }}>
                            <Box sx={{ margin: '10px 20px 10px 20px', height: '190px', width: '190px', backgroundColor: '#e7e7e7', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                <MyLabel component='p' text={'(NO IMAGE ADDED)'} sx={{ color: '#9b9b9b', fontWeight: 600, fontSize: '8pt' }} />
                            </Box>
                        </Box>
                }
            </Box>
        )

    }
    
    return (
        <>
            <Box sx={{textAlign:'center', height:'100%'}}>
            {window.innerWidth < 600  && <MyButton
                        sx={{ ...style.scheduleButton, marginBottom: '1rem', backgroundColor:'#1cd697' }}
                        onClick={() => handlerEdit()}
                        text={deleteShow ? 'Salva' : 'Modifica'}
                        variant="contained"
                        size="small"
                    />
            }
                <Box sx={{ marginBottom: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ marginRight: '10px' }}>
                            <SocialIcon icon={post.platform} linked={true} onClick={() => { window.open(post.url, '_blank'); }} sx={{ height: '50px', width: '50px', cursor: 'pointer' }} />
                        </Box>
                        <Box>
                            <Box sx={{textAlign:'left'}}>
                                <MyLabel component='p' text={name} sx={style.text} noTranslate={true} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', height: '40px', alignItems: 'center' }}>
                                <Box>
                                    {
                                        !states?.postDate?.value ?
                                            <MyLabel component='p' text={formatLocaleDate(post.post.post_date)} sx={{ fontSize: '10pt' }} noTranslate={true} />
                                            :
                                            <MyDataTimeInput
                                                value={dayjs(states?.postDate?.value)}
                                                onChange={(newValue) => states.postDate.set(newValue)}
                                                size='small'
                                            />

                                    }
                                </Box>
                           </Box>
                        </Box>
                    </Box>


                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <MyInputText
                        maxRows={15}
                        disabled={states.text.value === null}
                        value={states.text.value ? states.text.value : post.post.text}
                        onChange={(e) => {
                            states.text.set(e.target.value)
                        }
                        }
                    />
                </Box>
                <ImageArea />

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    {
                        post.status !== 'scheduled' && information ? <AnalitcArea /> : null
                    }
                </Box>

            </Box>
            <MessageDialogDelete open={dialogDelete} handleClose={() => setDialogDelete(false)} handlerSuccess={() => { states.delete.set(post.post.id); setDialogDelete(false) }} />
            {
                (post.post.image || (states.image.value !== '-' && states.image.value !== '')) && dialogImage?
                    <MessageDialogImage open={dialogImage} handleClose={()=>setDialogImage(false)} image={states.image.value && states.image.value !== '-' && states.image.value !== ''? states.image.value : post.post.image} />
                : <></>
            }
        </>
    )

}

function ViewDialog({ profileSelected, post, open, handleClose, information, updateListPost }) {
    const states = useViewTemplateState(profileSelected, post, handleClose, updateListPost)
    const [dialogDelete, setDialogDelete] = useState(false);
    const [dialogImage, setDialogImage] = useState(false);
    
    const [deleteShow, setDeleteShow] = useState(false);

    useEffect(()=>{
        if (!open){
            setDeleteShow(false)
            setDialogImage(false)
            states.postDate.set(null)
            states.text.set(null)
            states.text.set(null)
            states.image.set(null)
            states.file.set(null)
        }
    },[open])

    const handlerEdit = () => {
        setDeleteShow(true)
        states.postDate.set(post.post.post_date.toLocaleString())
        states.text.set(post.post.text)
        states.image.set(post.post.image ? post.post.image : '-')

    }

    const defView = (handleClose) => {

        if (post.platform === 'facebook') {
            return <ViewFacebook deleteShow={deleteShow} handlerEdit={handlerEdit} setDialogDelete={setDialogDelete} dialogDelete={dialogDelete} setDialogImage={setDialogImage} dialogImage={dialogImage} profileSelected={profileSelected} handleClose={handleClose} post={post} information={information} updateListPost={updateListPost} states={states} />
        }

        else {
            return <ViewFacebook deleteShow={deleteShow} handlerEdit={handlerEdit} setDialogDelete={setDialogDelete} dialogDelete={dialogDelete} setDialogImage={setDialogImage} dialogImage={dialogImage} profileSelected={profileSelected} handleClose={handleClose} post={post} information={information} updateListPost={updateListPost} states={states} />
        }

    }

    return (
        <Dialog 
            open={open} onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box sx={{display: 'flex', backgroundCo:'red', justifyContent:'end', margin:0, padding: 0, marginRight:'10px', marginTop:'10px'}}>
                <MyImage onClick={handleClose} src={close} sx={{width:"18px", cursor:"pointer"}}/>
            </Box>
            <DialogContent sx={style.content}>
                <Box >

                    {
                        defView(handleClose)
                    }

                
                {window.innerWidth > 600 && 
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: '100%', 
                    marginTop: '10px',
                    }}>
                    {deleteShow && <MyButton
                        sx={{ ...style.scheduleButton, backgroundColor: 'red' }}
                        onClick={() => setDialogDelete(true)}
                        text='Delete post'
                        variant="contained"
                        size="small"
                    />
                    }
                    {
                        post.status === 'scheduled' ?
                            <MyButton
                                sx={{ ...style.scheduleButton, marginLeft: '1rem' }}
                                onClick={() => {
                                    if (deleteShow)
                                    {
                                    states.update.set()
                                    }
                                    else{
                                        handlerEdit()
                                    }
                                }}
                                text={deleteShow ? 'Save' : 'Modify'}
                                variant="contained"
                                size="small"
                            /> : <></>
                    }

                </Box>
                }
                {
                    states.submit.value ? 
                        <Box sx={{marginTop:'10px', display: 'flex', flexDirection:'column',justifyContent:'center', alignItems: 'center'}}>
                            <CircularProgress size='2em' color="primary" />
                        </Box>
                    : <></>
                }
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const ViewTemplate = ({ profileSelected, post, open, handleClose, updateListPost }) => {

    const [information, setInformation] = useState(null)

    const KEY = process.env.REACT_APP_AYRSHARE_KEY

    useEffect(() => {

        const get = async () => {

            let result = await getAyrsharePost(
                post.post.profile_key,
                post.post.ayrshare_id,
                [post.platform],
                KEY
            )
            setInformation(result)
        }

        if (open) {
            get()
        }

    }, [open]);

    return (
        <>
            <ViewDialog profileSelected={profileSelected} open={open} handleClose={handleClose} post={post} information={information} updateListPost={updateListPost} />
        </>
    )
}

export default ViewTemplate;