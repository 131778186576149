import React, {useContext, useEffect} from "react";
import { AuthContext } from "../../../contexts/auth";
import useEssayOutlineLocalState from "../../../hooks/openAI/useEssayOutlineLocalState";
import imgWuestions from "../../../assets/imgs/panels/questions.svg";
import imgTopics from "../../../assets/imgs/panels/topics.svg";
import imgOutline from "../../../assets/imgs/panels/outline.svg";

import FunctionOpenAi from "../functionOpenAI";

function EssayOutline({ updateHistoric }) {

    const action = useEssayOutlineLocalState();
    const { historic } = useContext(AuthContext);

    useEffect(() => {
        if (historic.value){
            action.returnText.set("");
            action.sendText.set(historic.value.text);
            action.type.set(historic.value.type);
            action.target.set(historic.value.target);
            action.language.set(historic.value.language);
            historic.set(null)
        }
        
    }, [historic.value]);
    
    let title = "What do you want to create?";
    let createAction = action.type;

    let createOptions = [
        { name: "List of topics", icon: imgTopics, action: "list of topics"},
        { name: "Outline", icon: imgOutline, action: "an outline" },
        { name: "List of questions", icon: imgWuestions, action: "list of questions"}
    ];

    let selects = [
        {
            name: "target",
            title: "For what?",
            options: [
                "an essay",
                "an interview with someone",
                "a job interview",
                "an article/post",
                "a serie of posts",
                "a serie of Podcasts",
            ],
            action: action.target,
        },
        {
            name: "language",
            title: "Which language do you want it?",
            options: ["english", "spanish", "portuguese", "italian"],
            action: action.language,
        },
    ];

    let describes = [
        {
            title: "Enter the subject",
            placeholder:
                "How Artificial Intelligence can help with the climate changing?",
            action: action.sendText,
        },
    ];

    let returnText = {
        action: action.returnText,
    };

    return (
        <>
            <FunctionOpenAi
                returnText={returnText}
                describes={describes}
                selects={selects}
                createOptions={createOptions}
                createAction={createAction}
                title={title}
                updateHistoric={updateHistoric}
                action={action}
            />
            <br />
            <br />
            <br />
            <br />
        </>
    );
}

export default EssayOutline;
