import React, { useContext } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MyButton from "../myButton";
import MyLabel from "../myLabel";
import { AuthContext } from "../../contexts/auth";
import { useNavigate } from "react-router-dom";


const UpgradePopUp = () => {
    
    const { upgradePanel } = useContext(AuthContext)
    let navigate = useNavigate();

    const clickButton= ()=>{
        navigate(upgradePanel.value.navegate);
        let upg = {...upgradePanel.value}
        upg.show = false
        upgradePanel.set(upg)
    }
    
    const clickClose = ()=>{
        let upg = {...upgradePanel.value}
        upg.show = false
        upgradePanel.set(upg)
    }

    return (
    <Dialog
        open={upgradePanel.value.show}
        onClose={clickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle component="div" id="alert-dialog-title">
            <MyLabel component='h2' text={upgradePanel.value.title} sx={{fontSize:'14pt', fontWeight: 'bold',}}/>
            
        </DialogTitle>
        <DialogContent>
        <DialogContentText component="div" id="alert-dialog-description">
            <MyLabel component='p' text={upgradePanel.value.text} sx={{fontSize:'12pt'}}/>

        </DialogContentText>
        </DialogContent>
        <DialogActions>
            <MyButton onClick={clickButton} text={upgradePanel.value.textButton} variant="contained" size="large" />
        </DialogActions>
      </Dialog>
    );
}
export default UpgradePopUp;
