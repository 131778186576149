import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import SchoolIcon from '@mui/icons-material/School';
import { useIntl } from "react-intl";


const MyInputText = (props) => {

    let intl = useIntl();

    let label = props.label;
    label = label && label !=='' ? intl.formatMessage({ id: label }):'';

    let placeholder = props.placeholder;
    placeholder = placeholder && placeholder !=='' ? intl.formatMessage({ id: placeholder }):'';

    if (props.rows){
        return <TextField fullWidth maxRows={props.rows} minRows={props.rows} multiline {...props} label={label} placeholder={placeholder} variant="outlined" />
    }
    else {
        return <TextField fullWidth  minRows={10} multiline {...props} label={label} placeholder={placeholder} variant="outlined" />
    }
    

};

export default MyInputText;
