import { useState, useContext } from "react";
import { AuthContext } from "../contexts/auth";

import { FormattedMessage } from "react-intl";
import { getBillingDetail } from "../services/getBillingDetail";
import { postBillingDetail } from "../services/postBillingDetail";

import toast from "react-hot-toast";

const useBillingDetailState = () => {
    const { signOut } = useContext(AuthContext);

    const [countrys, setCountrys] = useState([]);
    const [country, setCountry] = useState("Italy");
    const [name, setName] = useState("");
    const [fiscalCode, setFiscalCode] = useState("");
    const [region, setRegion] = useState("");
    const [province, setProvince] = useState("");
    const [city, setCity] = useState("");
    const [adress, setAdress] = useState("");
    const [cap, setCap] = useState("");
    const [uniqueCode, setUniqueCode] = useState("");
    const [emailPec, setEmailPec] = useState("");

    const [update, setUpdate] = useState(false);
    const [get, setGet] = useState(false);

    const handleGet = async () => {
        if (update || get) {
            return;
        }
        setGet(true);
        getBillingDetail()
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    } else if (json.data.code === "violation of guidelines") {
                        toast(
                            <FormattedMessage key={4} id={json.data.detail} />
                        );
                    } else if (json.data.code === "no tokens") {
                        toast.error(<FormattedMessage id={json.data.detail} />);
                    } else {
                        <FormattedMessage id={"unknown error"} />;
                    }
                } else {
                    let billing = json.data.billing;

                    let count = [];

                    json.data.countries.forEach((item) => {
                        if (
                            billing.country !== "" &&
                            item[0] === billing.country
                        ) {
                            setCountry(item[1]);
                        }
                        count.push(item[1]);
                    });
                    setCountrys(count);
                    if (billing.country !== "") {
                        setCountry(billing.country);
                    }

                    setName(billing.name);
                    setFiscalCode(billing.fiscal_code);
                    setRegion(billing.region);
                    setProvince(billing.province);
                    setCity(billing.city);
                    setAdress(billing.adress);
                    setCap(billing.cap);
                    setUniqueCode(billing.unique_code);
                    setEmailPec(billing.email_pec);
                }
                setGet(false);
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast("Connection failed to server");
                } else {
                    toast(<FormattedMessage key={4} id={error.message} />);
                }
                setGet(false);
            });
    };

    const handleUpdate = async () => {
        if (update || get) {
            return;
        }
        setUpdate(true);
        let json = {
            country: country,
            name: name,
            fiscal_code: fiscalCode,
            region: region,
            province: province,
            city: city,
            adress: adress,
            cap: cap,
            unique_code: uniqueCode,
            email_pec: emailPec,
        };

        postBillingDetail(json)
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    } else if (json.data.code === "violation of guidelines") {
                        toast(
                            <FormattedMessage key={4} id={json.data.detail} />
                        );
                    } else if (json.data.code === "no tokens") {
                        toast.error(<FormattedMessage id={json.data.detail} />);
                    } else {
                        <FormattedMessage id={"unknown error"} />;
                    }
                } else {
                    if (json?.type === "success") {
                        toast.success(
                            <FormattedMessage id={"Udated  profile."} />
                        );
                    }
                }
                setUpdate(false);
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast("Connection failed to server");
                } else {
                    toast(<FormattedMessage key={4} id={error.message} />);
                }
                setUpdate(false);
            });
        setUpdate(false);
    };
    return {
        name: {
            value: name,
            set: setName,
        },
        fiscalCode: {
            value: fiscalCode,
            set: setFiscalCode,
        },
        region: {
            value: region,
            set: setRegion,
        },
        province: {
            value: province,
            set: setProvince,
        },
        city: {
            value: city,
            set: setCity,
        },
        adress: {
            value: adress,
            set: setAdress,
        },
        cap: {
            value: cap,
            set: setCap,
        },
        uniqueCode: {
            value: uniqueCode,
            set: setUniqueCode,
        },
        emailPec: {
            value: emailPec,
            set: setEmailPec,
        },
        countrys: {
            value: countrys,
            set: setCountrys,
        },
        country: {
            value: country,
            set: setCountry,
        },
        update: {
            value: update,
            set: handleUpdate,
        },
        get: {
            value: get,
            set: handleGet,
        },
    };
};

export default useBillingDetailState;
