import { Fragment, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { Toaster } from "react-hot-toast";

import NotValidated from "../views/notValidated";
import Application from "../views/application";
import Login from "../views/login";
import ChangePassword from "../views/changePassword";
import Gifts from '../views/gifts'

import Registration from "../views/registration";

import { AuthContext } from "../contexts/auth";
import Private from "../middleware/private.js";
import LANGUAGES from "../translation/languages";
import translations from "../translation/translations.json";
import AisolutionsBstranslator from "../views/aisolutions/bstranslator/index.jsx";

const RoutesApp = () => {
    const { language } = useContext(AuthContext);

    const languageNavigator = window.navigator.language;
    let currentLanguage = language.value !== undefined ? LANGUAGES[language.value] : languageNavigator

    return (
        <IntlProvider
            locale={currentLanguage}
            messages={translations[currentLanguage]}
        >
            <BrowserRouter>
                <Fragment>
                    <Routes>
                        <Route 
                            path="/aisolutions/bstranslator"
                            element={<AisolutionsBstranslator />}
                        />
                        <Route
                            exact
                            path="/userNotValited"
                            element={<NotValidated />}
                        />
                        <Route path="/login" element={<Login />} />
                        <Route path="/gifts/:code" element={<Gifts />} />
                        
                        <Route
                            path="/changePassword"
                            element={<ChangePassword />}
                        />

                        <Route
                            exact
                            path="/dashboard"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/vision"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/image"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/ad"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/articles"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/products"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/keywords"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/topics"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/chat"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/translator"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/user"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/pricing"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/integrate"
                            element={<Private Item={Application} />}
                        />
                         <Route
                            exact
                            path="/planner-settings"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/planner"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/planner-welcome"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/planner-editorial"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/planner-add-editorial"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/posts"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/credits"
                            element={<Private Item={Application} />}
                        />
                        <Route
                            exact
                            path="/registration"
                            element={<Registration />}
                        />
                        <Route
                            exact
                            path="/registration/:partnerships"
                            element={<Registration />}
                        />
                        <Route path="/" element={<Login />} />
                        <Route path="*" element={<Login />} />
                    </Routes>
                </Fragment>
            </BrowserRouter>


            <Toaster 
            position="top-center" 
            containerStyle={{

            }}
            toastOptions={{
                success: {
                    style: {
                      background: '#e3fff5',
                      border: '1px solid #00eeb0',
                    },
                  },
                  error: {
                    style: {
                      background: '#fff1f1',
                      border: '1px solid #ff6c6c',
                    },
                  },

                style: {
                    padding:'10px',
                    color: 'black',
                    fontSize: '13pt',
                    marginTop: 200,
                    boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
                },
              }}/>
        </IntlProvider>
    );
};

export default RoutesApp;
