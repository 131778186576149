import React, { useEffect, useState } from "react";

import Login from "../views/login";
import useAuth from "../hooks/useAuth";
import useUserDetailState from "../hooks/useUserDetailState";
import Cookies from 'js-cookie'

const Screens = (signed, Item, userState) => {
    return signed ? <Item userState={userState} /> : <Login />;
};

const Private = ({ Item }) => {
    const { signed, signIn } = useAuth();
    const [loading, setLoading] = useState(!signed);
    const userState = useUserDetailState();

    const update = () => {
        
    if (!userState.get.value) {
        userState.get.set();
       }
    };
   
    useEffect(() => {
        const session = Cookies.get('airton@data') && JSON.parse(Cookies.get('airton@data'))
        if (session && session.user !== "" && session.token !== "" && !signed) {
            signIn(session);
        }
        update()
        setLoading(false);
        
    }, []);

    return loading ? <></> : Screens(signed, Item, userState);
};

export default Private;
