import { useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import eyeSvg from "../../../assets/imgs/panels/eye.svg";
import alertSvg from "../../../assets/imgs/panels/alert.svg";
import checkSvg from "../../../assets/imgs/check.svg"
import { scrollbarTheme } from '../../../assets/customThemes/scrollbarTheme';
import formatPostDate from "../../../utils/formatPostDate"
import formatPostDateHours from "../../../utils/formatPostDateHours"
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';
import MyLabel from "../../myLabel";
import MyButton from "../../myButton";
import ViewTemplate from "../viewTemplate"
import MyImage from "../../myImage"
import SocialIcon from '../socialIcons'


import twitter from "../../../assets/imgs/socials/connects/twitter.svg";
import linkedin from "../../../assets/imgs/socials/connects/linkedin.svg";
import fbg from "../../../assets/imgs/socials/connects/facebookGroups.svg";
import instagram from "../../../assets/imgs/socials/connects/instagram.svg";
import facebook from "../../../assets/imgs/socials/connects/facebook.svg";
import moment from "moment";



const imgsMapped = {
    twitter,
    linkedin,
    fbg,
    instagram,
    facebook
  }

export const style = {
    card: {
        maxWidth: '260px',
        maxHeight: '330px',
        boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
        border: '1px solid rgba(0, 0, 0, 0.36)',
        borderWeight: '1px',
        '@media (max-width: 600px)': {
            maxWidth: '100%',
            maxHeight: '100%',
        }
    },
    content: {
        display: 'block',
        height: '60px',
        overflowY: 'auto',
        padding: '0 20px 0 20px',
        ...scrollbarTheme
    },
    text: {
        fontSize: '13px',
        fontStyle: 'italic'
    },
    img: {
        height: '50px',
    },
    status: {
        marginTop: '5px',
    },
    statusPublish: {
        backgroundColor: '#376db4',
        color: '#fff',
        paddingRight: '.6em',
        paddingLeft: '.6em',
        borderRadius: '10rem',
        display: 'flex',
        justifyContent: 'center',

    },
    statusNotPublish: {
        backgroundColor: '#c05a1f',
        color: '#fff',
        paddingRight: '.6em',
        paddingLeft: '.6em',
        borderRadius: '10rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    botoon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    linked: {
        marginBottom: '10px',
        justifyContent: 'center',
        alignItems: 'center'
    }
}


function MessageDialogDelete({ open, handleClose, handlerSuccess }) {

    return (
        <Dialog open={open} onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <MyLabel component='p' text={" "} />
            </DialogTitle>
            <DialogContent>
                <Box sx={{ margin: '30px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', margin: '10px' }}>
                        <MyLabel component='p' text={"Do you really want to delete this post?"} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', margin: '10px' }}>
                        <MyButton
                            sx={style.cancelButton}
                            onClick={handleClose}
                            text='no'
                            variant="contained"
                            size="small"
                        />
                        <MyButton
                            sx={{ ...style.exitButton, marginBottom: 0 }}
                            onClick={handlerSuccess}
                            text='yes'
                            variant="contained"
                            size="small"
                            color='warning'
                        />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

function MessageDialog({ open, handleClose, message }) {

    return (
        <Dialog open={open} onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <MyLabel component='p' text={"Message"} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <MyLabel component='p' text={message} />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}


function toIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}

const PostPanel = ({ profileSelected, post, deletePost, isCalendar, mobile, updateListPost }) => {

    const [open, setOpen] = useState(false);
    const [dialogDelete, setDialogDelete] = useState(false);
    const [dialogViewTemplate, setDialogViewTemplate] = useState(false);
    const [message, setMessage] = useState('');

    let title = post.platform
    let text = post.post.text
    let data = toIsoString(new Date(post.post.post_date))
    let status = post.status

    if (post.status === 'success') {
        status = 'published'
    }
    else {
        status = 'not published'
    }

    if (title === 'facebook') {
        title = 'facebook pages'
    } else if (title === 'fbg') {
        title = 'facebook groups'
    }

    
    if (isCalendar && mobile) return (
        <Box>
            <ViewTemplate profileSelected={profileSelected} post={post} open={dialogViewTemplate} handleClose={() => setDialogViewTemplate(false)} updateListPost={updateListPost} />
            <div onClick={() => setDialogViewTemplate(true)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', marginBottom: '0.2rem' }}>
               
                <Box sx={{
                    width: '90%',
                    
                    color:  'grey',
                    padding: '0.2rem 0',
                    
                    
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    borderRadius: '8px',
                    '@media (max-width: 600px)': {
                        fontSize: '0.8rem'
                    }
                }} onClick={() => setDialogViewTemplate(true)} onClick={() => setDialogViewTemplate(true)}><MyImage sx={{width:'20px', height:"20px"}} src={imgsMapped[post.platform]} /> <span style={{fontSize: '0.8rem', position:'relative', bottom:'0.3rem', left:'0.2rem'}}>{post.status === "scheduled" && window.innerWidth < 600 && `${formatPostDateHours(post?.post?.post_date)}`}</span></Box>
                
            </div>
            <Box sx={{position:'relative', left:'1rem', bottom:'0.8rem', fontWeight:'bold'}}>{post.post.text.length > 33 ? `${post.post.text.slice(0, 33)}...` : post.post.text} {post.post.image ? <MyImage sx={{position:'relative', top:'1rem',  left:'1rem', width:'40px', height:"40px"}} src={post.post.image} />:<></>}</Box>
            <Box sx={{position:'relative', bottom:'0.5rem', left:'1rem', color:'grey'}}>Status: {post.post.status} at {moment(post.post.send_date).format('hh:mm')}
            <span style={{ marginLeft:'1rem', color: '#e2945c', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                        setDialogViewTemplate(post)
                        
                      }}>
                        View post
                      </span>
            </Box>

        </Box>
    )

    if (isCalendar) return (
        <Box>
            <ViewTemplate profileSelected={profileSelected} post={post} open={dialogViewTemplate} handleClose={() => setDialogViewTemplate(false)} updateListPost={updateListPost} />
            <div onClick={() => setDialogViewTemplate(true)} style={{ position: 'relative', cursor: 'pointer', display: 'flex', justifyContent: 'center', marginBottom: '0.2rem' }}>
                {post.status === 'error' ? <WarningAmberIcon sx={{
                    color: 'red', fontSize: '0.8rem', '@media (max-width: 600px)': {
                        fontSize: '1rem'
                    }, position: 'relative', left: '2rem', top: '0.2rem'
                }} /> : post.status === 'success' ? <Box sx={{position:'absolute', left:'12px', top:'1px', borderRadius:'100px', width:'0.9rem', height:'0.9rem', margin:0, padding:0, backgroundColor:'#FFF'}}><DoneIcon sx={{
                    position:'absolute', left:'0px', top:'0px', 
                    color: 'green', fontSize: '0.8rem', '@media (max-width: 600px)': {
                        fontSize: '1rem'
                    }
                }} /> </Box>: null}
                <Box sx={{
                    marginLeft:'0.7rem',
                    width: '4rem',
                    backgroundColor: !mobile && post.image?.backgroundColor,
                    color: !mobile ? post.image?.color : 'black',
                    padding: '0.1rem 0',
                    textAlign: mobile ? 'left' : 'center',
                    fontWeight: 'bold',
                    fontSize: '0.6rem',
                    borderRadius: '8px',
                    '@media (max-width: 600px)': {
                        fontSize: '0.8rem'
                    }
                }} onClick={() => setDialogViewTemplate(true)} onClick={() => setDialogViewTemplate(true)}>{post.image?.text} {post.status === "scheduled" && window.innerWidth < 600 && `- ${formatPostDateHours(post?.post?.post_date)}`}</Box>
            </div>
        </Box>
    )
    return (

        <>
            <Card sx={style.card}>
                <CardContent sx={{ height: '300px', position: 'relative', padding: '20px 0 0 0 !important', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} >

                    {
                        post.post === '' ?
                            <>
                                <Box sx={{ height: '130px', backgroundColor: '#e7e7e7', borderRadius: '5px', display: 'flex', justifyContent: 'center' }}>
                                </Box>
                                <Box sx={style.content}>
                                    <Box sx={{ width: '60%', height: '15px', backgroundColor: '#e7e7e7', borderRadius: '5px', display: 'flex' }} />
                                    <Box sx={{ marginTop: '5px', height: '15px', backgroundColor: '#eeeeee', borderRadius: '5px', display: 'flex' }} />
                                    <Box sx={{ marginTop: '5px', height: '15px', backgroundColor: '#eeeeee', borderRadius: '5px', display: 'flex' }} />
                                </Box>
                                <Box>
                                </Box>
                                <Box sx={{ position: 'absolute', bottom: 15, display: 'flex', height: '35px', width: '65%' }}>
                                    <Box sx={{ marginTop: '5px', height: '35px', width: '100%', backgroundColor: '#eeeeee', borderRadius: '5px', display: 'flex', justifyContent: 'center' }} />
                                </Box>
                                <Box sx={{ position: 'absolute', bottom: 15, right: 15, display: 'flex', height: '35px', width: '35px' }}>
                                    <Box sx={{ marginTop: '5px', height: '35px', width: '100%', backgroundColor: '#eeeeee', borderRadius: '5px', display: 'flex', justifyContent: 'center' }} />
                                </Box>
                                <Box sx={{ position: 'absolute', bottom: '50%', left: '50%', transform: 'translateX(-50%) translateY(50%)' }}>
                                    <Button variant="text" >
                                        <AddIcon sx={{ height: '40px', width: '40px' }} />
                                    </Button>
                                </Box>
                            </>

                            :
                            <>
                                <Box sx={{ position: 'absolute', top: 3, right: 30, cursor: 'pointer', color: '#333333' }} onClick={() => setDialogViewTemplate(true)}>
                                    <img src={eyeSvg} />
                                    <MyLabel component='p' text={'View'} sx={{ fontSize: '9pt', float: 'right', textDecoration: 'underline', position: 'relative', top: '2pt', left: '3pt' }} />

                                </Box>
                                <Box sx={{ margin: '10px 20px 10px 20px', height: '130px', backgroundColor: '#e7e7e7', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                    {
                                        post.post.image ? <MyImage sx={{ height: '130px', borderRadius: '5px', width: '100%', objectFit: 'cover' }} src={post.post.image} alt={"image post"} /> :
                                            <MyLabel component='p' text={'(NO IMAGE ADDED)'} sx={{ color: '#9b9b9b', fontWeight: 600, fontSize: '8pt' }} />
                                    }
                                </Box>
                                <Box sx={style.content}>
                                    <MyLabel component='p' text={text.length > 80 ? `${text.slice(0, 80)}...` : text} sx={style.text} noTranslate={true} />
                                </Box>
                                <Box>
                                </Box>
                                <Box>

                                </Box>
                                <Box style={{ backgroundColor: '#f6f6f6', height: '18%' }}>

                                    {
                                        post.status === 'scheduled' ?
                                            <>
                                                <Box sx={{ position: 'absolute', left: 15, bottom: 15, display: 'flex', flexDirection: 'column' }}>
                                                    <DeleteOutlinedIcon onClick={() => setDialogDelete(true)} sx={{ color: 'red', cursor: 'pointer' }} />

                                                </Box>
                                                <Box sx={{ position: 'absolute', left: 50, bottom: 4, display: 'flex', flexDirection: 'column' }}>
                                                    <MyLabel component='p' text={'scheduled'} sx={style.title} />

                                                    <MyLabel component='p' text={formatPostDate(data)} sx={style.title} noTranslate={true} />
                                                </Box>
                                            </>

                                            :

                                            <Box sx={{ position: 'absolute', bottom: post.url?.indexOf('http') !== -1 ? 12 : 15, display: 'flex', left: 12 }}>
                                                {
                                                    post.url?.indexOf('http') !== -1 ?
                                                        <a target="_blank" style={{ cursor: 'pointer', marginRight: '5px', color: '#376db4' }}>
                                                            <img style={{ height: '15px', width: '15px' }} src={checkSvg} />
                                                        </a>
                                                        : <img style={{ marginRight: '8px' }} src={alertSvg} />
                                                }

                                                <MyLabel onClick={() => window.open(post.url)} component='p' text={formatPostDate(data)} sx={{
                                                    textDecoration: 'underline',
                                                    color: post.url?.indexOf('http') !== -1 ? '#4a9d77' : '#e03625',
                                                    cursor: 'pointer',
                                                    fontWeight: 600,
                                                    '&:hover': {
                                                        color: post.url?.indexOf('http') !== -1 ? '#00acee' : '#e03625'
                                                    },
                                                    fontSize: '10pt', position: 'relative', top: '1pt'
                                                }} noTranslate={true} />
                                            </Box>
                                    }

                                    <Box sx={{ position: 'absolute', bottom: 7, right: 12 }}>
                                        <SocialIcon icon={post.platform} linked={true} sx={{ height: '25px', width: '25px' }} />
                                    </Box>
                                </Box>

                            </>
                    }
                </CardContent>
            </Card>
            <MessageDialog open={open} handleClose={() => setOpen(false)} message={message} />
            <MessageDialogDelete open={dialogDelete} handleClose={() => setDialogDelete(false)} handlerSuccess={() => { deletePost(post.post.id); setDialogDelete(false) }} />
            <ViewTemplate post={post} open={dialogViewTemplate} handleClose={() => setDialogViewTemplate(false)} />
        </>
    );


};

export default PostPanel;
