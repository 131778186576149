import Box from '@mui/material/Box';

import MyCard from "./myCard";

import { style } from "./styles";


const PricingCards = ({ pricing, pricings, update, buttonClick, currency, stateModal }) => {

    return (
        <Box sx={style.area}>
            {
                pricings?.map((subscraption, key) =>{

                    return (

                    <MyCard
                        stateModal={stateModal}
                        paypalId={subscraption.paypal_id}
                        buttonClick={buttonClick}
                        update={update}
                        currency={currency}
                        key={key}
                        pricing={pricing}
                        title={subscraption.name}
                        value={subscraption.original_price}
                        information={{
                            characteres:subscraption.tokens * 4,
                            translate:subscraption.english_grammar_tokens * 4,
                            images:subscraption.images,
                            integrations:subscraption.integrations
                        }}
                    />
                )})
            }
        </Box>
    )
};

export default PricingCards;
