import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { getPurchasedTokens } from "../getPurchasedTokens";
import { postModeration } from "./moderation";


export async function getConfigOpenAI(sendText, api, setSubmit, signOut, updateHistoric, action) {
    return await getPurchasedTokens('tokens')
    .then((json) => {
        if (json?.type === "error") {
            if (json.data.code === "token_not_valid") {
                setSubmit(false);
                signOut();
                return null;
            } else if (
                json.data.code === "violation of guidelines"
            ) {
                setSubmit(false);
                toast.error(
                    <FormattedMessage id={json.data.detail} />
                );
                return null;
            } else if (
                json.data.detail === "Not found."
            ) {
                toast.error(
                    <FormattedMessage id="your user has no usage tokens" />
                );
                setSubmit(false);
                return null;
            }
            else {
                toast.error(<FormattedMessage id="unknown error" />);
                setSubmit(false);
                return null;
            }
        } else {
            if (
                json.data.tokens_number <= 0
            ) {
                toast.error(
                    <FormattedMessage id="your user has no usage tokens" />
                );
                setSubmit(false);
                return null;
            }
            else {
                return postModeration({text: sendText.trim(), api: api})
                .then(async (json) => {
                    if (json?.type === "error") {
                        if (json.data.code === "token_not_valid") {
                            signOut();
                        } else if (
                            json.data.code === "violation of guidelines"
                        ) {
                            toast.error(
                                <FormattedMessage id={json.data.detail} />
                            );
                        } else {
                            toast.error(<FormattedMessage id="unknown error" />);
                        }
                        setSubmit(false);
                        return null;
                    } else {
                        if (
                            json.data.tokens_number <= 0 ||  json.data.tokens_number * 4 < sendText.trim().length
                        ) {
                            toast.error(
                                <FormattedMessage id="your user has no usage tokens" />
                            );
                            setSubmit(false);
                            return null;
                        }
                        else {
                            return json.data.config
                        }
                    }
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(
                            <FormattedMessage id="Connection failed to server" />
                        );
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }
                    setSubmit(false);
                });
            }
        }
    })
    .catch((error) => {
        if (error.message === "NetWork request failed") {
            toast.error(
                <FormattedMessage id="Connection failed to server" />
            );
        } else {
            toast.error(<FormattedMessage id={error.message} />);
        }
        setSubmit(false);
    });
}
