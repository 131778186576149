import imgLeft from "../../assets/imgs/index/01.png";

export const style = {
    stack: {
        width: 'auto',
        height: '100vh',
        '@media (max-width: 600px)': {
            marginTop: '10px',
            height: 'auto',
          }
    },
    leftArea: {
        backgroundImage:`url(${imgLeft})`,
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display:{ xs: 'none', sm: 'block'}
    },
    rightArea:{
        width: '100%',
        height: '100%',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'column'
    },
    inputArea:{
        display:'flex',
        flexDirection:'column',
        gap: '25px',
        width: '70%',
    },
    titleArea:{
        display:'flex',
        width: '70%',
    },
    centerArea:{
        width: '70%',
        marginTop: '10px',
        marginBottom: '10px',
    },
    lineArea:{
        width: '70%',
    },
   checkboxArea:{
        display:'flex',
        width: '100%',
        alignItems: 'center',
        margin: 0,
        padding: 0,
    },
    loginArea:{
        width: '70%',
    },
    oldUserArea:{
        display:'flex',
        flexDirection:'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70%',
        marginTop: '20px',
        marginBottom: '20px',
    },
    title: {
        fontSize: '20pt',
        color: "#5f615f",
        fontWeight: '700',
        marginBottom: '20px',
        marginTop: '20px',
        
    },
    text: {
        fontSize: '11pt',
        color: "#5f615f",
        fontWeight: '700',
        marginBottom: '20px'
        
    },
    loginButton: {
        width: '100%',
    },
    logo:{
        width: '350px',
    },
    logo1: {
        width: '100%',
        height: '100%',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo2: {
        display:{ xs: 'block', sm: 'none'}
    },
}
