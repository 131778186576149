export const style = {
    stack: {
        '@media (max-width: 600px)': {
            width:'100%',
        }
    },
    titleArea:{
        display:'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection:'row',
        margin: '14px',
        width:'100%',
    },
    title:{
        margin: '0px',
        padding: '0px',
        fontSize: '14pt',
        fontWeight: 600,
        marginBottom: '10px'
    },
    panel: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        backgroundColor: '#FFF',
        padding: '40px',
        marginBottom: '20px',
        '@media (max-width: 600px)': {
            minWidth: '100%',
        }
    },
    row: {
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
        gap: '5px',
        justifyContent:'space-between',
        '@media (max-width: 600px)': {
            flexDirection:'column',
        }
    },
    channel: {
        marginRight:'25px',
        marginLeft:'25px',
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
        gap: '5px',
        justifyContent:'space-between',
        '@media (max-width: 600px)': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
            gap: '5px',
            marginRight:'0px',
            marginLeft:'0px',
        }
    },
    planDescription:{
        fontSize: '11pt',
        fontWeight: 600,
    },
    planButton:{
        fontSize: '11pt',
        fontWeight: 500,
        textDecoration: 'underline',
    },
    input:{
        marginBottom:'5px',
    },
    selectArea: {
        width: '200px',
        '@media (max-width: 600px)': {
            width: '100%',
        }
    },
    postArea: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: '15px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',
        }
    },
    blueButton: {
        backgroundColor: '#376db4',
        marginRight: '50px',
        ':hover': {
            backgroundColor: '#598FD6',
        },

        '@media (max-width: 600px)': {
            marginRight: 0,
        }

    },
    blackButton: {
        backgroundColor: '#000',
        ':hover': {
            backgroundColor: '#555',
        }

    },
    cancelButton:{
        width: '180px',
        color:'black',
        backgroundColor: '#cfcfcf',
        ':hover': {
            backgroundColor: '#ebebeb',
        }
    },
    exitButton:{
        width: '180px',
    },

}