import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';


import useHistoricState from "./../../hooks/useHistoricState";
import useUpgradePopUp from './../../hooks/useUpgradePopUp';

import Credits from "../../views/credits";
import PlannerWelcome from "../../views/planner/planner-welcome"
import PlannerEditorial from "../../views/planner/planner-editorial"
import PlannerSettings from "../../views/planner/planner-settings";
import Planner from "../../views/planner";
import DashboardOld from "../../views/dashboardOld";
import Dashboard from "../../views/dashboard";
import Pricing from "../../views/pricing";
import User from "../../views/user";

import MyHeader from "../../components/myHeader";
import UpgradePopUp from "../../components/upgradePopUp";

import Functions from "./functions";
import Generates from "./generates";
import Historic from "./historic";

import { style } from "./styles";

import TryProDialog from "../../components/try-pro-modal";
import TryProBlackFridayDialog from "../../components/try-pro-modal/black-friday"
import { AuthContext } from './../../contexts/auth';
import Vision from "../vision";
import PlannerAddEditorial from "../planner/planner-add-editorial";

const DefineScreen = ({url, popup, setOpenHist, children, showPopup, openHist, userState, setDrawerWidth, user}) =>{

    if (url ==='/user'){
        return <User popup={popup} />
    }

    else if (url ==='/dashboard'){
        return <Dashboard setDrawerWidth={setDrawerWidth} userState={userState} openHist={openHist} popup={popup} setOpenHist={setOpenHist} showPopup={showPopup} />

    }
    else if (url ==='/vision'){
        return <Vision userState={userState} openHist={openHist} popup={popup} setOpenHist={setOpenHist} showPopup={showPopup} />
    }
    
    else if (url ==='/pricing'){
        return <Pricing />
    }
    else if (url ==='/credits'){
        return <Credits />
    }
    else if (url ==='/planner'){
        return <Planner />
    }
    else if (url ==='/planner-settings'){
        return <PlannerSettings />
    }
    else if (url ==='/planner-welcome'){
        return <PlannerWelcome />
    }
    else if (url ==='/planner-editorial'){
        return <PlannerEditorial />
    }
    else if (url ==='/planner-add-editorial'){
        return <PlannerAddEditorial />
    }
    else {
        return <>{children}</>
    }

}

const Application = ({userState}) =>{
    const { historics, updateHistoric } = useHistoricState();
    const { user } = useContext(AuthContext);
    let navigate = useNavigate();
    const stateUpgradePopUp = useUpgradePopUp();
    const location = useLocation();
    const [openMenu, setOpenMenu] = useState(false);
    const [openHist, setOpenHist] = useState(false);
    const blackFriday = new Date(new Date("Nov 23, 2023 14:00:00").toLocaleString("en-US", {timeZone: "Europe/Rome"}))
    const blackFridayExpired = new Date(new Date("Nov 25, 2023 08:00:00").toLocaleString("en-US", {timeZone: "Europe/Rome"}))

    const now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Rome"}))
    const [drawerWidth, setDrawerWidth] = useState(300);
   
    const blackFridayModal = (now < blackFridayExpired) && (blackFriday < now)

    let viewPro= [
        '/chat',
        '/image',
        '/keywords',
        '/products',
        '/translator',
        '/integrate',
        '/planner-settings',
        '/planner',
        '/posts',
        '/analitic',
    ]

    let viewProLight= [
        '/integrate',
        '/planner-settings',
        '/planner',
        '/posts',
        '/analitic',
    ]

    useEffect(() => {
        updateHistoric.set();
        if ( !user.is_pro && viewPro.includes(location.pathname)){
            navigate('/dashboard')
        }
        else if ( user.subscription_name === 'PRO Light' && viewProLight.includes(location.pathname)){
            navigate('/dashboard')
        }
    },[])


    return (
        <>
            <MyHeader userState={userState} />
            {blackFridayModal ? <TryProBlackFridayDialog /> : <TryProDialog /> }
            <Stack sx={style.stack}>
                <Functions
                    setDrawerWidth={setDrawerWidth}
                    drawerWidth={drawerWidth}
                    userState={userState}
                    open={openMenu}
                    setOpen={setOpenMenu}
                    openHist={openHist}
                    setOpenHist={setOpenHist}
                />

                <Box sx={style.centerArea}
                    onClick={() => {
                        setOpenMenu(false);
                    }}
                >
                    <DefineScreen 
                        
                        userState={userState}
                        url={location.pathname}
                        openHist={openHist}
                        setOpenHist={setOpenHist}
                        popup={stateUpgradePopUp.popup.set}
                        showPopup={stateUpgradePopUp.view}
                        setDrawerWidth={setDrawerWidth}
                        user={user}
                    >
                        <Generates
                            updateHistoric={updateHistoric}
                            openHist={openHist}
                            setOpenHist={setOpenHist}
                            popup={stateUpgradePopUp.popup.set}
                            showPopup={false && stateUpgradePopUp.view}
                        />
                    </DefineScreen>
                    <UpgradePopUp />
                

                </Box>
                {
                    location.pathname !=='/user' && location.pathname !=='/pricing' && location.pathname !=='/credits' && location.pathname !=='/planner' && location.pathname !=='/planner-settings' ?
                        <Box  sx={style.historic}>
                            <Collapse orientation="horizontal" in={openHist} collapsedSize={window.innerWidth >=600 ? 40: 0} >
                                <Card >
                                    <Historic
                                        historics={historics}
                                        open={openHist}
                                        setOpen={setOpenHist}
                                    />

                                </Card>
                            </Collapse>
                        </Box>
                    :
                    <></>
                }

            </Stack>
        </>
    );
}
export default Application;
