export const style = {
    stack: {
        marginTop: '70px',
        minWidth:'800px',
        '@media (max-width: 600px)': {
            marginTop:'12px',
            paddingLeft: '10px',
            paddingRight: '10px',
            width:'100%',
            minWidth:'100%',
            marginLeft:'0px', 
            marginTop:'20px'

          }
          

    },
    counter: {
        display:'flex',
        flexDirection:'column',
        justifyContent: 'center',
    },
    header: {
        marginTop: '10px',
    },
    title:{
        fontSize: '25px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 0,
        margin: 0,
    },
    selectArea:{
        display: 'flex',
        justifyContent: 'end',
        alignItem: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        width: '100%',
    },
    select: {
        width: '100px',
        backgroundColor: '#FFF',
    },
    
}