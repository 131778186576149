import React from "react";
import { FormattedMessage } from "react-intl";

import currencySimbol from "../../utils/currencySimbol";
import checkImg from "../../assets/imgs/Raggruppa_159.svg";
import PayPal from "../paypal";

import * as C from "./stylesCreditPanel.js";


const Value2 = ({value, currency}) =>{
    let icon = currencySimbol(currency)


    return <C.Value>{value} {icon}</C.Value>
    
}

const Information = ({text, value, currency}) => {

    if (value) {
        return (
            <C.InformationArea>
                <C.InLine>
                    <C.Icon alt="checkImg" src={checkImg} ></C.Icon> 
                    <C.InformationDescription><FormattedMessage id={text} /> <Value2 value={value} currency={currency} /></C.InformationDescription>
                </C.InLine>
            </C.InformationArea>
        )
    }
    else {

        return (
            <C.InformationArea>
                <C.InLine>
                    <C.Icon alt="checkImg" src={checkImg} ></C.Icon> 
                    <C.InformationDescription><FormattedMessage id={text} /></C.InformationDescription>
                </C.InLine>
            </C.InformationArea>
        )
    }
}


const CreditPanel = ({
    title,
    images,
    value,
    closeModal,
    imageId,
    userId,
    currency
}) => {

    let priceImage =  value / images;

    return (
        <>
            <C.Row>
                <Information  text = "No Expiration" currency={currency} />
                <Information  text = "Immediately Available" currency={currency} />
                <Information  text = "Cost Per Image" value={priceImage.toFixed(2)} currency={currency} />
            </C.Row>
            <C.Row>
                <C.PanelPaypal>
                    <PayPal
                        closeModal={closeModal}
                        title={title}
                        value={value}
                        credit={true}
                        imageId={imageId}
                        userId={userId}
                        currency={currency}
                    />  
                </C.PanelPaypal>
            </C.Row>
        </>

    );
};
export default CreditPanel;
