import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import svgCrown from "../../assets/imgs/crown.svg";
import MyImage from "../myImage";
import MyLabel from "../myLabel";
import autopostTextSvg from "../../assets/imgs/dashboard/autopost-text.svg";
import tagPng from '../../assets/imgs/dalle/tag.png'

export const style = {

    card:{
        position:'relative',
        padding: '5px',
        display:'flex',
        cursor: 'pointer',
        '&:hover': {
            border: '1px solid #721384',
            boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
         },
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            width: '300px',
            height: '220px',
          }
    },

    cardOff:{
        position:'relative',
        padding: '5px',
        display:'flex',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            width: '300px',
            height: '220px',
          }
    },

    content:{
        display:'flex',
        width: '100%',
        height:'100%',
        justifyContent: 'left',
        alignItems: 'left',
        flexDirection: 'column',
        marginTop:'5pt'
    },

    titleArea: {
        width: '100%',
        display:'flex',
        backgroundColor: '#FFF',
        marginTop: '10px',
        justifyContent: 'left',
        alignItems: 'left',
    },

    title: {
        marginTop:'5pt',
        fontSize: '12pt',
        fontWeight:600
    },

    titleContent: {
        marginTop:'8pt',
        fontSize: '10pt',
        fontWeight:500
        
        
    },

    icon: {
        height: '25px',
        width: '25px',
        background: 'none',
    },
    iconCrown: {
        height: '15px',
        width: '15px',
        background: 'none',
        float:'right',
        position: 'absolute',
        bottom: '200px',
        left: '300px'
    },
    iconOff:{
        filter:'grayscale(1)',
        height: '25px',
        width: '25px',
        background: 'none',
    },
    new:{
        top: '1px',
        left: '5px',
        position:'absolute',
        textDecoration: 'underline',
        padding: '5px',
        borderRadius: '40px',
        color: '#de4394',
        fontWeight:700,
        fontSize: '12pt',
        
    }
}

const MyPanel = ({ onClick, icon, isOff, name, url, width, height, selected, isNew, content, bottomPanel}) => {
    const card  =  isOff ? {...style.cardOff} : {...style.card}
    let cardStyle = { ...card, width: `${width}px`, height:`${height}px`,};
    
    if (selected ){
        cardStyle = {...cardStyle, boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)'}
    }
    return (
        <Card onClick={onClick} sx={cardStyle}>
            {bottomPanel && <MyImage src={svgCrown} sx={style.iconCrown} alt={`icon crown`}/>}
            {
                isNew ? <MyLabel component='p' text={'new'} sx={style.new}  /> 
                :
                null
            }
            
            
            
            <CardContent sx={style.content}>
                
                
                {name === 'Create your monthly editorial plan in a few clicks' ?
                    <Box sx={{display:'flex', flexDirection:'row', height:'26px'}}>
                        <MyImage src={icon} alt={`icon ${name}`} sx={isOff ? style.iconOff : style.icon} />
                        <MyImage src={autopostTextSvg} alt={`icon ${name}`} sx={{marginRight:'10px',width: '120px', height: '120px', position:'relative', bottom:'3rem', left:'0.8rem'}} />
                        <MyImage src={tagPng} sx={{ width: '30px', height: '30px' }} />
                    </Box>
                    : <MyImage src={icon} alt={`icon ${name}`} sx={isOff ? style.iconOff : style.icon} />
                }
                
                <Box sx={style.titleArea}  >
                    <MyLabel component='p' text={name} sx={style.title}  />   
                    {url === 'chat' && <MyImage src={tagPng} sx={{width:'35px', height:'35px', marginLeft:'0.5rem', }} alt={`new tag`}/>}
                </Box>
                <MyLabel component='p' text={content} sx={style.titleContent}  />
            </CardContent>
        </Card>
    )
};

export default MyPanel;
