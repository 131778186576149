import api from "../../services/api";
import backEnd from "../../constants/backEnd";
import endpoints from "../../constants/endpoints";

export async function getTemplatePost() {
    return api
        .get(`${backEnd.url}${endpoints.ayrshare.profileTemplate}`)
        .then((response) => {
            return { type: "success", data: response.data };
        })
        .catch((error) => {
            
            if (error.response.status === 404){
                return {
                    type: "error",
                    data: {
                        code: error.response.status,
                        detail: error.response.statusText,
                    },    
                }
            }
            return {
                type: "error",
                data: {
                    code: error.response.data.code,
                    detail: error.response.data.detail,
                },
            };
        });
}
