import { useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import dayjs from 'dayjs';
import { AuthContext } from "../../contexts/auth";
import defLanguage from "../../utils/defLanguage";

import { getPurchasedTokens } from "../../services/getPurchasedTokens";
import { postModeration } from "../../services/openAI/moderation";

import { postHistoric } from "../../services/postHistoric";
import formatDate from "../../utils/formatDate";

import OpenAI from "openai";
import createADMessage from "./utils/createADMessage";
import getIdAssistant from "./utils/getIdAssistant";

const openai = new OpenAI({apiKey: process.env.REACT_APP_OPENAI_KEY, dangerouslyAllowBrowser: true});

const useAdFromProductDescriptionState = (setSocials) => {
    const api = 0;
    const { signOut, language } = useContext(AuthContext);

    let lang = defLanguage(language.value);

    let [sendText, setSendText] = useState("");
    let [content, setContent] = useState("a post");
    let [messageUser, setMessageUser] = useState("");
    let [tone, setTone] = useState("creative");
    let [ad, setAd] = useState("");
    let [type, setType] = useState("----");
    let [target, setTarget] = useState("all");
    let [sector, setSector] = useState("all");
    let [_language, setLanguage] = useState(lang);
    let [product, setProduct] = useState("");

    let [profileSelected, setProfileSelected] = useState(0);
    let [open, setOpen] = useState(false);
    let [messageOpen, setMessageOpen] = useState(false);
    let [returnText, setReturnText] = useState(``);
    let [progress, setProgress] = useState(0);
    let [stop, setStop] = useState(false);
    let [generating, setGenerating] = useState([]);
    let [submit, setSubmit] = useState(false);

    
    useEffect(()=>{
        
            if (generating.filter(item=> item.channel.value && item.generating)[0] && !stop){
                let genera = generating.filter(item=> item.channel.value && item.generating)[0]
                _openAILocal(genera)
            }
        }
        ,[generating])

    useEffect(()=>{
        if (stop){
            setGenerating([])
            setSubmit(false)
        }
    },[stop])
    

    const _completions = async (messages, send, social) => {

        let idAsssistent = getIdAssistant(social.social)

        if (stop){
            return;
        }
        
        const awaitAssistent = async () =>{
            if (stop){
                return;
            }

            const run = await openai.beta.threads.runs.retrieve(
                thread.thread_id,
                thread.id
            );

            if (run.status === 'in_progress')
            {
                setTimeout(awaitAssistent, 500);
            }else if (run.status === 'completed')
            {
                if (stop){
                    return;
                }

                const messagesOpenAI = await openai.beta.threads.messages.list(
                    thread.thread_id,
                );
                
                let finalText = messagesOpenAI.data[0]?.content[0]?.text.value;
    
                let newSend = {...send, assistentID:idAsssistent}
                if (stop){
                    return;
                }
                await postHistoric({
                    send: JSON.stringify(newSend),
                    text: JSON.stringify(messages),
                    result: finalText,
                    date: formatDate(new Date()),
                    action: api
                })

                let genera = generating.map((item) => {
                    if(item.social === social.social){
                        item.channel.set(true)
                        setGenerating({ ...item, text: finalText, active:true, generating: false})
                        return { ...item, text: finalText, active:true, generating: false}
                    } else{
                        return item
                    } 
                })
                
                setSocials(genera)
                setGenerating(genera)
                
                if (genera.filter(item =>  item.channel.value && item.generating).length <= 0){
                    toast.success(`all publications generated`);
                    setMessageUser(``)
                    setSubmit(false)
                }
                else {

                }
                
                
            }
        }

        setMessageUser(`${social.social}: generating publication text!`)
       
        const thread = await openai.beta.threads.createAndRun({
            assistant_id: idAsssistent,
            thread: {
              messages: messages,
            },
        });

        setTimeout(awaitAssistent, 500);
    
    
    }

    

    const AdFromProduc = async (social) => {
        let platform = social.social
        let messages = createADMessage(platform, ad, type, product, tone, target, sector, sendText, _language);

        let send = {
            tone: tone, 
            type: type, 
            platform: platform, 
            target: target, 
            product: sendText, 
            the_amount: 'one', 
            ad: ad, 
            sector: sector, 
            language: _language};

        await _completions(messages, send, social)
    }

    const _openAILocal = async (social) => {
    
        let json = await getPurchasedTokens('tokens')

        if (json?.type === "error") {
            if (json.data.code === "token_not_valid") {
                signOut();
            } else if (
                json.data.code === "violation of guidelines"
            ) {
                toast.error(
                    <FormattedMessage id={json.data.detail} />
                );
            } else if (
                json.data.detail === "Not found."
            ) {
                toast.error(
                    <FormattedMessage id="your user has no usage tokens" />
                );
                setSubmit(false);
            }
            else {
                toast.error(<FormattedMessage id="unknown error" />);
            }
        } else {
            if (
                json.data.tokens_number <= 0
            ) {
                toast.error(
                    <FormattedMessage id="your user has no usage tokens" />
                );
                setSubmit(false);
            }
            else {
                if (stop){
                    setSubmit(false);
                    return null;
                }
                let moderation = await postModeration({text: sendText.trim(), api: api})
    
                if (moderation?.type === "error") {
                    if (moderation.data.code === "token_not_valid") {
                        signOut();
                    } else if (
                        moderation.data.code === "violation of guidelines"
                    ) {
                        toast.error(
                            <FormattedMessage id={moderation.data.detail} />
                        );
                    } else {
                        toast.error(<FormattedMessage id="unknown error" />);
                    }
                    setSubmit(false);
                } else {
                    if (
                        moderation.data.tokens_number <= 0 ||  moderation.data.tokens_number * 4 < sendText.trim().length
                    ) {
                        toast.error(
                            <FormattedMessage id="your user has no usage tokens" />
                        );
                        setSubmit(false);
                    }
                    else {
                        if (stop){
                            setSubmit(false);
                            return null;
                        }
                    
                        return AdFromProduc(social)
                    }
                }
            }
        }
    }

    const handleAPI = async (socials) => {
        if (socials.filter(item =>  item.channel.value && item.text==='').length <= 0){
            toast.error(<FormattedMessage id="You have already generated the text from all social networks." />);
            return;
        }
        
        if (submit) {
            return;
        }

        let isSendTextValid = false;
        let isAdValid = false;

        if (sendText !== "") {
            isSendTextValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }

        if (isAdValid !== "") {
            isAdValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid type of content" />);
        }

        if (isSendTextValid && isAdValid) {
            setSubmit(true);
            setMessageUser('thinking about your publications...')
            setGenerating(socials)
        }

    };

    return {
        sendText: {
            value: sendText,
            set: setSendText,
        },
        tone: {
            value: tone,
            set: setTone,
        },
        type: {
            value: type,
            set: setType,
        },
        target: {
            value: target,
            set: setTarget,
        },
        ad: {
            value: ad,
            set: setAd,
        },
        sector: {
            value: sector,
            set: setSector,
        },
        language: {
            value: _language,
            set: setLanguage,
        },
        returnText: {
            value: returnText,
            set: setReturnText,
        },
        product: {
            value: product,
            set: setProduct,
        },
        messageUser: {
            value: messageUser,
            set: setMessageUser,
        },
        profileSelected: {
            value: profileSelected,
            set: setProfileSelected,
        },
        content: {
            value: content,
            set: setContent,
        },
        open: {value: open, set: setOpen},
        messageOpen: {value: messageOpen, set: setMessageOpen},
        progress: {value: progress, set: setProgress},
        stop: {value: stop, set: setStop},
        submit: {
            value: submit,
            set: handleAPI,
        },
    };
};

export default useAdFromProductDescriptionState;
