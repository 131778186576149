import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { register } from "../services/auth";
import { getCountryes } from "../services/getCountryes";
import validEmail from "../utils/validEmail";
import validPassWord from "../utils/validPassWord";
import formatEmail from "../utils/formatEmail";

import { FormattedMessage } from "react-intl";

const useRegisterState = () => {
    const navigate = useNavigate();

    let languageNavigator = window.navigator.language;

    if (languageNavigator === "sp") {
        languageNavigator = 'spanish';
    } else if (languageNavigator === "pt-BR") {
        languageNavigator = 'portuguese';
    } else if (languageNavigator === "it") {
        languageNavigator = 'italian';
    } else {
        languageNavigator = 'english';
    }

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConf, setPasswordConf] = useState("");
    const [profession, setProfession] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [partnership, setPartnership] = useState("");
    const [country, setCountry] = useState("Italy");
    const [countrys, setCountrys] = useState([]);
    const [language, setLanguade] = useState(languageNavigator)

    const [company, setCompany] = useState("");
    const [privacy, setPrivacy] = useState(false);
    const [terms, setTerms] = useState(false);
    const [offers, setOffers] = useState(false);

    const [marketingProfessional, setMarketingProfessional] = useState(0);

    const [submit, setSubmit] = useState(false);

    const handleLogin = () => {
        if (submit) {
            return;
        }

        
        let isEmailValid = false;
        let isPasswordValid = false;
        let isBirthday = true;

        if (email === "" || !validEmail(email)) {
            toast.error(<FormattedMessage id="Provide a valid email" />);
        }
        else {
            isEmailValid = true;
        }

        if (password === "" || !validPassWord(password)) {
            toast.error(
                <FormattedMessage id="Provide a valid password. It must have at least 8 characters, letters and numbers." />
            );
        }
        else if (passwordConf === "" && !validPassWord(passwordConf)) {
            toast.error(
                <FormattedMessage id="Provide a valid password. It must have at least 8 characters, letters and numbers." />
            );
        } else if (passwordConf !== password) {
            toast.error(<FormattedMessage id="Password are different" />);
        } else {
            isPasswordValid = true;
        }

        if (birthday && birthday !== ''){
            let birthdaySplit = birthday.split("-").map(Number);
            let eighteen = new Date(birthdaySplit[0]+18, birthdaySplit[1] - 1, birthdaySplit[2]);
            let now = new Date();

            if (birthday === "" || birthday === null || eighteen > now ) {
                toast.error(
                    <FormattedMessage id="Provide a valid birthday. You must be over eighteen." />
                );
            } else {
                isBirthday = true;
            }
        }

        if (marketingProfessional === 1) {
            if (profession === "" || profession === "") {
                toast.error(
                    <FormattedMessage id="Provide a valid profession" />
                );
            }
            if (company === "") {
                toast.error(<FormattedMessage id="Provide a valid company" />);
            }
        }

        let lang = 0;

        if (language === "spanish") {
            lang = 1;
        } else if (language === "portuguese") {
            lang = 2;
        } else if (language === "italian") {
            lang = 3;
        }

        if (isEmailValid && isPasswordValid && isBirthday) {
            let json = {
                email: formatEmail(email.replace(" ", "")),
                password: password,
                profession: profession,
                company: company,
                country: country,
                marketing_professional: marketingProfessional,
                privacy: privacy,
                terms: terms,
                offers: offers,
                partner: partnership,
                language: lang,
                //birthday: `${birthdaySplit[0]}-${birthdaySplit[1]}-${birthdaySplit[2]} 00:00:00`,
            };
            setSubmit(true);
            register(json)
                .then(async (user) => {
                    toast.success("User registered successfully");
                    navigate("/login");
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(
                            <FormattedMessage id="Connection failed to server" />
                        );
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }
                    setSubmit(false);
                });
        }
    };

    const GetCountryes = () =>{

        getCountryes()
            .then(async (json) => {
                let count = [];

                json.countries.forEach((item) => {
                    count.push(item[1]);
                });
                
                setCountrys(count);
            })
    }

    return {
        email: {
            value: email,
            set: setEmail,
        },
        password: {
            value: password,
            set: setPassword,
        },
        passwordConf: {
            value: passwordConf,
            set: setPasswordConf,
        },
        marketingProfessional: {
            value: marketingProfessional,
            set: setMarketingProfessional,
        },
        profession: {
            value: profession,
            set: setProfession,
        },
        company: {
            value: company,
            set: setCompany,
        },
        privacy: {
            value: privacy,
            set: setPrivacy,
        },
        terms: {
            value: terms,
            set: setTerms,
        },
        offers: {
            value: offers,
            set: setOffers,
        },
        partnership: {
            value: partnership,
            set: setPartnership,
        },
        country: {
            value: country,
            set: setCountry,
        },
        countrys: {
            value: countrys,
            set: GetCountryes,
        },
        birthday: {
            value: birthday,
            set: setBirthday,
        },
        language: {
            value: language,
            set: setLanguade,
        },
        submit: {
            value: submit,
            set: handleLogin,
        },
    };
};

export default useRegisterState;
