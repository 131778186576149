import { useState, useContext } from "react";
import { AuthContext } from "../contexts/auth";

import { FormattedMessage } from "react-intl";
import { putCancelPaypalSubscription } from "../services/putCancelPaypalSubscription";

import toast from "react-hot-toast";

const useCancelPaypalSubscription = () => {
    const { signOut } = useContext(AuthContext);

    const [put, setPut] = useState(false);

    const handlePut = async (paymentID) => {
        if (put) {
            return;
        }

        setPut(true);

        putCancelPaypalSubscription()
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    } else if (json.data.code === "violation of guidelines") {
                        toast(
                            <FormattedMessage key={4} id={json.data.detail} />
                        );
                    } else if (json.data.code === "no tokens") {
                        toast.error(<FormattedMessage id={json.data.detail} />);
                    } else {
                        <FormattedMessage id={"unknown error"} />;
                    }
                } else {
                    if (json?.type === "success") {
                        toast.success(
                            <FormattedMessage id={"Cancellation registered. Between 3 and 5 minutes your account will be updated."} />
                        );
                    }
                }
                setPut(false);
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast("Connection failed to server");
                } else {
                    toast(<FormattedMessage key={4} id={error.message} />);
                }
                setPut(false);
            });
        setPut(false);
    };
    return {
        put: {
            value: put,
            set: handlePut,
        },
    };
};

export default useCancelPaypalSubscription;
