import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useIntl } from "react-intl";


const CustonSelect = (props) => {

    let intl = useIntl();

    let label = props.label;
    label = label && label !=='' ? intl.formatMessage({ id: label }):'';

    const renderOptions = (key, id, option) => {

      if (props.noTranslate){
        return <MenuItem key={key} value={id}>{option}</MenuItem>  
      }

      return <MenuItem key={key} value={id}>{option}</MenuItem>
  };

    return (

    <FormControl fullWidth>
      <InputLabel id={`labelId${label}`}>{label}</InputLabel>
      <Select
        key={props.key}
        id={props.id}
        labelId={`labelId${label}`}
        value={props.value}
        label={label}
        onChange={props.onChange}
        size={props.size}
        sx={props.sx}
      >
        {props.options?.map((option, key) => renderOptions(key, option.id, option.name)
        )}
      </Select>
    </FormControl>

      );


};

export default CustonSelect;
