import MyIconFacebook from "../../myIcons/socials/facebook";
import MyIconFacebookGroups from "../../myIcons/socials/facebookGroups";
import MyIconGoogle from "../../myIcons/socials/google";
import MyIconInstagram from "../../myIcons/socials/instagram";
import MyIconLinkedin from "../../myIcons/socials/linkedin";
import MyIconPinterest from "../../myIcons/socials/pinterest";
import MyIconReddit from "../../myIcons/socials/reddit";
import MyIconTelegram from "../../myIcons/socials/telegram";
import MyIconTiktok from "../../myIcons/socials/tiktok";
import MyIconTwitter from "../../myIcons/socials/twitter";
import MyIconYoutube from "../../myIcons/socials/youtube";

export const style = {
     icon:{
        width:  '50px',
        height: '50px',
        
    },
}


const defIcon = (icon, linked, sx, onClick=null) => {

    if (icon === 'google'){
        return <MyIconGoogle onClick={onClick} sx={{...style.icon, ...sx}} connect={linked} />
    }
    else if (icon === 'instagram'){
        return <MyIconInstagram onClick={onClick} sx={{...style.icon, ...sx}} connect={linked} />
    }
    else if (icon === 'linkedin'){
        return <MyIconLinkedin onClick={onClick} sx={{...style.icon, ...sx}} connect={linked} />
    }
    else if (icon === 'pinterest'){
        return <MyIconPinterest onClick={onClick} sx={{...style.icon, ...sx}} connect={linked} />
    }
    else if (icon === 'reddit'){
        return <MyIconReddit onClick={onClick} sx={{...style.icon, ...sx}} connect={linked} />
    }
    else if (icon === 'telegram'){
        return <MyIconTelegram onClick={onClick} sx={{...style.icon, ...sx}} connect={linked} />
    }
    else if (icon === 'tiktok'){
        return <MyIconTiktok onClick={onClick} sx={{...style.icon, ...sx}} connect={linked} />
    }
    else if (icon === 'twitter'){
        return <MyIconTwitter onClick={onClick} sx={{...style.icon, ...sx}} connect={linked}/>
    }
    else if (icon === 'youtube'){
        return <MyIconYoutube onClick={onClick} sx={{...style.icon, ...sx}} connect={linked}/>
    }
    else if (icon === 'fbg'){
        return <MyIconFacebookGroups onClick={onClick} sx={{...style.icon, ...sx}} connect={linked}/>
    }
    else {
        return <MyIconFacebook onClick={onClick} sx={{...style.icon, ...sx}} connect={linked} />
    }
}


const SocialIcon = ({icon, linked, sx, onClick}) => defIcon(icon, linked, sx, onClick)

export default SocialIcon;