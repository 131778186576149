import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions'
import MyButton from './../../components/myButton';
import Divider from '@mui/material/Divider';


import MyLabel from "../myLabel";
import MyImage from "../myImage";

import checkImg from "../../assets/imgs/check.svg";
import svgRettangleModalPro from "../../assets/imgs/rettangle-modal-pro.svg";

import { style } from "./cardStyles";
import currencySimbol from "../../utils/currencySimbol";



const Select = () =>{

    return (
        <Box sx={style.selectDiv}>
            <Box sx={style.selectDivFull}>
                <Box sx={style.selectDivCicle} />
                {/*<input type="checkbox" sx={style.select} />*/}
            </Box>
        </Box>
    )
}

const Value = ({value, currency}) =>{
    let icon = currencySimbol(currency)

    let valueFormat = value.toLocaleString().replace(',','');
    
    let lastCharacteres = valueFormat.slice(-2)
    if (lastCharacteres === '00'){
        return (
                <Box>
                    <p style={style.value}>{icon} {parseInt(valueFormat)} <span style={style.valueMonth} >/ <FormattedMessage id="pricing_p_9" /></span></p>
                </Box>
            )
    }else {
        return (
            <Box>
                <p style={style.value}>{icon} {valueFormat} <span style={style.valueMonth} >/ <FormattedMessage id="pricing_p_9" /></span></p>
            </Box>
        )
    }
}

const Information = ({text}) => {
        return (
            <Box sx={style.informationArea}>
                <Box sx={style.inLine}>
                    <MyImage src={checkImg} alt={'green circle check'} sx={style.icon} />
                    <MyLabel component='p' text={text} sx={style.informationValue}  />
                </Box>
            </Box>
        )
}

const Information2 = ({text, text2}) => {
    return (
        <Box sx={style.informationArea}>
                <Box sx={style.inLine}>
                    <MyImage src={checkImg} alt={'green circle check'} sx={style.icon} />
                    <MyLabel component='p' text={text} sx={style.informationValue}  />
                </Box>
                <Box sx={style.informationArea2}>
                    <MyLabel component='p' text={text2} sx={style.informationValue2}  />
                </Box>
            </Box>

    )
}



const MyCard = ({stateModal, title, value, information, pricing, update, buttonClick, paypalId, currency}) => {
    
    

    let cardStyle = style.card

    if(stateModal.planId === paypalId){
        cardStyle = {...cardStyle,
            border: 'solid 1px rgba(0, 0, 0, 0.36)',
            boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
        }
    }

    const handlerButton = () => {
        if (update) {
            toast.success(
                <FormattedMessage id="Your plan is updating. Wait a few minutes" />
            );
        } 
        else if (pricing === title) {
            toast.success(
                <FormattedMessage id="Your Plan" />
            );
        } else {
            buttonClick(
                `${title} Plan`,
                paypalId,
                information,
                value
            );
        }
    }

    return (
        <Card sx={cardStyle}>
            {
                title.includes('Max') ?
                <Box style={{ position:'absolute', right:10, top: 5, padding:'4px 16px 2px 16px', borderRadius:'18px', backgroundRepeat: 'no-repeat',backgroundImage: `url(${svgRettangleModalPro})`}}>
                    <MyLabel sx={{color:'white'}} text={'BEST OPTION'} />
                </Box> : null
            }
            <CardContent>
                <Box sx={style.titleArea}>
                    <MyLabel component='h3' text={title} sx={style.title}  />
                    <h3 style={style.title} >Plan</h3>
                </Box>
                <Box>
                    <Value value={value} currency={currency} />
                </Box>
                <Box sx={style.selectArea}>
                    <Select />
                    <MyLabel component='p' text='pricing_p_12' sx={style.selectLabel}  />
                </Box>
                <Box sx={style.lineArea} >
                    <Divider variant="middle" sx={style.line} />
                    {
                        title.includes('Light') ?
                        <Box sx={{marginTop:'20px'}}>
                            <Information text = "Chat GPT-4o to included" />
                            <Information text = "10,000 characters every 2 hours of Chat GPT-4o mini" />
                            <Information text = "500,000 characters of GPT-4o to use with all tools" />
                            <Information text = "35 AI-generated images" />
                            <Information text = "Unlimited use of the English Grammar Check & Translator Tool" />
                        </Box>
                        : null
                    }
                    {
                        title.includes('Plus') ?
                        <>
                            <Information text = "Chat GPT-4o to included" />
                            <Information text = "Unlimited use of Chat GPT-4o mini (Free)" />
                            <Information text = "1,000,000 characters of GPT-4o to use with all tools" />
                            <Information text = "45 AI-generated images" />
                            <Information text = "Unlimited use of the English Grammar Check & Translator Tool" />
                        </>
                        : null
                    }
                    {
                        title.includes('Max') ?
                        <>
                            <Information text = "Chat GPT-4o to included" />
                            <Information text = "Unlimited use of Chat GPT-4o mini (Free)" />
                            <Information text = "1,500,000 characters of GPT-4o to use with all tools" />
                            <Information text = "180 AI-generated images" />
                            <Information text = "Unlimited use of the English Grammar Check & Translator Tool" />
                            <Information2 text = "Autopost🔥" text2 = "AI social media assistant to automate creation, scheduling, and publishing of social media posts."/>
                        </>
                        : null
                    }
                </Box>
            </CardContent>
            <CardActions>
                <Box sx={style.buttonArea}>
                    {
                        pricing === title ?
                        <MyButton onClick={handlerButton} text="pricing_p_4" variant="contained" sx={style.buttonYourPlan}/>
                        :
                        <MyButton onClick={handlerButton} text="pricing_p_5" variant="contained" sx={style.buttonEscolher} />
                    }
                </Box>
            </CardActions>
        </Card>

    )
}

export default MyCard