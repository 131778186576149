export const style = {
    collapse:{
      position: 'absolute',
      backgroundColor: '#FFF',
      top: '9px',
      padding: 0,
      right: '65px',
      zIndex:10,
      color: "#5f615f",
  
      '@media (max-width: 600px)': {
        top: '8px',
        right: '70px',
      }
    },
    bottonArea: {
      height: '42px',
      width: '60px',
      display: 'flex',
      alignItem: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    },
    botton: {
      margin: 0,
      padding: 0,
      color: "#5f615f",
      display: 'flex',
      alignItem: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    },
    flag:{
      width: '25px',
      margin: '0 10px',
      padding: '0px',
    },
    description:{
      margin: 0,
      padding: 0,
      marginTop: '10px',
    },
    dropdownButtom:{
      display: 'flex',
      flexDirection: 'row',
      padding: '5px',
      margin: '0px 2px',
      cursor: 'pointer',
      borderBottom: '1px solid "#f2f2f2"',
    }
}
