import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { AuthContext } from "../../contexts/auth";
import defLanguage from "../../utils/defLanguage";
import { openAILocal } from "../../services/openAI/openAILocal";
import { completions } from "../../services/openAI/completions";

const useAdFromProductDescriptionLocalState = () => {
    const api = 0;
    const { signOut, language } = useContext(AuthContext);

    let lang = defLanguage(language.value);

    const [sendText, setSendText] = useState("");
    const [tone, setTone] = useState("creative");
    const [ad, setAd] = useState("ad");
    const [type, setType] = useState("product");
    const [platform, setPlatform] = useState("facebook");
    const [target, setTarget] = useState("all");
    const [sector, setSector] = useState("all");
    const [_language, setLanguage] = useState(lang);
    const [theAmount, setTheAmount] = useState("one");
    const [product, setProduct] = useState("");

    const [returnText, setReturnText] = useState(``);
    const [submit, setSubmit] = useState(false);

    const createMessage = () =>{

        const sector_language = () => {
            let result = sector.toLowerCase()
            if (_language.toLowerCase() === 'italian')
            {
                if (result === 'all'){
                    result = 'tutti'
                } else if (result === 'arts'){
                    result = 'arti'
                } else if (result === 'business'){
                    result = 'affari'
                } else if (result === 'construction'){
                    result = 'costruzioni'
                } else if (result === 'engineering'){
                    result = 'ingineria'
                } else if (result === 'finance'){
                    result = 'finanza'
                } else if (result === 'healthcare'){
                    result = 'healthcare'
                } else if (result === 'veterinary medicine'){
                    result = 'medicina veterinaria'
                } else if (result === 'medicine'){
                    result = 'medicina'
                } else if (result === 'hospitality'){
                    result = 'travel - viaggi'
                } else if (result === 'technology'){
                    result = 'tecnologia'
                } else if (result === 'law'){
                    result = 'legge'
                } else if (result === 'media / marketing'){
                    result = 'media, marketing e pubblicità'
                } else if (result === 'public and charity'){
                    result = 'sistema pubblico e Carità'
                } else if (result === 'science'){
                    result = 'scienza'
                } else if (result === 'education'){
                    result = 'educazione'
                }
            
            }
            return result   
        }

        const ad_language = () => {
           let result = ad.toLowerCase()

           if (_language.toLowerCase() === 'italian'){
                if (result === 'ad') {
                    result = 'ad'
                } else if (result === 'e-mail') {
                    result = 'email'
                } else if (result === 'message') {
                    result = 'messaggio'
                }
                else if (result === 'slogan / phrase') {
                    result = 'slogan / frase'
                }
           }

           return result
        }

        const the_amount_language = () => {
            let result = theAmount.toLowerCase()
            if (_language.toLowerCase() === 'italian'){
                 if (result === 'one') {
                     result = 'una'
                 } else if (result === 'two') {
                     result = 'due'
                 } else if (result === 'three') {
                     result = 'tre'
                 }
            }

            return result
         }

         const target_language = () => {
            let result = target.toLowerCase()
            if (_language.toLowerCase() === 'italian'){
                 if (result === 'professionals') {
                     result = 'professionisti'
                 } else if (result === 'parents') {
                     result = 'genitor'
                 } else if (result === 'general') {
                     result = 'generale'
                 }
            }

            return result
         }

         const isType_language = () => {
            let result = type.toLowerCase()
            if (result === '----') {
                return ''
            }
            else if (_language.toLowerCase() === 'italian'){
                 if (result === 'product') {
                     result = 'prodotto'
                 } else if (result === 'service') {
                     result = 'servizio'
                 } else if (result === 'artist') {
                     result = 'artista'
                 } else if (result === 'company') {
                    result = 'società'
                } else if (result === 'tv show') {
                    result = 'serie tv'
                } else if (result === 'event') {
                    result = 'evento'
                } 
            }

            return result
         }

         const tone_language = () => {
            let result = tone.toLowerCase()
            if (_language.toLowerCase() === 'italian'){
                 if (result === 'creative') {
                     result = 'creativo'
                 } else if (result === 'funny') {
                     result = 'divertente'
                 } else if (result === 'modern') {
                     result = 'moderno'
                 } else if (result === 'formal') {
                    result = 'formale'
                } else if (result === 'informal') {
                    result = 'informale'
                } else if (result === 'classic') {
                    result = 'classico'
                }  else if (result === 'original') {
                    result = 'originale'
                }  else if (result === 'catchy') {
                    result = 'accattivante'
                } 
            }

            return result
         }


         const plataform_language = () => {
            let result = platform.toLowerCase()
            if (_language.toLowerCase() === 'italian'){
                 if (result === 'linkedin') {
                     result = `essere inviato via ${result}`
                 } else if (result === 'e-mail') {
                    result = `essere inviato via ${result}`
                 } else if (result === 'newsletter') {
                    result = `essere inviato in una ${result}`
                 } else {
                    result = `essere pubblicato su ${result}`
                } 
            } else {
                if (result === 'linkedin') {
                    result = `esend via ${result}`
                } else if (result === 'e-mail') {
                   result = `send via ${result}`
                } else if (result === 'newsletter') {
                    result = `send via ${result}`
                } else {
                    result = `run on ${result}`
                }
            }

            return result
         }

        const hasProduct = () =>{
            if (product ===''){
                return ''
            }
            else {
                if (_language.toLowerCase() === 'italian'){
                    return `Il nome del prodotto/sito web è ${product}`
                }
                else {
                    return `The name of the product/website is ${product}.`
                }
            }
            
        }


        let _tone = tone_language()
        let _isType = isType_language()
        let _target = target_language()
        let _the_amount = the_amount_language()
        let _ad = ad_language()
        let _sector = sector_language()
        let _plataform = plataform_language()

        let text = `Write ${_the_amount} ${_tone} ${_ad} to ${_plataform}, for the following ${_isType} aimed at ${_target} of the ${_sector} sector.${hasProduct()} The answer in language ${_language}${platform.toLowerCase()==='twitter'?' and with a maximum of 280 characters':''}:

${_isType}: ${sendText}.`

        if (_isType ===''){
            text = `Write ${_the_amount} ${_tone} ${_ad} to ${_plataform}, aimed at ${_target} of the ${_sector} sector.${hasProduct()} The answer in language ${_language}${platform.toLowerCase()==='twitter'?' and with a maximum of 280 characters':''}:

${sendText}.`
        }

        if (_language.toLowerCase() === 'italian'){
            if (_isType ===''){
                text = `Scrivi ${_the_amount} ${_tone} ${_ad} per ${_plataform}, rivolto a ${_target}${hasProduct()}${platform.toLowerCase()==='twitter'?' e con un massimo di 280 caratteri':''}:

${sendText}.`
            }
            else if (_sector === 'tutti'){
                text = `Scrivi ${_the_amount} ${_tone} ${_ad} per ${_plataform}, per il seguente ${_isType} rivolto a ${_target}${hasProduct()}${platform.toLowerCase()==='twitter'?' e con un massimo di 280 caratteri':''}:

${_isType}: ${sendText}.`
                
            }else{
                text = `Scrivi ${_the_amount} ${_tone} ${_ad} per ${_plataform}, per il seguente ${_isType} rivolto a ${_target} del settore ${_sector}${hasProduct()}${platform.toLowerCase()==='twitter'?' e con un massimo di 280 caratteri':''}:

${_isType}: ${sendText}.`

            }

        }

        let message = []
        message.push({'role':'system', "content": "you are a marketing and SEO expert and always create amazing content."})
        message.push({'role':'user', "content": text})
        return message;
        
    }

    const AdFromProduc = async (config, updateHistoric) => {

        let messages = createMessage();

        let send = JSON.stringify({
            tone: tone, 
            type: type, 
            platform: platform, 
            target: target, 
            product: sendText, 
            the_amount: theAmount, 
            ad: ad, 
            sector: sector, 
            language: _language});

            completions(api, messages, config, send, updateHistoric, setReturnText, setSubmit)
    
    }
    

    const handleAPI = async (updateHistoric) => {
        if (submit) {
            return;
        }

        let isSendTextValid = false;

        if (sendText !== "") {
            isSendTextValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }

        if (isSendTextValid) {
            setSubmit(true);
            openAILocal(sendText, api, setSubmit, signOut, updateHistoric, AdFromProduc)
        }
    };

    return {
        sendText: {
            value: sendText,
            set: setSendText,
        },
        tone: {
            value: tone,
            set: setTone,
        },
        type: {
            value: type,
            set: setType,
        },
        theAmount: {
            value: theAmount,
            set: setTheAmount,
        },
        platform: {
            value: platform,
            set: setPlatform,
        },
        target: {
            value: target,
            set: setTarget,
        },
        ad: {
            value: ad,
            set: setAd,
        },
        sector: {
            value: sector,
            set: setSector,
        },
        language: {
            value: _language,
            set: setLanguage,
        },
        returnText: {
            value: returnText,
            set: setReturnText,
        },
        product: {
            value: product,
            set: setProduct,
        },
        submit: {
            value: submit,
            set: handleAPI,
        },
    };
};

export default useAdFromProductDescriptionLocalState;
