import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const MyTimeInput = ({value, onChange}) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker 
            //MuiPickersToolbarText-root.Mui-selected
            slotProps={{
                layout: {
                  sx: {
                    [`.Mui-selected`]: {
                      color: '#721384',
                      fontWeight:500,
                      backgroundColor: '#FFF',

                    },
                  },
                },
              }}
            value={value} 
            onChange={onChange} 
            format="HH:mm"
            />
        </LocalizationProvider>
    );
};

export default MyTimeInput;
