import api from '../services/api';
import backEnd from '../constants/backEnd';
import endpoints from '../constants/endpoints';
import { getCSRF } from "./getCSRF";

export async function postHistoric(json) {
  let csrf = await getCSRF();
  return api
    .post(`${backEnd.url}${endpoints.historic}`, json, {
      headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrf?.csrfToken,
      },
  })
  .then((response) => {
      return { type: "success", data: response.data };
  })
  .catch((error) => {
      return {
          type: "error",
          data: {
              code: error.response.data.code,
              detail: error.response.data.detail,
          },
      };
  });
}
