export const style ={
    container:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '240px',
        height:'150px',
        border: '1px dashed #adb5bd',
        borderRadius: '10px',
        alignItems: 'center',
        cursor: 'pointer',
    },
    icon: {
        width: '30px',
        height:'30px',
    },
    title:{
        fontSize:'12pt',
    },
    title2:{
        fontSize:'10pt',
        color:'grey'
    }
}