export const style = {
    card:{
        height: '430px',
        display:'flex',
        flexDirection:'column',
        justifyContent: 'space-between',
        '@media (max-width: 600px)': {
            width: '100%',
          }
    },
    titleArea: {
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
    },
    title:{
        margin: '0px',
        marginLeft: '5px',
        fontWeight: 'bold',
        fontSize: '12pt',
        padding: '0px',
    },
    descriptionImageArea:{
        display: 'flex',
        justifyContent: 'center',
    },
    descriptionImage:{
        margin: '0px',
        marginLeft: '5px',
        fontWeight: 'bold',
        fontSize: '15pt',
        padding: '0px',
        color:'rgb(222, 67, 148)',
    },
    titleValueArea:{
        display: 'flex',
        justifyContent: 'center',
    },
    titleValue:{
        fontWeight: 'bold',
        fontSize: '30pt',
        margin: 0,
    },
    value:{
        fontWeight: 'bold',
        fontSize: '30pt',
        margin: 0,
    },
    valueMonth:{
        fontWeight: 'bold',
        fontSize: '20pt',
        color: 'rgb(128, 128, 128)',
    },
    selectArea:{
        marginTop: '20px',
        display:'flex',
        flexDirection:'row',
        alignItems: 'center',
    },
    selectLabel:{
        marginLeft: '20px',
        color: 'rgb(128, 128, 128)',
        fontSize: '16pt',
    },
    value2:{
        fontWeight: 'bold',
        color: 'rgb(128, 128, 128)',
        fontSize: '16pt',
        margin: 0,
        padding: 0,
    },
    lineArea: {
        marginTop: '20px',
        width: '100%',
    },
    informationArea: {
        paddingTop: '10px',
        paddingBottom: '20px',
    },
    inLine:{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
        margin: '0px',
    },
    inLine2:{
        display: 'flex',
        flexDirection:'row',
        padding: '0px',
        margin: '0px',
    },
    informationDescription:{
        color: 'rgb(128, 128, 128)',
        fontSize: '12pt',
        margin: '5px',
    },
    informationValue:{
        fontWeight: 'bold',
        fontSize: '14pt',
        color: '#000',
        padding: '0px',
        margin: '0px',
    },
    buttonArea:{
        width: '100%',
    },
    buttonYourPlan:{
        width: '100%',
    },
    buttonEscolher:{
        width: '100%',
        backgroundColor: '#227DFA',
    },
    icon:{
        marginRight: '5px',
        width: '20px'
    },
}

