import React, {useContext, useEffect} from "react";
import { AuthContext } from "../../../contexts/auth";
import useKeywordLocalState from "../../../hooks/openAI/useKeywordLocalState";
import useKeywordState from "../../../hooks/openAI/useKeywordState";

import imgExtract from "../../../assets/imgs/panels/extract.svg";
import imgGenerate from "../../../assets/imgs/panels/generate.svg";

import FunctionOpenAi from "../functionOpenAI";

function Keyword({ updateHistoric }) {

    const action = useKeywordLocalState();
    //const action = useKeywordState();
    
    const { historic } = useContext(AuthContext);

    useEffect(() => {

        if (historic.value){
            action.returnText.set("");
            action.sendText.set(historic.value.text);
            action.objective.set(historic.value.objective);
            action.language.set(historic.value.language);
            historic.set(null)
        }
        
    }, [historic.value]);

    let title = "What do you want to do?";
    let createAction = action.objective;
    let createOptions = [
        {
            name: "Generate a list of keywords",
            icon: imgGenerate,
            action: "generate",
        },
        {
            name: "Extract keywords from a text",
            icon: imgExtract,
            action: "extract",
        },
    ];

    let selects = [
        {
            name: "language",
            title: "In what language do you want it?",
            options: ["english", "spanish", "portuguese", "italian"],
            action: action.language,
        },
    ];
    let describes = [
        {
            title: `Add the subject you would like to create keywords or the text you would like to extract keywords from`,
            placeholder: `Type here`,
            action: action.sendText,
        },
    ];

    let returnText = {
        action: action.returnText,
    };

    return (
        <>
            <FunctionOpenAi
                returnText={returnText}
                describes={describes}
                selects={selects}
                createOptions={createOptions}
                createAction={createAction}
                title={title}
                updateHistoric={updateHistoric}
                action={action}
            />
            <br />
            <br />
            <br />
            <br />
        </>
    );
}

export default Keyword;
