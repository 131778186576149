
import React, {useContext, useEffect, useRef, useState} from "react";
import { useIntl } from "react-intl";
import { Box, Grow, Fade, CircularProgress, Dialog, DialogTitle, DialogContent } from "@mui/material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import uploadSvg from './upload.svg'
import useChatLocalState from "../../../hooks/openAI/useChatLocalState";
import { AuthContext } from "../../../contexts/auth";
import MyButton from "../../myButton";
import MyLabel from "../../myLabel";
import MyPanelHelp from "../../myPanelHelp";
import MyInput from "../../myInput";

import SavedChatsSelect from "./savedChatsSelect";

import { style } from "./styles";
import MyImage from "../../myImage";
import { array } from "prop-types";

function MessageDialog({open, name, handleClose, handleSuccess}) {
  
    return (
        <Dialog  open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={" "}  />
        </DialogTitle>
        <DialogContent>
          <Box sx={{margin:'30px'}}>
            <Box sx={{display: 'flex', flexDirection:'column',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                <Box sx={{marginBottom:'10px'}}>
                    <MyLabel component='p' text={"Enter a name for the chat."}  />
                </Box>
                <MyInput 
                    type='text'
                    size='small'
                    id='name'
                    label='Name'
                    required={true}
                    value={name.value}
                    onChange={(e)=>name.set(e.target.value)}
                />
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                    <MyButton 
                        sx={style.cancelButton}
                        onClick={handleClose}
                        text='return'
                        variant="contained" 
                        size="small"
                    />
                    <MyButton 
                        sx={style.saveButton}
                        onClick={()=> {handleSuccess(); handleClose()}}
                        text='Save'
                        variant="contained" 
                        size="small"
                    />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
}

const MessageArea = ({message, handleRemove, last }) =>{

    const isUser = message.role === 'user';
    return (
        <Grow  in={true} timeout={ last ? 600 : 0} >
            <Box sx={{...style.messageArea, backgroundColor: isUser ? '#ebebeb' : '#FFF'}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems:'center',
                    alignContent: 'center',
                    marginBottom: '10px',
                }}>
                    <Box sx={{...style.messageTitleArea, justifyContent: isUser ? 'end' : 'start'}}>
                        <MyLabel component='p' text={isUser ? 'YOU' : 'AI'} 
                        sx={{...style.title, color: isUser ? '#666670' : '#00a271'}} />
                    </Box>
                    <Box  sx={{
                        display: 'flex',
                        alignItems:'center',
                        alignContent: 'center',
                        }}>
                        <RemoveCircleOutlineIcon onClick={() => handleRemove(message)} sx={{ color: '#a3a3a3', cursor: 'pointer' }} />
                    </Box>
                </Box>
                <Box>
                    {
                        message.content[0]?.type === 'text' ?
                            message.content?.map((item, key)=>{
                                return (
                                    <Box>
                                        {item.type === 'text'? <MyLabel component='p' text={item.text} sx={{wordWrap: 'break-word', whiteSpace: 'break-spaces'}} noTranslate={true} /> : <></>}
                                        {item.type === 'image_url'? <MyImage src={item.image_url.url} sx={{width:'80px', height:'80px'}}  /> : <></>}
                                    </Box>
                                    )

                                }

                            )
                        
                        :
                            <MyLabel component='p' text={message.content} sx={{wordWrap: 'break-word', whiteSpace: 'break-spaces'}} noTranslate={true} />
                        
                    }
                </Box>

            </Box>
        </Grow >
    )
}

function Chat({ updateHistoric }) {

    let intl = useIntl();
    const isMobile = window.innerWidth < 600;
    const { historic } = useContext(AuthContext);
    const bottomOfMessages = useRef(null);
    const systemTextArea = useRef(null);
    const sendTextArea = useRef(null);
    const returnTextArea = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false)

    const [returnTextAreaFocus, setReturnTextAreaFocus] = useState(false)

    const onFocusReturnText = () => setReturnTextAreaFocus(true)
    const onBlurReturnText = () => setReturnTextAreaFocus(false)

    const [sendTextAreaFocus, setSendTextAreaFocus] = useState(false)

    const onFocusSendText = () => setSendTextAreaFocus(true)
    const onBlurSendText = () => setSendTextAreaFocus(false)

    const [systemTextAreaFocus, setSystemTextAreaFocus] = useState(false)

    const onFocusSystemText = () => setSystemTextAreaFocus(true)
    const onBlurSystemText = () => setSystemTextAreaFocus(false)

    const [help, setHelp] = useState({
        title: "Shape Your Chatbot's Identity",
        type: 1,
        open: false,
        top: 250,
        left: 500
    });

    const action = useChatLocalState(updateHistoric);

    const handlerCancel = ()=>{
        action.cancel.set(false)
    }

    useEffect(() => {
        action.getSaveChat.set()
    }, []);

    useEffect(() => {
        if (historic.value){
            action.sendText.set(historic.value.text);
            historic.set(null)
        }
    }, [historic.value]);

    useEffect(() => {

        if (returnTextArea.current){
            returnTextArea.current.style.height = '80px';
            returnTextArea.current.style.height = `${returnTextArea.current.scrollHeight}px`
        }
        bottomOfMessages.current.scrollTop = bottomOfMessages.current.scrollHeight;
    }, [action.returnText.value, action.talk.value]);

    const handleRemove = (item) => {
        let last = action.talk.value[action.talk.value.length-1]
        if (last && last.role === 'user' && !action.submit.value){
            action.sendText.set(last.content)
        }
        action.talk.set((prev) => [...prev.filter((i) => i !== item && i !== last)]);
    };

    const handleRemove2 = (item) => {
        action.returnText.set('');
    };

    const handleSystemAreaClick = () => {
        if (systemTextArea.current) {
            systemTextArea.current.focus();
        }
      }

      const handleSendTextAreaClick = () => {
        if (sendTextArea.current) {
            sendTextArea.current.focus();
        }
      }

      const handleReturnTextAreaClick = () => {
        
        if (returnTextArea.current) {
            returnTextArea.current.focus();
        }
      }

      const handlerHelp = (type) => {
        let h = {...help}
        if (type === 1){
            h.title = "Shape Your Chatbot's Identity"
            h.open = true
            h.top = 250
            h.left = 500
        }
        else if (type === 2){
            h.title = "Navigating Your Saved Chats"
            h.open = true
            h.top = 220
            h.left = 755
        }
        else if (type === 3){
            h.title = 'Preserve your progress with "Save Chat"'
            h.open = true
            h.top = 220
            h.left = 855
        }

        h.type = type
        h.open = true
        setHelp(h)
      }

      const saveChat = () => {
        action.postSaveChat.set()
      }
      
      const hiddenFileInput = useRef(null)

      const [hidePlaceholder, setHidePlaceholder] = useState(false)
      const handleUploadImage = (event) => {
        const [file] = event.target.files
        if (file) action.imageUploaded.set(file)
    }

      const changeSavedChats = (e) =>{
        let chat = action.saveChat.value.filter(i => i.id === e.target.value)[0]
        if (chat?.name === 'New Chat'){
            e.preventDefault()
            action.saveChatName.set('')
            action.returnText.set('')
            action.saveChatSelected.set('')
            action.system.set(chat.system)
            action.talk.set([])

        }
        else if (chat?.id > 0){
            action.saveChatName.set(chat.name)
            action.system.set(chat.system)
            action.talk.set(chat.messages)
            action.saveChatSelected.set(chat.id)
        }
        
      }

      const renderHelp = () => {

        if (help.type === 1){
            return (
                <Box>
                    <MyLabel component='p' text="In this section, you have the power to mold the chatbot's role and personality." />
                    <p>
                        {intl.formatMessage({ id: "Be it"})} {' '}
                        <span style={{fontWeight:'bold'}}>"{intl.formatMessage({ id: "You are an expert lawyer" })}"</span> 
                        {' '}
                        {intl.formatMessage({ id: " or "})}
                        {' '}
                        <span style={{fontWeight:'bold'}}>"{intl.formatMessage({ id: "You are a sarcastic and creative AI assistant" })}"</span>
                        {' '}
                        {intl.formatMessage({ id: ", the choice is entirely yours. Remember, a well-customized chatbot enhances AI engagement."})}
                    </p>
                    <MyLabel component='p' text="Let's breathe life into your chatbot!" />
                </Box>
            )
        }
        else if (help.type === 2){
            return (
                <Box>
                    <p>
                        {intl.formatMessage({ id: "In the"})} {' '}
                        <span style={{fontWeight:'bold'}}>"{intl.formatMessage({ id: "Saved Chats" })}"</span> 
                        {' '}
                        {intl.formatMessage({ id: "section, you’ll find all of your chatbot interactions that you’ve chosen to save. Whether you want to review a particularly successful conversation or pick up where you left off, it’s all here."})}
                    </p>
                    <MyLabel component='p' text="Simply click on a chat to view it in detail. You can easily switch between different saved chats as per your need." />
                    <br />
                    <MyLabel component='p' text="So, go ahead, leap back into your past conversations and make the most of this handy resource!" />
                </Box>
            )
        }
        else if (help.type === 3){
            return (
                <Box>
                    <MyLabel component='p' text="Found a chat you’d like to revisit or learn from?" />
                    <p>
                        {intl.formatMessage({ id: "By clicking on the"})} {' '}
                        <span style={{fontWeight:'bold'}}>"{intl.formatMessage({ id: "Save Chat" })}"</span> 
                        {' '}
                        {intl.formatMessage({ id: "button, you can easily store the current chatbot interaction for future reference."})}
                    </p>
                    <MyLabel component='p' text='Once saved, you can locate these chats in the "Saved Chats" section. This feature allows you to review your past conversations whenever you like, making it easier to track your progress and improve your chatbot’s performance.' />
                    <br />
                    <MyLabel component='p' text="Remember, every interaction is a learning opportunity, so don’t hesitate to save!" />
                </Box>
            )
        }

        return <></>

      }

    return (
        <Box sx={{
        width:'100%',
        display:'flex', 
        flexDirection:'row', 
        marginTop:'20px',
        '@media (max-width: 600px)': {
            flexDirection:'column', 
            width: '90vw',
        }}}>
            <Box 
                onClick={handleSystemAreaClick} 
                sx={{border: systemTextAreaFocus?'2px solid #00a271': 'none', display:'flex', backgroundColor:'#FFF', flexDirection:'column', height:'70vh', padding:'10px', '@media (max-width: 600px)': {height:'100px'}}}
                >
                <Box sx={{display: 'flex'}}>
                    <MyLabel component='p' text='Chatbot Identity' sx={style.title} />
                    <HelpOutlineIcon onClick={()=>handlerHelp(1)} sx={{marginBottom: '5px', height:'20px', cursor: 'pointer', }}/>
                </Box>
                <textarea 
                    ref={systemTextArea}
                    onFocus={onFocusSystemText}
                    onBlur={onBlurSystemText}
                    style={{outline: "none", fontSize: '12pt', resize: "none",height:'70vh', border:'none', '@media (max-width: 600px)': {height:'100px'}}} 
                    value={action.system.value !=='You are a helpful and creative assistant.'? action.system.value : ''} 
                    onChange={(e)=>action.system.set(e.target.value)}
                    placeholder={'You are a helpful and creative assistant.'}
                />
            </Box>
            <Box sx={{
                marginLeft:'20px', 
                width:'100%',
                '@media (max-width: 600px)': {
                    marginLeft:'0', 
                    marginTop:'10px', 
                }}}>
                <Box sx={{display:'flex', justifyContent:'space-between', marginBottom:'10px', width:'100%'}}>
                    <Box sx={{display:'flex', width:'190px'}}>
                        <SavedChatsSelect 
                            id='saved_chats'
                            label='Saved Chats'
                            options={action.saveChat.value}
                            size='small'
                            value={action.saveChatSelected.value}
                            onChange={changeSavedChats}
                        />
                        <HelpOutlineIcon onClick={()=>handlerHelp(2)} sx={{marginBottom: '5px', height:'20px', cursor: 'pointer', }}/>
                    </Box>
                    {
                        !isMobile ? 
                            <Box>
                                <Box sx={style.modelButton}
                                    onClick={()=>action.mini.set(!action.mini.value)}
                                >
                                    <Box
                                        sx={action.mini.value ? style.modelButtonSelected : style.modelButtonNotSelected}
                                    >
                                        <MyLabel
                                        component="p"
                                        text="GPT4o mini"
                                        sx={{color: action.mini.value ?  "purple" : 'black'}}
                                    />
                                    </Box>
                                    <Box
                                        sx={!action.mini.value ? style.modelButtonSelected : style.modelButtonNotSelected}
                                    >
                                    <MyLabel
                                        component="p"
                                        text="GPT4o"
                                        sx={{color: !action.mini.value ?  "purple" : 'black'}}
                                    />
                                    </Box>
                                </Box>
                            </Box>
                        : <></>
                    }
                    <Box>
                        <MyButton 
                            disabled={action.submit.value}
                            onClick={() => {
                                setDialogOpen(true);
                            }}
                            text='save chat'
                            variant="contained"
                            size="large"
                            sx={{
                                backgroundColor: '#00a271',
                                ':hover':{
                                    backgroundColor: '#11B382',
                                },
                            }}
                        />
                        <HelpOutlineIcon onClick={()=>handlerHelp(3)} sx={{marginBottom: '5px', height:'20px', cursor: 'pointer', }}/>
                    </Box>
                </Box>
                {
                        isMobile ? 
                            <Box sx={{justifyContent:'center', display:'flex'}}>
                                <Box sx={style.modelButton}
                                    onClick={()=>action.mini.set(!action.mini.value)}
                                >
                                    <Box
                                        sx={action.mini.value ? style.modelButtonSelected : style.modelButtonNotSelected}
                                    >
                                        <MyLabel
                                        component="p"
                                        text="GPT4o mini"
                                        sx={{color: action.mini.value ?  "purple" : 'black'}}
                                    />
                                    </Box>
                                    <Box
                                        sx={!action.mini.value ? style.modelButtonSelected : style.modelButtonNotSelected}
                                    >
                                    <MyLabel
                                        component="p"
                                        text="GPT4o"
                                        sx={{color: !action.mini.value ?  "purple" : 'black'}}
                                    />
                                    </Box>
                                </Box>
                            </Box>
                        : <></>
                    }
                <Box sx={style.messageContainer} ref={bottomOfMessages}>
                    {
                        action.talk.value.map((t, k) => {
                        
                        return <MessageArea key={k} message={t} handleRemove ={handleRemove} last={k===action.talk.value.length-1} />
                    
                    })
                    }
                    {
                        action.returnText.value !== '' ?
                            <Fade in={true} timeout={500} >
                                <Box onClick={handleReturnTextAreaClick} sx={{
                                    ...style.messageArea, 
                                    backgroundColor: '#FFF', 
                                    border: returnTextAreaFocus?'2px solid #00a271': 'none'
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems:'center',
                                        alignContent: 'center',
                                        marginBottom: '10px',
                                    }}>
                                        <Box sx={{...style.messageTitleArea, justifyContent: 'start'}}>
                                            <MyLabel component='p' text={'AI'} 
                                            sx={{...style.title, color: '#00a271'}} />
                                        </Box>
                                        <Box  sx={{
                                            display: 'flex',
                                            alignItems:'center',
                                            alignContent: 'center',
                                            }}>
                                            <RemoveCircleOutlineIcon onClick={() => handleRemove2('')} sx={{ color: '#a3a3a3', cursor: 'pointer' }} />
                                        </Box>
                                    </Box>
                                    <textarea 
                                        ref={returnTextArea}
                                        onFocus={onFocusReturnText}
                                        onBlur={onBlurReturnText}

                                        style={{
                                            width:'100%',
                                            border:'none',
                                            outline: "none", 
                                            resize: "none",
                                            backgroundColor: '#FFF',
                                            fontSize: '12pt',
                                            '@media (max-width: 600px)': {height:'300px'}
                                        }} 
                                        value={action.returnText.value} 
                                        onChange={(e)=>{
                                            action.returnText.set(e.target.value)
                                            returnTextArea.current.style.height = '80px';
                                            if (returnTextArea.current){
                                                returnTextArea.current.style.height = `${returnTextArea.current.scrollHeight}px`
                                            }
                                        }}
                                    />
                                </Box>
                            </Fade>

                        :
                        null
                    }
                </Box>
                <Box sx={{display:'flex', flexDirection:'column'}}>
                    {
                        action.submit.value ? 

                        <Box sx={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                            <Fade in={true} timeout={500} >
                                <CircularProgress 
                                    sx={{
                                        color: '#00a271',
                                    }}
                                    color="primary" 
                                />
                            </Fade>
                        </Box>
                        :
                        
                        <Fade in={true} timeout={500}>
                            <Box onClick={handleSendTextAreaClick} sx={{
                                ...style.sendArea,
                                border: sendTextAreaFocus?'2px solid #00a271': 'none'
                                }}>
                                <Box sx={style.messageTitleArea}>
                                    <Box sx={{display: 'flex', width:'100%', justifyContent: 'end' , marginRight:'10px'}}>
                                        <MyLabel component='p' text={'YOU'} 
                                        sx={style.title} />
                                    </Box>
                                </Box>
                                {action.imageUploaded.value &&  <MyImage src={URL.createObjectURL(action.imageUploaded.value)} sx={{width:'80px', height:'80px'}}  />}
                                <textarea 
                                    ref={sendTextArea}
                                    onFocus={onFocusSendText}
                                    onBlur={onBlurSendText}
                                    placeholder={intl.formatMessage({ id: "Enter a message here." })}
                                    style={{
                                        width:'100%',
                                        border:'none',
                                        outline: "none", 
                                        resize: "none",
                                        fontSize: '12pt',
                                        paddingLeft:"2.5rem",
                                        position:'relative',
                                        top:'3rem',
                                        '@media (max-width: 600px)': {height:'300px'},
                                        
                                    }} 
                                    value={action.sendText.value} 
                                    onChange={(e)=>{
                                        action.sendText.set(e.target.value)
                                        // sendTextArea.current.style.height = '80px';
                                        
                                        if (sendTextArea.current){
                                            if (sendTextArea.current.scrollHeight > 80 && sendTextArea.current.scrollHeight <=213){
                                                sendTextArea.current.style.height = `${sendTextArea.current.scrollHeight}px`
                                            }
                                            else if (sendTextArea.current.scrollHeight > 213){
                                                sendTextArea.current.style.height = `213px`
                                            }
                                        }
                                    }}
                                />
                                    <input style={{ display: 'none' }} onChange={handleUploadImage} ref={hiddenFileInput} type='file' accept='image/*' />
                                   
                                     
                                    
                                    
                                   
                                    <div>
                                        
                                    <MyImage onClick={() => hiddenFileInput.current.click()} src={uploadSvg} sx={{width:"25px", height:'25px', cursor:"pointer", position:'relative',}} />
                                   
                                    </div>
                                    

                                
                            </Box>
                        </Fade>

                    }
                    
                    <Box>

                        <MyButton
                            onClick={async () => {
                                if (action.submit.value){
                                    handlerCancel()
                                }
                                else {
                                    action.submit.set(updateHistoric);
                                }
                            }}
                            text={action.submit.value?'cancel':'submit'}
                            variant="contained"
                            size="large"
                            sx={
                                action.submit.value?
                                style.submitCancelButton
                                :
                                {
                                    backgroundColor: action.mini.value ? '#721384' : '#00a271',
                                    ':hover':{
                                        backgroundColor: action.mini.value ? '#9435A6' : '#11B382',
                                    },
                                }
                            }
                        />
                    </Box>
                </Box>
            </Box>
            <MyPanelHelp 
                title = {help.title}
                open = {help.open}
                onClose = {()=>{
                    let h = {...help}
                    h.open = false
                    setHelp(h)
                }}
                top = {help.top}
                left = {help.left} >
                {renderHelp()}
            </MyPanelHelp>
            <MessageDialog name={action.saveChatName} open={dialogOpen} handleClose={()=>setDialogOpen(false)} handleSuccess={saveChat} />
        </Box>
    );
}

export default Chat;
