import React from "react";

import checkImg from "../../assets/imgs/check.svg";
import PayPal from "../paypal";

import { style } from "./stylesSubscriptionPanel";
import { Box } from "@mui/material";
import MyImage from "../myImage/index.jsx";
import MyLabel from "../myLabel/index.jsx";


const Information = ({text}) => {
    return (
        <Box sx={style.informationArea}>
            <Box sx={style.inLine}>
                <MyImage src={checkImg} alt={'green circle check'} sx={style.icon} />
                <MyLabel component='p' text={text} sx={style.informationValue}  />
            </Box>
        </Box>
    )
}

const SubscriptionPanel = ({
    title,
    subscription,
    plan,
    closeModal,
    plan_id,
    paypalCancel,
    information,
    value,
    currency
}) => {
    return (
        <>
            <Box sx={{maxWidth:'400px'}}>
            {
                        title.includes('Light') ?
                        <>
                            <Information text = "Chat GPT-4o to included" />
                            <Information text = "10,000 characters every 2 hours of Chat GPT-4o mini" />
                            <Information text = "500,000 characters of GPT-4o to use with all tools" />
                            <Information text = "35 AI-generated images" />
                            <Information text = "Unlimited use of the English Grammar Check & Translator Tool" />
                        </>
                        : null
                    }
                    {
                        title.includes('Plus') ?
                        <>
                            <Information text = "- Access to a total of 1,000,000 characters for all tools (including Article Generator, AD Generator, Keyword Wizard, Product Name Generator, Topic Ideas Generator)" />
                            <Information text = "- Unlimited use of the Grammar Check & Translator Tool" />
                            <Information text = "- Generate up to 45 AI-designed images" />
                            <Information text = "- Link one social media account (which can include 1 profile each of Facebook, Facebook Group, Twitter/X, LinkedIn, Instagram)" />
                        </>
                        : null
                    }
                    {
                        title.includes('Max') ?
                        <>
                            <Information text = "- Access to a total of 1,500,000 characters for all tools (including Article Generator, AD Generator, Keyword Wizard, Product Name Generator, Topic Ideas Generator)" />
                            <Information text = "- Unlimited use of the Grammar Check & Translator Tool" />
                            <Information text = "- Generate up to 180 AI-designed images" />
                            <Information text = "- Link up to three social media accounts (each account can include 1 profile of Facebook, Facebook Group, Twitter/X, LinkedIn, Instagram)" />

                            
                        </>
                        : null
                    }
            </Box>
            <Box>
                <Box sx={style.panelPaypal}>
                    {subscription !== title || plan === "undefined" ? (
                        <PayPal
                            closeModal={closeModal}
                            plan_id={plan_id}
                            paypalCancel={paypalCancel}
                        />
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
        </>

    );
};
export default SubscriptionPanel;
