
import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {useDropzone} from 'react-dropzone';
import { FormattedMessage } from "react-intl";

import Box from '@mui/material/Box';

import MyLabel from "../../myLabel"
import MyButton from "../../myButton"
import svg from "../../../assets/imgs/update.svg";


const style ={
    container:{
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height:'50px',
        borderRadius: '4px',

        textTransform: 'uppercase',
        padding: '2px 8px',

        backgroundColor: '#721384',
        color: '#FFF',
        ':hover': {
            backgroundColor: '#783286',
        }
    },
    icon: {
        width: '30px',
        height:'30px',
    },
    p: {
        color: '#FFF',
        fontSize: '11pt',
        fontWeight: 500,
        padding: 0,
        margin: 0,
    }
}

const ButtonDropImage = ({image, setFile}) => {

    const validateFile = (file) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(async  file => {
               if (validateFile(file)) { 
                setFile(file)
                image.set(URL.createObjectURL(file))
               } else { toast.error(<FormattedMessage id='Supports jpg, png and gif' />)}
            })}});

      return (
      <Box sx={style.container} {...getRootProps({className: 'dropzone'})}>
        <MyLabel component='p' text='Upload another image' sx={style.p}  />
        <input {...getInputProps()} />
      </Box>
    );
}

export default ButtonDropImage;