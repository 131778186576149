import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import { AuthContext } from "../../../contexts/auth";

import MyModal from "../../../components/myModal";
import saveImage from "../../../utils/saveImage";
import icon01 from "../../../assets/imgs/icon01.svg";
import iconMaior from "../../../assets/imgs/maior.svg";

import MyImage from "../../../components/myImage";
import MyLabel from "../../../components/myLabel";
import MyButton from "../../../components/myButton";

import { style } from "./styles";

const ActionName = ({ action }) => {
    let title = "Text Generator";
    let color = "#F19149";
    let background = "rgb(242, 242, 242)";

    if (action === "Keyword") {
        title = "Keyword";
        color = "rgb(114, 19, 132)";
        background = "rgb(242, 242, 242)";
    } else if (action === "Essay outline") {
        title = "Topic Generator";
        color = "rgb(114, 19, 132)";
        background = "rgb(242, 242, 242)";
    } else if (action === "Product name generator") {
        title = "Name Product";
        color = "rgb(114, 19, 132)";
        background = "#FE65B6";
    } else if (action === "Dalle") {
        title = "Image";
        color = "rgb(114, 19, 132)";
        background = "#a7d0da";
    } else if (action === "English grammar check") { 
        title = "English grammar";
        color = "rgb(114, 19, 132)";
        background = "rgb(242, 242, 242)";
    } else if (action === "Chat") {
        title = "Chat";
        color = "#FFF";
        background = "#9061EF";
    } else if (action === "Ad from product description") {
        title = "AD Generator";
        color = "#992200";
        background = "#EE9051";
    }


    

    return (
        <Box sx={{...style.areaActionName, backgroundColor:background, color:color}}>
            <MyLabel component='p' text={title} sx={style.actionNameText}  />
        </Box>
    );
};

const ItemList = ({ hist, onClick }) => {
    
    let send = null
    try {
        send = JSON.parse(hist.send);
    }
    catch {
        
    }
    finally {

    }
    let title = send?.product;
    if (
        hist?.action === "Keyword" ||
        hist?.action === "Essay outline" ||
        hist?.action === "Dalle" ||
        hist?.action === "English grammar check" ||
        hist?.action === "Chat"
    ) {
        title = send?.text;
    } else if (hist.action === "Product name generator") {
        title = send?.description;
    }

    if (title?.length > 12) {
        title = title.slice(0, 12) + "...";
    }

    return (
        <ListItem disablePadding onClick={onClick}>
            <ListItemButton sx={style.areaListItem} >
                <Box >
                    <MyLabel component='p' text={title} sx={style.listItemText} noTranslate={true} />
                    <MyLabel component='p' text={hist.send_local_date} sx={style.listItemData} noTranslate={true} />
                </Box>
                <Box >
                    <ActionName action={hist.action} />
                </Box>
            </ListItemButton>
        </ListItem>
    );
};

const BasicModal = ({ action, send, textReturn, onClick, children }) => {
    return (
        <Box sx={style.modal}>
            <Box sx={style.modalTop}>
                <ActionName action={action} />
            </Box>
            <Box sx={style.modalTextArea}>
                <MyLabel component='p' text={send} sx={style.modalText} noTranslate={true} />
            </Box>
            {children}
            <Box>
                <MyLabel component='p' text={textReturn} sx={style.modalTextReturn}  noTranslate={true}  />
            </Box>
            <Box sx={style.modalButtonArea}>
                <MyButton size="small" onClick={onClick} text="Retry" variant="contained" sx={style.modalButton} />
            </Box>
        </Box>
    );
};

const BasicModalOptions = ({ title, value }) => {
    return (
        <Box sx={style.modalOptionLine} >
            <MyLabel component='p' text={title} sx={style.modalOptionTitle}  />
            <p style={style.modalOptionTitle}>:</p>
            <MyLabel component='p' text={value} sx={style.modalOptionDescription} noTranslate={true}  />
        </Box>
    );
};

const ProductNameModal = ({ setIsOpen, show, setHistoric, navigate }) => {
    let hist = JSON.parse(show?.send);

    
    const handleRpeat = (historic) => {

        let hist = {
            description: historic.description,
            seed_words: historic.seed_words,
            elements: historic.elements,
            language: historic.language,
        }

        navigate();
        setHistoric(hist)
        setIsOpen(false);
    };

    return (
        <BasicModal
            action={show?.action}
            send={hist?.description}
            textReturn={show?.result}
            onClick={() => handleRpeat(hist)}
        >
            <Box sx={style.optionsArea}>
                <BasicModalOptions title="Seeds" value={hist?.seed_words} />
                <BasicModalOptions title="Elements" value={hist?.elements} />
                <BasicModalOptions title="Language" value={hist?.language} />
                <BasicModalOptions
                    title="Characters"
                    value={show.characteres}
                />
                <BasicModalOptions title="Date" value={show?.send_local_date} />
            </Box>
        </BasicModal>
    );
};

const KeywordModal = ({ setIsOpen, show, setHistoric, navigate }) => {
    let hist = JSON.parse(show?.send);
    

    const handleRpeat = (historic) => {

        let hist = {
            text: historic?.text,
            objective: historic.objective,
            language: historic.language,
        }

        navigate();
        setHistoric(hist)
        setIsOpen(false);
    };

    return (
        <BasicModal
            action={show?.action}
            send={hist?.text}
            textReturn={show.result}
            onClick={() => handleRpeat(hist)}
        >
            <Box sx={style.optionsArea}>
                <BasicModalOptions title="Objective" value={hist?.objective} />
                <BasicModalOptions title="Language" value={hist?.language} />
                <BasicModalOptions
                    title="Characters"
                    value={show?.characteres}
                />
                <BasicModalOptions title="Date" value={show?.send_local_date} />
            </Box>
        </BasicModal>
    );
};

const EnglishModal = ({ setIsOpen, show, setHistoric,navigate }) => {
    let hist = JSON.parse(show?.send);

    const handleRpeat = (historic) => {

        let hist = {
            text: historic?.text,
        }

        navigate();
        setHistoric(hist)
        setIsOpen(false);
    };

    return (
        <BasicModal
            action={show?.action}
            send={hist?.text}
            textReturn={show?.result}
            onClick={() => handleRpeat(hist)}
        >
            <Box sx={style.optionsArea}>
                <BasicModalOptions
                    title="Characters"
                    value={show?.characteres}
                />
                <BasicModalOptions title="Date" value={show?.send_local_date} />
            </Box>
        </BasicModal>
    );
};

const ChatModal = ({ setIsOpen, show, setHistoric, navigate }) => {
    let hist = JSON.parse(show?.send);

    const handleRpeat = (historic) => {

        let hist = {
            text: historic?.text,
        }

        navigate();
        setHistoric(hist)
        setIsOpen(false);
    };

    return (
        <BasicModal
            action={show.action}
            send={hist?.text}
            textReturn={show.result}
            onClick={() => handleRpeat(hist)}
        >
            <Box sx={style.optionsArea}>
                <BasicModalOptions
                    title="Chat"
                    value={show?.characteres}
                />
                <BasicModalOptions title="Date" value={show?.send_local_date} />
            </Box>
        </BasicModal>
    );
};

const DalleModal = ({ setIsOpen, show, setHistoric, navigate }) => {
    let hist = JSON.parse(show?.send);
    let result = JSON.parse(show?.result);
    let images = result["urls"];
    const handleRpeat = (historic) => {

        let hist = {
            text: historic?.text,
            size: historic?.size,
            style: historic?.style,
        }

        navigate();
        setHistoric(hist)
        setIsOpen(false);
    };

    return (
        <Box sx={style.modal}>
            <Box sx={style.modalTop}>
                <ActionName action={show.action} />
            </Box>
            <Box sx={style.modalTextArea}>
                <MyLabel component='p' text={hist?.text} sx={style.modalText} noTranslate={true} />
            </Box>
            <Box sx={style.modalImageArea}>
                {images.map((img, key) => {
                            return (
                                <Box key={key} sx={style.modalImageCard}>
                                    <MyImage src={img} alt={`Image ${key}`} sx={style.modalImage} />
                                    <MyButton size="small" onClick={() => saveImage(img)} text="Download" variant="outlined" sx={style.modalButton} />
                                </Box>
                            );
                        })}
            </Box>
            <Box sx={style.optionsArea}>
                <BasicModalOptions title="Size" value={`${hist.size} x ${hist.size}`} />
                <BasicModalOptions title="Date" value={show.send_local_date} />
            </Box>
            <Box sx={style.modalButtonArea}>
                <MyButton size="small" onClick={() => handleRpeat(hist)} text="Retry" variant="contained" sx={style.modalButton} />
            </Box>
        </Box>
    );
};

const ADModal = ({ setIsOpen, show, setHistoric, navigate }) => {
    let hist = JSON.parse(show?.send);

    const handleRpeat = (historic) => {

        let hist = {
            product: historic?.product,
            tone: historic?.tone,
            type: historic?.type,
            platform: historic?.platform,
            target: historic?.target,
            ad: historic?.ad,
            sector: historic?.sector,
            the_amount: historic?.the_amount,
            language: historic?.language,
        }

        navigate();
        setHistoric(hist)
        setIsOpen(false);
    };

    return (
        <BasicModal
            action={show?.action}
            send={hist?.product}
            textReturn={show?.result}
            onClick={() => handleRpeat(hist)}
        >
            <Box sx={style.optionsArea}>
                <BasicModalOptions title="Product" value={hist?.product} />
                <BasicModalOptions title="Ad / Post" value={hist?.ad} />
                <BasicModalOptions title="Tone" value={hist?.tone} />
                <BasicModalOptions title="Platform" value={hist?.platform} />
                <BasicModalOptions title="Type" value={hist?.type} />
                <BasicModalOptions title="Target" value={hist?.target} />
                <BasicModalOptions title="Sector" value={hist?.sector} />
                <BasicModalOptions title="Amount" value={hist?.the_amount} />
                <BasicModalOptions title="Tone" value={hist?.tone} />
                <BasicModalOptions title="Language" value={hist?.language} />
                <BasicModalOptions
                    title="Characters"
                    value={show.characteres}
                />
                <BasicModalOptions title="Date" value={show.send_local_date} />
            </Box>
        </BasicModal>
    );
};

const ArticlePost = ({ setIsOpen, show, setHistoric, navigate }) => {
    let hist = JSON.parse(show?.send);

    const handleRpeat = (historic) => {

        let hist = {
            text: historic?.product,
            the_amount: historic?.the_amount,
            target: historic?.target,
            language: historic?.language,
            target_object: historic?.target_object,
            tone: historic?.tone,
        }

        navigate();
        setHistoric(hist)
        setIsOpen(false);
    };

    return (
        <BasicModal
            action={show?.action}
            send={hist?.text}
            textReturn={show?.result}
            onClick={() => handleRpeat(hist)}
        >
            <Box sx={style.optionsArea}>
                <BasicModalOptions title="Type" value={hist?.target_object} />
                <BasicModalOptions title="The Amount" value={hist?.the_amount} />
                <BasicModalOptions title="Tone" value={hist?.tone} />
                <BasicModalOptions title="Target" value={hist?.target} />
                <BasicModalOptions title="Language" value={hist?.language} />
                <BasicModalOptions
                    title="Characters"
                    value={show?.characteres}
                />
                <BasicModalOptions title="Date" value={show?.send_local_date} />
            </Box>
        </BasicModal>
    );
};

const EssayModal = ({ setIsOpen, show, setHistoric, navigate }) => {
    let hist = JSON.parse(show?.send);

    const handleRpeat = (historic) => {
        let hist = {
            text: historic?.text,
            type: historic?.type,
            target: historic?.target,
            language: historic?.language,
        }

        navigate();
        setHistoric(hist)
        setIsOpen(false);
    };

    return (
        <BasicModal
            action={show?.action}
            send={hist?.text}
            textReturn={show?.result}
            onClick={() => handleRpeat(hist)}
        >
            <Box sx={style.optionsArea}>
                <BasicModalOptions title="Type" value={hist?.type} />
                <BasicModalOptions title="Target" value={hist?.target} />
                <BasicModalOptions title="Language" value={hist?.language} />
                <BasicModalOptions
                    title="Characters"
                    value={show?.characteres}
                />
                <BasicModalOptions title="Date" value={show?.send_local_date} />
            </Box>
        </BasicModal>
    );
};

const BodyModal = ({ setIsOpen, show, setHistoric, navigate }) => {

    if (show) {
        if (show.action === "Ad from product description") {
            return (
                <ADModal
                    setIsOpen={setIsOpen}
                    show={show}
                    setHistoric={setHistoric}
                    navigate={()=>navigate('/ad')}
                />
            );
        } else if (show.action === "Essay outline") {
            return (
                <EssayModal
                    setIsOpen={setIsOpen}
                    show={show}
                    setHistoric={setHistoric}
                    navigate={()=>navigate('/topics')}
                />
            );
        } else if (show.action === "Article post generator") {
            return (
                <ArticlePost
                    setIsOpen={setIsOpen}
                    show={show}
                    setHistoric={setHistoric}
                    navigate={()=>navigate('/articles')}
                />
            );
        } else if (show.action === "Keyword") {
            return (
                <KeywordModal
                    setIsOpen={setIsOpen}
                    show={show}
                    setHistoric={setHistoric}
                    navigate={()=>navigate('/keywords')}
                />
            );
        } else if (show.action === "Product name generator") {
            return (
                <ProductNameModal
                    setIsOpen={setIsOpen}
                    show={show}
                    setHistoric={setHistoric}
                    navigate={()=>navigate('/products')}
                />
            );
        } else if (show.action === "Dalle") {
            return (
                <DalleModal
                    setIsOpen={setIsOpen}
                    show={show}
                    setHistoric={setHistoric}
                    navigate={()=>navigate('/image')}
                />
            );
        } else if (show.action === "English grammar check") {
            return (
                <EnglishModal
                    setIsOpen={setIsOpen}
                    show={show}
                    setHistoric={setHistoric}
                    navigate={()=>navigate('/translator')}
                />
            );
        } else if (show.action === "Chat") {
            return (
                <ChatModal
                    setIsOpen={setIsOpen}
                    show={show}
                    setHistoric={setHistoric}
                    navigate={()=>navigate('/chat')}
                />
            );
        } else {
            return <></>;
        }
    } else {
    }
};

function Historic({ historics, open, setOpen }) {
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState("");
    let navigate = useNavigate();

    const { historic } = useContext(AuthContext);

    const render_historics = () => {
        if (historics.length > 0) {
            return (
                <>
                    <Box>
                        <List sx={style.list}>
                            {historics.map((hist, key) => (
                                    <ItemList
                                        key={key}
                                        onClick={() => {
                                            setShow(hist);
                                            setIsOpen(true);
                                        }}
                                        id={key}
                                        hist={hist}
                                    />
                                ))}
                        </List>
                    </Box>
                </>
            );
        }

        return (
            <Box>
                <MyLabel component='h4' text="History not found" sx={style.title}  /> 
            </Box>
        );
    };
    return (
        <Box sx={style.area}>
            <Box sx={style.areaTitle} >
                <MyImage onClick={() => setOpen(!open)} src={iconMaior} alt={'icon'} sx={style.icon} />
                <MyLabel component='h4' text="Latest Generations" sx={style.title}  /> 
                <MyImage src={icon01} alt={'icon 2'} sx={style.icon2} />
            </Box>
            {render_historics()}
            <MyModal title="Historic" isOpen={isOpen} setIsOpen={setIsOpen} margin={0}>
                <BodyModal
                    setIsOpen={setIsOpen}
                    show={show}
                    setHistoric={historic.set}
                    navigate={navigate}
                />
            </MyModal>
        </Box>
    );
}

export default Historic;
