import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { AuthContext } from "../../contexts/auth";
import { openAILocal } from "../../services/openAI/openAILocal";
import { completions } from "../../services/openAI/completions";

const useEnglishGrammarCheckLocalState = () => {
    const api = 5;
    const { signOut } = useContext(AuthContext);

    const [sendText, setSendText] = useState("");
    const [returnText, setReturnText] = useState("");
    const [submit, setSubmit] = useState(false);

    const createMessage = () =>{

        let text = `Correct this to standard English:

${sendText}.

`

        let message = []
        message.push({'role':'system', "content": "You will be provided with statements, and your task is to convert them to standard English."})
        message.push({'role':'user', "content": text})
        return message;
    }

    const EnglishGrammar = async (config, updateHistoric) => {

        let messages = createMessage();

        let send = JSON.stringify({
            text: sendText.trim()
        });

        completions(api, messages, config, send, updateHistoric, setReturnText, setSubmit)
    }

    const handleAPI = async (updateHistoric) => {
        if (submit) {
            return;
        }

        let isSendTextValid = false;

        if (sendText.length > 4000) {
            toast.error(
                <FormattedMessage id="More than 4000 characters typed" />
            );
        } else if (sendText !== "") {
            isSendTextValid = true;
        } else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }

        if (isSendTextValid) {
            setSubmit(true);
            openAILocal(sendText, api, setSubmit, signOut, updateHistoric, EnglishGrammar)
        }
    };

    return {
        sendText: {
            value: sendText,
            set: setSendText,
        },
        returnText: {
            value: returnText,
            set: setReturnText,
        },
        submit: {
            value: submit,
            set: handleAPI,
        },
    };
};

export default useEnglishGrammarCheckLocalState;
