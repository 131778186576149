import { useState, useContext } from "react";
import { AuthContext } from "../contexts/auth";

import { FormattedMessage } from "react-intl";
import { getPanelDetail } from "../services/getPanelDetail";
import { getSubscriptionType } from '../services/getSubscriptionType';

import toast from "react-hot-toast";

const usePricingState = () => {
    const { signOut, user } = useContext(AuthContext);

    const [pricings, setPricings] = useState([]);
    const [currency, setCurrency] = useState("EUR");
    const [pricing, setPricing] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [get, setGet] = useState(false);

    const handleAPI = async () => {
        if (submit) {
            return;
        }
        setSubmit(true);
        getPanelDetail()
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    }
                }
                setSubmit(false);
                //setUserDetail(json.data);
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast("Connection failed to server");
                } else {
                    toast([<FormattedMessage key={4} id={error.message} />]);
                }
                setSubmit(false);
            });
    };

    const getPrincings = async () =>{
        if (get) {
            return;
        }
        setGet(true);

        let c = 'euro'

        if (currency === 'EUR'){
            c = 'euro'
        } else if (currency === 'USD'){
            c = 'dollar'
        } else  if (currency === 'BRL'){
            c = 'real'
        }

        getSubscriptionType(c)
            .then(async (json) => {
                if (json?.type === "error") {
                    if (json.data.code === "token_not_valid") {
                        signOut();
                    }
                }
                setGet(false);
                setPricing(json.data.user_subscription);
                setPricings(json.data.data);
            })
            .catch((error) => {
                if (error.message === "NetWork request failed") {
                    toast("Connection failed to server");
                } else {
                    toast([<FormattedMessage key={4} id={error.message} />]);
                }
                setGet(false);
            });
    }

    return {
        pricing: {
            value: pricing,
            set: setPricing,
        },
        pricings: {
            value: pricings,
            set: setPricings,
        },
        currency: {
            value: currency,
            set: setCurrency,
        }, 
        getPrincings: {
            value: get,
            set: getPrincings,
        },
        updateUserDetail: {
            value: submit,
            set: handleAPI,
        },
    };
};

export default usePricingState;
