import { Box, Switch } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyLabel from '../../components/myLabel'
import MyInputText from '../../components/myInputText'
import MyButton from '../../components/myButton'
import SocialIcon from '../../components/ayrshare/socialIcons'
import CHANNELS_NEW from '../../components/ayrshare/channelsNew'
import useProfileState from '../../hooks/ayrshare/useProfileState'
import iconMaior from "../../assets/imgs/maior.svg"
import MyImage from '../../components/myImage'
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import { AuthContext } from '../../contexts/auth'
import useSettingsState from '../../hooks/ayrshare/planner/useSettingsState'
import Loading from '../../components/loading'
import { useNavigate } from 'react-router-dom';


const styles = {
    text:{
        fontSize: '16pt',
        '@media (max-width: 600px)': {
            fontSize: '11pt',
        } 
    },
    creditText: { 
        color: '#22A06B', 
        fontWeight: 600, 
        marginRight: '0.5rem', 
    }
}
const PlannerSettings = () => {

    const states = useSettingsState()
    const navigate = useNavigate();

    let credits = states.user.value && states.user.value ? states.user.value?.tokens - states.user.value?.tokens_used : 0
    let credits_total = states.user.value ? states.user.value?.tokens : 0


    let images = states.user.value && states.user.value ? states.user.value?.images - states.user.value?.images_used : 0
    let images_total = states.user.value ? states.user.value?.images : 0

    
    const [collapsedBrands, setCollapsedBrands] = useState([])
    const { socials, url } = useProfileState()
    const [brands, setBrands] = useState([{
        title:'AdMind for business'
    }])

    useEffect(()=>{
        states.submit.set()
    },[])

    
    return <>
        <Box sx={{ width: '100%' }}>
            <Loading render={states.submit.value} />

            <Box sx={{ backgroundColor: 'white', padding: '2rem 2rem 0 2rem', width: '100%', textAlign: 'left', }}>
                <MyLabel sx={{ fontWeight: 600, fontSize: '18pt' }} text={'Monthly usage'} />

            </Box>

            <Box sx={{
                backgroundColor: 'white', padding: '2rem', width: '100%', display: 'flex', flexDirection: 'row',
                justifyContent:'space-between',
                '@media (max-width: 600px)': {
                    flexDirection: 'column',
                }
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between' }}>
                        <MyLabel sx={styles.text} text={'Available credits:'} />
                        <Box>
                            <MyLabel component={'span'} sx={{...styles.text,...styles.creditText}} text={credits} noTranslate={true} /> 
                            <MyLabel component={'span'} sx={styles.text} text={`/ ${credits_total}`} noTranslate={true} /> 
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between' }}>
                    <MyLabel sx={{ ...styles.text, marginRight: '5px' }} text={'Available images:'} />
                    <Box>
                        <MyLabel component={'span'} sx={{...styles.text,...styles.creditText}} text={images} noTranslate={true} /> 
                        <MyLabel component={'span'} sx={styles.text} text={`/ ${images_total}`} noTranslate={true} /> 
                    </Box>
                </Box>
            </Box>
            <Box sx={{ float: 'left' }}>
                
                <MyLabel sx={{ fontSize: '10pt', marginTop: '1rem', color: 'grey' }} text={'YOUR PLAN RENEWS ON'} /> {/* insert date here */}
            </Box>
            <Box sx={{ position:'relative', textAlign: 'center', width: '100%', marginTop: '5rem' }}>
                <ArrowBackIcon onClick={()=>navigate(-1)} fontSize={'large'} sx={{ position:'absolute', left: '10px', cursor:'pointer'}} />

                <MyLabel sx={{ fontWeight: 600, fontSize: '16pt', }} text={'YOUR BRANDS'} />

            </Box>
            <Box>
                {states.brands.value?.map((myBrand) => {
                
                    let title = myBrand.name
                    return <>
                        <Box sx={{ height: collapsedBrands.includes(title) ? '15vh' : 'fit-content', backgroundColor: 'white', padding: '2rem', width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '12px', marginTop: '1rem' }}>

                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                                <Box onClick={() => 
                                    {
                                        if (collapsedBrands.filter(b=> b === title).length > 0){
                                            setCollapsedBrands([])
                                        }else{
                                            setCollapsedBrands([title])
                                        }
                                    
                                    }
                                    
                                    }>
                                    <MyImage src={iconMaior} alt={'icon'} sx={{ float: 'right', height: '18px', transform: collapsedBrands.includes(title) ? 'rotate(90deg)' : 'rotate(-90deg)', cursor: 'pointer' }} />
                                </Box>

                                <Box>


                                    <MyLabel sx={{ fontWeight: 600, fontSize: '16pt', marginBottom: '2rem' }} text={title} />
                                
                                        <Box width={'100%'}>
                                        <Collapse orientation="vertical" in={!collapsedBrands.includes(title)} sx={{minWidth:'100%'}}>


                                            <MyLabel sx={{ fontSize: '14pt' }} text={'Enter the name of your brand or business'} />
                                            <MyInputText
                                                sx={{ backgroundColor: 'white', width: '100%' }}
                                                minRows={1}
                                                type='text'

                                                id="Enter the name of your brand or business"

                                                required={true}
                                                value={myBrand.name}
                                                onChange={(e) =>{
                                                        let _brands = states.brands.value.map(_b => {
                                                            if (_b.id === myBrand.id){
                                                                return {..._b, name:e.target.value}    
                                                            }
                                                            return _b
                                                        })
                                                        states.brands.set(_brands)
                                                    }
                                                }


                                            />
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '2rem' }}>
                                                <Box width={'50%'} marginRight={4}>
                                                    <MyLabel sx={{ fontSize: '14pt' }} text={'Do you have a website? Enter it here'} />
                                                    <MyInputText
                                                        sx={{ backgroundColor: 'white', width: '100%' }}
                                                        minRows={1}
                                                        type='text'

                                                        id='Do you have a website? Enter it here'

                                                        required={true}
                                                        value={myBrand.site}
                                                        onChange={(e) =>{
                                                                let _brands = states.brands.value.map(_b => {
                                                                    if (_b.id === myBrand.id){
                                                                        return {..._b, site:e.target.value}    
                                                                    }
                                                                    return _b
                                                                })
                                                                states.brands.set(_brands)
                                                            }
                                                        }

                                                    />
                                                </Box>
                                                <Box width={'50%'}>
                                                    <MyLabel sx={{ fontSize: '14pt' }} text={'Which language do you want the content in?'} />
                                                    <MyInputText
                                                        sx={{ backgroundColor: 'white', width: '100%' }}
                                                        minRows={1}
                                                        type='text'

                                                        id='Which language do you want the content in?'

                                                        required={true}
                                                        value={myBrand.language}
                                                        onChange={(e) =>{
                                                                let _brands = states.brands.value.map(_b => {
                                                                    if (_b.id === myBrand.id){
                                                                        return {..._b, language:e.target.value}    
                                                                    }
                                                                    return _b
                                                                })
                                                                states.brands.set(_brands)
                                                            }
                                                        }

                                                    />
                                                </Box>

                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '2rem' }}>
                                                <Box width={'100%'} marginRight={4}>
                                                    <MyLabel sx={{ fontSize: '14pt' }} text={'Describe your business in more detail; the more information you provide, the better the AI will be at creating customized content for you!'} />
                                                    <MyInputText
                                                        sx={{ backgroundColor: 'white', width: '100%' }}
                                                        minRows={12}
                                                        type='text'

                                                        id="Enter the name of your brand or business"

                                                        required={true}
                                                        value={myBrand.description}
                                                        onChange={(e) =>{
                                                                let _brands = states.brands.value.map(_b => {
                                                                    if (_b.id === myBrand.id){
                                                                        return {..._b, description:e.target.value}    
                                                                    }
                                                                    return _b
                                                                })
                                                                states.brands.set(_brands)
                                                            }
                                                        }

                                                    />
                                                </Box>


                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2rem' }}>
                                                <Box width={'100%'} marginRight={4}>
                                                    <MyLabel sx={{ fontSize: '14pt' }} text={`Click on the icon to connect your social accounts`} />

                                                </Box>
                                                <Box sx={{
                                                    display: 'flex', flexDirection: 'row', float: 'left', justifyContent: 'space-evenly',
                                                    '@media (max-width: 600px)': {
                                                        width:'100%',
                                                        justifyContent: 'center',
                                                        display: 'grid',
                                                        'grid-template-columns': '1fr 1fr  1fr',
                                                        gap: '0px'
                                                        }
                                                }}>
                                                    {
                                                        CHANNELS_NEW.map((channel, key) => {
                                                            let sx = { height: '60px', width: '60px', };

                                                            if (!channel.active) {
                                                                sx = { ...sx, opacity: '0.3' };
                                                            }
                                                            return (
                                                                <Box sx={{ margin: 0, position: 'relative' }} key={key} onClick={() => { myBrand.socials?.filter(item => item === channel.icon).length === 0 && window.open(myBrand.url, '_blank'); }}>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent:'center' }}>
                                                                        <Box sx={{display: 'flex', marginTop: '3em', 
                                                                            alignItems:'center',
                                                                            alignContent:'center',
                                                                            justifyContent:'center',
                                                                            width: '100%',

                                                                            '@media (max-width: 600px)': {
                                                                                alignItems:'center',
                                                                                alignContent:'center',
                                                                                justifyContent:'center',
                                                                                width: '60px',
                                                                            }}}>
                                                                            <SocialIcon sx={{
                                                                                ...sx,
                                                                                
                                                                            }} icon={channel.icon} linked={myBrand.socials?.filter(item => item === channel.icon).length > 0} />
                                                                        </Box>
                                                                        <Box sx={{width:'100%'}}>
                                                                            <MyButton
                                                                                sx={{
                                                                                    backgroundColor: myBrand.socials?.filter(item => item === channel.icon).length > 0 ? 'green' : 'grey',
                                                                                    marginTop: '1rem',
                                                                                    fontSize: '0.8rem',

                                                                                    '@media (max-width: 600px)': {
                                                                                        fontSize: '0.5rem',
                                                                                    },
                                                                                    ':disabled': {
                                                                                        backgroundColor: "green",
                                                                                        color: 'white'
                                                                                    }

                                                                                }}
                                                                                disabled={myBrand.socials?.filter(item => item === channel.icon).length > 0}
                                                                                text={myBrand.socials?.filter(item => item === channel.icon).length > 0 ? 'Connect' : 'Connect'}
                                                                                variant="contained"
                                                                                size="small"
                                                                            />
                                                                        </Box>
                                                                        {myBrand.socials?.filter(item => item === channel.icon).length > 0 && <Box sx={{ top: '0.2rem', display: 'flex', justifyContent:'center', }}>
                                                                            <MyLabel onClick={() => { myBrand.socials?.filter(item => item === channel.icon).length === 1 && window.open(myBrand.url, '_blank'); }} component='p' text='Disconnect' sx={{ fontWeight: 500, textDecoration: 'underline', fontSize: '0.8rem', }} />
                                                                        </Box>
                                                                        }
                                                                    </Box>
                                                                    <Box sx={{ position: 'absolute', padding: '5px', width: '100px', display: 'none' }}>
                                                                        <MyLabel component='p' text='update soon' sx={{ fontWeight: 500, fontStyle: 'italic' }} />
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        })
                                                    }


                                                </Box>

                                            </Box>

                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '3rem' }}>
                                                <Box width={'100%'} marginRight={4} display={'flex'} flexDirection={'row'}>
                                                    <Switch 
                                                        checked={myBrand.active} 
                                                        onChange={(e) =>{
                                                            let _brands = states.brands.value.map(_b => {
                                                                if (_b.id === myBrand.id){
                                                                    return {..._b, active:!_b.active}    
                                                                }
                                                                return _b
                                                            })
                                                            states.brands.set(_brands)
                                                        }
                                                    }
                                                        sx={{ position: 'relative', bottom: '0.4rem' }} defaultChecked />
                                                    <MyLabel sx={{ fontSize: '14pt', color: 'grey' }} text={`Automatic compilation of your monthly editorial plan`} />

                                                </Box>
                                            </Box>

                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '3rem' }}>
                                                <Box width={'100%'} marginRight={4} display={'flex'} flexDirection={'row'} sx={{
                                                    justifyContent: 'right',
                                                    '@media (max-width: 600px)': {
                                                        justifyContent: 'center',
                                                    },
                                                }}>
                                                    <MyButton
                                                        sx={{ backgroundColor: 'lightgrey', width: '120px' }}

                                                        disabled={false}
                                                        text='cancel'
                                                        variant="contained"
                                                        size="medium"
                                                    />

                                                    <MyButton
                                                        onClick={()=>states.update.set(myBrand)}
                                                        sx={{ width: '120px', marginLeft: '1rem' }}
                                                        disabled={states.update.value}

                                                        text='Save'
                                                        variant="contained"
                                                        size="medium"
                                                    />

                                                </Box>
                                            </Box>
                                            </Collapse>
                                        </Box>
                                        

                                
                                </Box>
                            </Box>






                        </Box>
                        



                    </>

                })}
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2rem 0' }}>
                            <Box  width={'100%'} marginRight={4} display={'flex'} flexDirection={'row'} sx={{ color: 'rgb(114 18 133)', cursor:'pointer' }}>
                                <AddIcon /> Aggiungi un nuovo brand

                            </Box>
                        </Box>
            </Box>
        </Box>
    </>

}

export default PlannerSettings 