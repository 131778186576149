import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import SchoolIcon from '@mui/icons-material/School';
import { useIntl } from "react-intl";


const MyInput = (props) => {

    let intl = useIntl();

    let label = props.label;
    label = label && label !=='' ? intl.formatMessage({ id: label }):'';

    let placeholder = props.placeholder;
    
    placeholder = placeholder && placeholder !=='' ? intl.formatMessage({ id: placeholder }):'';

    let icon = props.icon

    if (!icon){
        return <TextField {...props} label={label} placeholder={placeholder} variant="outlined" />

    }
    else {

        let IconConponent = AccountCircle

        if (icon ==='password'){
            IconConponent = LockIcon
        }
        else if (icon ==='school'){
            IconConponent = SchoolIcon
        }

        return (
            <TextField 
                
                {...props} 
                label={label} 
                placeholder={placeholder}
                variant="outlined" 
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconConponent />
                      </InputAdornment>
                    ),
                  }}
            />
        )
    }

};

export default MyInput;
