const stack =  {
    width:'100%', 
    '@media (max-width: 600px)': {
        marginLeft:'0px', 
        marginTop:'20px'}
}

export const style = {
    stack: stack,
    titleArea:{
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection:'row',
        padding: '10px'
    },
    formRow:{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',
          }
    },
    formRowButton: {
        display:'flex',
        justifyContent: 'right',
        paddingTop: '20px',
    },
    formColumn:{
        display:'flex',
        flexDirection:'column',
        gap: '10px',
    },
    form:{
        marginTop: '10px',
    },
    titleAreaDescription:{
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection:'row',

    },
    panelTitleArea:{
        display:'flex',
        alignItems: 'center',
        flexDirection:'row'

    },
    monthArea:{
        width:'100%',
        display:'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexDirection:'row',
        marginBottom: '20px'
    },
    title:{
        margin: '0px',
        padding: '0px',
        fontSize: '15pt',
        fontWeight: 600,
        marginBottom: '10px'
    },
    titleDescription:{
        margin: '0px',
        padding: '0px',
        fontSize: '13pt',
        marginRight: '5px',

    },
    upgradeButton:{
        backgroundColor: 'rgb(240, 115, 122)',
        ':hover':{
            backgroundColor: 'rgb(240, 115, 122)',
        }
    },
    cancelButton:{
        backgroundColor: 'red',
    },
    panel:{
        display:'flex',
        flexDirection:'column',
        justifyContent: 'space-between',
    },
    icon:{
        width: '50px',
        marginRight: '20px'
    },
    monthUsageTitle:{
        fontSize: '12pt',
        fontWeight: '600',
    },
    monthDescriptionArea:{
        margin: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    monthUsageDescription:{
        fontSize: '10pt',
        width: '50%',
        '@media (max-width: 600px)': {
            width: '100%',
          }
    },
    monthUsageValue:{
        fontSize: '10pt',
    },
    expansiveIcon:{
        marginLeft: '10px',
        width: '10px',
        marginBottom: '8px',
    },
}