import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { getTemplatePost } from "../../../services/ayrshare/getTemplatePost";
import { putTemplatePost } from "../../../services/ayrshare/putTemplatePost";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

const useSettingsState = () => {

    //const { user } = useContext(AuthContext);

    let [submit, setSubmit] = useState(false);
    let [user, setUser] = useState(null);
    let [brands, setBrands] = useState([]);

    const handlerSubmit = async () =>{
        if (submit){
            return 
        }
        setSubmit(true)
        let result = await getTemplatePost();
        if (result.data.code !== 404){
            setUser(result.data.user)
            setBrands(result.data?.posts_template.map(item=>{return {...item, socials:result.data?.socials, url:result.data?.url}}))
        }
        setSubmit(false)

    }

    const handlerUpdate = async (brand) =>{
        if (brand){
            setSubmit(true)
            let result = await putTemplatePost(brand)
            if (result.type === 'success'){
                toast.success(
                    <FormattedMessage id="brand updated successfully" />
                );
            }
            else{
                toast.error(
                    <FormattedMessage id={"unknown error"} />
                );
            }
            setSubmit(false)
        }
    }

    return {
        user: {value: user, set:null},
        brands: {value: brands, set:setBrands},
        update: {set:handlerUpdate, value: submit},
        submit: {set:handlerSubmit, value: submit}
    }

}

export default useSettingsState