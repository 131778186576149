export const style = {
    area: {
        width:'73vw',
        padding: '20px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '10px',
        '@media (max-width: 600px)': {
            width:'100%',
            gridTemplateColumns: '1fr',
          }
          
    },

}