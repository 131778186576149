import React from 'react'
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import MyLabel from "../myLabel";


import { style } from "./styles";



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const MyPanelHelp = ({ title, children, open, onClose, top, left }) => {

    let card = style.card
    if (window.innerWidth < 600) {
        card = {...card, bottom:0, left:6};
    }
    else {
        card = {...card, top:top, left:left};
    }

    if (window.innerWidth < 600) {
        return (
            <Dialog  open={open} onClose={onClose}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    
                            <Box sx={style.titleArea} >
                                <MyLabel component='h5' text={title} sx={style.title}  />
                                <CloseIcon onClick={onClose} sx={{cursor: 'pointer', marginRight:'5px', color:'#b0b0b0'}} />
                            </Box>
                            <Box sx={style.descriptionArea} >
                                {children}
                            </Box>
                    
                </DialogContent>
            </Dialog>
        )
    }

    else {

        return (
            <Fade in={open} timeout={500}>
                <Card  sx={card}>
                    <CardContent sx={style.content}>
                        <Box sx={style.titleArea} >
                            <MyLabel component='h5' text={title} sx={style.title}  />
                            <CloseIcon onClick={onClose} sx={{cursor: 'pointer', marginRight:'5px', color:'#b0b0b0'}} />
                        </Box>
                        <Box sx={style.descriptionArea} >
                            {children}
                        </Box>
                    </CardContent>
                </Card>
            </Fade>
        )
    }
};

export default MyPanelHelp;
