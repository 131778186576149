import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import * as C from "./styles";

const Loading = ({ render }) => {

    return (
    <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={render}
    >
        <CircularProgress color="primary" />
    </Backdrop>)
    /*
    if (render) {
        return (
            <C.Content>
                <Digital color="#9061ef" size={45} speed={1} />
            </C.Content>
        );
    } else {
        return <></>;
    }*/
};
export default Loading;
