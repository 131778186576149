import { useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { AuthContext } from "../../contexts/auth";
import defLanguage from "../../utils/defLanguage";
import { openAILocal } from "../../services/openAI/openAILocal";
import { completions } from "../../services/openAI/completions";
import { getSaveChat } from "../../services/openAI/getSaveChat";
import { postSaveChat } from "../../services/openAI/postSaveChat";


const messageChecking = (message, setTalk) =>{

    let system = message[0]
    let men = message.reverse()
    let menReturn = []
    let tokens = 0;
    men.forEach(m => {
        tokens += m.content.length / 4;
        
        if (tokens < 3800 && m.role !== 'system')
        {
            menReturn.push(m)
        }
    });
    menReturn.push(system)
    menReturn.reverse()
    setTalk(menReturn.filter(i=> i.role !== 'system'))
    return menReturn

}

const createMessage = async (text, returnText, talk, setTalk, system, image) => {
    let lines = text;
    
    let talks = [...talk]
    if (returnText !== '' && talk[talk.length-1].content !== returnText){ 
        talks.push({'role':'assistant', "content": returnText})
    }

    await new Promise((resolve, reject)=>{
        if (lines !== ''){
            if (image) {
                let reader = new FileReader();
                reader.readAsDataURL(image)
                reader.onload = () => {
                    
                    talks.push({'role':'user', "content": [
                            {"type": 'text', "text": lines },
                            {"type": 'image_url', "image_url": {'url': reader.result} },
                        ]
                    })
                    resolve()
                }
               
            }else {
                talks.push({'role':'user', "content": lines})
                resolve()
            }
            
        }

    })
    
    let message = messageChecking([{'role': 'system', "content": system}, ...talks], setTalk)
    
    if (message.length <= 1){
        return false
    }
    else{
        
        return message;
    }
}


const useChatLocalState = (updateHistoric) => {
    const api = 7;
    const { signOut, language } = useContext(AuthContext);

    let lang = defLanguage(language.value);

    const [sendText, setSendText] = useState("");
    const [returnText, setReturnText] = useState("");

    const [talk, setTalk] = useState([]);
    const [system, setSystem] = useState("You are a helpful and creative assistant.")
    const [saveChat, setSaveChat] = useState(['New Chat']);
    const [saveChatName, setSaveChatName] = useState("")
    const [saveChatSelected, setSaveChatSelected] = useState("")
    const [mini, setMini] = useState(false)
    const [imageUploaded, setImageUploaded] = useState(null)

    const [_language, setLanguage] = useState(lang);
    const [submit, setSubmit] = useState(false);
    const [submitSaveChat, setSubmitSaveChat] = useState(false);

    useEffect(() => {
       if (submit){
        openAILocal(sendText, api, setSubmit, signOut, updateHistoric, ChatOpenAI, submit, mini)
       }
    }, [submit]);
    
    useEffect(() => {
        if (submitSaveChat){
            handleGetSaveChat()
        }
     }, [submitSaveChat]);


    const ChatOpenAI = async (config) => {

        
        if (submit){
            let sendT = sendText.trim()
            let returnT = returnText.trim()
            let image = imageUploaded
            setSendText('')
            setReturnText('')
            setImageUploaded(null)
            let messages = await createMessage(sendT, returnT, talk, setTalk, system, image);
            if (!messages){
                toast.error(<FormattedMessage id="The text entered is too large, please provide smaller text." />)
                setSubmit(false)
            }
            else {
                let send = JSON.stringify({text: sendT});
                

                let __hist = {
                    set: (result)=>{
                        if (saveChat !== null && saveChat !== 'New Chat' && saveChat !== '')
                        {
                            registerChat([...messages.filter((m)=>m.role !== 'system'),
                            {'role':'assistant', "content": result}], true)
                        }
                        
                        updateHistoric.set()
                    },
                    value: null
                }

                completions(api, messages, config, send, __hist, setReturnText, setSubmit, mini, submit)
            }
        }
    }

    const handleAPI = async () => {
        if (submit) { return }

        let isSendTextValid = false;

        if (sendText !== "") { isSendTextValid = true }
        else { toast.error(<FormattedMessage id="Provide a valid text" />) }

        if (isSendTextValid) {
            setSubmit(true);
        }
    };

    const  handleGetSaveChat = async () => {
        if (submitSaveChat) { return }
        setSubmitSaveChat(true);

        let result = await getSaveChat();


        if (result.type === 'success'){
            setSaveChat([
                { id: 0, name: "New Chat", system: "You are a helpful and creative assistant."}, 
                ...result.data])
        }
        else {
            if (result.data?.code === "token_not_valid") {
                signOut();
            }
        }
        
       setSubmitSaveChat(false);
    };

    
    const registerChat = async (  messages, noMessage=false ) =>{

        if (saveChatName ){
            let json = {
                user: 0,
                name: saveChatName,
                system: system !== '' ? system : "You are a helpful and creative assistant.",
                messages: messages
            }
    
            let result = await postSaveChat(json);
    
            if (result.type === 'success'){
                if (!noMessage){
                    toast.success(<FormattedMessage id="success" />) 
                }
            }else {
                if (result.data?.code === "token_not_valid") {
                    signOut();
                }
            }
        }
    }

    const handlePostSaveChat = async (noMessage=false) => {
        
        if (submitSaveChat) { return }

        setSubmitSaveChat(true);

        let sendT = sendText.trim()
        let returnT = returnText.trim()
        let messages = await createMessage(sendT, returnT, talk, setTalk, system);

        messages = messages?.filter(m => m.role !=='system' && m.content !== '')
        await registerChat(messages, noMessage)

        setSubmitSaveChat(false);
    };

    return {
        sendText: { value: sendText,  set: setSendText },
        returnText: { value: returnText,  set: setReturnText },
        language: { value: _language, set: setLanguage },
        talk: { value: talk, set: setTalk },
        saveChat: { value: saveChat, set: setSaveChat },
        saveChatName: { value: saveChatName, set: setSaveChatName },
        getSaveChat: { value: submitSaveChat, set: handleGetSaveChat },
        postSaveChat: { value: submitSaveChat, set: handlePostSaveChat },
        system: { value: system, set: setSystem },
        saveChatSelected: { value: saveChatSelected, set: setSaveChatSelected },
        imageUploaded: { value: imageUploaded, set: setImageUploaded },
        mini: { value: mini, set: setMini },
        cancel:   { value: submit,    set: setSubmit   },
        submit:   { value: submit,    set: handleAPI   },
    };
};

export default useChatLocalState;
