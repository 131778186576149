import Box from '@mui/material/Box';

import MyPanel from "./myPanel";


import { style } from "./styles";


const RenderCreateOptions = ({ options, selected }) => {
    const onClick = (clicked) => selected.set(clicked);

    return (
        <Box sx={style.optionRow}>
            {options?.map((option, key) => (
                <Box key={key} sx={{margin:'5px'}}>
                    <MyPanel width={220} height={160} selected={option.action === selected.value } icon={option.icon} onClick={() => onClick(option.action)} name={option.name} />
                </Box>
            ))}
        </Box>
    );
};

export default RenderCreateOptions;