import { scrollbarTheme } from '../../../assets/customThemes/scrollbarTheme';

export const style = {
    area:{
        margin: '10px',
        height: '85vh',
        overflowY: "auto", 
        ...scrollbarTheme,
        
    },
    areaTitle:{
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection:'row',
        width:'100%',
        
    },
    areaListItem:{
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection:'row',
        width:'100%',
        
    },
    areaActionName:{
        fontSize: '8pt',
        borderRadius: '10px',
        padding: '6px',
        minWidth: '90px',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'row',
    },
    title:{
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 0,
        margin: 0,
    },
    icon: {
        height: '18px',
    },
    icon2: {
        height: '27px',
        marginRight:'5px',
    },
    list:{
        overflow: 'auto',
        '&:-webkit-scrollbar': {
            width: '10px',
        },
        '&:-webkit-scrollbar-track': {
            backgroundColor: "#FFF",
        },
        '&:-webkit-scrollbar-thumb': {
            backgroundColor: "#FFF",
        },
        '&:-webkit-scrollbar-thumb:hover': {
            backgroundColor: "#FFF",
        },
    },
    listItemText:{
        fontSize: '14px',
        fontWeight: 'bold',
    },
    listItemData:{
        fontSize: '12px',
    },

    actionNameText:{
        fontSize: '11px',
    },
    modal:{
        padding:'10px',
    },
    modalText:{
        fontSize: '16px',
        fontWeight: 'bold',
    },
    modalTextReturn:{
        fontSize: '12px',
        fontStyle: 'italic',
        wordBreak: 'break-all',
    },
    modalTextArea:{
        paddingTop:'10px',
        paddingBottom:'10px',
    },
    optionsArea: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '5px',
        margin: '5px',
    },
    modalOptionTitle: {
        fontSize: '14px',
        fontWeight: 'bold',
        marginRight: '5px',
    },
    modalOptionDescription: {
        fontSize: '14px',
        fontStyle: 'italic',
    },
    modalOptionLine:{
        display:'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexDirection:'row',
        width:'100%',
    },
    modalButtonArea: {
        marginTop: '10px',
    },
    modalImageArea: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '5px',
        margin: '5px',
    },
    modalImage:{
        width: '150px',
        marginBottom: '10px'
    },
    modalImageCard:{
        display:'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexDirection:'column',
        width:'100%',
    }
}