import Box from '@mui/material/Box';
import MySelect from "../../mySelect";

import { style } from "./styles";
import { FormControl } from '@mui/material';
import MyInput from '../../myInput';

const RenderOptions = ({ selects, inputs }) => {
    return (
        <Box sx={style.selectArea}>
            {selects?.map((select, key) => (
                
                <MySelect
                    key={key} 
                    sx={style.select}
                    id={select.name}
                    label={select.title}
                    options={select.options}
                    value={select.action.value}
                    onChange={(e)=>select.action.set(e.target.value)}
                />
                
            ))}
            {inputs?.map((input, key) => (
                <FormControl sx={{backgroundColor:'white'}} key={key} fullWidth>
                    <MyInput 
                        type='text'
                        id={input.name}
                        label={input.title}
                        placeholder={input.placeholder ? input.placeholder : input.title}
                        required={false}
                        value={input.action.value}
                        onChange={(e)=>input.action.set(e.target.value)}
                    />
                </FormControl>
            ))}
        </Box>
    );
};

export default RenderOptions;