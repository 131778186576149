import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../services/changePassword";
import validEmail from "../utils/validEmail";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";

const useChangePasswordState = () => {
    let e = {
        email: undefined,
    };

    const [email, setEmail] = useState("");
    const [submit, setSubmit] = useState(false);
    const [errors, setErrors] = useState(e);

    const handleLogin = () => {
        if (submit) {
            return;
        }

        let isEmailValid = false;

        if (email !== "" && validEmail(email)) {
            isEmailValid = true;
        } else {
            setErrors({...e, email:true})
            toast.error(<FormattedMessage id="Provide a valid email" />);
        }

        if (isEmailValid) {
            setSubmit(true);
            changePassword(email.replace(" ", ""))
                .then(async (data) => {
                    toast(<FormattedMessage id={data.detail} />);
                    setSubmit(false);
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(
                            <FormattedMessage id="Connection failed to server" />
                        );
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }
                    setSubmit(false);
                });
        }
    };

    return {
        email: {
            value: email,
            set: setEmail,
        },
        errors: {
            value: errors,
            set: setErrors,
        },
        submit: {
            value: submit,
            set: handleLogin,
        },
    };
};

export default useChangePasswordState;
