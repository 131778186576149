import React, {useContext, useEffect} from "react";
import { AuthContext } from "../../../contexts/auth";
import useEnglishGrammarCheckLocalState from "../../../hooks/openAI/useEnglishGrammarCheckLocalState";
import useEnglishGrammarCheckState from "../../../hooks/openAI/useEnglishGrammarCheckState";




import FunctionOpenAi from "../functionOpenAI";

function EnglishGrammarCheck({ updateHistoric }) {
    //const action = useEnglishGrammarCheckState();
    const action = useEnglishGrammarCheckLocalState();
    const { historic } = useContext(AuthContext);

    useEffect(() => {
        if (historic.value){
            action.returnText.set("");
            action.sendText.set(historic.value.text);
            historic.set(null)
        }
        
    }, [historic.value]);

    let describes = [
        {
            title: "Write the text you would like to translate to English or verify the English grammar",
            placeholder: `(max 1000 characters)`,
            action: action.sendText,
        },
    ];

    let returnText = {
        action: action.returnText,
    };

    return (
        <>
            <FunctionOpenAi
                returnText={returnText}
                describes={describes}
                updateHistoric={updateHistoric}
                action={action}
                textCounter={true}
            />
            <br />
            <br />
            <br />
            <br />
        </>
    );
}

export default EnglishGrammarCheck;
