export const style = {
    area: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: '10px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',
          }
    },

}